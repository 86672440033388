// @vendors
import { RichText } from 'prismic-reactjs'
import { useState } from 'react'

export default function Principle({ info, current }) {
  const page = info.primary
  const [currentPrinciple] = useState(current + 1)

  return (
    <div className='lnt__principle' id={`principio-${currentPrinciple}`}>
      <div className='lnt__principle-message'>
        <a href={`#principio-${currentPrinciple}`} className='lnt__principle-message-title'>
          <div className='title__counter'>
            Principio
          </div>
          <h2 className='title__ppal sm c-dr'>
            <strong>{page.name[0].text}</strong>
          </h2>
        </a>
        <div className='text__enr-2'>
          <RichText render={page.description} />
        </div>
      </div>
      <div className='lnt__principle-image' style={{ backgroundImage: `url(${page.image.url})` }} />
    </div>
  )
}
