export function prismicDestinationToDestination(prismicDestination, link) {
  return {
    type: 'destinos',
    typeLabel: 'destino',
    department: prismicDestination.depto,
    title: prismicDestination.name,
    image: prismicDestination.thumb[0].image.url,
    imageAlt: prismicDestination.thumb[0].image.alt,
    link: link,
    caption: prismicDestination.depto,
    uid: prismicDestination.uid
  }
}

export function prismicDepartmentToDepartment(prismicDepartment, link) {
  return {
    type: 'departamentos',
    typeLabel: 'departamento',
    department: '',
    title: prismicDepartment.dpto_name[0].text,
    image: prismicDepartment.thumb[0].image.url,
    imageAlt: prismicDepartment.thumb[0].image.alt,
    link: link,
    caption: 'Departamento',
    uid: prismicDepartment.uid
  }
}

export function prismicNationalParkToNatinoalPark(prismicNationalPark, link) {
  return {
    type: 'pnn',
    typeLabel: 'Parque Nacional Natural',
    department: prismicNationalPark.deptos[0].name,
    title: typeof prismicNationalPark.name === 'string'
      ? prismicNationalPark.name
      : prismicNationalPark.name[0].text,
    image: prismicNationalPark.thumb[0].image.url,
    imageAlt: prismicNationalPark.thumb[0].image.alt,
    link: link,
    caption: 'Parque Nacional Natural',
    uid: prismicNationalPark.uid
  }
}
