// @vendors
import { useCallback, useEffect, useState } from "react"
import { useGoogleLogin, useGoogleLogout } from 'react-google-login'
import { notification } from 'antd'

// @hooks
import useLocalStorage from 'hooks/useLocalStorage'

// @api
import {
  getUserInfoByToken,
  manualUserLogin,
  registerNewUser,
  registerNewUserWithSocialNetwork,
  updateUser
} from 'api/user'

// @constants
import {
  USER_INFO_LOCAL_STORAGE_KEY,
  USER_REGISTER_TYPE,
  SOCIAL_USER_PICTURE_LOCAL_STORAGE_KEY
} from 'constants/user'

export default function useAuth(modalsHandlers) {
  const {
    info: accessToken,
    removeInfo: removeAccessToken,
    setInfo: setAccessToken
  } = useLocalStorage(USER_INFO_LOCAL_STORAGE_KEY)
  const {
    info: socialPicture,
    removeInfo: removeSocialPicture,
    setInfo: setSocialPicture
  } = useLocalStorage(SOCIAL_USER_PICTURE_LOCAL_STORAGE_KEY)
  const { onCloseAuthForm } = modalsHandlers
  const [user, setUser] = useState(null)
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const onSuccessGoogleLogin = useCallback(async(response, callback = () => { }) => {
    if (!response.error) {
      setIsLoggingIn(true)
      const { error, result } = await registerNewUserWithSocialNetwork({
        socialToken: response.tokenId,
        email: response.profileObj.email,
        picture: response.profileObj.imageUrl,
        source: 'google'
      })

      if (error) {
        notification.open({
          message: '',
          description: 'Error iniciando sesión con Google',
          placement: 'bottomLeft'
        })
      } else {
        const updatedUser = {
          accessToken: result.access_token,
          birthday: result.birthday,
          cookies: result.cookies,
          email: response.profileObj.email,
          fullRecord: result.fullRecord,
          gender: result.gender,
          firstName: result.firstName,
          lastName: result.lastName,
          nationality: result.nationality,
          picture: result.picture || response.profileObj.imageUrl,
          source: 'google'
        }

        setUser({ ...updatedUser })
        setAccessToken(result.access_token)
        setSocialPicture(response.profileObj.imageUrl)
        callback()
        onCloseAuthForm()
      }
      setIsLoggingIn(false)
    }
  }, [])

  const { signIn: loginWithGoogle } = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    cookiePolicy: 'single_host_origin',
    isSignedIn: false,
    onFailure: () => { }
  })

  const { signOut: logoutGoogle } = useGoogleLogout({
    onLogoutSuccess: () => { },
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    cookiePolicy: 'single_host_origin'
  })

  const loginWithFacebook = useCallback(async(response, callback = () => { }) => {
    try {
      setIsLoggingIn(true)
      const { error, result } = await registerNewUserWithSocialNetwork('facebook', {
        socialToken: response.accessToken,
        email: response.email,
        picture: response.picture.data.url,
        source: 'facebook'
      })

      if (!error) {
        const updatedUser = {
          accessToken: result.access_token,
          cookies: result.cookies,
          firstName: response.name,
          email: response.email,
          fullRecord: result.fullRecord,
          picture: result.picture || response.picture.data.url,
          source: 'facebook',
          gender: result.gender
        }
        setUser({ ...updatedUser })
        setAccessToken(result.access_token)
        setSocialPicture(response.picture.data.url)
        callback()
        onCloseAuthForm()
      } else {
        notification.open({
          message: '',
          description: 'Error iniciando sesión con Facebook',
          placement: 'bottomLeft'
        })
      }
      setIsLoggingIn(false)
    } catch (error) {
      console.warn('error login with facebook', error)
    }
  }, [])

  const manualLogin = useCallback(async(email, password) => {
    setIsLoggingIn(true)
    const { error, result } = await manualUserLogin(email, password)

    if (!error) {
      if (result.Item.source === USER_REGISTER_TYPE.MANUAL && result?.access_token) {
        const loggedInUser = {
          ...result.Item,
          accessToken: result?.access_token,
          fullRecord: true
        }
        setUser({ ...loggedInUser })
        setAccessToken(result?.access_token)
      }
    }

    setIsLoggingIn(false)

    return { error, result }
  }, [user])

  const getUserByToken = useCallback(async() => {
    if (accessToken) {
      const { error, result: userFromToken } = await getUserInfoByToken(accessToken)

      if (!error) {
        const mappedUser = {
          ...userFromToken.Item,
          accessToken: userFromToken.access_token || userFromToken.accessToken,
          picture: userFromToken.Item.picture || socialPicture
        }
        setUser({ ...mappedUser })
        setAccessToken(userFromToken.access_token)

        if (userFromToken.Item.picture) {
          removeSocialPicture()
        }
      } else {
        notification.open({
          message: '',
          description: 'Error iniciando sesión',
          placement: 'bottomLeft'
        })
      }
    }
  }, [accessToken])

  const logout = () => {
    setUser(null)
    removeAccessToken()

    if (user?.source === USER_REGISTER_TYPE.GOOGLE) {
      logoutGoogle()
    }
  }

  const registerUser = useCallback(async(userInfo) => {
    const { error, result } = await registerNewUser(userInfo)

    if (!error) {
      delete userInfo.password
      setAccessToken(result.accessToken)
      setUser({ ...userInfo })
    }

    return { error, result }
  }, [])

  const onUpdateUser = async(updatedData, successCallback = () => {}, errorCallback = () => {}) => {
    const { accessToken, email } = user
    const { error } = await updateUser(accessToken, email, { ...updatedData, fullRecord: true })

    if (error) {
      if (!errorCallback) {
        notification.open({
          message: 'Error',
          description: 'Error completando registro',
          placement: 'bottomLeft'
        })
      }
      errorCallback()
    } else {
      successCallback()
      setUser({ ...user, ...updatedData, fullRecord: true })
    }

    return { error }
  }

  useEffect(() => {
    getUserByToken()
  }, [])

  return {
    isLoggingIn,
    accessToken,
    user,
    setUser,
    logout,
    loginWithGoogle,
    loginWithFacebook,
    manualLogin,
    getUserByToken,
    registerUser,
    updateUser: onUpdateUser
  }
}