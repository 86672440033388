// @vendors
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'
import { Skeleton } from 'antd'

// @components
import Principle from './principle'

// @images
import SharksSVG from 'components/icons/svg/sharks'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle'

// @constants
import { DEFAULT } from 'constants/default'

// @styles
import './styles.scss'

const SECTIONS = {
  principio: Principle
}

export default function LNT() {
  const { info, loading } = usePrismicQuerySingle('clientPGS', 'lnt')

  return (
    <>
      <Helmet>
        <title>No Deje Rastro {DEFAULT.site.name}</title>
      </Helmet>
      {loading
        ? (
          <section className='sec__default sec__default-basic'>
            <div className='container'>
              <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
            </div>
          </section>
        )
        : (
          <>
            <section className='sec__default sec__default-basic sec__default-ndr'>
              <div className='container'>
                <h1 className='title__ppal md'>
                  <strong>{RichText.asText(info.page.title)}</strong>
                </h1>
                <div className='block__infographic side-right'>
                  <SharksSVG />
                  <div className='text__enr'>
                    <RichText render={info.page.message} />
                    <img src={info.page.logo.url} alt={info.page.logo.alt} className='atmosphere-img' />
                  </div>
                </div>
                <div className='lnt__principles-list'>
                  {
                    (info.slices || []).map((item, index) => {
                      const SelectedSlice = SECTIONS[item.slice_type]

                      return (
                        <SelectedSlice
                          info={item}
                          current={index}
                          key={`principle-item-${item.slice_type}-${index}`}
                        />
                      )
                    })
                  }
                </div>
              </div>
            </section>
          </>
        )}
    </>
  )
}
