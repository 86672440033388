// @vendors
import { useRef } from 'react'
import { Button, Form, Input, Typography } from 'antd'
import { Link } from 'react-router-dom'

// @constants
import { ROUTES } from 'constants/routes'
import { EMAIL_REGEX } from 'constants/format'

// @hooks
import useFetch from 'hooks/useFetch'

// @api
import { sendInfoEmail } from 'api/notifications'

export default function SuggestionsForm({ type }) {
  const ref = useRef()
  const { refetch, loading } = useFetch(
    sendInfoEmail,
    {
      errorMessage: 'Error enviando correo',
      successMessage: 'Correo enviado correctamente',
      onSuccess: () => {
        ref.current.resetFields()
      }
    }
  )

  const onFinish = (values) => {
    const { email, ...replace } = values
    refetch([email, { ...replace, form_type: type }, 'support'])
  }

  return (
    <Form layout='vertical' onFinish={onFinish} autoComplete="" ref={ref}>
      <Form.Item
        label='Nombre completo'
        name='fullname'
        rules={[{
          required: true,
          message: 'Este campo es obligatorio'
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Correo electrónico'
        name='email'
        type="email"
        rules={[{
          required: true,
          message: 'Este campo es obligatorio'
        }, {
          pattern: EMAIL_REGEX,
          message: 'Formato de correo inválido'
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Asunto'
        name='subject'
        rules={[{
          required: true,
          message: 'Este campo es obligatorio'
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Mensaje'
        name='message'
        help='Ingresa los detalles de tu solicitud.'
        rules={[{
          required: true,
          message: 'Este campo es obligatorio'
        }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Typography.Text
        type='secondary'
        style={{ margin: '-10px 0 20px', display: 'block' }}
      >
        Al dar clic en <strong>Enviar</strong>, estás aceptando los <Link to={ROUTES.LEGALS.TERMS} target='_blank'>Términos y condiciones</Link> y la <Link to={ROUTES.LEGALS.PRIVACY} target='_blank'>Política de privacidad</Link> de Noomada.
      </Typography.Text>

      <Form.Item className='actions ant-form-item--last'>
        <Button className='solid c-1' htmlType="submit" loading={loading}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
  )
}
