// @vendors
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

// @utils
import { client, clientPNN } from 'utils/prismic'

const initialState = {
  dest_photos: [],
  loading: true
}

const clients = {
  client,
  clientPNN
}

export default function useDestinationPage(client, source, uid) {
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [destinationPageInfo, setDestinationPageInfo] = useState(() => ({ ...initialState }))
  const params = useParams()
  const location = useLocation()

  const getDestinationPageInfo = useCallback(() => {
    clients[client].getByUID(source, params[uid])
      .then(({ data, uid, id }) => {
        setDestinationPageInfo({ ...data, uid, id })
        setLoading(false)
      })
      .catch(() => {
        setNotFound(true)
        setLoading(false)
      })
  }, [params, source])

  useEffect(() => {
    getDestinationPageInfo()
  }, [params, source])

  useEffect(() => {
    setLoading(true)
    setNotFound(false)
  }, [location.pathname])

  return {
    destinationPageInfo,
    loading,
    notFound,
    refetch: getDestinationPageInfo
  }
}
