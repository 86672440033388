// @vendors
import { useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Skeleton } from 'antd'

// @components
import CardDestination from 'components/card-destination'

// @hooks
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags'

// @utils
import { convertToSlug } from 'utils/format'

export default function NationalParks({ mapDestinationItems, name }) {
  const { info, loading, refetch } = usePrismicQueryByTags(
    'clientPNN',
    'pnn',
    [convertToSlug(name)],
    [''],
    20
  )

  useEffect(() => {
    refetch([convertToSlug(name)])
  }, [name])

  if (!info.length) {
    return null
  }

  return (
    <>
      <h2 className='subtitle__ppal xs c-dr'>
        Parques Nacionales Naturales
      </h2>
      {loading &&
        <Skeleton
          className='ant-skeleton-cards'
          paragraph={{ rows: 5 }}
          title=''
          active
        />}
      <ScrollContainer className='list__items-home'>
        {!loading &&
          mapDestinationItems(info).map((item, k) => {
            return <CardDestination destination={item} key={k} type='' />
          }
          )
        }
      </ScrollContainer>
    </>
  )
}
