export default function ManBeach() {
  return (
    <svg
      id="prefix__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 500 500"
      xmlSpace="preserve"
    >
      <style>
        {
          '.prefix__st0{opacity:.7;fill:#fff;enable-background:new}.prefix__st1{fill:#e7edf4}.prefix__st2,.prefix__st3,.prefix__st5,.prefix__st6{fill:none;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st3,.prefix__st5,.prefix__st6{fill:#bfbfbf}.prefix__st5,.prefix__st6{fill:#8e9ca7}.prefix__st6{fill:#757575}.prefix__st7{fill:#92e3a9}.prefix__st9{opacity:.6;fill:#fff;enable-background:new}.prefix__st15{fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st20{opacity:.2;fill-opacity:.7;enable-background:new}.prefix__st22{fill:#92e3a9;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st24{fill:#263238}.prefix__st27{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round}'
        }
      </style>
      <g id="prefix__freepik--background-simple--inject-319">
        <path
          className="prefix__st0"
          d="M67.9 167.1s-27.9 72 11.3 144.5 119.9 110.8 184.5 143.6 131.7 16.8 163.5-36.8-11.8-91.9-11.9-168.4 11.5-96.7-30-161.4-143.1-78.9-219.9-31-97.5 109.5-97.5 109.5z"
        />
        <path
          className="prefix__st1"
          d="M313.6 436.8c79 0 157-65.5 152.2-144.3-3.5-57.5-18.5-117.7-50.4-163.5-74.4-106.7-152.8-92.7-174.3-7.4s-134.4 22.6-191.2 114c-5.8 9.4-10.1 19.7-13 30.3-23.8 86.6 49.9 169.8 139.7 169.8l137 1.1z"
        />
        <path
          className="prefix__st0"
          d="M313.6 436.8c79 0 157-65.5 152.2-144.3-3.5-57.5-18.5-117.7-50.4-163.5-74.4-106.7-152.8-92.7-174.3-7.4s-134.4 22.6-191.2 114c-5.8 9.4-10.1 19.7-13 30.3-23.8 86.6 49.9 169.8 139.7 169.8l137 1.1z"
        />
      </g>
      <g id="prefix__freepik--Beach--inject-319">
        <path
          className="prefix__st2"
          d="M43.7 373.5H468M377.3 386.5c-3.9.1-7.9.3-11.8.6M439.6 385.4c-4.5.5-9.1.2-13.5-.9-4.2-.9-10 1.2-14.4 1.7-9.9 1.2-19.8 1.3-29.7.2M454.4 385.9c-3.4 0-6.5-1.2-10.1-.9M404.1 396.5c2.8.2 5.7.6 8.5 1.1M325.3 395.8c12.5-1 24.6-.3 37 .8 12.2 1.1 24.4-.6 36.6-.4M291.6 393.6c9.6-.2 18.6 1.2 28.1 2M184.9 389.2h-3.4M239.6 389.1c-16.7-.1-33.3-.1-50 .1M277.4 387.1c-11.1-.7-22.1.9-33.1 1.8M147 389.8h14.8M43.5 388.2C50 386 57 386 64 387c5 1 10 4 16 3 7-2 14-3 21 0 14-2 28 1 41.6-.2M219.6 399.3c3.9.1 7.7.1 11.6 0M130.8 397.1c16.2-1.1 31.2.9 47.2-.1 12 0 24 1 35.5 2M262.4 378H259M317.2 377.9c-16.7-.1-33.3-.1-50 .1M354.9 376c-11.1-.7-22.1.9-33.1 1.8M224.5 378.6h14.8M297.1 388.1c3.9.1 7.7.1 11.6 0M244.3 411.8l5.4.5M213.8 411.3c8.6.2 17.2.3 25.8.1M180.4 410.4c9.3.1 18.6.5 27.9.8M166.9 410.4h8.1M115 396.8l11.8.2M171.2 382.2c4.3.3 8.2-.5 11.9 0s8.5-.7 9.8 0M292.2 402.1c13.8.9 28.8.9 42.8 1.2M275.5 403.3c4.1-.3 8.1-.6 12.2-.9M300.7 419.2c-13.8-.9-28.8-.9-42.8-1.2M317.5 418c-4.1.3-8.1.6-12.2.9"
        />
        <path
          className="prefix__st2"
          d="M479.2 393.6s-15.4.8-19 5.4-14.6 5-34 8.4-13.5 7.5-32.6 11.5-58 4.1-64.7 6.5-44.3 3.4-76.2 3.4-105.4 0-109.8-5.7-13.4-2.6-25.6-5.7-47.1-2.4-51.5-5.7-3.6-10-17-9.6c-7.5.3-7.1-8-12.3-8.4-4.1-.2-8.2-.2-12.3 0M210.6 372.6s48-5.2 53.9-7.9 28.9-23.4 32.6-23.8 18.6 2 20.1 3.2 20.5-17.3 24.2-20.9 13.2-10.6 16.4-8.7 4.9 5.5 9.6 8.7 23.9 6.1 23.9 6.1 2.1 5.7 5.5 7.3 18.9 4.9 18.4 6.8-.1 10.2 8.3 12.6 18.3 7.8 20.8 8.6 12 8.1 19.1 8.8"
        />
        <path
          className="prefix__st2"
          d="M277.4 353.9s-14.1-7.4-19.5-9.7-14.4 10.7-18.6 9.7-14.9-3.2-18.4 0-16.6 12.3-18.2 10.8-44.6-3.3-48.8-3.8-14.3 8.7-18 6.3-29.4-26.7-36-24.9-27.2 15.3-29.6 16.9-6.2 1.2-8.8 1.7-11.5 7.5-13.9 8.1M283.7 183.6s-3.1-10.8-24.1-10.9-33.3 18.9-33.3 18.9-8.8-8.3-19.4-2.8-10.6 8.6-10.6 8.6H109M350.5 283.4s3.1-10.8 24.1-10.9 33.3 18.9 33.3 18.9 8.8-8.3 19.4-2.8 10.6 8.6 10.6 8.6h27.3M273.1 256.8h-59.6s4.2-8-5.3-17.5-19.1-3.2-19.1-3.2-9.3-28.9-36.5-25.5-30.1 27.1-30.1 27.1-5.8-5.8-17.7-2-13.6 18.5-10.7 24.7c0 0-13.5 2.4-14.4 12.8 0 0-.8-9.2-13.5-9.5s-15.8 14-15.8 14H36.2"
        />
      </g>
      <g id="prefix__freepik--Map--inject-319">
        <path
          className="prefix__st3"
          d="M356.6 84.9l-2.6 4.9c-.6 1.1-1.7 1.7-2.9 1.7h-44c-1.2 0-2.3-.7-2.9-1.7l-2.6-4.9c-.6-1.1-1.7-1.7-2.9-1.7H258c-1.8 0-3.3 1.5-3.3 3.3v263.1c0 1.8 1.5 3.3 3.3 3.3h142.5c1.8 0 3.3-1.5 3.3-3.3V86.5c0-1.8-1.5-3.3-3.3-3.3h-40.7c-1.5 0-2.6.7-3.2 1.7z"
        />
        <path className="prefix__st1" d="M258.9 109.2h140.3V265H258.9z" />
        <defs>
          <path id="prefix__SVGID_1_" d="M258.9 109.2h140.3V265H258.9z" />
        </defs>
        <clipPath id="prefix__SVGID_2_">
          <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_2_)">
          <path className="prefix__st0" d="M258.9 109.2h140.3V265H258.9z" />
        </g>
        <path className="prefix__st2" d="M258.9 109.2h140.3V265H258.9z" />
        <path
          className="prefix__st5"
          d="M399.1 123.5c-1.8 1.9-3.2 3.9-4.8 3.6-3.2-.5-5.5 1-7.3 0s-2.3 4-2.3 4l-3.2 2.8-2.7 1s-1.4 2.5 0 3.8 2.9 3.6-.6 3.6h-5.8s-1.8-3.5-4.1-3.6-3.2-4.8-3.2-4.8V130l-8.2 3.9s-5 1.2-5 2.9v6.1l-2.3 4.4 7.8 1.3 2.8-4.4s3.2-1.1 3.7 1c.3 3 .5 6 .5 8.9-1.1 1.8-2.1 3.7-2.8 5.6 3.2 3.1 2.3 3.7 4.1 4.3s2.7 3.2 2.7 4.6v1.4s-8.7.7-2.7 4.7c1.8 1.2 2.5 1.7 2.7 1.9-1.2-.6 0 .1.1.1v-.1l.5.3c1 .5 1.9 1.1 2.7 1.8 0-.1.4-.1 2.3.1 2.8.4 4.6-6 7.8-1.9s5 6.6 6.4 7.9l1.4 1.3 9.1 2.7.6.1-.2-65.4zM380 215.1c-2.3-4.6 0 0-1.4.8s-3.7 1.5-6.4-.7c0 0 1.4-1.9 0-5.4s-3.2-.2-5.5 0c-1.6.1-3.2-.2-4.6-1-.9-.4-1.4-1.9-4.1-2.7s-1.8-.1-6 5.7h-2.3s-.9 2.9-2.3 3.5-4.6-.6-5.5-2.7 5-2.9 9.6-4.3-2.3-6-2.3-6l-.5-4-1.8-1.9c-1.8-1.8-5.5-.9-6.9-5.1s1.4-1.8-.9-4.3c-1.2-1.4-2.7-2.6-4.3-3.6 0 0-11.3 2.9-14.9 0s-4.1.9-4.1.9l-4.1 5.5v6.5h-9.2l-3.2-4.3s-4.1-8-1.4-8.6 3.7 0 3.7 0l3.2-3.6.1.1c.1.1 4 .4 4.5-.9s-1.4-5.6-1.4-5.6l-5.5-4.1-3.2-1.3c-1.4-.6-2.8-1.2-4.1-1.9l-1.8-1.6c-1.8-1.6-3.2 2.1-4.6 2.2s-6-4.3-7.3-7 5-.4 6.9-1.7 3.2-3.3 3.2-9.6-2.3-.5-4.1.4-4.6.9-8.2-.4-.9-1.9-3.2-1.8-12.4 1.8-12.4 1.8-2.4 3.1-4.8 6.1V265H271c0-.1 0 .1 0 0 0-2.7 4.6-2.8 6-4.8s4.1-5.6 4.1-5.6v-2.4l.9-3.5c.9-3.5 3.7 1.2 6.9 1.4s6-.2 6.4-2.4 0 0 0-5 0-1.9 3.2-2l6 2c2.5.9 5.1 1.6 7.8 2.1 4.1.8 1.8 3.9 1.8 3.9l6 1.9h8.7l3.2 3.6 3 .5h5.7l2.3-4c1.1-.8 2.4-1.5 3.7-1.9 1.4-.3 5.5.7 5.5.7l2.9-.1c2.9-.1 2.6-4.4 2.6-4.4l1.8-2.3.9-2 4.1-5.4 2.8-2.8c2.8-2.8 1.3-4 1.3-4l-1.3-3.1c-1.3-3.1 2.7-.4 2.7-.4 2.7.8 5.2 1.8 7.8 2.9 5 2.1 4.1-1.7 4.6-2.9s-.2-5.3-2.4-9.9z"
        />
        <path
          className="prefix__st5"
          d="M305 154.7c-4.7-6.7 7.2-6.2 8.6-5.4s6 0 6 0v-2.8s3.2.1 4.6 0 3.7-2.6 4.1.2c.2 2.2.2 4.3 0 6.5 0 1.4-1 3.5.2 4.8s-4.3 2.8-4.3 4.3 1.8 9.1-1.8 4.4-2.8-6.3-5.5-7.6l-2.8-1.3c-.1.1-4 4.2-9.1-3.1zM282.5 121.3l-.9-1.8h-3s-4.7-5.5-6.1-6.5c-1.1-.8 2.8-1.2 7.2-3.8h-20.8V137c.3.4.7.7 1.2.9 1.8.5 6.4-.1 9.1-.8s0-2 0-5.7 3 .1 3 .1l3-1.5c1.3-1.1 2.2-2.5 2.7-4.2.9-2.8 2 0 2 0l4 1.2s3.6.2 3.6-1.2-5-4.5-5-4.5zM399.1 243c-.4 1.2-.5 3.2-1.8 4-2.3 1.4 0 0-2.3-.1s-3.7 2.4-2.5 5.9l-3.5.5V251l-5.9-1.4-.8.2c-.8.2-2.5 4.4-2.9 7.2-3 .2-6.1.2-9.1 0-4.6-.4 0 2.4 0 2.4l7.3 1.2 2.8-1.2c2.7-1.2 5 3.7 5 3.7s2.3-4.8 3.7-6.2 4.6.6 8.2.2l1.8-1V243z"
        />
        <path
          className="prefix__st6"
          d="M305 187.7c-2.8-2.8-7.3-2.8-10.2 0-2.8 2.8-2.8 7.3 0 10.2l5.1 5.1 5.1-5.1c2.8-2.9 2.9-7.4 0-10.2z"
        />
        <path
          className="prefix__st7"
          d="M303.1 195.9c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.5 0 6.3z"
        />
        <defs>
          <path
            id="prefix__SVGID_3_"
            d="M303.1 195.9c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.5 0 6.3z"
          />
        </defs>
        <clipPath id="prefix__SVGID_4_">
          <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_4_)">
          <path
            className="prefix__st9"
            d="M303.1 195.9c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.5 0 6.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M303.1 195.9c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.5 0 6.3z"
        />
        <path className="prefix__st2" d="M297.9 192.8l1.6 1.4 2.3-3.1" />
        <path
          className="prefix__st6"
          d="M278.2 114.4c-2.8-2.8-7.3-2.8-10.2 0-2.8 2.8-2.8 7.3 0 10.2l5.1 5.1 5.1-5.1c2.8-2.9 2.8-7.4 0-10.2z"
        />
        <path
          className="prefix__st7"
          d="M276.3 122.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.7 1.7 1.7 4.5 0 6.3z"
        />
        <defs>
          <path
            id="prefix__SVGID_5_"
            d="M276.3 122.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.7 1.7 1.7 4.5 0 6.3z"
          />
        </defs>
        <clipPath id="prefix__SVGID_6_">
          <use xlinkHref="#prefix__SVGID_5_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_6_)">
          <path
            className="prefix__st9"
            d="M276.3 122.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.7 1.7 1.7 4.5 0 6.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M276.3 122.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.7 1.7 1.7 4.5 0 6.3z"
        />
        <path className="prefix__st2" d="M271.2 119.4l1.5 1.5 2.3-3.1" />
        <path
          className="prefix__st6"
          d="M327.1 141.1c-2.8-2.8-7.3-2.8-10.1 0s-2.8 7.3 0 10.2l5.1 5.1 5.1-5.1c2.7-2.9 2.7-7.4-.1-10.2z"
        />
        <path
          className="prefix__st7"
          d="M325.2 149.3c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.6 0 6.3z"
        />
        <defs>
          <path
            id="prefix__SVGID_7_"
            d="M325.2 149.3c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.6 0 6.3z"
          />
        </defs>
        <clipPath id="prefix__SVGID_8_">
          <use xlinkHref="#prefix__SVGID_7_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_8_)">
          <path
            className="prefix__st9"
            d="M325.2 149.3c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.6 0 6.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M325.2 149.3c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.7 4.6 0 6.3z"
        />
        <path className="prefix__st2" d="M320 146.1l1.6 1.5 2.3-3.1" />
        <path
          className="prefix__st6"
          d="M352.9 228.9c-2.8-2.8-7.3-2.8-10.2 0-2.8 2.8-2.8 7.3 0 10.2l5.1 5.1 5.1-5.1c2.8-2.8 2.9-7.3.2-10.1-.1-.1-.1-.2-.2-.1z"
        />
        <path
          className="prefix__st7"
          d="M350.9 237.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.8 4.5 0 6.3z"
        />
        <defs>
          <path
            id="prefix__SVGID_9_"
            d="M350.9 237.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.8 4.5 0 6.3z"
          />
        </defs>
        <clipPath id="prefix__SVGID_10_">
          <use xlinkHref="#prefix__SVGID_9_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_10_)">
          <path
            className="prefix__st9"
            d="M350.9 237.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.8 4.5 0 6.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M350.9 237.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.7 1.8 4.5 0 6.3z"
        />
        <path className="prefix__st2" d="M345.8 233.9l1.5 1.5 2.3-3.1" />
        <path
          className="prefix__st6"
          d="M376.7 143.9c-2.8-2.8-7.4-2.8-10.2 0-2.8 2.8-2.8 7.4 0 10.2l5.1 5.1 5.1-5.1c2.8-2.9 2.8-7.4 0-10.2z"
        />
        <path
          className="prefix__st7"
          d="M374.8 152.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.8 1.7 4.6 0 6.3z"
        />
        <defs>
          <path
            id="prefix__SVGID_11_"
            d="M374.8 152.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.8 1.7 4.6 0 6.3z"
          />
        </defs>
        <clipPath id="prefix__SVGID_12_">
          <use xlinkHref="#prefix__SVGID_11_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_12_)">
          <path
            className="prefix__st9"
            d="M374.8 152.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.8 1.7 4.6 0 6.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M374.8 152.1c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3 1.7-1.7 4.5-1.7 6.3 0 1.7 1.8 1.7 4.6 0 6.3z"
        />
        <path className="prefix__st2" d="M369.6 149l1.6 1.4 2.3-3.1" />
        <path
          className="prefix__st6"
          d="M391.1 239.4c-2.8-2.8-7.3-2.8-10.2 0-2.8 2.8-2.8 7.3 0 10.2l5.1 5.1 5.1-5.1c2.8-2.8 2.9-7.3.2-10.1-.1-.1-.1-.2-.2-.1z"
        />
        <path
          className="prefix__st7"
          d="M389.1 247.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.8 1.7 1.8 4.5 0 6.3z"
        />
        <defs>
          <path
            id="prefix__SVGID_13_"
            d="M389.1 247.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.8 1.7 1.8 4.5 0 6.3z"
          />
        </defs>
        <clipPath id="prefix__SVGID_14_">
          <use xlinkHref="#prefix__SVGID_13_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_14_)">
          <path
            className="prefix__st9"
            d="M389.1 247.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.8 1.7 1.8 4.5 0 6.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M389.1 247.6c-1.7 1.7-4.5 1.7-6.3 0-1.7-1.7-1.7-4.5 0-6.3s4.5-1.7 6.3 0c1.8 1.7 1.8 4.5 0 6.3z"
        />
        <path className="prefix__st2" d="M384 244.4l1.6 1.5 2.3-3.1" />
        <path
          className="prefix__st15"
          d="M260.5 91.9h11.7v5h-11.7zM272.2 92.7h1.8V96h-1.8zM383.3 95.1h2.1v1.8h-2.1zM387.3 93.3h2.1v3.6h-2.1zM391.4 91.4h2.1v5.4h-2.1zM395.4 89.6h2.1v7.3h-2.1zM267.5 284.8h47.8c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4h-47.8c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1.1 2.4 2.4 2.4zM391.7 290H266.3c-.7-.1-1.3.4-1.3 1.1s.4 1.3 1.1 1.3H391.7c.7.1 1.3-.4 1.3-1.1s-.4-1.3-1.1-1.3h-.2zM391.7 296.5H266.3c-.7-.1-1.3.4-1.3 1.1s.4 1.3 1.1 1.3H391.7c.7.1 1.3-.4 1.3-1.1s-.4-1.3-1.1-1.3h-.2zM361.7 303h-95.4c-.7-.1-1.3.4-1.3 1.1s.4 1.3 1.1 1.3H361.7c.7.1 1.3-.4 1.3-1.1s-.4-1.3-1.1-1.3h-.2zM364.5 333.9h8.9v8.9h-8.9zM389 342.8c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5-4.5 2-4.5 4.5 2 4.5 4.5 4.5z"
        />
        <path className="prefix__st6" d="M343.5 342.8h11l-5.5-8.9z" />
        <path fill="#fff" d="M264.7 209.4h46v50.1h-46z" />
        <g>
          <defs>
            <path id="prefix__SVGID_15_" d="M264.7 209.4h46v50.1h-46z" />
          </defs>
          <clipPath id="prefix__SVGID_16_">
            <use xlinkHref="#prefix__SVGID_15_" overflow="visible" />
          </clipPath>
          <g opacity={0.4} clipPath="url(#prefix__SVGID_16_)">
            <path
              className="prefix__st7"
              d="M310.7 213.1c-.6.6-1.1 1.3-1.6 1.2-1-.2-1.8.3-2.4 0s-.8 1.3-.8 1.3l-1 .9-.9.3s-.5.8 0 1.2 1 1.2-.2 1.2H302s-.6-1.1-1.4-1.2-1-1.6-1-1.6v-1.3l-2.7 1.3s-1.6.4-1.6.9v2l-.8 1.4 2.5.4.9-1.4s1-.4 1.2.3c.1 1 .2 2 .1 2.9-.4.6-.7 1.2-.9 1.9 1 1 .8 1.2 1.4 1.4.6.3.9.9.9 1.5v.5s-2.9.2-.9 1.5c.6.4.8.6.9.6-.4-.2 0 0 0 0l.1.1.9.6s.1-.1.8 0c.9.1 1.5-2 2.6-.6.6.9 1.3 1.8 2.1 2.6l.5.4 3 .9h.2l-.1-21.2zM268.7 259.3c0-1 1.5-.7 2-1.4s1.4-1.8 1.4-1.8v-.8l.3-1.1c.3-1.1 1.2.4 2.2.4s2-.1 2.1-.8 0 0 0-1.6 0-.6 1.1-.7l2 .7c.8.3 1.7.5 2.5.7 1.4.3.6 1.3.6 1.3l2 .6h2.9l1 1.2 1 .2h1.9l.8-1.3c.4-.3.8-.5 1.2-.6.6 0 1.2.1 1.8.2h1c1 0 .8-1.4.8-1.4l.6-.8.3-.7 1.4-1.8.9-.9c.9-.9.4-1.3.4-1.3l-.4-1c-.4-1 .9-.1.9-.1.9.3 1.7.6 2.5 1 1.7.7 1.4-.6 1.5-1 .1-1.1-.2-2.2-.8-3.2-.8-1.5 0 0-.5.3-.7.4-1.5.3-2.1-.2.3-.6.3-1.2 0-1.8-.5-1.1-1-.1-1.8 0-.5 0-1-.1-1.5-.3-.3-.1-.5-.6-1.4-.9s-.6 0-2 1.9h-.8s-.3 1-.8 1.1c-.7.1-1.5-.2-1.8-.9-.3-.7 1.6-.9 3.1-1.4s-.8-2-.8-2l-.1-1.3-.6-.6c-.6-.6-1.8-.3-2.2-1.7s.5-.6-.3-1.4c-.4-.5-.9-.9-1.4-1.2 0 0-3.7.9-4.9 0s-1.4.3-1.4.3l-1.4 1.8v2.1h-3l-1-1.4s-1.4-2.6-.5-2.8 1.2 0 1.2 0l1-1.2s1.3.1 1.5-.3-.5-1.9-.5-1.9l-1.8-1.4-1.1-.4c-1-.4-1.4-.6-1.4-.6l-.6-.5c-.6-.5-1 .7-1.5.7s-2-1.4-2.4-2.3 1.6-.1 2.2-.6 1-1.1 1-3.2-.8-.2-1.4.1c-.9.3-1.9.3-2.7-.1-1.2-.4-.3-.6-1-.6s-4 .6-4 .6l-1.6 2v36.1h4.4z"
            />
            <path
              className="prefix__st7"
              d="M279.8 223.3c-1.5-2.2 2.4-2 2.8-1.8.7.1 1.3.1 2 0v-.9h1.5c.5 0 1.2-.9 1.4.1.1.7.1 1.4 0 2.1 0 .5-.3 1.1.1 1.6s-1.4.9-1.4 1.4.6 3-.6 1.4-.9-2.1-1.8-2.5l-.9-.4c-.1.1-1.4 1.4-3.1-1zM272.4 212.3l-.3-.6h-1c-.6-.7-1.3-1.5-2-2.1.8.2 1.6.1 2.4-.2h-6.8v8.1c.1.1.2.2.4.3 1 .1 2 0 3-.2.9-.2 0-.7 0-1.9s1 0 1 0l1-.5c.4-.4.7-.8.9-1.4.3-.9.6 0 .6 0l1.3.4s1.2.1 1.2-.4-1.7-1.5-1.7-1.5zM310.7 252.3c-.1.4-.2 1-.6 1.3-.8.4 0 0-.8 0s-1.2.8-.8 1.9l-1.1.2v-.7l-1.9-.4-.2.1c-.5.7-.9 1.5-1 2.4-1 .1-2 .1-3 0-1.5-.1 0 .8 0 .8l2.4.4.9-.4c.9-.4 1.6 1.2 1.6 1.2.3-.7.7-1.4 1.2-2 .5-.5 1.5.2 2.7.1l.6-.3v-4.6z"
            />
          </g>
        </g>
        <path className="prefix__st2" d="M264.7 209.4h46v50.1h-46z" />
      </g>
      <g id="prefix__freepik--Character--inject-319">
        <path
          className="prefix__st3"
          d="M165.1 276.6s23.3 38.8 25.5 51c2.1 12-36.6 81-32.9 90.4s6.3 18.5 12.9 21.6 3.3 4.9-1.3 5.4-15.3-1.4-20.7-8-12-3.6-13.8-8.7c-1.6-4.7.8-6.5 6.6-15.7s10.5-33.7 11.9-46.5c2.5-22.4 12.2-37.5 12.2-37.5s-15.9-15.5-24.3-30.8l23.9-21.2z"
        />
        <path
          className="prefix__st3"
          d="M140 297.9s-2 40.3-3.5 47.7-4 16.6-5.2 22.9c-4.7 24.7-13.2 45.3-11.8 60.7.4 4.4 3.4 8.7 10.6 13.9 7.4 5.4 18.1 4.5 16.2 7.4-6.9 10.7-24.4 2.1-33.3 2.5-4.1.2-13.3 2.8-13.4-5.5-.1-6.3 3-9.9 4.3-18.5 3.5-21.6-.9-38.3.7-51.5 2.2-18 7.8-25 5.7-36.6s-6-34.5-8.5-55.6 38.2 12.6 38.2 12.6z"
        />
        <path
          className="prefix__st7"
          d="M133.9 146.4s10.5-3.5 15-3.9c3-.2 11.8-5.2 11.8-5.2s.9-2.3 1.9-2.3c5.2 0 14.1 9.5 15.3 18.6 1.3 9.5-5.3 10.2-5.3 10.2-7.8 13.2-12.5 8.8-16.6 18.3-2.3 5-22.1-35.7-22.1-35.7z"
        />
        <defs>
          <path
            id="prefix__SVGID_17_"
            d="M133.9 146.4s10.5-3.5 15-3.9c3-.2 11.8-5.2 11.8-5.2s.9-2.3 1.9-2.3c5.2 0 14.1 9.5 15.3 18.6 1.3 9.5-5.3 10.2-5.3 10.2-7.8 13.2-12.5 8.8-16.6 18.3-2.3 5-22.1-35.7-22.1-35.7z"
          />
        </defs>
        <clipPath id="prefix__SVGID_18_">
          <use xlinkHref="#prefix__SVGID_17_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_18_)">
          <path
            className="prefix__st20"
            d="M174.3 163.2c-.6.3-1.2.5-1.8.6-7.8 13.2-12.5 8.8-16.6 18.3-.5 1.2-2-.1-3.9-2.9l1-23.9s5.6 6.9 10.8 10.4c0 0-2.6-4.6 0-5.2 1.5-.4 5.7 1.5 10.5 2.7z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M133.9 146.4s10.5-3.5 15-3.9c3-.2 11.8-5.2 11.8-5.2s.9-2.3 1.9-2.3c5.2 0 14.1 9.5 15.3 18.6 1.3 9.5-5.3 10.2-5.3 10.2-7.8 13.2-12.5 8.8-16.6 18.3-2.3 5-22.1-35.7-22.1-35.7z"
        />
        <path
          className="prefix__st3"
          d="M173.5 158.8c10.8-13.8 25.1-32.5 22.6-38.4S177 109.2 169.3 107s-22.6-6.4-22.6-6.4-11.1-9.1-12.6-9.1-9.1 4.7-9.1 4.7 3.5 11.5 3.6 11 17.1.4 26.5 8.5c5.6 4.8 12 8.6 18.9 11.1-3.2 3.8-6.6 7.4-10.4 10.6-.5 3.9 0 7.8 1.5 11.4 3.3 8.8 8.4 10 8.4 10z"
        />
        <path
          d="M156.9 217.1c-.3 12.8-4.2 31.4-18.4 32.8s-36.3-.2-51.4 6.2C71.9 262.4 72.3 244 72.3 244s22.2-35.4 30.8-47 47.8-20.2 47.8-20.2 6.5 19.1 6 40.3z"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="prefix__st3"
          d="M90 164.2c9.8-10.6 30.7-13.9 30.7-13.9s21.7 6.1 26.6 19.8 5.3 40.6 5.3 40.6l4.4 49.5s-37 6.7-51.6 2.8c0 0 1.2-34.2-4.3-50S83.3 171.3 90 164.2z"
        />
        <path
          className="prefix__st22"
          d="M141.3 146.5c-8.5-.9-18.5 2.1-18.5 2.1 10.1 3.8 18.5 17 18.5 17 7.3 9.1 14.1 36.5 15.6 51.4 0 0 1-59.1-6.4-66.8"
        />
        <path
          className="prefix__st3"
          d="M107.7 164.9s15 2 19.4 1.8c2.7-.1 5.3 1.2 7 3.3M141.2 162.4s-5 3.6-5.2 3.6c-.1 1-.1 1.9 0 2.9"
        />
        <path
          className="prefix__st7"
          d="M111.6 145c12.4-.9 22.8 4.9 28.3 8.5s9.1 11.4 9.1 11.4l-7.6-5.6v6.3s-24.3-17.1-35.9-10.6c-.1.1-2.1-9.5 6.1-10z"
        />
        <defs>
          <path
            id="prefix__SVGID_19_"
            d="M111.6 145c12.4-.9 22.8 4.9 28.3 8.5s9.1 11.4 9.1 11.4l-7.6-5.6v6.3s-24.3-17.1-35.9-10.6c-.1.1-2.1-9.5 6.1-10z"
          />
        </defs>
        <clipPath id="prefix__SVGID_20_">
          <use xlinkHref="#prefix__SVGID_19_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_20_)">
          <path
            className="prefix__st20"
            d="M139.6 164.5c-5.4-3.5-22.3-13.6-32.5-10.2 0-1.7.8-3.9 3.8-5.6 5.7-3.2 26 3.1 28.7 15.8z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M111.6 145c12.4-.9 22.8 4.9 28.3 8.5s9.1 11.4 9.1 11.4l-7.6-5.6v6.3s-24.3-17.1-35.9-10.6c-.1.1-2.1-9.5 6.1-10z"
        />
        <path
          className="prefix__st3"
          d="M111.6 155.4c4.3-11.6 1.7-20.2 1.7-20.2 2.3-.2 4.6-.3 6.8-.2 6 .2 13.7 1.4 13.9 6.4.1 2.3 0 4.6-.2 6.8-.5 4.7-1.2 9.5-2.1 14.1"
        />
        <path
          className="prefix__st24"
          d="M133.8 148.2c-12.9-2.2-12.9-9.8-12.9-9.8l-.8-3.4c6 .2 13.7 1.4 13.9 6.4.1 2.3 0 4.6-.2 6.8z"
        />
        <path
          className="prefix__st7"
          d="M93 129.8c-1.2 5.8 11.3 5.9 32.5-7s28.7-21.9 19.9-25.6-47.8 11.1-52.4 32.6z"
        />
        <defs>
          <path
            id="prefix__SVGID_21_"
            d="M93 129.8c-1.2 5.8 11.3 5.9 32.5-7s28.7-21.9 19.9-25.6-47.8 11.1-52.4 32.6z"
          />
        </defs>
        <clipPath id="prefix__SVGID_22_">
          <use xlinkHref="#prefix__SVGID_21_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_22_)">
          <path
            className="prefix__st20"
            d="M100.2 129.8c-2.5.1.2-12.3 18.9-22s24.1-5.2 23.1-2-28.6 23.6-42 24z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M93 129.8c-1.2 5.8 11.3 5.9 32.5-7s28.7-21.9 19.9-25.6-47.8 11.1-52.4 32.6z"
        />
        <path
          className="prefix__st3"
          d="M119.1 138.4c1.5 4.4 13.1 6.9 17.2 5.8 2.9-.8 4.1-5.4 4.8-11.2.9-7.7-1.5-10.4-1.5-16.3 0-7.4-6.2-15.1-9.3-16.5-9.1-4.1-24.9 2.2-24.9 11.3.1 10.4 2.2 17.9 5.4 23.1.8 1.2 1.9 2.1 3.2 2.8"
        />
        <path
          className="prefix__st2"
          d="M135.9 113.7c1.1-.5 2.3-.5 3.4 0M130 114.7c-2.2-.4-4.6.3-6.1 2.1"
        />
        <path
          className="prefix__st24"
          d="M129.4 120.2c.1.8-.1 1.4-.6 1.5s-.8-.5-.9-1.3.2-1.4.6-1.5.7.6.9 1.3zM138.6 118.6c.1.8-.1 1.4-.6 1.5s-.8-.5-.9-1.3.1-1.4.6-1.5.9.5.9 1.3z"
        />
        <path
          className="prefix__st3"
          d="M135.4 114.8s-.9 3.5-.2 5.1 6.4 5.1 6 7c-.4 1.9-6.9 2.2-7.1 1"
        />
        <path
          className="prefix__st2"
          d="M139.3 132.1c-3.4 1.2-7.1 1.5-10.7.6"
        />
        <path
          className="prefix__st24"
          d="M134.1 103.4s-.5 1.5-4.9 1-10.8 1.1-10.8 1.1 4.4 4.2 3 6.8-4.2 7.3-2.9 11.3c-1.8 1.1-3.3 2.6-4.4 4.4-1.4 2.6.7 6.6-.9 8.9s-10-6.6-11.5-16.5-1.1-17.5 10.2-21.5 21.5.2 22.2 4.5z"
        />
        <path
          className="prefix__st3"
          d="M117.6 125.1s-2-6.7-5.1-6.2-4.4 8.1-2.9 9.8c1.5 1.7 3.5 5.8 5.3 5.8s2.7-3 2.7-3"
        />
        <path
          className="prefix__st22"
          d="M132.6 98.7s-6-5.7-10.6-7.1c0 0 .4-2.1-3.8-.6s-16 5.3-20.7 12.3 0 5 0 5 .7 7.6 2.2 10.3c.1 0 15.3-17.2 32.9-19.9z"
        />
        <path
          className="prefix__st7"
          d="M101.5 123.4s12.2-20.5 38.3-20.4l5.5-5.8s-13.3-5.3-33.9 7.8c-17 10.7-18.4 24.8-18.4 24.8l8.5-6.4z"
        />
        <path
          className="prefix__st2"
          d="M101.5 123.4s12.2-20.5 38.3-20.4M145.3 97.2s-13.3-5.3-33.9 7.8c-17 10.7-18.4 24.8-18.4 24.8"
        />
        <path
          className="prefix__st24"
          d="M105.3 245s30 3.9 36.4 1.4 13.2-8.5 13.2-8.5 3 3.3 3 4.7v4.6s2.6 5.9 2.6 15.4c0 0 17.4 23.6 25.7 45.6 0 0 4.7 1 2 6.8-1.1 2.3-9.4 13.5-34.4 16.6-1.4.2-1.5-5.5-1.5-5.5s-8.2-.9-9.4-4.2l-2.5 12.7s.5 3.9-.2 4.9c-7.1 10-25.6 11.7-43.1 1.3-1.6-1 2.5-7.1 2.5-7.1-2.5-4.6 1.3-17.6 1.3-17.6s-4.3-14.9-3.8-30.3 7.6-29.7 7.6-29.7-2.1-8.1.6-11.1z"
        />
        <path
          className="prefix__st2"
          d="M117.6 200.3c4 .8 8.1 1.2 12.2 1.3 5.3 0 8.7-5 8.7-5M142.9 195.5s2.6 1.6 3.7 1.1c2-1 3.7-2.6 4.8-4.6"
        />
        <path
          className="prefix__st22"
          d="M136.4 208.3s-6.7 9-9.4 15.8M147.1 205.8c1.9 1.1 3.4 2.8 4.2 4.9"
        />
        <path
          className="prefix__st7"
          d="M120.9 162.4s.5 13.9 1.9 23.7-19.3 77.3-43 87.1c0 0 1.8-4.6 0-6.9S64 259 61.7 250.6s12.2-19.7 12.2-19.7 17.5-14.6 14.2-23.7-2.9-42.3-2.9-42.3c5.7-4.4 11.8-8.2 18.2-11.3 0 0 7.2 9.9 10.5 11.3s7-2.5 7-2.5z"
        />
        <defs>
          <path
            id="prefix__SVGID_23_"
            d="M120.9 162.4s.5 13.9 1.9 23.7-19.3 77.3-43 87.1c0 0 1.8-4.6 0-6.9S64 259 61.7 250.6s12.2-19.7 12.2-19.7 17.5-14.6 14.2-23.7-2.9-42.3-2.9-42.3c5.7-4.4 11.8-8.2 18.2-11.3 0 0 7.2 9.9 10.5 11.3s7-2.5 7-2.5z"
          />
        </defs>
        <clipPath id="prefix__SVGID_24_">
          <use xlinkHref="#prefix__SVGID_23_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_24_)">
          <path
            className="prefix__st20"
            d="M101.1 210.7l.4 10.4-3.6-.9s-7.6 21.5-32.3 19c2.5-3.1 5.2-5.9 8.3-8.3 0 0 17.5-14.6 14.2-23.7-1.2-3.4-1.9-10-2.3-17l16.2.6s-1.2 4.3 1.1 6.2l1.7-1.6c.1 0 2.3 10.2-3.7 15.3z"
          />
        </g>
        <path
          className="prefix__st2"
          d="M120.9 162.4s.5 13.9 1.9 23.7-19.3 77.3-43 87.1c0 0 1.8-4.6 0-6.9S64 259 61.7 250.6s12.2-19.7 12.2-19.7 17.5-14.6 14.2-23.7-2.9-42.3-2.9-42.3c5.7-4.4 11.8-8.2 18.2-11.3 0 0 7.2 9.9 10.5 11.3s7-2.5 7-2.5z"
        />
        <path
          className="prefix__st22"
          d="M107.4 150.3c-2.1 4.9 13.5 12.1 13.5 12.1l-3.3 10.2s-16.5-11.7-16.5-17.2c0-7.2 5.2-10 10.4-10.4M89.7 288.2s4.3.9 5.3 7.3-1.8 42.4 4.7 43.6 8.5-18.5 8.5-18.5l1.5-1s-2.3 23.2-10.2 21.2-7-19.2-6.5-26.2 2-22-4.6-23l1.3-3.4z"
        />
        <path
          className="prefix__st3"
          d="M96.7 203.7s-5 23.5-4.5 28.5 3 19.1 0 30.8-2.4 32.5-2.4 32.5H77.3S73.6 249.3 75 239c0 0-1.4-8.4-.3-12.1s0-15.6 1.1-25.3 20.9 2.1 20.9 2.1z"
        />
        <path
          className="prefix__st22"
          d="M100.5 179c1.5 4.8 2 9.7 1 13.1-2.8 10-4.8 21.3-4.8 21.3s2.2 3 0 5.9-24.4-1.5-29.1-5.9 4.7-5.3 4.7-5.3v-15.5c0-2.6 3.2-4.9 3.2-10.9s4.6-13.1 9.8-16.8c2.2-1.6 4.5-1.4 6.7.1"
        />
        <path
          className="prefix__st6"
          d="M101 322c-.1.4-1.2 9.1-1.2 9.1l1.2 1.7-1.2 9.5s-9.2 5.4-16.5-2.4l1.6-10 2.1-1.5 1.3-8.7L101 322z"
        />
        <path
          className="prefix__st6"
          d="M82.3 323.4l6 4.1c1.1.7 2.4 1 3.7.7l16.9-4.2c1.4-.3 2.4-1.4 2.8-2.7l.7-2.5c.5-1.9-.1-4-1.7-5.2l-7.9-6.1c-.8-.6-1.8-.9-2.8-.9l-19.8.9 2.1 15.9z"
        />
        <path
          className="prefix__st24"
          d="M87.4 309.1l6.3 6.5 13.3-1.8-6.9-5.8z"
        />
        <path
          className="prefix__st2"
          d="M89.2 328c-1.5-.9-.2-8 .4-9.1s18.2-3.4 18.2-3.4"
        />
        <path
          className="prefix__st2"
          d="M94 323.4c-.2.8-.7 1.3-1.2 1.2s-.7-.8-.6-1.6.7-1.3 1.2-1.2.7.9.6 1.6zM100.3 322.6c-.3 1.3-1.2 2.2-2 2s-1.2-1.4-1-2.7 1.2-2.2 2-2 1.3 1.4 1 2.7zM84.9 329.9s1.8 3.2 6.7 4.2 7.8-.6 7.8-.6M99.8 331.1s-7 1.2-11.3-1.7"
        />
        <path
          className="prefix__st3"
          d="M77 291c-.9 3.9-6.8 19.3-6.8 21.5s3.7 2.5 4 4.1 4.7 3.4 4.7 3.4l1.6 4.5c1.3 1.4 3 2.4 4.9 2.6 3.3.5.3-2.6.1-3.4s.6-6.6 0-7.9 6.1-7.6 6.7-6.1-.3 4.7 0 6.6 3.6 4 3.1 1 3.3-12.2 0-14.5c-3.1-2.5-5.1-6.2-5.6-10.1"
        />
        <path
          className="prefix__st22"
          d="M87.4 320.3s-12.5-14.6-10.6-32.1l-1.8 7.3s.5 17.5 12.4 27.8v-3z"
        />
        <path
          className="prefix__st2"
          d="M105.8 190.8s.7 6.2-6.5 9.9M97.9 207.2s1.2 9.7 4.7 16.1"
        />
        <path
          className="prefix__st27"
          d="M142.9 318.8s.2-8.7 2.8-10.8c1.2-.9.5-11.2.9-11.8s9.9-9.8 10.4-14.3M152.1 300.2s7.6 2.7 11.8 0M184.4 309.1s-17.2 4.5-26.5 0M154.1 275.2s-8.3 20.1-26 32.9M142.9 261.9c5.4-5.6-.8-12.7-.8-12.7s9.6 6.7 9.4 1.7-9.3-2.2-9.3-2.2-10.3.1-9.2 6c1.1 5.9 9.2-4.7 9.2-4.7s-.5 8.8-7.7 11.5M109.8 248c-1.5 1.4 0 8.1 0 8.1s-6.4 25.5-4.7 36.7 1.1 15.2 1.1 15.2"
        />
        <path
          className="prefix__st27"
          d="M142.2 268.8s-1.2 10.1.7 11.5 2.2 6.3 2.2 6.3"
        />
      </g>
    </svg>
  )
}