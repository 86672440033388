export const onScroll = (offset, container) => () => {
    try {
        const scrollContainer = document.querySelector(`.ant-tabs-tabpane-active .list__items-${container}`)
        const currentScroll = scrollContainer.scrollLeft
        let newScrollLeft = 0

        if (offset < 0) {
            if (currentScroll > (-1 * offset)) {
                newScrollLeft = currentScroll + offset
            } else {
                newScrollLeft = 0
            }
        } else {
            newScrollLeft = currentScroll + offset
        }

        scrollContainer.scrollLeft = newScrollLeft
    } catch (_) { }
}