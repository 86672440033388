// @vendors
import { Modal } from 'antd'

export function showConfirm({
    content,
    onOk,
    okText = 'Eliminar',
    cancelText = 'Cancelar',
    onCancel = () => {},
    loading = false
}) {
    Modal.confirm({
        title: '',
        icon: <em className='fas fa-trash-alt' />,
        content,
        okText,
        cancelText,
        width: '450px',
        okButtonProps: {
            className: 'solid c-1'
        },
        cancelButtonProps: {
            className: 'outline c-dr'
        },
        onOk,
        onCancel,
        confirmLoading: loading
    })
}