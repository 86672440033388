export default function WomanCity() {
    return (
        <svg
            id="prefix__Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 398 333"
            xmlSpace="preserve"
        >
            <style>
                {
                    '.prefix__st2{fill:#fff}.prefix__st3{fill:none;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st4{fill:#263238}.prefix__st5{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round}.prefix__st6{opacity:.5;fill:#fff;enable-background:new}.prefix__st9{fill:#ced6dd}.prefix__st10{fill:#92e3a9}.prefix__st16{fill:#b1bcc4}.prefix__st19{fill:#fff;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}'
                }
            </style>
            <g id="prefix__freepik--background-simple--inject-255">
                <path
                    d="M287.2 291.3c80.3-19.6 112.9-112.2 86.9-193.5C367.6 77 357.2 57.6 343 42c-22.4-24.4-55.3-33.3-86.1-33.9-24.6-.4-48.3 7.6-70.8 17.7-36.5 16.4-75.6 21.3-112 36.9-36.9 15.8-60.6 50.4-65.7 93.8-2.6 22.2.2 45.2 9.1 65.4 13.8 31.3 41.3 49.9 70.7 60 62.4 21.4 135.1 25 199 9.4z"
                    fill="#e7edf4"
                />
                <path
                    d="M287.2 291.3c80.3-19.6 112.9-112.2 86.9-193.5C367.6 77 357.2 57.6 343 42c-22.4-24.4-55.3-33.3-86.1-33.9-24.6-.4-48.3 7.6-70.8 17.7-36.5 16.4-75.6 21.3-112 36.9-36.9 15.8-60.6 50.4-65.7 93.8-2.6 22.2.2 45.2 9.1 65.4 13.8 31.3 41.3 49.9 70.7 60 62.4 21.4 135.1 25 199 9.4z"
                    opacity={0.7}
                    fill="#fff"
                />
            </g>
            <g id="prefix__freepik--Clouds--inject-255">
                <path
                    className="prefix__st2"
                    d="M362.1 72c-3-1.6-4.4-.3-5.4-1.6s3-4.9-5.2-8.4c0 0-.5-7.6-11.7-7.9s-8.2 7.6-11.1 9.5-3.8-1.1-6.8.8-1.6 5.7-4.1 6.3-5.8-2.4-8.8-.5-3 3.5-4.4 3.5-4.1-1.1-5.7 0-3.5 2.4-3.5 2.4h68.8s.9-2.5-2.1-4.1zM319.6 94.7c-1.6-.9-2.3-.1-2.9-.9s1.6-2.6-2.7-4.5c0 0-.3-4-6.2-4.2s-4.3 4-5.9 5-2-.6-3.6.4-.9 3-2.2 3.3-3-1.3-4.6-.3-1.6 1.9-2.3 1.9-2.2-.6-3 0-1.9 1.3-1.9 1.3h36.5c.2-.8-.3-1.7-1.2-2zM73.2 93c-1.6-.9-2.3-.1-2.9-.9s1.6-2.6-2.7-4.5c0 0-.3-4-6.2-4.2s-4.3 4-5.9 5-2-.6-3.6.4-.9 3-2.2 3.3-3-1.3-4.6-.3-1.6 1.9-2.3 1.9-2.2-.6-3 0-1.9 1.3-1.9 1.3h36.4c.2-.8-.3-1.7-1.1-2zM242.7 44.6c-4.2-2.3-6.1-.4-7.6-2.3s4.2-6.8-7.2-11.8c0 0-.8-10.6-16.3-11S200.2 30.2 196 32.9s-5.3-1.5-9.5 1.1-2.3 8-5.7 8.7-8-3.4-12.2-.8c-4.2 2.7-4.2 4.9-6.1 4.9s-5.7-1.5-8 0-4.9 3.4-4.9 3.4h96.1c.1.2 1.2-3.3-3-5.6z"
                />
            </g>
            <g id="prefix__freepik--Buildings--inject-255">
                <path
                    className="prefix__st3"
                    d="M26.3 244.9h194.5M66.4 245.5v7.5H23.3M94.9 256.3H32.6M227.6 244.9H387M268.4 245.5v7.5H95.1M382 256.3H115.1M239.6 242.8v-47.4h56.7v47.4M272.9 194.8v-46.4h-29.1v46.7M248.3 148.4s1.8-7.5 10.8-7.5 11.1 7.2 11.1 7.2"
                />
                <path
                    className="prefix__st3"
                    d="M254.9 140.9V53.4h15.6l12 119.6h18.9v25.7h14.9v10.5h25.8v35.7M270.5 53.4l-.6 88.4M262.4 53.1V21.9"
                />
                <path
                    className="prefix__st3"
                    d="M309.8 198.7V158h20.6v28.4h7.2M339.1 208.6v-27.9h19.2v60.9M352.9 181.6v60.3"
                />
                <path
                    className="prefix__st3"
                    d="M341.5 181v-5.9h11.1v4.7M346.6 174.2v-54.6h11.7v13.5h13.2v30.3"
                />
                <path
                    className="prefix__st3"
                    d="M367.3 240.7v-76.4h9v75.8M358.3 133.1v36.6M347.8 119.3V116h6.9v3.3M349.9 115.7s-.9-2.4 0-2.4h3.3v2.7M76.3 173.6H37.4v69.2"
                />
                <path
                    className="prefix__st3"
                    d="M73.9 163.1H43.4v10.8M49.1 162.5v-12.3h25.8v9.7M55.7 149.9v-37.5h14.1v37.7M359.8 266.7h-61.7M321.7 278.1h-52.1M290 270h-29.1M306.5 261.9H296M222 267h17.9M79.5 279.9H47.1M29.3 270h19.2M54.2 270.3h4.2M63.2 264H43.1M28.7 287.4h15.4M318.1 287.4h15.4M241.1 279.9h-32.3M118.1 267h87.5M76.7 163.5h2.7v10.7h4v68.2M74.9 150.2v-27.8h30.8v51.8M84.3 180h33v63.3"
                />
                <path
                    className="prefix__st3"
                    d="M106.2 149.2h18.2v94.5M124.4 149.2v-36.1h21.9v130.2M129.8 112.9V110h11.6v2.7M132.3 109.8v-3.7h6v3.7"
                />
                <path className="prefix__st3" d="M146 132.8h20.3v43H194v68.3" />
                <path
                    className="prefix__st3"
                    d="M193.7 212.1h-41v31.7M194 233.1h22.3v-92.3h32"
                />
                <path
                    className="prefix__st3"
                    d="M216.7 148.8H188v26.6M190.3 148.8v-4.4h6v-4.3h16v4h2.4"
                />
            </g>
            <g id="prefix__freepik--Character--inject-255">
                <path
                    className="prefix__st4"
                    d="M210.4 106.3c5.1-2.5 10.6-7.4 13.4-17 2.3-7.9 1.3-15.1-2.7-20.7-4.1-5.5-10.5-9-17.4-9.5-.3 0-.6.2-.6.5s.2.6.5.6c6.6.5 12.6 3.8 16.5 9.1 3.9 5.3 4.7 12.1 2.6 19.7-1.2 4.6-3.6 8.7-6.8 12.1 2.3-3.1 3.8-6.7 4.4-10.5 1.2-7 0-12.8-3.8-17.2-1.4-1.7-3.2-3.1-5.1-4.3-10.8-10.2-31.6-10.3-44.2-.6-14.9 11.4-18.4 25.5-34.1 45.4s-31.9 34-56.8 34-36.4 43.6-7.1 56.8 83 17 107.8 5.7 38.3-12.8 57.5-44c16.8-27.1-3.9-58.1-24.1-60.1zm5.2-32.1c3.5 4.1 4.7 9.6 3.5 16.3-1.4 7.8-5.2 12-8.9 14.4 3.4-3.9 12.3-15.8 5.9-29.2-.4-.8-.8-1.6-1.3-2.3.3.2.6.5.8.8z"
                />
                <path
                    className="prefix__st5"
                    d="M70 201.6c2.4 1.1 4.9 1.9 7.4 2.4M196.4 108.7s8-19.4-4.6-27.8c-10.3-6.9-22.8 5.9-26.6 16.4s-11 29.1-32.1 40.1-73.8 16.4-76.7 39.6c-1.5 11.8 4 18.6 9.8 22.4M209.4 87.2c.1-2-.1-4-.7-5.9M196.4 108.7c3.6-4.1 9.4-9.5 11.9-16.3"
                />
                <path
                    className="prefix__st5"
                    d="M194.8 107.8s-3.4-18.1-13.1-16.4-4.2 11.8-4.2 11.8M207 114.2s35 15.6 32.5 36.7M224.4 172.1c-1.5 2.1-3.3 4-5.2 5.7M217.5 120.1s21.6 24.8 9.7 47.6"
                />
                <path
                    className="prefix__st6"
                    d="M161.5 141.4s-2.8-4.8-4.8-.2 6.1 15.2 7.6 13.4-2.8-13.2-2.8-13.2z"
                />
                <defs>
                    <path
                        id="prefix__SVGID_1_"
                        d="M161.5 141.4s-2.8-4.8-4.8-.2 6.1 15.2 7.6 13.4-2.8-13.2-2.8-13.2z"
                    />
                </defs>
                <clipPath id="prefix__SVGID_2_">
                    <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
                </clipPath>
                <g clipPath="url(#prefix__SVGID_2_)">
                    <path
                        className="prefix__st6"
                        d="M161.5 141.4s-2.8-4.8-4.8-.2 6.1 15.2 7.6 13.4-2.8-13.2-2.8-13.2z"
                    />
                </g>
                <path
                    className="prefix__st3"
                    d="M161.5 141.4s-2.8-4.8-4.8-.2 6.1 15.2 7.6 13.4-2.8-13.2-2.8-13.2z"
                />
                <path
                    className="prefix__st2"
                    d="M76.7 324.6s2.4-8.9 6.2-23.9S110 192.6 124 177.1s19.8-.5 34.4 5.3 18.2 6.6 18.2 6.6 3 .5 12.4 5.6 13.4 6.8 21.8 9.4 19.2 8.4 13.9 25.8-19.7 44.1-20.7 62.9 4.1 32 4.1 32"
                />
                <defs>
                    <path
                        id="prefix__SVGID_3_"
                        d="M76.7 324.6s2.4-8.9 6.2-23.9S110 192.6 124 177.1s19.8-.5 34.4 5.3 18.2 6.6 18.2 6.6 3 .5 12.4 5.6 13.4 6.8 21.8 9.4 19.2 8.4 13.9 25.8-19.7 44.1-20.7 62.9 4.1 32 4.1 32"
                    />
                </defs>
                <clipPath id="prefix__SVGID_4_">
                    <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
                </clipPath>
                <g clipPath="url(#prefix__SVGID_4_)">
                    <path
                        className="prefix__st9"
                        d="M133 188.9c16 3.4 28.9 30.5 28.9 30.5l23.6-18.7c.1-.3 0-3.7-.3-8.1-6.3-3.2-8.5-3.5-8.5-3.5s-3.6-.8-18.2-6.6-20.6-20.8-34.5-5.3c-2.3 2.6-4.9 7.3-7.6 13.5 5.2-2.5 11-3.1 16.6-1.8z"
                    />
                    <path
                        className="prefix__st6"
                        d="M133 188.9c16 3.4 28.9 30.5 28.9 30.5l23.6-18.7c.1-.3 0-3.7-.3-8.1-6.3-3.2-8.5-3.5-8.5-3.5s-3.6-.8-18.2-6.6-20.6-20.8-34.5-5.3c-2.3 2.6-4.9 7.3-7.6 13.5 5.2-2.5 11-3.1 16.6-1.8z"
                    />
                    <path
                        className="prefix__st9"
                        d="M127.6 219s1 4.2-10.5 34.2 14.8 7.9 3.7 25.3-43.7 36.4-46.9 36.4-6.8-59-4.2-62.2 18.5-28.7 33.5-38.6 24.4 4.9 24.4 4.9z"
                    />
                    <path
                        className="prefix__st6"
                        d="M127.6 219s1 4.2-10.5 34.2 14.8 7.9 3.7 25.3-43.7 36.4-46.9 36.4-6.8-59-4.2-62.2 18.5-28.7 33.5-38.6 24.4 4.9 24.4 4.9z"
                    />
                    <path
                        className="prefix__st9"
                        d="M211.4 234.3s1 6.9-1.6 21.1-8.4 35.8-15.8 47.4-10.5 10-10.5 10 4.7-22.7 11.6-34.8 15.3-35.3 16.3-43.7z"
                    />
                    <path
                        className="prefix__st6"
                        d="M211.4 234.3s1 6.9-1.6 21.1-8.4 35.8-15.8 47.4-10.5 10-10.5 10 4.7-22.7 11.6-34.8 15.3-35.3 16.3-43.7z"
                    />
                </g>
                <path
                    className="prefix__st3"
                    d="M76.7 324.6s2.4-8.9 6.2-23.9S110 192.6 124 177.1s19.8-.5 34.4 5.3 18.2 6.6 18.2 6.6 3 .5 12.4 5.6 13.4 6.8 21.8 9.4 19.2 8.4 13.9 25.8-19.7 44.1-20.7 62.9 4.1 32 4.1 32"
                />
                <path
                    className="prefix__st10"
                    d="M107.7 325.2s16.5-25.5 27.9-43.7 14.9-39.3 19-63.8 12.4-30.4 18-29.6 20.8 7.4 22.8 10.1 4.8 5.1 4.3 20.5-5.6 35.2-14.2 53.2-19.5 29.6-23.5 38.2-4.8 14.2-4.8 14.2"
                />
                <defs>
                    <path
                        id="prefix__SVGID_5_"
                        d="M107.7 325.2s16.5-25.5 27.9-43.7 14.9-39.3 19-63.8 12.4-30.4 18-29.6 20.8 7.4 22.8 10.1 4.8 5.1 4.3 20.5-5.6 35.2-14.2 53.2-19.5 29.6-23.5 38.2-4.8 14.2-4.8 14.2"
                    />
                </defs>
                <clipPath id="prefix__SVGID_6_">
                    <use xlinkHref="#prefix__SVGID_5_" overflow="visible" />
                </clipPath>
                <g clipPath="url(#prefix__SVGID_6_)">
                    <path
                        className="prefix__st10"
                        d="M161.9 219.4l23.6-18.7c.1-.4-.1-3.8-.4-8.5-4.7-2.1-9.8-3.9-12.6-4.2-4.9-.7-11.9 3.8-16.3 21.5 2.1 3.2 4 6.5 5.7 9.9z"
                    />
                    <path
                        d="M161.9 219.4l23.6-18.7c.1-.4-.1-3.8-.4-8.5-4.7-2.1-9.8-3.9-12.6-4.2-4.9-.7-11.9 3.8-16.3 21.5 2.1 3.2 4 6.5 5.7 9.9z"
                        opacity={0.3}
                    />
                </g>
                <path
                    className="prefix__st3"
                    d="M107.7 325.2s16.5-25.5 27.9-43.7 14.9-39.3 19-63.8 12.4-30.4 18-29.6 20.8 7.4 22.8 10.1 4.8 5.1 4.3 20.5-5.6 35.2-14.2 53.2-19.5 29.6-23.5 38.2-4.8 14.2-4.8 14.2M212.1 229.1c-1.3 25.6-17.1 46.5-24.8 70.2-2.4 7.1-4.1 14.4-5.1 21.8M205.3 284.8s-2.8 4.3-13.7 3.3"
                />
                <path
                    className="prefix__st2"
                    d="M169.6 168s3 5.3 0 16.7-12.2 38.5-8.1 56.7 11.1 21.8 20 15.5 14.7-21.3 17-38.8-5.3-19-1.8-29.9 4.3-26.6 4.3-26.6-10.9 6.3-18.8 7.4-12.6-1-12.6-1z"
                />
                <defs>
                    <path
                        id="prefix__SVGID_7_"
                        d="M169.6 168s3 5.3 0 16.7-12.2 38.5-8.1 56.7 11.1 21.8 20 15.5 14.7-21.3 17-38.8-5.3-19-1.8-29.9 4.3-26.6 4.3-26.6-10.9 6.3-18.8 7.4-12.6-1-12.6-1z"
                    />
                </defs>
                <clipPath id="prefix__SVGID_8_">
                    <use xlinkHref="#prefix__SVGID_7_" overflow="visible" />
                </clipPath>
                <g clipPath="url(#prefix__SVGID_8_)">
                    <path
                        className="prefix__st9"
                        d="M199.5 162.5c-3.2 1.7-11.2 5.8-17.3 6.5-7.9 1-12.7-1-12.7-1s3 5.3 0 16.7c-2.4 8.8-8.4 27.1-9.1 43.4 1.2-8.1 3.6-15.5 5.8-15.5 3.7 0 11.5 6.9 11.5 5s-3.2-4.6-4.6-6 9.2-10.5 16-21.1c4.2-6.6 7.9-13.6 11-20.8.2-1.4.3-2.7.4-3.8l-1-3.4zM171.3 259.3c2.2.9 4.7.6 7.3-.6.8-3.7.1-7.1-1.3-11.2-1.7-5.3-3.4 6.2-6 11.8z"
                    />
                    <g opacity={0.5}>
                        <path
                            className="prefix__st2"
                            d="M199.5 162.5c-3.2 1.7-11.2 5.8-17.3 6.5-7.9 1-12.7-1-12.7-1s3 5.3 0 16.7c-2.4 8.8-8.4 27.1-9.1 43.4 1.2-8.1 3.6-15.5 5.8-15.5 3.7 0 11.5 6.9 11.5 5s-3.2-4.6-4.6-6 9.2-10.5 16-21.1c4.2-6.6 7.9-13.6 11-20.8.2-1.4.3-2.7.4-3.8l-1-3.4zM171.3 259.3c2.2.9 4.7.6 7.3-.6.8-3.7.1-7.1-1.3-11.2-1.7-5.3-3.4 6.2-6 11.8z"
                        />
                    </g>
                </g>
                <path
                    className="prefix__st3"
                    d="M169.6 168s3 5.3 0 16.7-12.2 38.5-8.1 56.7 11.1 21.8 20 15.5 14.7-21.3 17-38.8-5.3-19-1.8-29.9 4.3-26.6 4.3-26.6-10.9 6.3-18.8 7.4-12.6-1-12.6-1z"
                />
                <path
                    className="prefix__st2"
                    d="M208.6 137.4s1-11.7-5.3-20.3-17.2-12.2-29.4-7.4-13.4 17-13.2 26.4 3.3 26.1 6.6 31.9c3.3 5.8 9.1 12.7 17.7 10.9s18-13.7 20.3-19c1.2-2.6 2.1-5.3 2.8-8.1 0 0 8.4-4.8 8.6-10.9s-3.6-12.7-8.1-3.5z"
                />
                <path
                    className="prefix__st9"
                    d="M186.6 176.6c-5.3 1.2-9.3-5.3-4.9-9.7s5.3-5.7 6.1-8.9-3.2 1.6-6.9 1.6-4.9-4.1-4.9-4.1-2-8.5 2.4-14.9 0-4.6-2.8-7.5 0-1.1 4-5.1-1.2-9 4.9-13.1 14.2-1.6 14.2-1.6l.2-.8c-6.4-5.2-15.4-6.7-25.1-2.9-12.2 4.8-13.4 17-13.2 26.4s3.3 26.1 6.6 31.9 9.1 12.7 17.7 10.9c5.4-1.1 11-6.1 15.1-11.1-3.8 3.1-9.4 8-13.4 8.9z"
                />
                <path
                    className="prefix__st6"
                    d="M186.6 176.6c-5.3 1.2-9.3-5.3-4.9-9.7s5.3-5.7 6.1-8.9-3.2 1.6-6.9 1.6-4.9-4.1-4.9-4.1-2-8.5 2.4-14.9 0-4.6-2.8-7.5 0-1.1 4-5.1-1.2-9 4.9-13.1 14.2-1.6 14.2-1.6l.2-.8c-6.4-5.2-15.4-6.7-25.1-2.9-12.2 4.8-13.4 17-13.2 26.4s3.3 26.1 6.6 31.9 9.1 12.7 17.7 10.9c5.4-1.1 11-6.1 15.1-11.1-3.8 3.1-9.4 8-13.4 8.9z"
                />
                <path
                    className="prefix__st3"
                    d="M208.6 137.4s1-11.7-5.3-20.3-17.2-12.2-29.4-7.4-13.4 17-13.2 26.4 3.3 26.1 6.6 31.9c3.3 5.8 9.1 12.7 17.7 10.9s18-13.7 20.3-19c1.2-2.6 2.1-5.3 2.8-8.1 0 0 8.4-4.8 8.6-10.9s-3.6-12.7-8.1-3.5z"
                />
                <path
                    className="prefix__st2"
                    d="M176.4 125.7s-.8 9.4-3.8 11.1-10.9 4.3-14.2 1.8-6.8-11.1-4.3-13.2 16.2-4.6 18.5-4.6 3.8 2.1 3.8 4.9z"
                />
                <defs>
                    <path
                        id="prefix__SVGID_9_"
                        d="M176.4 125.7s-.8 9.4-3.8 11.1-10.9 4.3-14.2 1.8-6.8-11.1-4.3-13.2 16.2-4.6 18.5-4.6 3.8 2.1 3.8 4.9z"
                    />
                </defs>
                <clipPath id="prefix__SVGID_10_">
                    <use xlinkHref="#prefix__SVGID_9_" overflow="visible" />
                </clipPath>
                <g clipPath="url(#prefix__SVGID_10_)">
                    <path
                        className="prefix__st16"
                        d="M157.6 137.9l.8.8c3.3 2.5 11.1 0 14.2-1.8s3.8-11.1 3.8-11.1c0-.6-.1-1.3-.3-1.9-12.3 3.9-16.8 10.1-18.5 14z"
                    />
                </g>
                <path
                    className="prefix__st3"
                    d="M176.4 125.7s-.8 9.4-3.8 11.1-10.9 4.3-14.2 1.8-6.8-11.1-4.3-13.2 16.2-4.6 18.5-4.6 3.8 2.1 3.8 4.9z"
                />
                <path
                    className="prefix__st2"
                    d="M185.5 124.4s2.7 9 6 10.1 11.5 2 14.2-1.2 4.4-12.3 1.5-13.8-16.8-1.1-19-.7-3.3 2.8-2.7 5.6z"
                />
                <defs>
                    <path
                        id="prefix__SVGID_11_"
                        d="M185.5 124.4s2.7 9 6 10.1 11.5 2 14.2-1.2 4.4-12.3 1.5-13.8-16.8-1.1-19-.7-3.3 2.8-2.7 5.6z"
                    />
                </defs>
                <clipPath id="prefix__SVGID_12_">
                    <use xlinkHref="#prefix__SVGID_11_" overflow="visible" />
                </clipPath>
                <g clipPath="url(#prefix__SVGID_12_)">
                    <path
                        className="prefix__st16"
                        d="M205.7 133.3c2.2-2.5 3.7-9 2.7-12.1-11.2.1-18.2 4.4-21.5 7.2 1.1 2.6 2.7 5.5 4.5 6.1 3.4 1.1 11.6 2 14.3-1.2z"
                    />
                </g>
                <path
                    className="prefix__st3"
                    d="M185.5 124.4s2.7 9 6 10.1 11.5 2 14.2-1.2 4.4-12.3 1.5-13.8-16.8-1.1-19-.7-3.3 2.8-2.7 5.6zM176.7 123.7c2-2.7 5.8-3.3 8.6-1.5M180.5 124.3s1.5 1.3-.8 3.8-4.8 3.3-4 5.1 4.3 3 5.1 3.8"
                />
                <path
                    d="M197.9 142.7s-.3 1.3-6.3.3-7.9-4-13.2-2.3-7.1 4.6-8.4 5.1l-1.3.5s4.8 6.6 7.6 11.1 6.6 4.6 9.6 2.7 8.6-10.9 9.6-12.9l2.4-4.5z"
                    fill="#263238"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className="prefix__st19"
                    d="M196.2 144.2s-2.2 1.5-6.7.8-5.8-3-9.7-1.7-5.9 2.3-6.9 2.7c-.7.3-1.4.8-2 1.3 1.9 2 3.6 4.3 5.1 6.6 2 3.4 6.9 4.1 9.1 2.8s6.7-7 7.4-8.5 3.7-4 3.7-4z"
                />
                <path
                    className="prefix__st2"
                    d="M152.3 181.7c-.8-.3-3.6-8.4-14.7-12.2s-17.5 3-23 12.7-11.6 27.1-16 35.7-12.6 14.2-12.9 25.4 4.8 18 11.1 17.5 11.4-7.6 20.8-20.5 18.5-35.7 18.5-35.7"
                />
                <g>
                    <defs>
                        <path
                            id="prefix__SVGID_13_"
                            d="M152.3 181.7c-.8-.3-3.6-8.4-14.7-12.2s-17.5 3-23 12.7-11.6 27.1-16 35.7-12.6 14.2-12.9 25.4 4.8 18 11.1 17.5 11.4-7.6 20.8-20.5 18.5-35.7 18.5-35.7"
                        />
                    </defs>
                    <clipPath id="prefix__SVGID_14_">
                        <use xlinkHref="#prefix__SVGID_13_" overflow="visible" />
                    </clipPath>
                    <g clipPath="url(#prefix__SVGID_14_)">
                        <path
                            className="prefix__st9"
                            d="M152.3 181.7c-.8-.3-3.6-8.4-14.7-12.2s-17.5 3-23 12.7c-2.5 4.6-4.8 9.3-6.8 14.2 7.6-6.7 15.6-9.5 25.1-7.5 3.8 1 7.4 2.8 10.4 5.5l9-12.7z"
                        />
                        <path
                            className="prefix__st6"
                            d="M152.3 181.7c-.8-.3-3.6-8.4-14.7-12.2s-17.5 3-23 12.7c-2.5 4.6-4.8 9.3-6.8 14.2 7.6-6.7 15.6-9.5 25.1-7.5 3.8 1 7.4 2.8 10.4 5.5l9-12.7z"
                        />
                    </g>
                </g>
                <path
                    className="prefix__st3"
                    d="M152.3 181.7c-.8-.3-3.6-8.4-14.7-12.2s-17.5 3-23 12.7-11.6 27.1-16 35.7-12.6 14.2-12.9 25.4 4.8 18 11.1 17.5 11.4-7.6 20.8-20.5 18.5-35.7 18.5-35.7"
                />
                <path
                    className="prefix__st19"
                    d="M119 238.3c-1.8-3.2-3.9-6.3-6.2-9.2-3.7-4.8-8.9-8.3-14.8-10-4.4 7.9-12 13.5-12.3 24.1-.2 11.1 4.8 18 11.1 17.5s11.4-7.6 20.8-20.5c.5-.6.9-1.3 1.4-1.9z"
                />
                <path
                    d="M88.5 67.9h43.1c1.3 0 2.3 1 2.3 2.3v79.1c0 1.3-1 2.3-2.3 2.3H88.5c-1.3 0-2.3-1-2.3-2.3V70.2c0-1.2 1-2.3 2.3-2.3z"
                    fill="#92e3a9"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className="prefix__st5"
                    d="M131.7 113.2v6.3M125.3 70h4.1c1.3 0 2.3 1 2.3 2.3v36.4M115.8 70h4.5"
                />
                <path
                    className="prefix__st4"
                    d="M99.4 77.1c0-2.2-1.8-4-4.1-4-2.2 0-4 1.8-4 4.1 0 2.2 1.8 4 4.1 4 2.2 0 4-1.8 4-4.1 0 .1 0 0 0 0z"
                />
                <path
                    className="prefix__st2"
                    d="M89.8 104.2s-9.1 11.6-15.7 17.5-2.3 10.3 0 15.1c2.3 4.8 6.3 30.7 7.6 39.9 1.3 9.1-3.3 38.5-4.6 55.2s9.4 31.4 14.4 33.9 8.4-1 14.2-12.7.8-24.6-2.5-39-9.4-38-9.4-38 5.3-1.5 5.8-6.6-1.5-12.9-1.5-12.9 15.4.2 21.5-.8 12.7-2.5 8.1-3.6-8.6 0-17.7-.5c-5.8-.4-11.3-2.2-16.2-5.3 4.3-2 9-3.2 13.7-3.3 6.8-.3 14.2 1.5 18.3.6 3.8-.8 6.5-2.1 5-3.6s-4.3-1-10-2c-3.4-.6-8.6-3.6-13.6-3.6-4.4.1-8.8.8-13 2.1-1 0 6.4-6.6 9.4-7.4s13.4 3.1 17.7 3.1 9.4-1.5 9.6-3.3 1.8-2.5-4.1-2.8-14.4-3.6-19.5-5.3-8.6-.2-11.7 1.3-10.6 4.3-10.6 4.3l9.7-12.6s10.6 4.5 16.6 4.3 13.4-4.6 10.9-5.8-8.1-.5-17.5-3.3-11.4-7.7-14.9-4.9z"
                />
                <g>
                    <defs>
                        <path
                            id="prefix__SVGID_15_"
                            d="M89.8 104.2s-9.1 11.6-15.7 17.5-2.3 10.3 0 15.1c2.3 4.8 6.3 30.7 7.6 39.9 1.3 9.1-3.3 38.5-4.6 55.2s9.4 31.4 14.4 33.9 8.4-1 14.2-12.7.8-24.6-2.5-39-9.4-38-9.4-38 5.3-1.5 5.8-6.6-1.5-12.9-1.5-12.9 15.4.2 21.5-.8 12.7-2.5 8.1-3.6-8.6 0-17.7-.5c-5.8-.4-11.3-2.2-16.2-5.3 4.3-2 9-3.2 13.7-3.3 6.8-.3 14.2 1.5 18.3.6 3.8-.8 6.5-2.1 5-3.6s-4.3-1-10-2c-3.4-.6-8.6-3.6-13.6-3.6-4.4.1-8.8.8-13 2.1-1 0 6.4-6.6 9.4-7.4s13.4 3.1 17.7 3.1 9.4-1.5 9.6-3.3 1.8-2.5-4.1-2.8-14.4-3.6-19.5-5.3-8.6-.2-11.7 1.3-10.6 4.3-10.6 4.3l9.7-12.6s10.6 4.5 16.6 4.3 13.4-4.6 10.9-5.8-8.1-.5-17.5-3.3-11.4-7.7-14.9-4.9z"
                        />
                    </defs>
                    <clipPath id="prefix__SVGID_16_">
                        <use xlinkHref="#prefix__SVGID_15_" overflow="visible" />
                    </clipPath>
                    <g clipPath="url(#prefix__SVGID_16_)">
                        <path
                            className="prefix__st9"
                            d="M74.1 136.8c2.3 4.8 6.3 30.7 7.6 39.9 1.3 9.1-3.3 38.5-4.6 55.2s9.4 31.4 14.4 33.9c4.8 2.4 8.1-.7 13.4-11.2-.7.7-1.4 1.5-2.4 2.5-5.5 5.5-9.6 6.4-13.8-6.4s-1.8-55.5-2.8-68.3c-.5-8.3-.4-16.5.5-24.8 0 0-.3-8.6-6.9-18.3s-5.6-17.6-5.6-17.6c-6.4 5.8-2.1 10.2.2 15.1z"
                        />
                        <path
                            className="prefix__st6"
                            d="M74.1 136.8c2.3 4.8 6.3 30.7 7.6 39.9 1.3 9.1-3.3 38.5-4.6 55.2s9.4 31.4 14.4 33.9c4.8 2.4 8.1-.7 13.4-11.2-.7.7-1.4 1.5-2.4 2.5-5.5 5.5-9.6 6.4-13.8-6.4s-1.8-55.5-2.8-68.3c-.5-8.3-.4-16.5.5-24.8 0 0-.3-8.6-6.9-18.3s-5.6-17.6-5.6-17.6c-6.4 5.8-2.1 10.2.2 15.1z"
                        />
                    </g>
                </g>
                <path
                    className="prefix__st3"
                    d="M89.8 104.2s-9.1 11.6-15.7 17.5-2.3 10.3 0 15.1c2.3 4.8 6.3 30.7 7.6 39.9 1.3 9.1-3.3 38.5-4.6 55.2s9.4 31.4 14.4 33.9 8.4-1 14.2-12.7.8-24.6-2.5-39-9.4-38-9.4-38 5.3-1.5 5.8-6.6-1.5-12.9-1.5-12.9 15.4.2 21.5-.8 12.7-2.5 8.1-3.6-8.6 0-17.7-.5c-5.8-.4-11.3-2.2-16.2-5.3 4.3-2 9-3.2 13.7-3.3 6.8-.3 14.2 1.5 18.3.6 3.8-.8 6.5-2.1 5-3.6s-4.3-1-10-2c-3.4-.6-8.6-3.6-13.6-3.6-4.4.1-8.8.8-13 2.1-1 0 6.4-6.6 9.4-7.4s13.4 3.1 17.7 3.1 9.4-1.5 9.6-3.3 1.8-2.5-4.1-2.8-14.4-3.6-19.5-5.3-8.6-.2-11.7 1.3-10.6 4.3-10.6 4.3l9.7-12.6s10.6 4.5 16.6 4.3 13.4-4.6 10.9-5.8-8.1-.5-17.5-3.3-11.4-7.7-14.9-4.9zM98.1 156.6h-6.8"
                />
            </g>
        </svg>
    )
}