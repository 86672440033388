// @components
import Services from "components/drawer-provider/services"
import Schedule from "components/drawer-provider/schedule"

const providers = {
    servicios: ({ items }) => <Services services={items} />,
    horarios: ({ items }) => <Schedule schedules={items} />
}

export default function Restaurant({ provider }) {
    return provider.data.body.map((item, index) => {
        const SelectedSlice = providers[item.slice_type]
        return <SelectedSlice items={item.items} key={`${item.slice_type}-${index}`} />
    })
}