// @vendors
import { useContext, useRef } from 'react'
import { Button, Form, Input, Typography, Select } from 'antd'

// @context
import { AppContext } from 'context/app'

// @hooks
import useFetch from 'hooks/useFetch'

// @utils
import { convertToSlug } from 'utils/format'

// @constants
import { EMAIL_REGEX } from 'constants/format'
import { DEPARTAMENTS } from 'constants/departaments'

// @api
import { sendInfoEmail } from 'api/notifications'

export default function RecommendSearchForm({ onClose, search }) {
    const { auth: { user } } = useContext(AppContext)
    const ref = useRef()

    const { refetch, loading } = useFetch(
        sendInfoEmail,
        {
            errorMessage: 'Error enviando la información',
            successMessage: 'Datos enviados exitosamente.',
            onSuccess: () => {
                ref.current.resetFields()
                onClose()
            }
        }
    )

    const onFinish = (values) => {
        const { email } = values
        refetch(
            [
                email,
                {
                    ...values
                },
                'recommend-search'
            ]
        )
    }

    return (
        <>
            <h3 className='title__ppal sm c--dr' style={{ marginBottom: '30px' }}>
                <strong>Recomiéndanos</strong>
                <span style={{ fontSize: '22px' }}>un destino</span>
            </h3>
            <Form
                layout='vertical'
                ref={ref}
                autoComplete=''
                onFinish={onFinish}
                fields={[
                    {
                        name: ["email"],
                        value: user && user.email
                    },
                    {
                        name: ["destination"],
                        value: search
                    }
                ]}
            >
                <Form.Item
                    label='Correo electrónico'
                    name='email'
                    type="email"
                    hidden={user}
                    rules={[{
                        required: true,
                        message: 'Este campo es obligatorio'
                    },
                    {
                        pattern: EMAIL_REGEX,
                        message: 'Formato de correo inválido'
                    }]}
                >
                    <Input disabled={user} />
                </Form.Item>
                <Form.Item
                    label='Nombre del destino'
                    name='destination'
                    rules={[{
                        required: true,
                        message: 'Debemos conocer el nombre del destino'
                    }]}
                >
                    <Input />
                </Form.Item>
                <div id='depto-recommend-modal' style={{ position: 'relative' }}>
                    <Form.Item
                        label='Departamento'
                        name='department'
                        rules={[{
                            required: true,
                            message: 'Este campo es obligatorio'
                        }]}
                    >
                        <Select
                            placeholder='Seleccionar'
                            dropdownClassName='ant-dropdown-always-bottom'
                            showSearch
                            getPopupContainer={() => document.getElementById('depto-recommend-modal')}
                        >
                            {DEPARTAMENTS.map((depto) => (
                                <Select.Option value={depto.name} key={convertToSlug(depto.name)}>
                                    {depto.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item
                    label='¿Por qué lo quiéres recomendar?'
                    name='message'
                    rules={[{
                        required: true,
                        message: 'Por favor, cuéntanos algo acerca de este destino'
                    }]}
                >
                    <Input.TextArea allowClear maxLength={250} showCount autoSize={{ minRows: 2, maxRows: 4 }} />
                </Form.Item>
                <Form.Item className='actions'>
                    <Button className='solid c-1' htmlType='submit' loading={loading}>
                        <span className='text'>
                            Enviar
                        </span>
                    </Button>
                </Form.Item>
                <Typography.Text
                    type='secondary'
                    style={{ margin: '20px 0 0', display: 'block', fontSize: '14px' }}
                >
                    * Después de validar tu recomendación, nuestro equipo te avisará cuando subamos la información.
                </Typography.Text>
            </Form>
        </>
    )
}
