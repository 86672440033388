// @vendors
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Drawer, Skeleton } from 'antd'

// @components
import TripsList from 'components/trips/list'
import { EmptyData } from 'components/empty'

// @context
import { AppContext } from 'context/app'

// @hooks
import useMyTrips from 'hooks/useMyTrips'
import useFetch from 'hooks/useFetch'

// @api
import { addDestinationToMultipleTrips } from 'api/destination'

// @utils
import { mapDestinationToSave } from 'utils/format'
import { notify } from 'utils/notifications'

// @constants
import { QUERY_PARAM_CREATE_TRIP_FORM } from 'constants/query-params'

function isDestinationAlreadyAddedOnThisTrip(selectedDestination, tripDestinations) {
  if (!selectedDestination || !tripDestinations) {
    return true
  }

  return !!tripDestinations.find(({ linkTo }) => linkTo === selectedDestination.link)
}

export default function TripsAdd() {
  const {
    app: {
      onOpenCreateTripForm,
      openModalWithSessionOnly,
      onCloseAddDestinationToMyTrips,
      isModalOpened,
      addTripsModalOpened
    },
    auth: { user },
    trips: {
      handleChangeAddToTripsVisibility,
      selectedDestination
    }
  } = useContext(AppContext)
  const [selectedTrips, setSelectedTrips] = useState([])
  const [isMounted, setIsMounted] = useState(false)
  const { refetchMyTrips, myTrips, loading } = useMyTrips({ fetchOnMount: false })
  const { refetch: addDestinationToTrips, loading: addingToTrips } = useFetch(
    addDestinationToMultipleTrips,
    {
      showErrorMessage: false
    }
  )

  const openCreateTrip = openModalWithSessionOnly(() => isModalOpened(QUERY_PARAM_CREATE_TRIP_FORM))

  const handleSetSelectedTrips = (trip) => () => {
    if (!isDestinationAlreadyAddedOnThisTrip(selectedDestination, trip.destinations)) {
      setSelectedTrips((currentSelectedTrips) => {
        const foundIndex = currentSelectedTrips.findIndex(({ title }) => title === trip.title)

        if (foundIndex < 0) {
          return [...currentSelectedTrips, trip]
        }

        return currentSelectedTrips.filter((_, i) => i !== foundIndex)
      })
    }
  }

  const handleAddDestinationToTrips = useCallback(() => {
    const tripsToAdd = selectedTrips.map(({ codeId, shared, email }) => ({
      codeId,
      isInvited: !!shared.find(({ email }) => email === user?.email),
      owner: email
    }))

    const destinationToAdd = mapDestinationToSave(selectedDestination)

    addDestinationToTrips(
      [user.email, destinationToAdd, tripsToAdd],
      () => {
        handleChangeAddToTripsVisibility(false, onCloseAddDestinationToMyTrips)()
        const tripsWereDestinationWasAdded = selectedTrips
          .slice(0, 2)
          .map(({ title, codeId }) =>
            <a href={`/viajes/${codeId}`} key={`trip-added-${codeId}`}>{title}</a>
          )
          .reduce((prev, curr) => [prev, ', ', curr])
        const restTripsNumber = selectedTrips.length > 2 ? `y ${selectedTrips.length - 2} más` : ''
        notify(
          'success',
          <>Se agregó <strong>{destinationToAdd.title}</strong> {selectedTrips.length === 1 ? 'al viaje' : 'a los viajes'} {tripsWereDestinationWasAdded} {restTripsNumber}.</>,
          ''
        )
      },
      (errorMessage) => {
        const tripsWereDestinationWasAdded = selectedTrips.map(({ title }) => title).join(', ')
        const alreadyExistDestinationOnTrip = errorMessage.search('ya existe') >= 0
        const finalMessage = alreadyExistDestinationOnTrip
          ? `${destinationToAdd.title} ya ha sido agregado al viaje "${tripsWereDestinationWasAdded}".`
          : errorMessage
        if (alreadyExistDestinationOnTrip) {
          notify('error', finalMessage, '')
        }
      }
    )
  }, [selectedDestination, selectedTrips, user])

  useEffect(() => {
    if (addTripsModalOpened && user?.email) {
      refetchMyTrips([user.email])
    }
  }, [addTripsModalOpened])

  useEffect(() => {
    if (isMounted && !openCreateTrip && user?.email) {
      refetchMyTrips([user.email])
    }
  }, [openCreateTrip])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (!addTripsModalOpened) {
      setSelectedTrips([])
    }
  }, [addTripsModalOpened])

  return (
    <Drawer
      width='500px'
      placement="right"
      title='Agregar a Viajes'
      closable={false}
      onClose={handleChangeAddToTripsVisibility(false, onCloseAddDestinationToMyTrips)}
      className='drawer__default'
      visible={addTripsModalOpened}
      footer={myTrips.all?.length && (
        [
          <Button
            className='outline c-dr'
            disabled={addingToTrips}
            key='trip-create'
            onClick={onOpenCreateTripForm}
          >
            Crear
          </Button>,
          <Button
            className='solid c-1'
            disabled={!selectedTrips.length || addingToTrips}
            loading={addingToTrips}
            key='trip-add'
            onClick={handleAddDestinationToTrips}
          >
            Agregar
          </Button>
        ]
      )}
    >
      <div
        className='ant-drawer-close'
        onClick={handleChangeAddToTripsVisibility(false, onCloseAddDestinationToMyTrips)}
      >
        <em className='fal fa-times' />
      </div>
      {loading && (
        <Skeleton className='ant-skeleton-trips' paragraph={{ rows: 3 }} active />
      )}
      {
        (!myTrips.all.length && !loading) && <>
          <EmptyData type='tripsDrawer' flex='vertical' />
          <Button
            className='outline c-dr'
            style={{ marginTop: '20px' }}
            onClick={onOpenCreateTripForm}
          >
            Crear
          </Button>
        </>
      }
      {
        (!!myTrips.all.length && !loading) && (
          <div
            className='list__myTrips list__myTrips-one'
          >
            {
              myTrips.all.map((trip, index) =>
              (
                <TripsList
                  disabled={isDestinationAlreadyAddedOnThisTrip(selectedDestination, trip.destinations)}
                  trip={trip}
                  link={false}
                  key={`trip-modal-${index}`}
                  selected={!!selectedTrips.find(({ title }) => trip.title === title)}
                  setSelected={handleSetSelectedTrips(trip)}
                />
              )
              )
            }
          </div>
        )
      }
    </Drawer>
  )
}
