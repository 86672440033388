// @constants

export const ACTIVITIES = [
  {
    iconName: 'umbrella-beach',
    title: 'Playas'
  },
  {
    iconName: 'church',
    title: 'Cultura'
  },
  {
    iconName: 'mountain',
    title: 'Senderismo'
  },
  {
    iconName: 'mug-hot',
    title: 'Eje cafetero'
  },
  {
    iconName: 'route',
    title: 'Oriente Antioqueño'
  },
  {
    iconName: 'tree-palm',
    title: 'Golfo de Morrosquillo'
  },
  {
    iconName: 'sun',
    title: 'Clima Caliente'
  },
  {
    iconName: 'hat-winter',
    title: 'Clima Frío'
  },
  {
    iconName: 'parachute-box',
    title: 'Parapente'
  },
  {
    iconName: 'campfire',
    title: 'Camping'
  },
  {
    iconName: 'water',
    title: 'Rafting'
  },
  {
    iconName: 'building',
    title: 'City Tour'
  }
]