// @vendors
import {
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { Button, Drawer, Skeleton } from "antd"

// @components
import Score from "./score"
import NewCommentDrawer from "./add"
import CommentsList from "./list"
import ReportComment from "./report"

// @context
import { AppContext } from "context/app"

// @api
import { removeReviewFromTrip } from "api/destination"

// @hooks
import useFetch from "hooks/useFetch"
import useDestinationReviews from "hooks/useDestinationReviews"

// @utils
import { notify } from "utils/notifications"

// @styles
import './styles.scss'

export default function Comments({
  average,
  totalComments,
  destinationId,
  visible,
  onClose,
  refetchReviewsSummary
}) {
  const { auth: { user } } = useContext(AppContext)
  const {
    comments,
    loading,
    fetchMoreComments,
    shouldFetchMore
  } = useDestinationReviews({ destinationId, email: user?.email })
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false)
  const [openReportComment, setOpenReportComment] = useState(false)
  const [removingComment, setRemovingComment] = useState(false)
  const [selectedComment, setSelectedComment] = useState(null)

  const { refetch: removeReview } = useFetch(
    removeReviewFromTrip,
    {
      showErrorMessage: false,
      onError: () => {
        notify('error', 'Error eliminando reseña')
        setRemovingComment(false)
      },
      onSuccess: () => {
        notify('error', 'Reseña eliminada correctamente')
        fetchMoreComments()
        refetchReviewsSummary()
        setRemovingComment(false)
      }
    }
  )

  const userAlreadyAddedReview = useMemo(
    () => {
      if (!comments?.length) {
        return false
      }

      if (!user) {
        return true
      }

      return !!comments.find(({ createdBy }) => createdBy === user.email)
    },
    [comments, user]
  )

  const newCommentDrawerProps = {
    visible: openCommentDrawer,
    onClose: () => setOpenCommentDrawer(false),
    selectedComment,
    destinationId,
    getReviews: fetchMoreComments,
    refetchReviewsSummary
  }

  useEffect(() => {
    if (!openCommentDrawer) {
      setSelectedComment(null)
      if (!comments?.length) {
        onClose()
      }
    }
  }, [openCommentDrawer])

  useEffect(() => {
    if (visible) {
      fetchMoreComments()

      if (user && !totalComments) {
        setOpenCommentDrawer(true)
      }
    }
  }, [visible])

  const isFetching = loading && !!comments.length
  const loadingComments = (loading && !isFetching) || removingComment

  return (
    <>
      <Drawer
        width='500px'
        height='100%'
        placement='right'
        title={null}
        footer={
          !loading & !userAlreadyAddedReview
            ? (
              <Button
                onClick={() => setOpenCommentDrawer(true)}
                className='outline c-dr'
              >
                Deja tu reseña
              </Button>
            )
            : null
        }
        closable={true}
        onClose={onClose}
        className='drawer__default drawer__default-comments'
        visible={visible && !!totalComments}
      >
        <Score
          average={average}
          total={comments?.length}
          loading={loadingComments}
        />
        <div className='comments__list'>
          {
            loadingComments && (
              <>
                <Skeleton
                  paragraph={8}
                  className='ant-skeleton-reviews'
                  active
                />
                <Skeleton
                  paragraph={8}
                  className='ant-skeleton-reviews'
                  active
                />
              </>
            )
          }
          {!loadingComments && (comments || []).map((item, index) => (
            <CommentsList
              showActions={user?.email === item.createdBy}
              comment={item}
              email={user?.email}
              key={`comment-${index}`}
              openReportComment={() => setOpenReportComment(true)}
              openCommentDrawer={openCommentDrawer}
              openReviewDrawer={(commentToEdit) => {
                setOpenCommentDrawer(true)
                setSelectedComment(commentToEdit)
              }}
              removeReview={() => {
                setRemovingComment(true)
                removeReview([user.email, destinationId])
              }}
              clearSelectedComment={() => setSelectedComment(null)}
              selectComment={() => setSelectedComment(item)}
              isSelected={item?.createdBy === selectedComment?.createdBy}
              refetchReviewsSummary={refetchReviewsSummary}
            />
          )
          )}
        </div>
        {
          shouldFetchMore && (
            <Button
              className='outline c-dr'
              loading={loading || isFetching}
              style={{ margin: '30px auto' }}
              onClick={fetchMoreComments}
            >
              Cargar más
            </Button>
          )
        }
        {
          !!totalComments && (
            <NewCommentDrawer {...newCommentDrawerProps} />
          )
        }
      </Drawer>
      {
        !totalComments && (
          <NewCommentDrawer {...newCommentDrawerProps} />
        )
      }

      <ReportComment
        destinationId={destinationId}
        visible={openReportComment}
        onClose={() => setOpenReportComment(false)}
        selectedComment={selectedComment}
      />
    </>
  )
}