// @vendors
import { Button } from "antd"
import { useState } from "react"

const MAX_SERVICES_TO_SHOW = 6

export default function Services({ services }) {
    const [showMoreServices, setShowMoreServices] = useState(false)

    return (
        <div className="provider__detail-body-services">
            <h6 className='title__ppal xs c-dr'>
                Lo que te ofrece este lugar
            </h6>
            <ul className='list__provider-services'>
                {services.slice(0, MAX_SERVICES_TO_SHOW).map((item, index) => (
                    <li
                        key={`provider-service-${index}`}
                        className='list__provider-services-item'
                    >
                        <em className={`fal fa-${item.icon}`} />
                        <span className='text'>
                            {item.title}
                        </span>
                    </li>
                ))}
                {showMoreServices && services.slice(MAX_SERVICES_TO_SHOW).map((item, index) => (
                    <li
                        key={`provider-service-${index}`}
                        className='list__provider-services-item'
                    >
                        <em className={`fal fa-${item.icon}`} />
                        <span className='text'>
                            {item.title}
                        </span>
                    </li>
                ))}
            </ul>
            {services.length > MAX_SERVICES_TO_SHOW && (
                <Button
                    className="outline c-dr"
                    style={{ marginTop: '20px' }}
                    onClick={() => setShowMoreServices(!showMoreServices)}
                >
                    <span className="text">Mostrar {showMoreServices ? 'menos' : 'todos'}</span>
                </Button>
            )}
        </div>
    )
}