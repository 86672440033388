// @vendors
import { useCallback, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Button,
  Typography,
  Modal,
  Form,
  Dropdown,
  Menu,
  Tooltip,
  Skeleton,
  Popover
} from 'antd'
import { Helmet } from 'react-helmet'

// @components
import CardDestination from 'components/card-destination'
import ShareTrip from 'components/trips/share'
import AddDestination from './add-destination'
import { EmptyData } from 'components/empty'

// @context
import { AppContext } from 'context/app'

// @constants
import { DEFAULT } from 'constants/default'

// @hooks
import useTripDetails from 'hooks/useTripDetails'

// @utils
import { convertToSlug } from 'utils/format'

// @styles
import './styles.scss'

const { Text } = Typography

const MAX_INVITED_TO_SHOW = 3

function showConfirm(type, name, onOk, onCancel = () => { }, okText = 'Eliminar') {
  Modal.confirm({
    title: '',
    icon: <em className='fas fa-trash-alt' />,
    content: <>¿Seguro(a) que deseas {type} viaje &quot;<strong>{name}</strong>&quot;?</>,
    okText,
    cancelText: 'Cancelar',
    width: '450px',
    okButtonProps: {
      className: 'solid c-1'
    },
    cancelButtonProps: {
      className: 'outline c-dr'
    },
    onOk,
    onCancel
  })
}

function MenuTripActions({ edit, onEdit, onDelete, onShare, onLeave, isThisTripOwner }) {
  return (
    <div className='menu__container menu__container-xs'>
      <Menu>
        <Menu.ItemGroup>
          <Menu.Item key="0" disabled>
            <Tooltip
              title='Próximamente'
              placement='left'
            >
              <span>
                Planificar
              </span>
            </Tooltip>
          </Menu.Item>
          {
            isThisTripOwner === true && (
              <Menu.Item key="1">
                <Button
                  onClick={onShare}
                >
                  Invitar noomada
                </Button>
              </Menu.Item>
            )
          }
          {
            isThisTripOwner === true && (
              <Menu.Item key="2">
                <Button onClick={onEdit} disabled={edit}>
                  Editar
                </Button>
              </Menu.Item>
            )
          }
          {
            isThisTripOwner === true && (
              <Menu.Item key="3">
                <Button onClick={onDelete}>
                  Eliminar
                </Button>
              </Menu.Item>
            )
          }
          {
            isThisTripOwner === false && (
              <Menu.Item key="4">
                <Button onClick={onLeave}>
                  Abandonar
                </Button>
              </Menu.Item>
            )
          }
        </Menu.ItemGroup>
      </Menu>
    </div>
  )
}

function AllNoomadasInvited({ invited, isThisTripOwner, openConfirmationModal }) {
  return (
    <ul className='more__noomada'>
      {invited.slice(MAX_INVITED_TO_SHOW).map((sharedWith, index) => (
        <div
          className='more__noomada-item'
          key={`popover-trip-shared-with-${index}`}
        >
          <div className='more__noomada-item-data'>
            <div className='more__noomada-item-photo'>
              {sharedWith.picture && <img src={sharedWith.picture} />}
              {!sharedWith.picture && sharedWith.fullName.split('')[0]}
            </div>
            <div className='more__noomada-item-name'>
              {sharedWith.fullName}
            </div>
          </div>
          {
            isThisTripOwner === true && (
              <button
                className='more__noomada-item-actions'
                onClick={() => openConfirmationModal(sharedWith)}
              >
                <em className='fal fa-times' />
              </button>
            )
          }
        </div>
      ))}
    </ul>
  )
}

function sortDestinationsList(items) {
  return items.sort((a, b) => {
    if (!a.visited && b.visited) {
      return -1
    }

    return 0
  })
}

export default function TripDetail() {
  const { auth: { user }, app: { onOpenAddDestinationToTripForm } } = useContext(AppContext)
  const { tripId } = useParams()
  const {
    tripDetails,
    setTripDetails,
    visitedPercentage,
    onDeleteTrip,
    onEditTrip,
    loadingEditingTrip,
    isTripEditMode,
    setIsTripEditMode,
    loadingTripDetails,
    visibleMenu,
    setVisibleMenu,
    updatedTripTitle,
    setUpdatedTripTitle,
    visibleShareTrip,
    setVisibleShareTrip,
    onDeleteDestinationFromTrip,
    markDestinationInTrip,
    onRemoveInvitedFromTrip,
    onLeaveTrip
  } = useTripDetails(tripId)

  const handleMarkDestinationAsVisited = useCallback((destination) => (onSuccess, onError) => {
    markDestinationInTrip(
      [user.email, tripDetails.codeId, destination.name, !destination.visited],
      ({ destinations }) => {
        setTripDetails(currentTripDetails => ({
          ...currentTripDetails,
          destinations: [...destinations]
        }))
        onSuccess()
      },
      onError
    )
  }, [user, tripDetails])

  const isThisTripOwner = tripDetails
    ? user.email === tripDetails?.email
    : null

  if (loadingTripDetails) {
    return (
      <div className='container'>
        <Skeleton
          className='ant-skeleton-basic ant-skeleton-buttons'
          paragraph={{ rows: 3 }}
          title=''
          active
        />
        <Skeleton
          className='ant-skeleton-basic ant-skeleton-status-trip'
          paragraph={{ rows: 2 }}
          active
        />
        <Skeleton
          title=''
          className='ant-skeleton-mosaic ant-skeleton-mosaic-profile'
          paragraph={{ rows: 4 }}
          active
        />
      </div>
    )
  }

  if (!tripId || (!loadingEditingTrip && !tripDetails)) {
    return (
      <>
        <Helmet>
          <title>Viaje no encontrado {DEFAULT.site.name}</title>
        </Helmet>
        <div className='container'>
        <div className='actions__generic'>
          <div className='actions__generic-left'>
            <Link
              to='/viajes'
              className='ant-btn ant-btn-prev solid c-gr-2 ant-btn-only--icon'
            >
              <em className='fal fa-arrow-left' />
            </Link>
          </div>
        </div>
          <EmptyData type='tripNotFound' />
        </div>
      </>
    )
  }

  const destinations =
    tripDetails.destinations.map((item) => ({
      linkTo: item.linkTo,
      imageUrl: item.imageUrl,
      imageAlt: item.title,
      name: item.title,
      caption: item.caption,
      visited: item.visited,
      type: 'trip'
    }))

  return (
    <>
      <Helmet>
        <title>Viaje: {tripDetails.title} {DEFAULT.site.name}</title>
      </Helmet>
      <div className='container'>
        {!isTripEditMode && (
          <Tooltip placement='left' title='Agrega destinos al viaje'>
            <Button
              className='solid c-1 ant-btn-add-new'
              onClick={onOpenAddDestinationToTripForm}
            >
              <em className='fal fa-plus' />
            </Button>
          </Tooltip>
        )}
        <div className='actions__generic'>
          <div className='actions__generic-left'>
            <Link
              to='/viajes'
              className='ant-btn ant-btn-prev solid c-gr-2 ant-btn-only--icon'
            >
              <em className='fal fa-arrow-left' />
            </Link>
          </div>
          <div className='actions__generic-right'>
            {isTripEditMode && (
              <>
                <Button
                  className='solid c-2'
                  loading={!!loadingEditingTrip}
                  onClick={onEditTrip}
                >
                  <em className='fal fa-check' />
                  <span className='text'>
                    Guardar
                  </span>
                </Button>
                <Button
                  className='outline c-dr'
                  onClick={() => setIsTripEditMode(false)}
                >
                  <em className='fal fa-times' />
                  <span className='text'>
                    Cancelar
                  </span>
                </Button>
              </>
            )}
            <div className='guest__users'>
              {
                !isTripEditMode && !!tripDetails.shared.length && (
                  <Tooltip
                    placement='top'
                    title={user.email === tripDetails.email ? 'Tú' : tripDetails.ownerFullName}
                  >
                    <div className='guest__users-item'>
                      {tripDetails.ownerPicture && <img src={tripDetails.ownerPicture} />}
                      {!tripDetails.ownerPicture && tripDetails.ownerFullName.split('')[0]}
                    </div>
                  </Tooltip>
                )
              }
              {
                !isTripEditMode && tripDetails.shared.slice(0, MAX_INVITED_TO_SHOW).map((sharedWith, index) => (
                  <Tooltip
                    placement='top'
                    title={sharedWith.fullName}
                    key={`trip-shared-with-user-${tripId}-${index}`}
                  >
                    <div className='guest__users-item'>
                      {sharedWith.picture && <img src={sharedWith.picture} />}
                      {!sharedWith.picture && sharedWith.fullName.split('')[0]}
                      {
                        isThisTripOwner === true && (
                          <em
                            className='fal fa-times'
                            onClick={() => showConfirm(<>eliminar a <strong>{sharedWith.fullName}</strong> del</>, tripDetails.title, () => onRemoveInvitedFromTrip(sharedWith))}
                          />
                        )
                      }
                    </div>
                  </Tooltip>
                ))
              }
              {
                !isTripEditMode && tripDetails.shared.length > MAX_INVITED_TO_SHOW && (
                  <Popover
                    content={
                      <AllNoomadasInvited
                        invited={tripDetails.shared}
                        isThisTripOwner={isThisTripOwner}
                        openConfirmationModal={(sharedWith) => showConfirm(<>eliminar a <strong>{sharedWith.fullName}</strong> del</>, tripDetails.title, () => onRemoveInvitedFromTrip(sharedWith))}
                      />
                    }
                    title='Más noomadas'
                    placement='bottomRight'
                    overlayClassName='popover__generic'
                  >
                    <div
                      className='guest__users-item guest__users-item-all-users'
                    >
                      {tripDetails.shared.length - MAX_INVITED_TO_SHOW}+
                    </div>
                  </Popover>
                )
              }
              {!isTripEditMode && tripDetails.shared.length && isThisTripOwner === true
                ? (
                  <Tooltip
                    placement='top'
                    title='Invitar noomada'
                  >
                    <div
                      className='guest__users-item guest__users-item-share'
                      onClick={() => setVisibleShareTrip(true)}
                    >
                      <em className='fal fa-plus' />
                    </div>
                  </Tooltip>
                )
                : null
              }
            </div>
            <Dropdown
              overlay={
                <MenuTripActions
                  edit={!!isTripEditMode}
                  onEdit={() => {
                    setVisibleMenu(false)
                    setIsTripEditMode(true)
                  }}
                  onShare={() => {
                    setVisibleMenu(false)
                    setVisibleShareTrip(true)
                  }}
                  onDelete={() => {
                    setVisibleMenu(false)
                    showConfirm('eliminar el', tripDetails.title, onDeleteTrip)
                  }}
                  onLeave={() => {
                    showConfirm(
                      'abandonar el',
                      tripDetails.title,
                      () => {
                        onLeaveTrip(() => setVisibleMenu(false))
                      },
                      () => { },
                      'Abandonar'
                    )
                  }}
                  isThisTripOwner={isThisTripOwner}
                />
              }
              onVisibleChange={setVisibleMenu}
              visible={visibleMenu}
              trigger={['click']}
              placement="bottomRight"
              arrow
            >
              <Button
                className='outline c-dr ant-btn-only--icon'
                onClick={() => setVisibleMenu(!visibleMenu)}
              >
                <em className='fal fa-ellipsis-v' style={{ fontSize: '30px' }} />
              </Button>
            </Dropdown>
          </div>
        </div>
        <h3 className='title__ppal md c-dr'>
          {isTripEditMode
            ? (
              <Form.Item className='ant-form-item-editName'>
                <textarea
                  className='ant-input'
                  autoFocus
                  onChange={e => setUpdatedTripTitle(e.target.value)}
                  value={updatedTripTitle}
                />
              </Form.Item>
            )
            : (
              <strong>{tripDetails.title}</strong>
            )}
        </h3>

        <div className='additional__info'>
          <span className='progress'>
            {visitedPercentage === 0 && 'Sin comenzar'}
            {(visitedPercentage < 100 && visitedPercentage > 0) &&
              <>
                <em className='fal fa-check' /> Llevas el {visitedPercentage}%
              </>
            }
            {visitedPercentage === 100 && 'Completado'}
          </span>
          <Text type='secondary'>
            <strong>{tripDetails.destinations.length} </strong>
            {visitedPercentage < 100 && 'destinos en este viaje'}
            {visitedPercentage === 100 && 'destinos visitados en este viaje'}
          </Text>
        </div>
        {!tripDetails.destinations.length && (
          <EmptyData type='tripDetail' />
        )}

        <div className='list__items-mosaic-card list__items-mosaic-card-profile'>
          {sortDestinationsList(destinations).map((destination) => (
            <CardDestination
              key={convertToSlug(destination.name)}
              destination={destination}
              edit={!!isTripEditMode}
              onAddToTrip={handleMarkDestinationAsVisited(destination)}
              onRemove={(onSuccess, onError) => {
                onDeleteDestinationFromTrip(destination.name, onSuccess, onError)
              }}
              tripTitle={tripDetails.title}
            />
          ))}
        </div>
      </div>
      <ShareTrip
        visible={visibleShareTrip}
        onCancel={() => setVisibleShareTrip(false)}
        tripId={tripId}
        tripTitle={tripDetails.title}
        user={user}
        sharedWith={tripDetails.shared}
      />
      <AddDestination
        tripDetails={tripDetails}
        setTripDetails={setTripDetails}
      />
    </>
  )
}