// @components
import ServicesProvider from "components/drawer-provider/services"

export default function Lodging({ provider }) {
    return provider.data.body.map((item, index) => {
      if (item.slice_type !== 'servicios') {
        return null
      }

      return (
        <ServicesProvider
          key={`${item.slice_type}-${index}`}
          services={item.items}
        />
      )
    })
}