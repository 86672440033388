// @vendors
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import { Skeleton } from 'antd'
import cx from 'classnames'

// @context
import { AppContext } from "context/app"
import { DestinationPageContext } from 'context/pages'

// @hooks
import useFlags from 'hooks/useFlags'
import useFetch from "hooks/useFetch"

// @components
import Comments from '../comments'

// @api
import { getReviewsAverage } from "api/destination"

// @utils
import { convertToSlug, getPathFromUrl, pluralWord } from 'utils/format'
import { notify } from "utils/notifications"
import { sortToDoList } from 'utils/sort-link'

const trafficLabel = {
  abierto: 'Normalmente se encuentra',
  cerrado: 'Posiblemente se encuentra',
  reserva: 'Se puede ingresar con'
}

export default function PlaceInfo() {
  const {
    auth: { user },
    app: { onOpenAuthForm }
  } = useContext(AppContext)
  const info = useContext(DestinationPageContext)
  const flags = useFlags()
  const [visible, setVisible] = useState(false)

  const {
    data: { average, total },
    loading,
    refetch: refetchReviewsSummary
  } = useFetch(
    getReviewsAverage,
    {
      fetchOnMount: true,
      initialData: { average: 0, total: 0 },
      showErrorMessage: false,
      params: [info.id],
      onError: () => {
        notify('error', 'Error cargando prmedio de reseñas')
      }
    }
  )

  const classes = {
    placeInfo: cx(
      'place__info',
      { 'with-state': !!info.state },
      { 'without-state': !info.state }
    ),
    traffic: cx(
      'state',
      { open: info.state === 'abierto' },
      { closed: info.state === 'cerrado' },
      { reservation: info.state === 'reserva' }
    )
  }

  const roundedAverage = average ? Number(average).toFixed(1) : average

  const handleOpenReviewDrawer = () => {
    if (user) {
      setVisible(true)
    } else {
      if (total) {
        setVisible(true)
      } else {
        notify(
          'error',
          <>Para agregar tu reseña debes estar registrado. <div className="fake-link" onClick={onOpenAuthForm}>¿Qué esperas? ¡es gratis!</div></>,
          ''
        )
      }
    }
  }

  return (
    <>
      <ul className={classes.placeInfo}>
        {loading && (
          <li>
            <em className="fas fa-star" />
            <Skeleton paragraph={0} className='ant-skeleton-only-one' active />
          </li>
        )}
        {
          flags.ENABLED_COMMENTS_IN_DESTINATION &&
          !loading &&
          (
            <li
              className='icon rate'
              onClick={handleOpenReviewDrawer}
            >
              <em className="fas fa-star" />
              {!loading && !!total && (
                <span>
                  <strong>{roundedAverage}</strong> {total} {pluralWord('reseña', total)}
                </span>
              )}
              {!loading && !total && (
                <span>Aún no hay reseñas</span>
              )}
            </li>
          )
        }
        {
          (info.dest_type === 'Parque Nacional' ||
            info.dest_type === 'Santuario' ||
            info.dest_type === 'Distrito Nacional' ||
            info.dest_type === 'Reserva Nacional' ||
            info.dest_type === 'Destino') &&
          !!info.state && (
            <li className={classes.traffic}>
              <span>{trafficLabel[info.state]} <strong>{info.state}</strong></span>
            </li>
          )
        }
        {info.nearby_city && !!info.nearby_city[0]?.text && (
          <li className="icon">
            <em className="fal fa-map-marker-alt" />
            <span>{RichText.asText(info.nearby_city_complement)} <Link to={`/${convertToSlug(info.depto_nearby_city)}/${convertToSlug(info.nearby_city[0].text)}`}>{RichText.asText(info.nearby_city)}</Link></span>
          </li>
        )}
        {!!info.dest_altitude && info.dest_altitude.length > 0 && (
          <li className="icon">
            <em className="fal fa-arrow-from-bottom" />
            <span><strong>Altitud-Media</strong> {RichText.asText(info.dest_altitude)} msnm</span>
          </li>
        )}
        {!!info.weather && info.weather.length > 0 && (
          <li className="icon">
            <em className="fal fa-cloud" />
            <span><strong>Clima</strong> {info.weather.map((item) => (item.weather_type)).reduce((prev, curr) => [prev, ', ', curr])}</span>
          </li>
        )}
        {info.demonym && info.demonym.length > 0 && (
          <li className="icon">
            <em className="fal fa-users-class" />
            <span><strong>Gentilicio</strong> {RichText.asText(info.demonym)}</span>
          </li>
        )}
        {!!info.temperature && info.temperature.length > 0 && (
          <li className="icon">
            <em className="fal fa-thermometer-quarter" />
            <span><strong>Temperatura</strong> {RichText.asText(info.temperature)}°C</span>
          </li>
        )}
        {!!info.extension && info.extension.length > 0 && (
          <li className="icon">
            <em className="fal fa-layer-group" />
            <span><strong>Extensión</strong> {RichText.asText(info.extension)}</span>
          </li>
        )}
        {!!info.creation_year && info.creation_year.length > 0 && (
          <li className="icon">
            <em className="fal fa-calendar-check" />
            <span><strong>Año Creación</strong> {RichText.asText(info.creation_year)}</span>
          </li>
        )}
        {!!info.near_cities && info.near_cities.length > 0 && (
          <li className={`icon full`}>
            <em className="fal fa-map-marker-alt" />
            <span>
              <strong>Ciudades cercanas</strong>
              {sortToDoList(info.near_cities).map((item, index) => (
                item.link.link_type !== 'Any'
                  ? <Link
                    to={getPathFromUrl(item.link.url)}
                    key={`near-city-${index}`}
                  >
                    {RichText.asText(item.city)}
                  </Link>
                  : <span key={index}>
                    {RichText.asText(item.city)}
                  </span>
              )).reduce((prev, curr) => [prev, ', ', curr])}
            </span>
          </li>
        )}
      </ul>
      {
        flags.ENABLED_COMMENTS_IN_DESTINATION && (
          <Comments
            average={roundedAverage}
            totalComments={total}
            destinationId={info.id}
            visible={visible}
            onClose={() => setVisible(false)}
            refetchReviewsSummary={refetchReviewsSummary}
          />
        )
      }
    </>
  )
}