// @vendors
import { useState } from 'react'

// @constants
import {
  QUERY_PARAM_AUTH_FORM,
  QUERY_PARAM_SEARCH_FORM,
  QUERY_PARAM_CREATE_TRIP_FORM,
  QUERY_PARAM_ADD_DESTINATION_TO_TRIP,
  QUERY_PARAM_SHARE_DESTINATION,
  QUERY_PARAM_NOTIFICATIONS_PANEL
} from 'constants/query-params'

// @hooks
import useSearchParams from 'hooks/useSearchParams'

const MODAL_OPENED = '1'

export default function useModals() {
  const searchParamsInfo = useSearchParams()
  const {
    addSearchParam,
    compairSearchParam,
    removeSearchParam
  } = searchParamsInfo

  const [loggedInEmail, setLoggedInEmail] = useState(null)

  const openModalWithSessionOnly = (openModalCallback, shouldHasSession = true) => {
    if (
      (shouldHasSession && loggedInEmail) ||
      (!shouldHasSession && !loggedInEmail)
    ) {
      return openModalCallback()
    }
  }

  const isModalOpened = (modalName) => {
    return compairSearchParam(modalName, MODAL_OPENED)
  }

  const onOpenModal = (modalName) => {
    return addSearchParam(modalName, MODAL_OPENED)
  }

  const onCloseModal = (modalName) => {
    return removeSearchParam(modalName)
  }

  const onOpenAuthForm = () => {
    openModalWithSessionOnly(() => onOpenModal(QUERY_PARAM_AUTH_FORM), false)
  }

  const onCloseAuthForm = () => {
    onCloseModal(QUERY_PARAM_AUTH_FORM)
  }

  const onOpenSearchSidebar = () => {
    onOpenModal(QUERY_PARAM_SEARCH_FORM)
  }

  const onCloseSearchSidebar = () => {
    onCloseModal(QUERY_PARAM_SEARCH_FORM)
  }

  const onOpenCreateTripForm = () => {
    openModalWithSessionOnly(() => onOpenModal(QUERY_PARAM_CREATE_TRIP_FORM))
  }

  const onCloseCreateTripForm = () => {
    onCloseModal(QUERY_PARAM_CREATE_TRIP_FORM)
  }

  const onOpenAddDestinationToTripForm = () => {
    openModalWithSessionOnly(() => onOpenModal(QUERY_PARAM_ADD_DESTINATION_TO_TRIP))
  }

  const onCloseAddDestinationToTripForm = () => {
    onCloseModal(QUERY_PARAM_ADD_DESTINATION_TO_TRIP)
  }

  const onOpenShareTrip = () => {
    onOpenModal(QUERY_PARAM_SHARE_DESTINATION)
  }

  const onCloseShareTrip = () => {
    onCloseModal(QUERY_PARAM_SHARE_DESTINATION)
  }

  const onOpenNotificationsPanel = () => {
    onOpenModal(QUERY_PARAM_NOTIFICATIONS_PANEL)
  }

  const onCloseNotificationsPanel = () => {
    onCloseModal(QUERY_PARAM_NOTIFICATIONS_PANEL)
  }
  return {
    searchParamsInfo,
    setLoggedInEmail,
    isModalOpened,
    onCloseAuthForm,
    onOpenAuthForm,
    onCloseSearchSidebar,
    onOpenSearchSidebar,
    onOpenCreateTripForm,
    onCloseCreateTripForm,
    onOpenAddDestinationToTripForm,
    onCloseAddDestinationToTripForm,
    openModalWithSessionOnly,
    onOpenShareTrip,
    onCloseShareTrip,
    onOpenNotificationsPanel,
    onCloseNotificationsPanel
  }
}
