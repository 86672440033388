// @vendors
import { useContext, useState } from 'react'
import { Button, Form, Input } from 'antd'

// @api
import { sendEmailToRecoveryPassword } from 'api/user'

// @context
import { AppContext } from 'context/app'
import { RegisterFormContext } from 'context/register-form'

// @hooks
import useFetch from 'hooks/useFetch'

// @utils
import { validateEmail } from 'utils/format'
import { notify } from 'utils/notifications'

export default function RecoveryForm({ onClose, onSubmit = () => { }, showPreviousButton }) {
  const { app: { onCloseAuthForm } } = useContext(AppContext)
  const { registerFormInfo } = useContext(RegisterFormContext)
  const { loading: sendingEmail, refetch: sendRecoveryEmail } = useFetch(
    sendEmailToRecoveryPassword,
    {
      showErrorMessage: false,
      showSuccessMessage: false,
      onSuccess: () => {
        notify('success', 'Te hemos enviado un correo para recuperar tu contraseña')
        onCloseAuthForm()
      },
      onError: () => {
        notify('error', 'Error enviando un correo')
      }
    }
  )
  const [helpText] = useState('Te enviaremos un enlace a tu correo electrónico para restablecer tu contraseña.')

  const onFinish = (values) => {
    sendRecoveryEmail([values.email])
  }

  return (
    <>
      <h3 className='title__ppal sm c--dr' style={{ marginBottom: '20px' }}>
        <strong>¿Olvidaste tu contraseña?</strong>
      </h3>
      <Form
        layout='vertical'
        onFinish={onFinish}
        fields={[
          {
            name: ['email'],
            value: registerFormInfo.email
          }
        ]}
      >
        <Form.Item
          label='Correo electrónico'
          name='email'
          help={helpText}
          rules={[
            {
              required: true,
              message: 'Este campo es obligatorio'
            },
            {
              validator(_, value) {
                if (validateEmail(value) || !value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Ingrese un correo válido'))
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item className='actions ant-form-item--last'>
          {showPreviousButton && (
            <Button className='ant-btn-only--link' onClick={onClose}>
              Volver
            </Button>
          )}
          <Button
            className='solid c-1'
            htmlType="submit"
            loading={sendingEmail}
          >
            Enviar enlace
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
