// @vendors
import PropTypes from 'prop-types'

export default function UserAvatar({ user }) {
  let content = user?.firstName?.slice(0, 1)

  if (!user) {
    content = <em className='fal fa-user' />
  }

  if (user && user.picture) {
    content = <img src={user.picture} alt={user.firstName} />
  }

  return content
}

UserAvatar.propTypes = {
  size: PropTypes.number,
  user: PropTypes.object
}

UserAvatar.defaultProps = {
  user: null
}
