// @vendors
import { Button, Form, Input, Select } from 'antd'

// @constants
import { DEPARTAMENTS } from 'constants/departaments'

// @utils
import { convertToSlug } from 'utils/format'

export default function ExperiencesForm() {
  return (
    <>
      <h3 className='title__ppal sm c--dr'>
        <strong>Sé parte</strong>
        <span>de los primeros</span>
      </h3>
      <Form layout='vertical'>
        <Form.Item
          label='Experiencia que ofrecerías'
          name='experience'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Departamento'
          name='depto'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Select placeholder='Seleccionar' showSearch>
            {DEPARTAMENTS.map((depto) => (
              <Select.Option value={depto.name} key={convertToSlug(depto.name)}>
                {depto.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Municipio donde estás ubicado(a)'
          name='city'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Tu nombre'
          name='name'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Correo'
          name='email'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Número de contacto'
          name='phone'
        >
          <Input />
        </Form.Item>
        <Form.Item className='actions ant-form-item--last'>
          <Button className='solid c-1' htmlType='submit'>
            <span className='text'>
              Recomendar
            </span>
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
