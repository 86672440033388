// @vendors
import {
  useCallback,
  useContext,
  useState
} from 'react'
import { Button, Form, Divider, Input } from 'antd'
import FacebookLogin from 'react-facebook-login'

// @hooks
import useFlags from 'hooks/useFlags'

// @components
import RecoveryForm from 'components/forms/recovery'

// @context
import { AppContext } from 'context/app'
import { RegisterFormContext, REGISTER_FORM_ACTIONS } from 'context/register-form'

// @constants
import { USER_REGISTER_TYPE } from 'constants/user'

// @utils
import { validateEmail } from 'utils/format'

export default function RegisterForm({ goToFullRegisterForm, onClose }) {
  const {
    app: {
      onCloseAuthForm
    },
    auth: {
      loginWithFacebook,
      loginWithGoogle,
      manualLogin
    }
  } = useContext(AppContext)
  const flags = useFlags()
  const {
    dispatchRegisterForm,
    registerFormInfo
  } = useContext(RegisterFormContext)
  const [isTryingToLogIn, setIsTryingToLogIn] = useState(false)
  const [alreadyFetchedBaseUserInfoType, setAlreadyFetchedBaseUserInfoType] = useState('')
  const [recoveryPassword, setRecoveryPassword] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  const handleManualLogin = async (values) => {
    setIsTryingToLogIn(true)
    const { error, result: manualUserInfo } = await manualLogin(values.email, values.password)

    if (error) {
      if (manualUserInfo.message === 'Contraseña incorrecta') {
        setPasswordError('Contraseña incorrecta')
      } else {
        dispatchRegisterForm({ payload: values.email, type: REGISTER_FORM_ACTIONS.SET_EMAIL })
        goToFullRegisterForm()
      }
    } else {
      if (manualUserInfo?.access_token) {
        onCloseAuthForm()
      } else {
        setAlreadyFetchedBaseUserInfoType(manualUserInfo.Item.source)
        if (manualUserInfo.Item.source === 'google') {
          handeLogin(loginWithGoogle)()
        }
      }
    }
    setIsTryingToLogIn(false)
  }

  const handeLogin = useCallback(loginCallback => () => {
    loginCallback()
    onClose()
  }, [])

  if (recoveryPassword) {
    return <RecoveryForm onClose={() => setRecoveryPassword(false)} showPreviousButton={true} />
  }

  const loggedInBy = {
    facebook: !alreadyFetchedBaseUserInfoType || alreadyFetchedBaseUserInfoType === USER_REGISTER_TYPE.FACEBOOK,
    google: !alreadyFetchedBaseUserInfoType || alreadyFetchedBaseUserInfoType === USER_REGISTER_TYPE.GOOGLE,
    manual: alreadyFetchedBaseUserInfoType === USER_REGISTER_TYPE.MANUAL
  }

  const isAnySocialButtonEnabled = flags.SHOW_SOCIAL_GOOGLE_BUTTON || flags.SHOW_SOCIAL_FACEBOOK_BUTTON

  return (
    <>
      <h3 className='title__ppal sm c--dr'>
        <strong>Ingresa o regístrate</strong>
      </h3>
      <Form
        layout='vertical'
        onFinish={handleManualLogin}
        fields={[
          {
            name: ['email'],
            value: registerFormInfo.email
          }
        ]}
      >
        <Form.Item
          label='Correo electrónico'
          name='email'
          rules={[
            {
              required: true,
              message: 'Este campo es obligatorio'
            },
            {
              validator(_, value) {
                if (validateEmail(value) || !value) {
                  dispatchRegisterForm({ payload: value, type: REGISTER_FORM_ACTIONS.SET_EMAIL })
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Ingrese un correo válido'))
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        {
          loggedInBy.manual && (
            <Form.Item
              label='Contraseña'
              name='password'
              help={passwordError || null}
              validateStatus={passwordError ? 'error' : ''}
              rules={[
                {
                  required: true,
                  message: 'Este campo es obligatorio'
                }
              ]}
            >
              <Input.Password
                onChange={() => {
                  setPasswordError('')
                }}
              />
            </Form.Item>
          )
        }
        <Form.Item className='actions ant-form-item--last'>
          <Button
            className='ant-btn-only--link'
            onClick={() => setRecoveryPassword(true)}
          >
            ¿Olvidaste tu contraseña?
          </Button>
          <Button
            className='solid c-1'
            htmlType="submit"
            loading={isTryingToLogIn}
          >
            Continuar
          </Button>
        </Form.Item>
        {
          isAnySocialButtonEnabled && !loggedInBy.manual && (
            <Divider plain>
              o
            </Divider>
          )
        }
        {
          isAnySocialButtonEnabled && (
            <div className='ant-form-item-social'>
              {
                flags.SHOW_SOCIAL_GOOGLE_BUTTON && loggedInBy.google && (
                  <Button
                    className='outline c-dr'
                    onClick={handeLogin(loginWithGoogle)}
                  >
                    <em className='fab fa-google' />
                    <span className='text'>Continuar con Google</span>
                  </Button>
                )
              }
              {
                flags.SHOW_SOCIAL_FACEBOOK_BUTTON && loggedInBy.facebook && (
                  <FacebookLogin
                    cssClass='ant-btn outline c-dr facebook'
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    fields='name,email,picture'
                    textButton={<span className='text'>Continuar con Facebook</span>}
                    callback={handeLogin(loginWithFacebook)}
                    cookie
                  />
                )
              }
            </div>
          )
        }
      </Form>
    </>
  )
}
