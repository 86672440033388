// @constants
export const MY_TRIPS = [
  {
    title: 'Oriente antioqueño',
    creation_date: '30 de Enero del 2021',
    finish_date: '',
    path: 'viajes/123',
    status: 'created',
    destinations: {
      total: 2,
      visited: 0
    },
    images: [
      {
        src: 'https://images.prismic.io/travelyando/86625d08-55a0-4456-9214-6d06deb63191_piedra-penol-thumb.jpg?auto=compress,format'
      },
      {
        src: 'https://images.prismic.io/travelyando/a20e65c6-57c4-449a-813c-7bd926453e54_represa-de-guatape.jpg?auto=compress,format'
      }
    ]
  },
  {
    title: 'Manizales y cercanías',
    creation_date: '21 de Enero del 2021',
    status: 'completed',
    path: 'viajes/123',
    destinations: {
      total: 1,
      visited: 1
    },
    images: [
      {
        src: 'https://images.prismic.io/travelyando-pnn/e1be25df-c6d7-40ff-9ecd-6085cf5ebf0e_panoramica-los-nevados.jpg?auto=compress,format'
      }
    ]
  },
  {
    title: 'Pueblos Patrimonios',
    creation_date: '15 de Enero del 2021',
    status: 'created',
    path: 'viajes/123',
    destinations: {
      total: 5,
      visited: 2
    },
    images: [
      {
        src: 'https://images.prismic.io/travelyando/d8b2c55c-8dc9-4dd7-b257-b04213ef6bc9_barichara-santander-thumb.jpg?auto=compress,format'
      },
      {
        src: 'https://images.prismic.io/travelyando/0e18bcf6-934c-4385-b490-50cbdf37241e_jardin-antioquia.jpg?auto=compress,format'
      },
      {
        src: 'https://images.prismic.io/travelyando/dbce009a-c268-44d0-86dd-7f05e46e5331_medellin-metrocable-thumb.jpg?auto=compress,format'
      }
    ]
  },
  {
    title: 'Travesía Tequendama, Tatacoa y Nevado',
    creation_date: '22 de Febrero del 2021',
    status: 'created',
    path: 'viajes/123',
    destinations: {
      total: 10,
      visited: 2
    },
    images: [
      {
        src: 'https://images.prismic.io/travelyando/7a15880e-a8c6-4cbf-93c8-686c07b8281e_salto-de-tequendama-cundinamarca.jpg?auto=compress,format'
      },
      {
        src: 'https://images.prismic.io/travelyando/5eec2e26-5296-4f0f-ae5e-0959852a0da5_desierto-tatacoa-4.jpg?auto=compress,format'
      },
      {
        src: 'https://images.prismic.io/travelyando-pnn/e1be25df-c6d7-40ff-9ecd-6085cf5ebf0e_panoramica-los-nevados.jpg?auto=compress,format'
      }
    ]
  }
]

export const TYPE_DESTINATIONS_WITH_HIDDEN_ADD_TO_TRIP_OPTION = [
  'trip',
  'departamentos',
  'departament'
]
