export default class LocalStorage {
  constructor(key) {
    this.key = key
  }

  get() {
    return JSON.parse(window.localStorage.getItem(this.key))
  }

  set(newValue) {
    window.localStorage.setItem(this.key, JSON.stringify(newValue))
  }

  remove(newValue) {
    window.localStorage.removeItem(this.key)
  }
}