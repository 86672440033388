export function convertToSlug(info) {
  if (!info) return ''
  return info
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export function validateEmail(email) {
  if (!email) {
    return true
  }

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email.match(regex)
}

export function pluralWord(word, number) {
  return `${word}${number > 1 ? 's' : ''}`
}

export function mapDestinationToSave(baseDestination) {
  return {
    caption: baseDestination.caption,
    imageUrl: baseDestination.image || baseDestination.imageUrl,
    imageAlt: baseDestination.imageAlt,
    title: baseDestination.title || baseDestination.name,
    linkTo: baseDestination.link || baseDestination.linkTo,
    visited: false
  }
}

export function shuffleArray(array) {
  const shuffleArray = []
  const shuffleIndees = array.sort((_, __) => 0.5 - Math.random())

  shuffleIndees.forEach((_, index) => {
    shuffleArray.push(array[index])
  })

  return shuffleArray
}

export function getPathFromUrl(url) {
  return url.replace(/^.*\/\/[^/]+/, '')
}