// @vendors
import { useCallback, useEffect, useState } from 'react'

// @utils
import LocalStorage from 'utils/local-storage'

export default function useLocalStorage(key) {
  const localStorageInstance = new LocalStorage(key)
  const [value, setValue] = useState(() => localStorageInstance.get())

  const setInfo = useCallback((newItem) => {
    localStorageInstance.set(newItem)
    setValue(newItem)
  }, [key])

  const removeInfo = useCallback(() => {
    localStorageInstance.remove()
    setValue(null)
  }, [key])

  useEffect(() => {
    setValue(localStorageInstance.get())
  }, [])

  return {
    info: value,
    removeInfo,
    setInfo
  }
}
