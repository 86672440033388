// @vendors
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from 'prismic-config'
import cx from 'classnames'

// @context
import { DestinationPageContext } from 'context/pages'

// @utils
import { customLink } from 'utils/prismic'
import { convertToSlug } from 'utils/format'

export default function Description() {
  const info = useContext(DestinationPageContext)

  const classes = {
    placeDates: cx('place__dates'),
    titlePpal: cx(
      'title__ppal',
      'c-dark',
      'md'
    )
  }

  return (
    <div className='dest__sidebar-caption-resume'>
      <h1 className={classes.titlePpal} translate='no'>
        <span className="destination">
          {info.dest_type}
          {info.dest_type === 'Parque Nacional' ? ' Natural' : ''}
          {info.dest_type === 'Ciudad' ? ' Capital' : ''}
        </span>
        {info.name}
        {
          info.dest_type === 'Ciudad' && (
            <span className="slogan">
              {`"${RichText.asText(info.slogan)}"`}
            </span>
          )
        }
      </h1>
      <div className={classes.placeDates}>
        {
          info.depto && info.depto.length > 0 && (
            <Link to={`/${convertToSlug(info.depto)}`} className="place__dates-item depto">
              <div className="text">Departamento: <strong translate='no'>{info.depto}</strong></div>
            </Link>
          )
        }
        {
          !!info.deptos && (
            <div className='place__dates-item depto'>
              <div className='text'>
                Departamento{info.deptos.length > 1 && 's'}:
                <div className='deptos'>
                  {
                    info.deptos
                      .map((depto, index) => (
                        <Link
                          to={`/${convertToSlug(depto.name)}`}
                          key={index}
                          className='depto-item'
                          translate='no'
                        >
                          {depto.name}
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ' , ', curr])
                  }
                </div>
              </div>
            </div>
          )
        }
        {
          !!info?.municipality?.length &&
          info.municipality[0].text && (
            <Link
              className={
                cx(
                  'place__dates-item municipality',
                  { 'place__dates-item-disabled': info.municipality_link?.link_type === 'Any' }
                )
              }
              to={linkResolver(info.municipality_link)}
            >
              <div className="text">
                Municipio: <strong translate='no'>{RichText.asText(info.municipality)}</strong>
              </div>
            </Link>
          )
        }
      </div>
      <div className="text__enr">
        {
          info.depto_nearby_city && info.dest_type === 'Parque Nacional'
            ? (
              <p>
                <strong>
                  Se encuentra a {RichText.asText(info.nearby_city_complement)} de
                  <Link
                    to={`/${convertToSlug(info.depto_nearby_city)}/${convertToSlug(RichText.asText(info.nearby_city))}`}
                    translate='no'
                  >
                    {RichText.asText(info.nearby_city)}
                  </Link>.
                </strong>
              </p>
            )
            : ''
        }
        <RichText
          render={info.dest_description}
          linkResolver={linkResolver}
          serializeHyperlink={customLink}
        />
      </div>
    </div>
  )
}