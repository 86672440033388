// @vendors
import { BrowserRouter as Router } from 'react-router-dom'

// @context
import { AppContextProvider } from 'context/app'

// @components
import TripsAdd from 'components/trips/add'
import TripCreate from 'components/trips/create'

// @pages
import Pages from 'pages'

// @hooks
import useApp from 'hooks/useApp'

// @api
import 'api/axios-config'

function MainWrapper({ children }) {
  const appContextValue = useApp()

  return (
    <AppContextProvider value={appContextValue}>
      {children}
    </AppContextProvider>
  )
}

export default function App() {
  return (
    <Router>
      <MainWrapper>
        <Pages />
        <TripsAdd />
        <TripCreate />
      </MainWrapper>
    </Router>
  )
}
