// @vendors
import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import cx from 'classnames'

// @components
import ModalShare from 'components/modal-share'

// @context
import { AppContext } from 'context/app'

// @constants
import {
  USER_INFO_LOCAL_STORAGE_KEY
} from 'constants/user'

// @hooks
import useFetch from 'hooks/useFetch'
import useLocalStorage from 'hooks/useLocalStorage'

// @utils
import { messageKey, notify } from 'utils/notifications'
import { useWindowDimensions } from 'utils/DOM-functions'

// @api
import {
  addDestinationAsVisited,
  getDestinationVisited,
  removeDestinationFromVisited
} from 'api/destination'

// @styles
import './styles.scss'

export default function Interactions({ destination }) {
  const {
    app: {
      onOpenShareTrip,
      onOpenAddDestinationToMyTrips,
      onOpenAuthForm
    },
    auth: { user },
    trips: { handleChangeAddToTripsVisibility }
  } = useContext(AppContext)
  const {
    info: accessToken
  } = useLocalStorage(USER_INFO_LOCAL_STORAGE_KEY)
  const params = useParams()
  const [isVisited, setIsVisited] = useState(false)
  const [classFixedPositionInteracion, setClassFixedPositionInteracion] = useState('')
  const { width } = useWindowDimensions()

  const { refetch: refetchVisitedDestination, loading: loadingIsVisited } = useFetch(
    getDestinationVisited,
    {
      onSuccess: (newVisited) => {
        setIsVisited(newVisited)
      },
      initLoading: !!accessToken
    }
  )

  const { error: errorAdding, refetch: markDestinationAsVisited } = useFetch(
    addDestinationAsVisited,
    {
      params: [user?.email, destination],
      onSuccess: () => {
        setIsVisited(true)
        const notificationInfo = messageKey.visited(destination.title).success
        notify('success', notificationInfo.description, notificationInfo.message)
      },
      onError: () => {
        setIsVisited(currentVisited => !currentVisited)
      }
    }
  )

  const { error: errorRemoving, refetch: markDestinationAsNotVisited } = useFetch(
    removeDestinationFromVisited,
    {
      params: [user?.email, destination.title],
      onSuccess: () => {
        setIsVisited(false)
        const notificationInfo = messageKey.notVisited(destination.title).success
        notify('error', notificationInfo.description, notificationInfo.message)
      },
      onError: () => {
        setIsVisited(currentVisited => !currentVisited)
      }
    }
  )

  const handleToggleVisited = useCallback(() => {
    if (!user) {
      notify(
        'error',
        <>Para agregar a visitados debes estar registrado. <div className="fake-link" onClick={onOpenAuthForm}>¿Qué esperas? ¡es gratis!</div></>,
        ''
      )
      return
    }
    setIsVisited(currentVisited => !currentVisited)

    if (isVisited) {
      markDestinationAsNotVisited()
    } else {
      markDestinationAsVisited()
    }
  }, [isVisited, user])

  function onScrollBody() {
    if (destination.type === 'departamentos') {
      if (width < 721) {
        if (document.querySelector('body').scrollTop >= 135) {
          setClassFixedPositionInteracion('dest__sidebar-interactions--mobile')
        } else {
          setClassFixedPositionInteracion('')
        }
      }

      if (width > 720 && width < 861) {
        if (document.querySelector('body').scrollTop >= 180) {
          setClassFixedPositionInteracion('dest__sidebar-interactions--tablet')
        } else {
          setClassFixedPositionInteracion('')
        }
        if (document.querySelector('body').scrollTop >= 100) {
          document.getElementsByClassName('header__ppal')[0].classList.add('header__ppal-active--scroll')
        } else {
          document.getElementsByClassName('header__ppal')[0].classList.remove('header__ppal-active--scroll')
        }
      }
    } else {
      if (width < 721) {
        if (document.querySelector('body').scrollTop >= 290) {
          setClassFixedPositionInteracion('dest__sidebar-interactions--mobile')
        } else {
          setClassFixedPositionInteracion('')
        }
      }

      if (width > 720 && width < 861) {
        if (document.querySelector('body').scrollTop >= 380) {
          setClassFixedPositionInteracion('dest__sidebar-interactions--tablet')
        } else {
          setClassFixedPositionInteracion('')
        }
        if (document.querySelector('body').scrollTop >= 100) {
          document.getElementsByClassName('header__ppal')[0].classList.add('header__ppal-active--scroll')
        } else {
          document.getElementsByClassName('header__ppal')[0].classList.remove('header__ppal-active--scroll')
        }
      }
    }
  }

  useEffect(() => {
    if (errorAdding) {
      const notificationInfo = messageKey.visited(destination.title).error
      notify('error', notificationInfo.description, notificationInfo.message)
    }
  }, [errorAdding])

  useEffect(() => {
    if (errorRemoving) {
      const notificationInfo = messageKey.notVisited(destination.title).error
      notify('error', notificationInfo.description, notificationInfo.message)
    }
  }, [errorRemoving])

  useEffect(() => {
    if (params.departmentId && user?.email) {
      refetchVisitedDestination([user?.email, destination.title])
    }
  }, [destination.title])

  useEffect(() => {
    if (user?.email) {
      refetchVisitedDestination([user?.email, destination.title])
    }
  }, [user])

  useEffect(() => {
    document.querySelector('body').addEventListener('scroll', () => {
      onScrollBody()
    })

    return () => {
      document.querySelector('body').removeEventListener('scroll', () => {
        onScrollBody()
      })
    }
  }, [width])

  const classes = {
    action: cx(
      'fa-heart',
      { fas: isVisited },
      { fal: !isVisited }
    ),
    actionItem: cx({ active: isVisited })
  }

  const shouldHideAddToTripButton = destination.type === 'departamentos'

  return (
    <>
      <ul className={`dest__sidebar-interactions ${classFixedPositionInteracion}`}>
        {loadingIsVisited && (
          <li className='disabled'>
            <em className='fal fa-heart' />
          </li>
        )}
        {!loadingIsVisited && (
          <Tooltip
            placement='left'
            title={isVisited ? 'No lo he visitado' : '¿Ya estuviste aquí?'}
          >
            <li
              onClick={handleToggleVisited}
              className={`dest__sidebar-interactions-visited ${classes.actionItem}`}
            >
              <em className={classes.action} />
            </li>
          </Tooltip>
        )}
        {
          !shouldHideAddToTripButton && (
            <Tooltip
              placement='left'
              title='Agregar a Viajes'
            >
              <li onClick={handleChangeAddToTripsVisibility(true, onOpenAddDestinationToMyTrips, destination)}>
                <em className='fal fa-bookmark' />
              </li>
            </Tooltip>
          )
        }
        <Tooltip placement='left' title='Compartir'>
          <li onClick={onOpenShareTrip}>
            <em className='fal fa-share-alt' />
          </li>
        </Tooltip>
      </ul>
      <ModalShare
        destination={destination}
      />
    </>
  )
}

Interactions.propTypes = {
  destination: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string
  }).isRequired
}
