// @vendors
import { useContext, useRef } from 'react'
import { Button, Modal, Typography } from 'antd'
import cx from 'classnames'

// @api
import { updateUserImage } from 'api/user'

// @context
import { AppContext } from 'context/app'

// @hooks
import useFetch from 'hooks/useFetch'
import useCompressImage from 'hooks/useCompressImage'

// @styles
import './styles.scss'

export default function ChangePhoto({ visible, onClose, photo }) {
  const { auth: { user, setUser } } = useContext(AppContext)
  const previewRef = useRef()
  const {
    image,
    setImage,
    onUpload,
    onSelect
  } = useCompressImage({
    previewRef,
    onFetch: (reader) => {
      refetch(
        [user.email, reader.result, user.picture],
        ({ picture }) => {
          onClose()

          const updatedUser = {
            ...user,
            picture
          }
          setUser({ ...user, picture: '' })

          setTimeout(() => {
            setUser({ ...updatedUser })
          }, 100)
        }
      )
    }
  })
  const { loading, refetch } = useFetch(updateUserImage)
  const defaultImage = image || photo

  return (
    <Modal
      footer={null}
      title={null}
      width='400px'
      centered
      wrapClassName={
        cx(
          'modal__default',
          'modal__default--full-mobile',
          'modal__steps',
          'modal__auto'
        )
      }
      visible={visible}
      onCancel={onClose}
    >
      <div className='step__message'>
        <h3 className='title__ppal sm c--dr'>
          <span>Cambiar</span>
          <strong>Foto de perfil</strong>
        </h3>
        <div className='upload__photo'>
          <div className='current__photo'>
            {!defaultImage && user?.firstName?.slice(0, 1)}
            {defaultImage && <img ref={previewRef} src={defaultImage} alt='Imagen de Usuario' />}
          </div>
          <Button className='solid c-1' disabled={loading}>
            Seleccionar foto
            <input type='file' onChange={onSelect} accept='image/jpg, image/png, image/jpeg' />
          </Button>
          <Typography.Text type='secondary'>
            <p>Sólo archivos JPEG, JPG, PNG</p>
          </Typography.Text>
        </div>
        {image && (
          <div className='step__message-actions'>
            <Button
              className='solid c-2'
              disabled={!image}
              loading={loading}
              onClick={onUpload}
            >
              Cambiar
            </Button>
            <Button
              className='outline c-dr'
              disabled={loading}
              onClick={() => setImage('')}>
              Cancelar
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}