// @vendors
import { Button, Form, Input, Select } from 'antd'
import moment from 'moment'

// @components
import UserAvatar from 'components/user-avatar'

// @constants
import { COUNTRIES } from 'constants/countries'
import { GENERES } from 'constants/default'

// @utils
import { convertToSlug } from 'utils/format'

export default function GeneralUserForm({
  editData,
  setEditData,
  editPass,
  onUpdateUser,
  user,
  onOpenPhotoEditor,
  loading
}) {
  return (
    <Form
      layout='vertical'
      onFinish={onUpdateUser}
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender,
        nationality: user.nationality
      }}
    >
      <Form.Item
        label=''
        className='ant-form-item-photo'
      >
        <div
          className='current__photo'
          onClick={onOpenPhotoEditor}
        >
          <UserAvatar user={user} />
          <Button
            className='solid c-2 ant-btn-only--icon'
            onClick={() => { }}
          >
            <em className='fal fa-pencil' />
          </Button>
        </div>
      </Form.Item>
      {
        !editData && (
          <>
            <Form.Item label="Nombre completo">
              <div className='subtitle__ppal xs c-dr'>
                {user.firstName} {user.lastName}
              </div>
            </Form.Item>
            <Form.Item label='Género'>
              <div className='subtitle__ppal xs c-dr'>
                <strong>{user.gender}</strong>
              </div>
            </Form.Item>
            <Form.Item label='Fecha de nacimiento'>
              <div className='subtitle__ppal xs c-dr'>
                <strong>{moment(user.birthday).format('LL')}</strong>
              </div>
            </Form.Item>
            <Form.Item label='Correo electrónico'>
              <div className='subtitle__ppal xs c-dr'>
                <strong>{user.email}</strong>
              </div>
            </Form.Item>
            <Form.Item label='Nacionalidad'>
              <div className='subtitle__ppal xs c-dr'>
                <strong>{user.nationality}</strong>
              </div>
            </Form.Item>
          </>
        )
      }
      {
        editData && (
          <>
            <Form.Item
              label="Nombre"
              name="firstName"
              rules={[{
                required: true,
                message: 'Este campo es obligatorio'
              }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label='Apellidos'
              name="lastName"
              rules={[{
                required: true,
                message: 'Este campo es obligatorio'
              }]}
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label='Género'
              name='gender'
              rules={[{
                required: true,
                message: 'Este campo es obligatorio'
              }]}
            >
              <Select placeholder='Seleccionar' disabled={loading}>
                {GENERES.map((item) => (
                  <Select.Option
                    value={item.value}
                    key={item.value}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label='Fecha de nacimiento'>
              <div className='subtitle__ppal xs c-dr'>
                <strong>{moment(user.birthday).format('LL')}</strong>
              </div>
            </Form.Item>
            <Form.Item label='Correo electrónico'>
              <div className='subtitle__ppal xs c-dr'>
                <strong>{user.email}</strong>
              </div>
            </Form.Item>
            <Form.Item
              label='Nacionalidad'
              name='nationality'
              rules={[{
                required: true,
                message: 'Este campo es obligatorio'
              }]}
            >
              <Select
                disabled={loading}
                placeholder='Seleccionar'
                showSearch
              >
                {COUNTRIES.map((country) => (
                  <Select.Option
                    value={country.name}
                    key={convertToSlug(country.code)}
                  >
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )
      }
      <Form.Item className='actions actions-one ant-form-item--last'>
        {!editData && (
          <Button
            className='outline c-dr'
            onClick={() => setEditData(true)}
            disabled={editPass}
          >
            <em className='fal fa-pencil' />
            <span className='text'>
              Editar
            </span>
          </Button>
        )}
        {editData && (
          <>
            <Button
              className='outline c-dr'
              disabled={loading}
              onClick={() => setEditData(false)}
            >
              <span className='text'>
                Cancelar
              </span>
            </Button>
            <Button
              className='solid c-2'
              htmlType="submit"
              loading={loading}
            >
              <em className='fal fa-check' />
              <span className='text'>
                Guardar
              </span>
            </Button>
          </>
        )}
      </Form.Item>
    </Form>
  )
}