// @vendors
import { Button } from 'antd'
import { RichText } from 'prismic-reactjs'
import ScrollContainer from 'react-indiana-drag-scroll'

// @utils
import { onScroll } from 'utils/on-scroll'

// @styles
import './styles.scss'

export default function Dictionary({ info }) {
  return (
    <div className="list__items-dictionary">
      <Button
        className='solid c-wh ant-btn-only--icon ant-btn-prev'
        onClick={onScroll(-300, 'dictionary-container')}
      >
        <em className='fal fa-chevron-left' />
      </Button>
      <Button
        className='solid c-wh  ant-btn-only--icon ant-btn-next'
        onClick={onScroll(300, 'dictionary-container')}
      >
        <em className='fal fa-chevron-right' />
      </Button>
      <ScrollContainer className="list__items-dictionary-container">
        {info.map((item, k) => (
          <div className="item" key={k}>
            <div className="word">
              <em className="far fa-language" />
              <span translate='no'>{RichText.asText(item.word)}</span>
            </div>
            <div className="description">
              <i>Def:</i> {RichText.asText(item.meaning)}
            </div>
            {item.examples && item.examples.length > 0 && (
              <div className="examples">
                <div className="subtitle">
                  Ejemplos:
                </div>
                <div className="examples__list">
                  <RichText render={item.examples} />
                </div>
              </div>
            )}
          </div>
        ))}
      </ScrollContainer>
    </div>
  )
}
