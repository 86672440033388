// @vendors
import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import ImageGallery from 'react-image-gallery'
import { Skeleton, Button, Tooltip, Popover, Typography } from 'antd'

// @assets
import 'assets/sass/components/_destination-layout.scss'

// @constants
import { ROUTES } from 'constants/routes'
import { DEFAULT } from 'constants/default'

// @context
import { DestinationPageContextProvider } from 'context/pages'

// @hooks
import useDestinationPage from 'hooks/useDestinationPage'

// @components
import Caption from 'components/caption'
import Interactions from 'components/caption/interactions'

// @utils
import { prismicNationalParkToNatinoalPark } from 'utils/destination'

const MessageReport = () => {
  return (
    <>
      <h1 className='title__ppal xs'>
        Informar un problema
      </h1>
      <Typography.Text type='secondary'>
        Por favor visite nuestro centro de ayuda para obtener instrucciones sobre cómo denunciar <Link to={ROUTES.LEGALS.REPORT}>infracciones de derechos de autor</Link>.
      </Typography.Text>
    </>
  )
}

export default function NationalParkPage() {
  const { destinationPageInfo, loading } = useDestinationPage('clientPNN', 'pnn', 'nationalParkId')
  const { pathname } = useLocation()

  if (loading) {
    return (
      <>
        <div className='dest__gallery' />
        <div className='dest__sidebar'>
          <div className='dest__sidebar-caption'>
            <div className='dest__sidebar-caption-resume'>
              <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <DestinationPageContextProvider value={destinationPageInfo}>
      <Helmet>
        <title>
          {RichText.asText(destinationPageInfo.dest_name)}, {destinationPageInfo.dest_type} Natural {DEFAULT.site.name}
        </title>
      </Helmet>
      <div className='dest__gallery'>
        <ImageGallery
          items={destinationPageInfo.dest_photos.map(({ photo: { url, copyright } }) => ({
            original: url,
            thumbnail: url,
            description: `Foto: ${copyright || 'Háblanos para créditos'}`
          }))}
          showPlayButton={false}
          showThumbnails={false}
          showNav
          showIndex
          additionalClass='image-gallery-banner'
        />
        <Tooltip placement='left' title='Informar'>
          <Popover
            title=""
            content={<MessageReport />}
            trigger='click'
            overlayClassName='popover__default popover__default-compact'
          >
            <Button className='report-problem'><em className='fal fa-flag' /></Button>
          </Popover>
        </Tooltip>
      </div>
      <div className='dest__sidebar'>
        <Interactions
          destination={prismicNationalParkToNatinoalPark(destinationPageInfo, pathname)}
        />
        <Caption />
      </div>
    </DestinationPageContextProvider>
  )
}
