// @vendors
import { RichText } from 'prismic-reactjs'

// @styles
import './styles.scss'

export default function Generic({ info }) {
  return (
    <section className={`sec__default generic ${info.image_orientation}`}>
      <div className="generic__image">
        <img src={info.image.url} alt={info.image.alt} />
      </div>
      <div className='text__enr'>
        <RichText render={info.description} />
      </div>
    </section>
  )
}
