// @vendors
import { useCallback, useEffect, useState } from 'react'
import { Predicates } from 'prismic-javascript'

// @utils
import { clientProviders } from 'utils/prismic'

export default function useProviders(customType, name) {
  const [loading, setLoading] = useState(true)
  const [providers, setProviders] = useState(null)

  const getProviders = useCallback(async() => {
    try {
      const providers = await clientProviders.query(
        [
          Predicates.at('document.type', customType),
          Predicates.at('document.tags', [name])
        ],
        { orderings: '[document.last_publication_date desc]' }
      )
      setProviders(providers.results)
      setLoading(false)
    } catch (error) {
      console.warn('Error getting blog info', error)
    }
  }, [])

  useEffect(() => {
    getProviders()
  }, [])

  return {
    providers,
    loading
  }
}
