import { Link } from 'react-router-dom'
import Prismic from 'prismic-javascript'
import {
  apiEndpoint,
  apiEndpointPNN,
  apiEndpointPages,
  apiEndpointProviders,
  accessToken,
  accessTokenPNN,
  accessTokenPages,
  accessTokenProviders,
  linkResolver
} from 'prismic-config'

export const customLink = (type, element, content, children, index) => {
  if (element.data.link_type === 'Web') {
    return (
      <Link to={{ pathname: element.data.url }} target='_parent' key={index}>
        {content}
      </Link>
    )
  }
  return (
    <Link to={linkResolver(element.data)} key={index}>
      {content}
    </Link>
  )
}

export const client = Prismic.client(apiEndpoint, { accessToken })
export const clientPGS = Prismic.client(apiEndpointPages, { accessTokenPages })
export const clientPNN = Prismic.client(apiEndpointPNN, { accessTokenPNN })
export const clientProviders = Prismic.client(apiEndpointProviders, { accessTokenProviders })
