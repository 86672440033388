// @vendors
import { createContext, useContext, useEffect, useState } from 'react'
import { Button, Tooltip, Skeleton } from 'antd'
import { Helmet } from 'react-helmet'

// @components
import TripsList from 'components/trips/list'
import { EmptyData } from 'components/empty'

// @context
import { AppContext } from 'context/app'

// @constants
import { DEFAULT } from 'constants/default'
import { INVITATION_ACCEPTED } from 'constants/broadcast-channels'
import { QUERY_PARAM_CREATE_TRIP_FORM } from 'constants/query-params'

// @hooks
import useMyTrips, { filterTripsTypes } from 'hooks/useMyTrips'

// @utils
import Broadcaster from 'utils/broadcaster'

// @styles
import './styles.scss'

const MyTripsContext = createContext()
const MyTripsContextProvider = MyTripsContext.Provider

function MyTripsFilters() {
  const { filterType, myTrips, setFilterType } = useContext(MyTripsContext)

  return (
    !!myTrips.all.length && (
      <ul className='filters__category solid c-2' style={{ marginBottom: '30px' }}>
        {
          !!myTrips.all.length && (
            <li
              onClick={setFilterType(filterTripsTypes.all)}
            >
              <div
                className={filterType === filterTripsTypes.all ? 'active' : ''}
              >
                Todos
                <span className='count'>
                  {myTrips.all.length}
                </span>
              </div>
            </li>
          )
        }
        {
          !!myTrips.current.length && (
            <li
              onClick={setFilterType(filterTripsTypes.current)}
            >
              <div
                className={filterType === filterTripsTypes.current ? 'active' : ''}
              >
                Actuales
                <span className='count'>
                  {myTrips.current.length}
                </span>
              </div>
            </li>
          )
        }
        {
          !!myTrips.completed.length && (
            <li
              onClick={setFilterType(filterTripsTypes.completed)}
            >
              <div
                className={filterType === filterTripsTypes.completed ? 'active' : ''}
              >
                Completados
                <span className='count'>
                  {myTrips.completed.length}
                </span>
              </div>
            </li>
          )
        }
        {
          !!myTrips.invited.length && (
            <li
              onClick={setFilterType(filterTripsTypes.invited)}
            >
              <div
                className={filterType === filterTripsTypes.invited ? 'active' : ''}
              >
                Invitados
                <span className='count'>
                  {myTrips.invited.length}
                </span>
              </div>
            </li>
          )
        }
      </ul>
    )
  )
}

function MyTripsList() {
  const { filterType, myTrips } = useContext(MyTripsContext)
  const tripsToShow = myTrips[filterType]

  return (
    <div
      className='list__myTrips list__myTrips-two'
    >
      {tripsToShow.map((trip, index) => (
        <TripsList
          trip={trip}
          link={true}
          key={`trip-modal-${index}`}
        />
      ))}
    </div>
  )
}

export default function Trips() {
  const {
    app: {
      onOpenCreateTripForm,
      openModalWithSessionOnly,
      isModalOpened
    }
  } = useContext(AppContext)
  const [isMounted, setisMounted] = useState(false)
  const myTripsContextInfo = useMyTrips({
    fetchOnMount: true
  })

  const {
    myTrips,
    loading,
    refetchMyTrips,
    addNewTrip
  } = myTripsContextInfo

  const openCreateTrip = openModalWithSessionOnly(() => isModalOpened(QUERY_PARAM_CREATE_TRIP_FORM))

  useEffect(() => {
    if (isMounted && !openCreateTrip) {
      refetchMyTrips()
    }
  }, [openCreateTrip])

  useEffect(() => {
    setisMounted(true)

    const acceptedTripChannel = new Broadcaster(INVITATION_ACCEPTED)
    acceptedTripChannel.sub((event) => {
      addNewTrip(event.data)
    })
  }, [])

  return (
    <MyTripsContextProvider value={myTripsContextInfo}>
      <Helmet>
        <title>
          Viajes {DEFAULT.site.name}
        </title>
      </Helmet>
      <div className='container my-trips-page'>

        {
          !!loading && <>
            <Skeleton
              className='ant-skeleton-basic ant-skeleton-basic-filters'
              paragraph={{ rows: 2 }}
              active
            />
            <div
              className='list__myTrips list__myTrips-two'
            >
              <Skeleton className='ant-skeleton-trips' paragraph={{ rows: 3 }} active />
              <Skeleton className='ant-skeleton-trips' paragraph={{ rows: 3 }} active />
            </div>
          </>
        }
        {!loading &&
          <>
            <Tooltip
              placement='left'
              title='Crear nuevo viaje'
            >
              <Button
                className='solid c-1 ant-btn-add-new'
                onClick={onOpenCreateTripForm}
              >
                <em className='fal fa-plus' />
              </Button>
            </Tooltip>
            <h3 className='title__ppal md c-dr'>
              <strong>Viajes</strong>
            </h3>

            <MyTripsFilters />
            {
              !myTrips.all.length && (
                <EmptyData type='trips' />
              )
            }

            <MyTripsList />
          </>
        }
      </div>
    </MyTripsContextProvider>
  )
}