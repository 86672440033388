// @vendors
import { Modal } from 'antd'
import cx from 'classnames'

// @components
import ReportCommentForm from 'components/forms/report-comment'

export default function ReportComment({
  visible,
  onClose,
  destinationId,
  selectedComment
}) {
    return (
        <Modal
            destroyOnClose
            footer={null}
            title={null}
            width='500px'
            centered
            wrapClassName={
                cx(
                    'modal__default',
                    'modal__default--full-mobile',
                    'modal__steps',
                    'modal__auto'
                )
            }
            visible={visible}
            onCancel={onClose}
        >
            <div className='step__message'>
                <h5 className='title__ppal sm c--dr'>
                    <strong><em className='fal fa-flag' /> Reportar reseña</strong>
                </h5>
                <ReportCommentForm
                  destinationId={destinationId}
                  onClose={onClose}
                  selectedComment={selectedComment}
                />
            </div>
        </Modal>
    )
}