// @vendors
import { useContext, useEffect, useState, useCallback } from 'react'
import { Button, Skeleton, Typography } from 'antd'
import ScrollContainer from 'react-indiana-drag-scroll'
import cx from 'classnames'

// @context
import { DestinationPageContext } from 'context/pages'

// @constants
import { DEFAULT } from 'constants/default'

// @components
import DrawerProvider from 'components/drawer-provider'

// @hook
import useProviders from 'hooks/useProviders'

// @utils
import { convertToSlug } from 'utils/format'

// @images
import CoffeeImage from 'assets/images/shapes/coffee-landscape.png'

// @styles
import './styles.scss'

const { Text } = Typography

const FILTERS_CATEGORIES = [
  {
    FILTER: 'restaurants',
    LABEL: 'Restaurantes'
  },
  {
    FILTER: 'lodgings',
    LABEL: 'Hospedajes'
  },
  {
    FILTER: 'experiences',
    LABEL: 'Experiencias'
  }
]

const getHightPriorityInfoFromProviders = (lodgings, restaurants, experiences) => {
  if (restaurants?.length) {
    return {
      data: restaurants ?? [],
      filter: FILTERS_CATEGORIES[0].FILTER
    }
  }
  if (lodgings?.length) {
    return {
      data: lodgings ?? [],
      filter: FILTERS_CATEGORIES[1].FILTER
    }
  }

  return {
    data: experiences ?? [],
    filter: FILTERS_CATEGORIES[2].FILTER
  }
}

export default function Providers() {
  const info = useContext(DestinationPageContext)
  const { providers: experiences, loading: loadingExperiences } = useProviders('experience', info.name)
  const { providers: lodgings, loading: loadingLodgings } = useProviders('lodging', info.name)
  const { providers: restaurants, loading: loadingRestaurants } = useProviders('restaurant', info.name)
  const [selectedProviders, setSelectedProviders] = useState(
    {
      data: null,
      filter: ''
    }
  )
  const [selectedProvider, setSelectedProvider] = useState({})
  const [openDrawerProvider, setOpenDrawerProvider] = useState(false)
  const handleSetProviderData = useCallback((item) => () => {
    setOpenDrawerProvider(currentOpenedModal => !currentOpenedModal)
    setSelectedProvider({ item })
  }, [])

  const providersInfo = {
    experiences,
    restaurants,
    lodgings
  }

  const openWhatsApp = () => {
    const baseUrl = 'https://wa.me/57'
    const wahtsappNumber = DEFAULT.whatsapp.providers.number
    const text = DEFAULT.whatsapp.providers.message
    const departmentName = info.deptos?.length ? info.deptos[0].name : info.depto
    window.open(`${baseUrl}${wahtsappNumber}?text=${text} ${info.name}, ${departmentName}`)
  }

  useEffect(() => {
    setSelectedProviders(
      getHightPriorityInfoFromProviders(lodgings, restaurants, experiences)
    )
  }, [lodgings, restaurants, experiences])

  if (loadingExperiences || loadingLodgings || loadingRestaurants) {
    return (
      <div className='dest__sidebar-caption-reservation no-data'>
        <Skeleton className='ant-skeleton-text' paragraph={{ rows: 2 }} active />
      </div>
    )
  }

  if (
    !experiences?.length &&
    !lodgings?.length &&
    !restaurants?.length
  ) {
    return (
      <div className='dest__sidebar-caption-reservation no-data' style={{ backgroundImage: `url(${CoffeeImage})` }}>
        <h4 className='title__ppal xs c-dr'>
          <strong>
            Hoteles, Restaurantes y Experiencias
          </strong>
        </h4>
        <Text type='secondary'>
          Si tienes un hotel, restaurante u ofreces una experiencia en este destino o cerca, escríbenos y te contamos como puedes estar en esta sección.
        </Text>
        <Button
          onClick={openWhatsApp}
          className='solid c-1'
        >
          <em className='fab fa-whatsapp' /> Hablemos
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className='dest__sidebar-caption-reservation'>
        <ScrollContainer
          className='filters__category solid c-2'
          component='ul'
        >
          {FILTERS_CATEGORIES.map((category) => (
            <li
              key={`${convertToSlug(info.name)}-${category.FILTER}-providers`}
              id={`${convertToSlug(info.name)}-${category.FILTER}-providers`}
              onClick={() => {
                setSelectedProviders(
                  {
                    data: providersInfo[category.FILTER],
                    filter: category.FILTER
                  }
                )
              }}
            >
              <div
                className={
                  cx(
                    { active: selectedProviders.filter === category.FILTER }
                  )
                }
              >
                {category.LABEL}
              </div>
            </li>
          ))}
        </ScrollContainer>
        {!selectedProviders?.data?.length && (
          <div
            className='empty__data'
          >
            <div className='text__enr-2' style={{ marginRight: '10px' }}>
              <p style={{ lineHeight: '1.3' }}>
                <strong style={{ fontSize: '20px' }}>¡Tu negocio podría estar aquí!</strong> Para conocer nuestras promociones, da clic en el siguiente botón.</p>
              <Button
                onClick={openWhatsApp}
                className='solid c-1'
              >
                <em className='fab fa-whatsapp' /> Hablemos
              </Button>
            </div>
            <img src={CoffeeImage} alt='Coffe Image' />
          </div>
        )}
        {selectedProviders.data && (
          <ScrollContainer className='list__items-providers'>
            {(selectedProviders.data ?? []).map((item, index) => (
              <article
                className={`list__items-providers-item ${selectedProviders.filter}`}
                key={`provider-${selectedProviders.filter}-${index}`}
                id={`provider-${selectedProviders.filter}-${convertToSlug(item.data.name)}-${convertToSlug(info.uid)}-${convertToSlug(info.depto)}`}
                onClick={handleSetProviderData(item)}
              >
                <div className='provider--content'>
                  <div className='provider--content-header'>
                    <img src={item.data.logo.url} alt={item.data.logo.alt} />
                  </div>
                  <div className='provider--content-body'>
                    <h5 className='title__ppal xxs c-dr'>
                      {item.data.name && item.data.name}
                    </h5>
                    <Typography.Text type='secondary'>
                      {item.data.description && item.data.description[0].text}
                    </Typography.Text>
                  </div>
                </div>
              </article>
            ))}
          </ScrollContainer>
        )}
      </div>
      {selectedProviders.data && (
        <DrawerProvider
          visible={openDrawerProvider}
          onClose={() => setOpenDrawerProvider(false)}
          data={selectedProvider}
          destination={info.name}
        />
      )}
    </>
  )
}
