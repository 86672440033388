export default function MailBox() {
    return (
        <svg
            id="prefix__Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 392 354"
            xmlSpace="preserve"
        >
            <style>
                {
                    '.prefix__st0{fill:#e7edf4}.prefix__st2{opacity:.5;fill:#fff;enable-background:new}.prefix__st4{fill:#8e9ca7}.prefix__st5{fill:none;stroke:#000;stroke-miterlimit:10}.prefix__st6,.prefix__st7{fill:#2e353a;stroke:#2e353a;stroke-linecap:round;stroke-linejoin:round}.prefix__st7{fill:#92e3a9}.prefix__st9{opacity:.3;enable-background:new}.prefix__st10,.prefix__st11,.prefix__st12{fill:none;stroke:#2e353a;stroke-linecap:round;stroke-linejoin:round}.prefix__st11,.prefix__st12{fill:#fff}.prefix__st12{stroke-width:1.2629}'
                }
            </style>
            <g id="prefix__freepik--background-simple--inject-1--inject-301">
                <path
                    className="prefix__st0"
                    d="M320 81.7c-15.6-8.5-31.8-11.9-49.5-9.8-21.8 2.7-39 5.9-59.9-2.3-17.5-6.8-33.5-17-50.3-25.4-34.2-17.3-68.2-22.7-93.8 11.5-13.6 18.2-24 39.1-31.2 61.2-11.6 35.6-21.1 75-15.8 112.2 6.6 47.1 47 69.2 95.3 52.2 19.6-6.9 38.4-17 59.3-19.9 43.6-6.1 79.6 34 131.9 24.3 90.3-16.8 83.7-165.9 14-204z"
                />
                <path
                    d="M320 81.7c-15.6-8.5-31.8-11.9-49.5-9.8-21.8 2.7-39 5.9-59.9-2.3-17.5-6.8-33.5-17-50.3-25.4-34.2-17.3-68.2-22.7-93.8 11.5-13.6 18.2-24 39.1-31.2 61.2-11.6 35.6-21.1 75-15.8 112.2 6.6 47.1 47 69.2 95.3 52.2 19.6-6.9 38.4-17 59.3-19.9 43.6-6.1 79.6 34 131.9 24.3 90.3-16.8 83.7-165.9 14-204z"
                    opacity={0.7}
                    fill="#fff"
                />
            </g>
            <g id="prefix__freepik--Clouds--inject-1--inject-301">
                <path
                    className="prefix__st0"
                    d="M333.6 43.3c-6.4-3.5-9.2-.6-11.5-3.5s6.4-10.4-11-17.9c0 0-1.1-16.2-24.8-16.7S269 21.3 262.6 25.4s-8.1-2.3-14.4 1.7-3.5 12.1-8.6 13.3-12.1-5.2-18.5-1.2-6.4 7.5-9.2 7.5-8.7-2.3-12.1 0-7.5 5.2-7.5 5.2h146c-.1 0 1.7-5.2-4.7-8.6z"
                />
                <path
                    className="prefix__st2"
                    d="M333.6 43.3c-6.4-3.5-9.2-.6-11.5-3.5s6.4-10.4-11-17.9c0 0-1.1-16.2-24.8-16.7S269 21.3 262.6 25.4s-8.1-2.3-14.4 1.7-3.5 12.1-8.6 13.3-12.1-5.2-18.5-1.2-6.4 7.5-9.2 7.5-8.7-2.3-12.1 0-7.5 5.2-7.5 5.2h146c-.1 0 1.7-5.2-4.7-8.6z"
                />
                <path
                    d="M168.1 173c-4.5-2.4-6.5-.4-8.1-2.4s4.5-7.3-7.7-12.6c0 0-.8-11.4-17.4-11.8s-12.2 11.4-16.6 14.2-5.7-1.6-10.1 1.2-2.4 8.5-6.1 9.3-8.5-3.6-13-.8-4.5 5.3-6.5 5.3-6.1-1.6-8.5 0-5.3 3.6-5.3 3.6h102.6c-.1.1 1.1-3.6-3.3-6z"
                    fill="#fff"
                />
                <path
                    className="prefix__st2"
                    d="M168.1 173c-4.5-2.4-6.5-.4-8.1-2.4s4.5-7.3-7.7-12.6c0 0-.8-11.4-17.4-11.8s-12.2 11.4-16.6 14.2-5.7-1.6-10.1 1.2-2.4 8.5-6.1 9.3-8.5-3.6-13-.8-4.5 5.3-6.5 5.3-6.1-1.6-8.5 0-5.3 3.6-5.3 3.6h102.6c-.1.1 1.1-3.6-3.3-6z"
                />
            </g>
            <g id="prefix__freepik--Plants--inject-1--inject-301">
                <path
                    className="prefix__st4"
                    d="M294.9 342h63.2c-1.2-7.7-6.2-6.4-6.8-18.4-.3-6 5.3-13.5 4.8-21.4-.4-6.5-6.8-13.2-7.3-20.7-.5-6.7 5-14.2 4.5-21.5-.5-6.9-7-13.5-7.6-20.6-.6-7 4.8-14.5 4.2-21.5s-7.3-13.5-8-20.5c-.7-7.1 4.5-14.8 3.7-21.6-.8-7.2-7.7-13.5-8.6-20.2-1-7.4 3.8-15.4 2.7-21.8-1.4-7.9-8.7-13.6-10.2-19.4-2.9-10.8-2.5-21.2-5.9-21.9-3.6-.7-4.4 9.5-7.2 20.9-1.4 5.7-8.5 11.5-9.7 19.4-1 6.4 4.1 14.1 3.3 21.5-.7 6.6-7.4 13-8.1 20.2-.6 6.8 4.8 14.2 4.3 21.3-.5 6.9-7 13.5-7.4 20.5s5.1 14.3 4.8 21.2c-.4 7.1-6.7 13.8-7.1 20.6-.3 7.2 5.4 14.5 5.1 21.2-.3 7.4-6.6 14.2-6.8 20.7-.3 7.9 5.5 15.1 5.3 21.1-.4 13-6.3 10.3-6.5 20.8l1.3.1z"
                />
                <path
                    className="prefix__st5"
                    d="M322 113.4v225.7M331.9 137l-10.3 8.9-8.4-11.2M331.9 176.3l-10.3 8.9-8.4-11.2M331.9 213.3l-10.3 8.8-8.4-11.2M331.9 257.2l-10.3 8.9-8.4-11.2M331.9 301.7l-10.3 8.8-8.4-11.2"
                />
                <path
                    className="prefix__st4"
                    d="M251.8 342h48.8c-.9-6-4.8-4.9-5.3-14.2-.3-4.7 4-10.4 3.7-16.5-.3-5-5.3-10.2-5.7-16-.4-5.2 3.9-11 3.5-16.6-.4-5.3-5.4-10.4-5.9-15.9s3.7-11.2 3.2-16.6-5.6-10.4-6.2-15.8c-.6-5.5 3.5-11.4 2.8-16.7-.6-5.6-5.9-10.4-6.6-15.6-.8-5.7 2.9-11.9 2.1-16.8-1.1-6.1-6.7-10.5-7.9-14.9-2.2-8.3-1.9-16.4-4.6-16.9-2.8-.6-3.4 7.3-5.5 16.1-1.1 4.4-6.6 8.9-7.5 15-.7 4.9 3.2 10.9 2.5 16.6-.6 5.1-5.7 10-6.2 15.6-.5 5.2 3.7 11 3.3 16.5-.4 5.3-5.4 10.4-5.7 15.8s4 11.1 3.7 16.4-5.2 10.6-5.5 15.9c-.3 5.6 4.1 11.2 3.9 16.3-.2 5.7-5.1 11-5.2 16-.2 6.1 4.2 11.7 4.1 16.3-.3 10-4.9 8-5 16h1.2z"
                />
                <path
                    className="prefix__st5"
                    d="M272.8 165.5v174.2M280.4 183.8l-8 6.8-6.5-8.6M280.4 214.1l-8 6.9-6.5-8.7M280.4 242.6l-8 6.9-6.5-8.7M280.4 276.6l-8 6.8-6.5-8.7M280.4 310.9l-8 6.8-6.5-8.7"
                />
            </g>
            <g id="prefix__freepik--Mailbox--inject-1--inject-301">
                <path className="prefix__st6" d="M208.3 341.5V139.3H219v202.2" />
                <path className="prefix__st7" d="M203 341.5V139.3h10.7v202.2" />
                <path opacity={0.3} fill="#2e353a" d="M203 139.3h10.7v24H203z" />
                <path
                    className="prefix__st7"
                    d="M248.5 60.4h-79.1l34.5 84.6H283V94.9c0-19.1-15.4-34.5-34.5-34.5z"
                />
                <path
                    className="prefix__st9"
                    d="M197.9 130.2l6 14.8H283V94.9c0-.8 0-1.5-.1-2.3h-85v37.6z"
                />
                <path className="prefix__st10" d="M248 92.6h-8.4M282.9 92.6h-25.2" />
                <path
                    className="prefix__st6"
                    d="M169.5 60.4c19 0 34.5 15.4 34.5 34.5v50.2h-69V94.9c0-19.1 15.4-34.5 34.5-34.5z"
                />
                <path className="prefix__st7" d="M144.5 139.3h59.4v5.7h-59.4z" />
                <path className="prefix__st7" d="M81 139.3h122.9v5.7H81z" />
                <path className="prefix__st9" d="M144.5 139.3h59.4v5.7h-59.4z" />
                <path className="prefix__st6" d="M229 40h24.7v17.3H229z" />
                <path className="prefix__st11" d="M225.7 40h5.3v54h-5.3z" />
                <circle className="prefix__st6" cx={228.3} cy={95.3} r={6} />
            </g>
            <g id="prefix__freepik--paper-planes--inject-1--inject-301">
                <path className="prefix__st12" d="M87.9 113.3V87.5l73.8 11.8z" />
                <path
                    fill="#8e9ca7"
                    stroke="#2e353a"
                    strokeWidth={1.263}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M91.5 92.1l-3.6 21.2 73.8-14z"
                />
                <path className="prefix__st12" d="M91.5 92.1l6.7 13.4 63.5-6.2z" />
                <path className="prefix__st12" d="M87.9 87.5l-7.8-7.8 81.6 19.6z" />
                <path
                    className="prefix__st10"
                    d="M114.3 112.8l6.4-1.3M91.8 117.4l16.1-3.3"
                />
                <path
                    d="M79 111.7s-58 10-69 62 47 55 57 30-34-35-44-11 20 65 61 67c34.1 1.7 129.6-38.1 189-48.2 0 0 44-12.2 112-9.5"
                    fill="none"
                    stroke="#2e353a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="2,4"
                />
            </g>
            <g id="prefix__freepik--Fence--inject-1--inject-301">
                <path className="prefix__st10" d="M365.3 274.7H382M365.3 299.3H382" />
                <path className="prefix__st11" d="M14.3 298.7h349v-23.4h-349" />
                <path
                    className="prefix__st6"
                    d="M38.6 341.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v117.5"
                />
                <path
                    className="prefix__st11"
                    d="M36 342.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v118.5"
                />
                <path
                    className="prefix__st6"
                    d="M115.5 341.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v117.5"
                />
                <path
                    className="prefix__st11"
                    d="M113.3 342.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v118.5"
                />
                <path
                    className="prefix__st6"
                    d="M192.8 341.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v118.5"
                />
                <path
                    className="prefix__st11"
                    d="M190.7 342.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v118.5"
                />
                <path
                    className="prefix__st6"
                    d="M270.2 341.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v117.5"
                />
                <path
                    className="prefix__st11"
                    d="M268 342.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v118.5"
                />
                <path
                    className="prefix__st6"
                    d="M347.5 341.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v117.5"
                />
                <path
                    className="prefix__st11"
                    d="M345.3 342.7V224.3c0-.6.2-1.1.7-1.5l9.1-8.6c.9-.8 2.2-.8 3 .1l8 8.5c.4.4.6.9.6 1.5v118.5"
                />
                <path
                    className="prefix__st10"
                    d="M48.8 214.2v8.6M52.2 218.6v20.3M117.5 220.3v25.6M120.9 217v11.1M200.5 214.1v25.2M205.2 216.2v37.2M271.8 220.3v28.1M282.5 217v7M352.4 216.6v14.9M361.5 218.2v43M345 281.1h-9.1M345 288.1h-31.9M226.3 288.1h-14.5M150.2 288.1h-14.5M35.7 284.4H21.2M35.7 291h-5.8M218.9 284.4h-7.1M141.9 292.2h-7M112.6 292.2h-7M373.1 288.1h-6.2M381.4 288.1h-3.8M57 245.1s-2.5 21.5-5.5 43.5 2.5 44.5 4 46.5M47.1 288.6c.5-4.3.9-7 .9-7"
                />
                <path
                    className="prefix__st10"
                    d="M54.5 332.6s-9-2-9-18c0-6.6.4-13.3.9-19M130 219.6s2 1.5-1.5 18.5-3 42 1.5 56M135 251.1s-5 15-1.5 36M192 260.6s2.5 14.5 5 29.5.5 36-1.5 41.5-4 10-4 10M201.4 334.2c-2.2 6.8-5-1.1-5-1.1M201.3 283.1s4.7 24.3 2 42.6M191 291.1s5 10.5.5 20.5M284.3 292.6c.7 3.1 1.5 6.1 2.6 9M288.4 240.6c-3.2 10.1-5.2 20.5-6 31-.3 5.3 0 10.7.9 15.9M278.9 286.6s4.5 17 7 23 1.5 20.5-1.5 27.5M346.4 248.1s10 18 .5 47.5M355.6 278.9c.1 5.6-.4 12.7-2.7 18.7-4.5 12-6.5 16-6.5 16M354.9 269.1s.3 2.1.5 5.5"
                />
            </g>
        </svg>
    )
}