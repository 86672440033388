// @vendors
import { useContext } from 'react'

// @context
import { DestinationPageContext } from 'context/pages'

// @components
import Description from './description'
import PlaceInfo from './place-info'
import ExtraInfo from './extra-info'
import Providers from './providers'
import LeaveNoTrace from './leave-no-trace'
import PNNMessage from './pnn-message'

// @styles
import './styles.scss'

export default function Caption() {
  const info = useContext(DestinationPageContext)

  if (!info.name) {
    return null
  }

  return (
    <div className='dest__sidebar-caption'>
      <Description />
      <PlaceInfo />
      <ExtraInfo />
      <Providers />
      <LeaveNoTrace />
      {
        (
          info.dest_type === 'Parque Nacional' ||
          info.dest_type === 'Santuario'
        ) && <PNNMessage />
      }
    </div>
  )
}
