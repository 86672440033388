// @vendors
import { Helmet } from 'react-helmet'
import ImageGallery from 'react-image-gallery'
import { Link, useLocation } from 'react-router-dom'
import { Button, Skeleton, Tooltip, Popover, Typography } from 'antd'

// @components
import Caption from 'components/caption'
import Interactions from 'components/caption/interactions'

// @constants
import { DEFAULT } from 'constants/default'
import { ROUTES } from 'constants/routes'

// @pages
import NotFound from 'pages/not-found'

// @context
import { DestinationPageContextProvider } from 'context/pages'

// @hooks
import useDestinationPage from 'hooks/useDestinationPage'

// @utils
import { convertToSlug } from 'utils/format'
import { prismicDestinationToDestination } from 'utils/destination'

// @styles
import 'assets/sass/components/_destination-layout.scss'
import './styles.scss'
import './gallery.scss'

function getTitle(type, name, department) {
  if (!type || !name) {
    return 'Noomada'
  }
  const additionalText = type === 'Prueblo Patrimonio'
    ? 'Pueblo Patrimonio de'
    : ''

  return `${name}, ${additionalText} ${department}`
}

export default function DestinationPage() {
  const { destinationPageInfo, loading, notFound } = useDestinationPage('client', 'destination', 'destinationId')
  const location = useLocation()

  if (loading) {
    return (
      <>
        <div className='dest__gallery' />
        <div className='dest__sidebar'>
          <div className='dest__sidebar-caption'>
            <div className='dest__sidebar-caption-resume'>
              <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
            </div>
          </div>
        </div>
      </>
    )
  }

  if (notFound && !loading) {
    return <NotFound />
  }

  const ROUTE_NOW = `/${convertToSlug(destinationPageInfo.depto)}/${destinationPageInfo.uid}`

  if (ROUTE_NOW !== location.pathname && !loading) {
    return <NotFound />
  }

  const {
    depto,
    dest_photos,
    name,
    dest_type,
    dest_description,
    thumb
  } = destinationPageInfo

  const MessageReport = () => {
    return (
      <>
        <h1 className='title__ppal xs'>
          Informar un problema
        </h1>
        <Typography.Text type='secondary'>
          Por favor visite nuestro centro de ayuda para obtener instrucciones sobre cómo denunciar <Link to={ROUTES.LEGALS.REPORT}>infracciones de derechos de autor</Link>.
        </Typography.Text>
      </>
    )
  }

  return (
    <DestinationPageContextProvider value={destinationPageInfo}>
      <Helmet
        title={`${getTitle(dest_type, name, depto)} ${DEFAULT.site.name}`}
        meta={[
          {
            name: 'description',
            content: dest_description[0]?.text
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: `${DEFAULT.site.urlBase}${ROUTE_NOW}`
          },
          {
            property: 'og:title',
            content: `${getTitle(dest_type, name, depto)} | ${DEFAULT.site.name}`
          },
          {
            property: 'og:image',
            content: thumb[0].image.url
          },
          {
            name: 'twitter:card',
            content: 'summary with large image'
          },
          {
            name: 'twitter:url',
            content: `${DEFAULT.site.urlBase}${ROUTE_NOW}`
          },
          {
            name: 'twitter:title',
            content: `${getTitle(dest_type, name, depto)} | ${DEFAULT.site.name}`
          },
          {
            name: 'twitter:description',
            content: dest_description[0]?.text
          },
          {
            name: 'twitter:image',
            content: thumb[0].image.url
          }
        ]}
      />
      <div className='dest__gallery'>
        <ImageGallery
          items={dest_photos.map(({ photo: { url, alt, copyright } }) => ({
            original: url,
            originalAlt: alt,
            thumbnail: url,
            description: `Foto: ${copyright || 'Háblanos para créditos'}`
          }))}
          showPlayButton={false}
          showThumbnails={false}
          showNav
          showIndex
          additionalClass='image-gallery-banner'
        />
        <Tooltip placement='left' title='Informar'>
          <Popover
            title=""
            content={<MessageReport />}
            trigger='click'
            overlayClassName='popover__default popover__default-compact'
          >
            <Button className='report-problem'><em className='fal fa-flag' /></Button>
          </Popover>
        </Tooltip>
      </div>
      <div className='dest__sidebar'>
        <Interactions
          destination={prismicDestinationToDestination(destinationPageInfo, location.pathname)}
        />
        <Caption />
      </div>
    </DestinationPageContextProvider>
  )
}
