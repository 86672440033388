// @vendors
import { useContext } from "react"
import { Modal, Form, Input, Button } from "antd"
import cx from 'classnames'

// @context
import { AppContext } from "context/app"

// @api
import { removeUserAccount } from "api/user"

// @hooks
import useFetch from "hooks/useFetch"

// @utils
import { notify } from "utils/notifications"
import { validateEmail } from "utils/format"

export default function ModalRemoveAccount({ visible, onClose }) {
  const { auth: { user, logout } } = useContext(AppContext)
  const { loading, refetch } = useFetch(
    removeUserAccount,
    {
      showErrorMessage: false,
      showSuccessMessage: false,
      onSuccess: () => {
        notify('success', 'Su cuenta ha sido eliminada')
        logout()
      },
      onError: () => {
        notify('error', 'Error eliminando cuenta')
      }
    }
  )

  const onRemoveAccount = (values) => {
    refetch([values.email])
  }

  return (
      <Modal
          destroyOnClose
          footer={null}
          title={<><em className='fal fa-skull-crossbones' /> Zona de Peligro</>}
          width='450px'
          centered
          wrapClassName={
              cx(
                  'modal__default',
                  'modal__default--bottom-mobile',
                  'modal__delete',
                  'modal__auto'
              )
          }
          visible={visible}
          onCancel={onClose}
      >

          <div className='step__message'>
              <Form layout='vertical' onFinish={onRemoveAccount}>
                  <Form.Item
                      label='Ingresa tu correo'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Este campo es obligatorio'
                        },
                        {
                          validator(_, value) {
                            const isEmailValid = validateEmail(value)
                            const areEmailsEqual = user.email === value

                            if (!value || (areEmailsEqual && isEmailValid)) {
                              return Promise.resolve()
                            }

                            let message = ''

                            if (!isEmailValid) {
                              message = 'Ingrese un correo válido'
                            } else if (!areEmailsEqual) {
                              message = 'Debes ingresar tu correo'
                            }

                            return Promise.reject(new Error(message))
                          }
                        }
                      ]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item className='actions'>
                      <Button
                          className='solid c-gr-2 ant-btn-no-shdw'
                          disabled={loading}
                          htmlType="button"
                          onClick={onClose}
                      >
                          Cancelar
                      </Button>
                      <Button
                          className='solid c-danger'
                          htmlType="submit"
                          loading={loading}
                      >
                          Eliminar
                      </Button>
                  </Form.Item>
              </Form>
          </div>
      </Modal>
  )
}