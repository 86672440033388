// @vendors
import { RichText } from 'prismic-reactjs'

// @ styles
import './styles.scss'

export default function Find({ info }) {
  return (
    <section className="sec__default you__find">
      <h3 className="title__ppal md center">
        <strong>{RichText.asText(info.primary.title)}</strong>
      </h3>
      <ul className="list-default list__activities">
        {info.items.map((item, index) => (
          <li key={`about-us-find-${index}`}>
            <div className="activity">
              <div className="activity-image">
                <img src={item.image.url} alt={item.image.alt} />
              </div>
              <div className="text__enr">
                <RichText render={item.description} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}
