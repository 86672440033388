// @vendors
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

// @hooks
import useFetch from 'hooks/useFetch'

// @context
import { AppContext } from 'context/app'

// @api
import { getMyTrips } from 'api/destination'

export const filterTripsTypes = {
    all: 'all',
    current: 'current',
    completed: 'completed',
    invited: 'invited'
}

function areAllDestinationsCompletedInTrip(destinations) {
  return destinations.length > 0 && destinations.filter(destination => destination.visited).length === destinations.length
}

export default function useMyTrips({ fetchOnMount }) {
  const { auth: { user } } = useContext(AppContext)
  const [filterType, setFilterType] = useState(filterTripsTypes.all)

  const extraInfo = useMemo(() => ({
    fetchOnMount,
    initialData: [],
    params: fetchOnMount ? [user.email] : [],
    showErrorMessage: false
  }), [fetchOnMount, user])

  const {
    refetch: refetchMyTrips,
    data: {
      myTrips,
      invitedTrips
    },
    setData: setTrips,
    loading
  } = useFetch(
    getMyTrips,
    extraInfo
  )

  const handleFilterTypeChange = useCallback((newType) => () => {
      setFilterType(newType)
  }, [])

  const filteredTrips = useMemo(() => {
    const totalTrips = myTrips || []
    const totalInvitedTrips = invitedTrips || []
    const totalFilteredTrips = {
      all: totalTrips.concat(totalInvitedTrips),
      invited: totalInvitedTrips
    }
    totalFilteredTrips.current = totalFilteredTrips.all
        .filter(({ destinations }) => !areAllDestinationsCompletedInTrip(destinations))
    totalFilteredTrips.completed = totalFilteredTrips.all
        .filter(({ destinations }) => areAllDestinationsCompletedInTrip(destinations))

    return totalFilteredTrips
  }, [filterType, myTrips, invitedTrips])

  const addNewTrip = (newTrip, type = 'invited') => {
    if (type === 'invited') {
      setTrips((currentTrips) => ({
        myTrips: [...currentTrips.myTrips],
        invitedTrips: [newTrip, ...currentTrips.invitedTrips]
      }))
    }
  }

  useEffect(() => {
      if (!(filteredTrips ?? []).length) {
          setFilterType(filterTripsTypes.all)
      }
  }, [myTrips])

  return {
    refetchMyTrips,
    myTrips: filteredTrips,
    loading,
    setFilterType: handleFilterTypeChange,
    filterType,
    addNewTrip
  }
}
