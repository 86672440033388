// @vendors
import { useContext, useRef } from 'react'
import { Button, Form, Input, Typography } from 'antd'
import { Link } from 'react-router-dom'

// @context
import { AppContext } from 'context/app'

// @hooks
import useFetch from 'hooks/useFetch'

// @constants
import { ROUTES } from 'constants/routes'
import { EMAIL_REGEX } from 'constants/format'

// @api
import { sendInfoEmail } from 'api/notifications'

export default function ColaboratorsForm({ onClose }) {
    const { auth: { user } } = useContext(AppContext)
    const ref = useRef()

    const { refetch, loading } = useFetch(
        sendInfoEmail,
        {
            errorMessage: 'Error enviando la información',
            successMessage: 'Datos enviados exitosamente.',
            onSuccess: () => {
                ref.current.resetFields()
                onClose()
            }
        }
    )

    const onFinish = (values) => {
        const { email } = values
        refetch(
            [
                email,
                {
                    ...values
                },
                'colaborators'
            ]
        )
    }

    return (
        <>
            <h3 className='title__ppal sm c--dr' style={{ marginBottom: '30px' }}>
                <span>¡Muéstrale al mundo</span>
                <strong>tus creaciones!</strong>
            </h3>
            <Form
                layout='vertical'
                ref={ref}
                autoComplete=''
                onFinish={onFinish}
                fields={[
                    {
                        name: ["fullname"],
                        value: user && `${user.firstName} ${user.lastName}`
                    },
                    {
                        name: ["email"],
                        value: user && user.email
                    }
                ]}
            >
                <Form.Item
                    label='¿Cómo te llamas?'
                    name='fullname'
                    hidden={user}
                    rules={[{
                        required: true,
                        message: 'Este campo es obligatorio'
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Correo electrónico'
                    name='email'
                    type="email"
                    hidden={user}
                    rules={[{
                        required: true,
                        message: 'Este campo es obligatorio'
                    }, {
                        pattern: EMAIL_REGEX,
                        message: 'Formato de correo inválido'
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Compártenos la URL de tus creaciones'
                    name='urls'
                    rules={[{
                        required: true,
                        message: 'Es importante conocer tu trabajo'
                    }]}
                >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                </Form.Item>
                <Form.Item
                    label={<>¿Quiéres agregar algo más? <span>(opcional)</span></>}
                    name='message'
                >
                    <Input.TextArea allowClear autoSize={{ minRows: 2, maxRows: 4 }} />
                </Form.Item>
                <Typography.Text
                    type='secondary'
                    style={{ margin: '-10px 0 10px', display: 'block' }}
                >
                    Al dar clic en <strong>Enviar</strong>, estás aceptando los <Link to={ROUTES.LEGALS.TERMS} target='_blank'>Términos y condiciones</Link> y la <Link to={ROUTES.LEGALS.PRIVACY} target='_blank'>Política de privacidad</Link> de Noomada.
                </Typography.Text>
                <Form.Item className='actions'>
                    <Button className='solid c-1' htmlType='submit' loading={loading}>
                        <span className='text'>
                            Enviar
                        </span>
                    </Button>
                </Form.Item>
                <Typography.Text
                    type='secondary'
                    style={{ margin: '20px 0 0', display: 'block', fontSize: '14px' }}
                >
                    * Nos pondremos en contacto contigo de 1 a 72 horas día laboral para continuar con el siguiente paso.
                </Typography.Text>
            </Form>
        </>
    )
}
