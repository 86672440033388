// @vendors
import { Helmet } from 'react-helmet'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Skeleton, Typography } from 'antd'

import CardDestination from 'components/card-destination'

// @constants
import { DEFAULT } from 'constants/default'

// @hooks
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags'

export default function NationalParks() {
  const {
    info: list,
    loading
  } = usePrismicQueryByTags(
    'clientPNN',
    'pnn',
    ['parques-nacionales'],
    [''],
    100
  )

  const parks = useMemo(() =>
    list.map((item) => ({
      linkTo: `/pnn/${item.uid}`,
      imageUrl: item.data.thumb[0].image.url,
      imageAlt: item.data.thumb[0].image.alt,
      name: item.data.name,
      caption: `Región ${item.data.region}`,
      type: item.type
    })), [list])

  const [categories, setCategories] = useState([])
  const [filteredParks, setFilteredParks] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('Todos')

  useEffect(() => {
    if (parks.length) {
      const categoriesIndexes = []

      parks.forEach(category => {
        if (!categoriesIndexes.includes(category.caption)) {
          categoriesIndexes.push(category.caption)
        }
      })

      setCategories(categoriesIndexes)
      setFilteredParks(parks)
    }
  }, [parks])

  useEffect(() => {
    let newParks = []
    if (selectedCategory === 'Todos') {
      newParks = parks
    } else {
      newParks = parks.filter(({
        caption
      }) => caption === selectedCategory)
    }
    setFilteredParks(newParks)
  }, [selectedCategory])

  return (
    <>
      <Helmet>
        <title>Parques Nacionales Naturales de Colombia {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          {loading
            ? <>
              <Skeleton
                className='ant-skeleton-basic ant-skeleton-basic-filters'
                paragraph={{ rows: 2 }}
                active
              />
              <Skeleton
                title=''
                className='ant-skeleton-mosaic'
                paragraph={{ rows: 6 }}
                active
              />
            </>
            : (
              <>
                <h1 className='title__ppal md'>
                  <strong>Parques Nacionales Naturales</strong>
                </h1>
                <Typography.Text type='secondary'>
                  * Los siguientes PNN son los que se encuentran abiertos al público.
                </Typography.Text>
                <ul className='filters__category solid c-2'>
                  <li>
                    <Link
                      to='?search=Todos'
                      className={selectedCategory === 'Todos' ? 'active' : ''}
                      onClick={() => setSelectedCategory('Todos')}
                    >
                      Todos
                    </Link>
                  </li>
                  {categories.map((item, k) => (
                    <li key={k}>
                      <Link
                        to={`?search=${item}`}
                        className={selectedCategory === item ? 'active' : ''}
                        onClick={() => setSelectedCategory(item)}
                      >
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className='list__items-mosaic-card'>
                  {filteredParks.map((item, k) => (
                    <CardDestination destination={item} key={`card-pnn-${k}`} />
                  ))}
                </div>
              </>
            )}
        </div>
      </section>
    </>
  )
}
