// @vendors
import { useContext, useReducer, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import cx from 'classnames'
import { Menu, Dropdown, Button } from 'antd'

// @components
import Logo from 'components/logo'
import UserDropdown from 'components/user-dropdown'
import AuthForm from 'components/forms/auth'
import SearchForm from 'components/search-form'
import ModalStep from 'components/modal-steps'

// @context
import { AppContext } from 'context/app'
import { RegisterFormContextProvider, registerFormReducer, INITIAL_STATE_REGISTER_FORM } from 'context/register-form'

// @constants
import { HEADER_ITEMS, ADDITIONAL_MENU, USER_ITEMS } from 'constants/header'
import { MODAL_STEPS } from 'constants/data-modals'
import { ROUTES } from 'constants/routes'
import { QUERY_PARAM_SEARCH_FORM } from 'constants/query-params'

// @styles
import './styles.scss'

function GeneralMenu({ setModalStep, setDropdownItems, setDataModalStep, onCloseSearchSidebar }) {
  const baseItemMethod = () => {
    setModalStep(true)
    setDropdownItems(false)
  }

  const itemsMethod = {
    recommend: () => {
      baseItemMethod()
      setDataModalStep(MODAL_STEPS.RECOMMEND)
    },
    experiences: () => {
      baseItemMethod()
      setDataModalStep(MODAL_STEPS.EXPERIENCE)
    },
    suggestions: () => {
      baseItemMethod()
      setDataModalStep(MODAL_STEPS.SUGGESTIONS)
    }
  }
  return (
    <div className='menu__container menu__container-menuGeneral menu__container-default'>
      {
        Object.entries(ADDITIONAL_MENU).map(([_, value], index) => (
          <Menu key={`general-menu-item-${index}`}>
            <Menu.ItemGroup title={value.title}>
              {
                value.items
                  .map((additionalItem, index) => (
                    <Menu.Item
                      key={`additional-menu-item-${index}`}
                      onClick={onCloseSearchSidebar}
                    >
                      {additionalItem.path && (
                        <Link to={{ pathname: additionalItem.path }} target={additionalItem.target} onClick={() => setDropdownItems(false)}>
                          {additionalItem.title}
                        </Link>
                      )}
                      {additionalItem.key && (
                        <Button className='ant-btn-access' onClick={itemsMethod[additionalItem.key]}>
                          {additionalItem.title}
                        </Button>
                      )}
                    </Menu.Item>
                  ))
              }
            </Menu.ItemGroup>
          </Menu>
        ))
      }
    </div>
  )
}

export default function Header() {
  const [registerFormInfo, dispatchRegisterForm] = useReducer(registerFormReducer, INITIAL_STATE_REGISTER_FORM)
  const [showDropdownItems, setShowDropdownItems] = useState(false)
  const [modalStepVisible, setModalStepVisible] = useState(false)
  const [dataModalStep, setDataModalStep] = useState([])
  const [stepModalOrigin, setStepModalOrigin] = useState('')
  const {
    app: {
      onOpenAuthForm,
      onCloseSearchSidebar,
      onOpenAddDestinationToMyTrips,
      onOpenSearchSidebar,
      isModalOpened,
      searchParamsInfo: {
        location
      }
    },
    auth: {
      user
    },
    trips: { handleChangeAddToTripsVisibility }
  } = useContext(AppContext)

  const showSearchSidebar = isModalOpened(QUERY_PARAM_SEARCH_FORM)

  const sharedClasses = {
    notBg: cx(
      'not-bg-home'
    ),
    modeLight: cx(
      'header__ppal-mode--light'
    ),
    modeDark: cx(
      'header__ppal-mode--dark'
    ),
    modeDuoTone: cx(
      'header__ppal-mode--duotone'
    ),
    showSearch: cx(
      'header__ppal-show--search'
    )
  }

  const pageClasses = {
    '/': cx(
      { 'my-home--logged': !!user },
      { 'my-home--logged': !user },
      sharedClasses.notBg,
      sharedClasses.showSearch
    ),
    [ROUTES.BLOG]: cx(
      sharedClasses.notBg,
      sharedClasses.modeLight,
      sharedClasses.showSearch
    ),
    [ROUTES.PROFILE.SUMMARY]: cx(
      sharedClasses.notBg,
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.PROFILE.MY_TRIPS]: cx(
      sharedClasses.notBg,
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.PROFILE.TRIP_DETAIL]: cx(
      sharedClasses.notBg,
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.PROFILE.ALBUMBS]: cx(
      sharedClasses.notBg,
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.PROFILE.VISITED]: cx(
      sharedClasses.notBg,
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.PROFILE.ACCOUNT]: cx(
      sharedClasses.notBg,
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.BLOG]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.ABOUT_US]: cx(
      sharedClasses.notBg,
      sharedClasses.modeLight,
      sharedClasses.showSearch
    ),
    [ROUTES.PATRIMONY_TOWNS]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.NATIONAL_PARKS]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.DEPARTMENTS]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.CONTACT]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.LNT]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    ),
    [ROUTES.RECOVERY_PASSWORD]: cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    )
  }

  let extraPathClasses = ''

  const isBlogDetailPage = location.pathname.match(/\/blog\/.+/g)
  const isLegalPage = location.pathname.match(/\/legal\/.+/g)
  const isTripsPage = location.pathname.match(/\/viajes\/.+/g)
  const isRequestPage = location.pathname.match(/\/solicitudes\/.+/g)

  if (isBlogDetailPage || isLegalPage || isTripsPage || isRequestPage) {
    extraPathClasses = cx(
      sharedClasses.modeDark,
      sharedClasses.showSearch
    )
  }

  return (
    <RegisterFormContextProvider value={{ dispatchRegisterForm, registerFormInfo }}>
      <header
        className={
          cx(
            'header__ppal',
            pageClasses[location.pathname],
            extraPathClasses,
            { 'header__ppal-active--search': showSearchSidebar }
          )
        }
      >
        <section className='container__left'>
          <Link
            to='/'
            className='logo'
            target='_parent'
            onClick={onCloseSearchSidebar}
          >
            <Logo />
          </Link>
        </section>

        <div className='container__right'>
          <ul className='container__right-navigation'>
            <li className='container__right-navigation--item'>
              <NavLink
                exact
                to={ROUTES.HOME}
                onClick={onCloseSearchSidebar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 110 80.53"
                >
                  <path
                    className="logo__noomada-type"
                    d="M90.35 80.48s-5.69.09-8.2 0c-3.16-.11-5.56-1.94-6.68-4.95-2.63-7.07-8.03-10.67-15.08-12.19-3.91-.85-7.86-1.5-11.77-2.29-8.73-1.77-12.13-8.07-8.26-16.18 4.84-10.13 9.51-20.35 14.23-30.54.97-2.1 2.05-4.16 2.96-6.29C59.7 3.02 63.57.22 68.9.01c5.59-.22 9.63 2.82 11.91 7.73 8.59 18.52 17.04 37.11 25.47 55.7 2.95 6.51-.2 14.43-6.54 16.33-3.33.75-9.39.71-9.39.71z"
                  />
                  <path
                    className="logo__noomada-type"
                    d="M32 80.48H11.87c-7.34.02-14.67-7.94-10.8-15.4 6.86-13.25 13.56-26.57 20.3-39.87 1-1.98 1.69-4.11 3.99-5.27 3.4-1.73 6.9-.88 8.74 2.3 1.57 2.74 2.83 5.62 4.37 8.37 1.3 2.32.5 4.38-.58 6.48-1.91 3.72-4.73 7-6.38 10.85-4.43 10.37-2.87 21.12 9.16 23.42 11.69 3.61 8.77 9.15 5.84 9.16-7.55.03-6.95-.03-14.51-.04z"
                    style={{
                      opacity: 0.7
                    }}
                  />
                </svg>
                <span>Explorar</span>
              </NavLink>
            </li>
            {
              HEADER_ITEMS.map(item => (
                <li className={
                  cx(
                    'container__right-navigation--item',
                    'container__right-navigation--item-generic',
                    { 'user-connected': user }
                  )
                } key={`hedaer-center-item-${item.path}`}>
                  <NavLink
                    exact
                    to={item.path}
                    onClick={onCloseSearchSidebar}
                  >
                    <em className={`fal fa-${item.iconName}`} />
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              ))
            }
            {
              user &&
              USER_ITEMS.map(item => (
                <li className={
                  cx(
                    'container__right-navigation--item',
                    'container__right-navigation--item-special',
                    { 'user-connected': user }
                  )
                } key={`hedaer-center-item-${item.path}`}>
                  <NavLink
                    exact
                    to={item.path}
                    onClick={onCloseSearchSidebar}
                  >
                    <em className={`fal fa-${item.iconName}`} />
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              ))
            }
            <li className='container__right-navigation--item container__right-navigation--item-more'>
              <Dropdown
                overlay={
                  <GeneralMenu
                    setDropdownItems={setShowDropdownItems}
                    setModalStep={setModalStepVisible}
                    setDataModalStep={setDataModalStep}
                    onCloseSearchSidebar={onCloseSearchSidebar}
                    user={user}
                  />
                }
                className='ant-dropdown-icon'
                arrow
                visible={showDropdownItems}
                onVisibleChange={setShowDropdownItems}
                trigger='click'
                placement='bottomRight'
              >
                <div className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                  <em className='fal fa-ellipsis-v' />
                </div>
              </Dropdown>
            </li>
            <li className='container__right-navigation--item container__right-navigation--item-search'>
              <div
                className={
                  cx(
                    'ant-dropdown-icon',
                    { 'ant-dropdown-icon-open': showSearchSidebar }
                  )
                }
                onClick={() => {
                  if (showSearchSidebar) {
                    onCloseSearchSidebar()
                  } else {
                    onOpenSearchSidebar()
                  }
                }}
              >
                {!showSearchSidebar ? <em className='fal fa-search' /> : <em className='fal fa-times' />}
              </div>
            </li>
          </ul>
          <UserDropdown
            openStepModal={() => {
              onOpenAuthForm()
            }}
            setData={setDataModalStep}
          />
        </div>
      </header>
      <AuthForm />
      <ModalStep
        visible={modalStepVisible}
        onClose={() => {
          setModalStepVisible(false)
          setStepModalOrigin('')
        }}
        data={dataModalStep}
        clearData={() => setDataModalStep([])}
        origin={stepModalOrigin}
      />
      <SearchForm
        title='Buscar'
        onBookmarClick={item => handleChangeAddToTripsVisibility(true, onOpenAddDestinationToMyTrips, item)()}
        visible={showSearchSidebar}
        onClose={onCloseSearchSidebar}
      />
    </RegisterFormContextProvider>
  )
}
