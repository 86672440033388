// @vendors
import { Link } from 'react-router-dom'
import cx from 'classnames'

// @utils
import { convertToSlug, pluralWord } from 'utils/format'
import { getPercentageOfVisitedDestinationInTrip } from 'utils/percentage'

// @constants
import { ROUTES } from 'constants/routes'

// @styles
import './styles.scss'

// TODO: sort trips and destinations into trips by createdOn
function renderContainer(trip) {
  const visitedPercentage = getPercentageOfVisitedDestinationInTrip(trip.destinations)

  return (
    <>
      <div className='list__myTrips-item-images'>
        {
          trip.destinations.slice(0, 3).map(({ imageUrl }, index) => (
            <img src={imageUrl} alt={`image-trip-${convertToSlug(trip.title)}`} key={`image-trip-${convertToSlug(trip.title)}-${index}`} />
          ))
        }
        {!trip.destinations.length && (
          <em className="fal fa-bookmark" />
        )}
      </div>
      <div className='list__myTrips-item-content'>
        <div className='list__myTrips-item-content-goal'>
          <span className='goal__state'>
            {(visitedPercentage !== 0 &&
              visitedPercentage !== 100) && <><em className='fal fa-check' /> Llevas el {visitedPercentage}%</>}
            {visitedPercentage === 100 && 'Completado'}
            {visitedPercentage === 0 && 'Sin comenzar'}
          </span>
          {!!trip.shared.length && (
            <div className='goal__invited'>
              <div className='goal__invited-item'>
                <em className='fal fa-user-friends' /> {trip.shared.length + 1}
              </div>
            </div>
          )}
        </div>
        <div className='list__myTrips-item-content-name'>
          {trip.title}
        </div>
        <div className='list__myTrips-item-content-quantity'>
          {
            trip.destinations.length
              ? `${trip.destinations.length} ${pluralWord('destino', trip.destinations.length)} ${pluralWord('agregado', trip.destinations.length)}`
              : 'No has agregado destinos'
          }
        </div>
      </div>
    </>
  )
}

export default function TripsList(props) {
  const {
    disabled,
    trip,
    link,
    selected,
    setSelected
  } = props

  if (link) {
    const tripDetailsLink = ROUTES.PROFILE.TRIP_DETAIL.replace(':tripId', trip.codeId)

    return (
      <Link
        to={tripDetailsLink}
        className={
          cx(
            'list__myTrips-item',
            { 'list__myTrips-item--trip-1': trip.destinations.length === 1 },
            { 'list__myTrips-item--trip-2': trip.destinations.length === 2 },
            { 'list__myTrips-item--trip-3': trip.destinations.length >= 3 },
            { 'list__myTrips-item--disabled': disabled },
            { 'list__myTrips-item--selected': disabled }
          )
        }
      >
        {renderContainer(trip)}
      </Link>
    )
  }

  return (
    <div
      className={
        cx(
          'list__myTrips-item',
          { 'list__myTrips-item--trip-1': trip.destinations.length === 1 },
          { 'list__myTrips-item--trip-2': trip.destinations.length === 2 },
          { 'list__myTrips-item--trip-3': trip.destinations.length >= 3 },
          { 'list__myTrips-item--selected': selected || disabled },
          { 'list__myTrips-item--disabled': disabled }
        )
      }
      onClick={() => setSelected(!selected)}
    >
      {renderContainer(trip)}
    </div>
  )
}
