// @vendors
import PropTypes from 'prop-types'
import cx from 'classnames'
import { RichText } from 'prismic-reactjs'

// @styles
import './styles.scss'

export default function Banner({ info }) {
  if (!info) {
    return null
  }

  const {
    backgroundImage,
    backgroundType,
    description
  } = info

  const classes = {
    container: cx(
      'banner__page',
      { light: backgroundType },
      { dark: !backgroundType }
    )
  }

  return (
    <div
      className={classes.container}
      style={{ backgroundImage: `url('${backgroundImage}')` }}
    >
      <h1 className='title__ppal sm'>
        <RichText render={description} />
      </h1>
    </div>
  )
}

Banner.propTypes = {
  info: PropTypes.object
}

Banner.defaultProps = {
  info: null
}
