// @vendors
import React from 'react'
import { Link } from 'react-router-dom'
import { linkResolver } from 'prismic-config'
import PropTypes from 'prop-types'
import moment from 'moment'

export default function PostItem({ post, type }) {
  const existSomeFeaturedItem = type !== 'normal' && post.tags.find(item => item === 'featured')

  const image = type === 'normal'
    ? (
      <img src={post.data.image.url} alt={post.data.image.alt} />
    )
    : (
      <div className='item__blog-content-image'>
        <img src={post.data.image.url} alt={post.data.image.alt} />
      </div>
    )

  return (
    <Link to={linkResolver(post)} className='item__blog'>
      <div className='item__blog-content'>
        {existSomeFeaturedItem && <div className='featured'>Destacado</div>}
        {image}
        <div className='pre__info'>
          <div className='pre__info-title'>
            {post.data.title}
          </div>
          <div className='pre__info-date'>
            {moment(post.data.date, 'YYYY-MM-DD').format('LL')}
          </div>
        </div>
      </div>
    </Link>
  )
}

PostItem.propTypes = {
  post: PropTypes.any.isRequired,
  type: PropTypes.string
}

PostItem.defaultProps = {
  type: 'normal'
}
