// @vendors
import { Helmet } from 'react-helmet'
import { Skeleton } from 'antd'

// @components
import Banner from './banner'
import Generic from './generic'
import Mission from './mission'
import Find from './find'

// @constants
import { DEFAULT } from 'constants/default'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle'

const SECTIONS = {
  generic: Generic,
  mission___vision: Mission,
  lo_que_encontraras: Find
}

const INFO = {
  generic: slice => slice.primary,
  mission___vision: slice => slice.items,
  lo_que_encontraras: slice => slice
}

export default function AboutUsPage() {
  const { info, loading } = usePrismicQuerySingle('clientPGS', 'about')

  if (loading) {
    return (
      <section className='sec__default sec__default-basic blog__main'>
        <div className='container'>
          <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
        </div>
      </section>
    )
  }

  const banner = {
    backgroundAlt: info.page.banner[0].image.alt,
    backgroundImage: info.page.banner[0].image.url,
    backgroundType: info.page.banner[0].bg_type,
    description: info.page.message
  }

  return (
    <>
      <Helmet>
        <title>Nosotros {DEFAULT.site.name}</title>
      </Helmet>
      {loading
        ? <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
        : (
          <>
            <Banner info={banner} />
            <div className='container'>
              {
                info.slices.map((item, index) => {
                  const SelectedSlice = SECTIONS[item.slice_type]
                  const selectedInfo = INFO[item.slice_type](item)

                  return (
                    <SelectedSlice
                      info={selectedInfo}
                      key={`about-us-item-${item.slice_type}-${index}`}
                    />
                  )
                })
              }
            </div>
          </>
        )}
    </>
  )
}
