export default function UserLost() {
    return (
        <svg
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 500 500"
            style={{
                enableBackground: "new 0 0 500 500"
            }}
            xmlSpace="preserve"
        >
            <style>
                {
                    ".st2{fill:none;stroke-linecap:round;stroke-linejoin:round}.st2,.st3,.st4{stroke:#263238}.st3{fill:none;stroke-miterlimit:10}.st4{fill:#92e3a9;stroke-linecap:round;stroke-linejoin:round}.st5{fill:#fff}.st10,.st11,.st6,.st7{fill:#263238;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.st10,.st11,.st7{fill:#fff}.st10,.st11{fill:none;stroke:#fff}.st11{stroke:#263238;stroke-width:.5}.st13{fill:#263238}.st14{fill:#909da7;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}"
                }
            </style>
            <g id="freepik--background-simple--inject-5">
                <path
                    d="M410.2 221.8c-.4-2-.7-3.9-.9-5.9-1.5-11.4-12-64.6-83.4-96.8-80.4-36.3-134.7 1-167.3 30.4s-55.4 91.1-73.9 145.1 25 95.1 78.2 112.7 120.6 19.6 172.8 1c51.5-18.4 94.6-76.2 74.5-186.5z"
                    style={{
                        fill: "#e7edf5"
                    }}
                />
                <path
                    d="M410.2 221.8c-.4-2-.7-3.9-.9-5.9-1.5-11.4-12-64.6-83.4-96.8-80.4-36.3-134.7 1-167.3 30.4s-55.4 91.1-73.9 145.1 25 95.1 78.2 112.7 120.6 19.6 172.8 1c51.5-18.4 94.6-76.2 74.5-186.5z"
                    style={{
                        opacity: 0.75,
                        fill: "#fff",
                        enableBackground: "new"
                    }}
                />
            </g>
            <g id="freepik--Buildings--inject-5">
                <path className="st2" d="M299.5 79.2v203.9l57.1 100h54.8" />
                <path
                    className="st2"
                    d="M299.5 277.9h-38.6L113.2 402.5c-7.4 6.2-8.3 17.3-2.1 24.7.6.7 1.3 1.4 2 2h0c3.2 2.7 7.2 4.2 11.4 4.2h285.6"
                />
                <path
                    className="st2"
                    d="M107 418.4c0 5.1 2.2 10 6.1 13.4h0c3.2 2.7 7.2 4.2 11.4 4.2h285.6M83.1 326l147.4-42.5M182.2 65.5v224.4L76 314.3"
                />
                <path className="st3" d="m204.7 284.7 24.8-5.2" />
                <path
                    className="st2"
                    d="m182.9 148.9 21.9-.4v136.2l-22.6.5M205.2 148.9l31.7 27.4v45.5M233.3 164.8v-15.2h15.8v54.7"
                />
                <path
                    className="st2"
                    d="m249.1 149.6 8.6 13v27.3h10.1v10.8M267.8 189.9l4.3 6.5M277.1 101h22.5v121.3h-22.5zM308.5 166.8h16.3v64.4h-16.3zM316.7 164l39.9-26M355.9 119.8l-48.3 41.3M356.6 85.1l-31.1 38.6M325.1 215.3l31.5-7.1M302 220.5l6.2-1.5M356.6 383.1V73.6M356.6 208.2H417M325.1 211.7l31.5-9.4M356.6 202.3H417"
                />
                <path
                    className="st2"
                    d="m324.8 202.4 30.7-15.2h42.4M302 213.6l6.6-3.2M277.2 126.9h21.6M277.2 150.8h21.6M277.2 174.7h21.6M277.2 198.5h21.6"
                />
                <path
                    className="st3"
                    d="M339.9 167H304c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h35.9c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5z"
                />
                <path
                    className="st2"
                    d="M308.5 81.2v71.9M316.4 74.7v73.2M325.5 68.1v71.3M325.5 108.3l22.9-36.2M182.3 168.6h22.1l29.1 19M182.3 185.7h22.1l29.1 17.4M182.3 202.8h22.1l29.1 15.5M182.3 219.8h22.1l26.4 11.7M182.3 236.9h22.1l19 6.3M182.3 254h22.1l6.4 1.4"
                />
                <path
                    className="st2"
                    d="M194.7 174.4h4.7v8.9h-4.7zM185.4 174.4h4.7v8.9h-4.7zM194.7 190.7h4.7v8.9h-4.7zM185.4 190.7h4.7v8.9h-4.7zM194.7 206.6h4.7v8.9h-4.7zM185.4 206.6h4.7v8.9h-4.7zM194.7 223.7h4.7v8.9h-4.7zM185.4 223.7h4.7v8.9h-4.7zM194.7 240.8h4.7v8.9h-4.7zM185.4 240.8h4.7v8.9h-4.7zM194.7 258.3h4.7v8.9h-4.7zM185.4 258.3h4.7v8.9h-4.7zM194.7 155.8h4.7v8.9h-4.7zM212.2 170.1l-3.5-2.7v-8.9l3.5 2.7M208.7 175.2l3.5 2.7v8.9l-3.5-2.7M212.2 195.4v8l-3.5-1.8v-8.9M212.2 218.8l-3.5-1.8v-8.9l3.5 2.1zM212.2 235.1l-3.5-1.3v-8.9l3.5 1.7M185.4 155.8h4.7v8.9h-4.7zM330.1 337.4V219.7l15.7-1.9v145M314.4 309.2v-75.1M317.7 314.5V238M334 113.2v21.6M341.5 104.8v21.5M334 71.1v23.2M341.5 62.7v19.7M77.3 321.7 234.7 281M89 240l92.8 5.2M89 225l92.8 12.4M134.7 300.3v-52.8l-15.7-.6v57M149.7 296.8v-48.7l-10.4-.4v51.5M114.4 305v-58.2l-24.1-.9v64.7M177.8 290.3v-41.2l-23.5-.8v47.5M215.3 171.5v-9.3M215.3 188.1v-9.3M215.3 220.4v-9.3M215.3 236.8v-9.3M218.7 222.3V213M222.4 209.1v-9.3M225.3 211.3V202M225.3 195.2v-9.3M357.5 217.1h33.4M357.5 228.2h33.4M357.5 239.3h33.4M357.5 250.4h33.4M357.5 261.5h33.4M357.5 272.7h33.4M357.5 283.8h33.4M357.5 294.9h33.4M357.5 306h33.4M357.5 317.1h33.4M357.5 328.2h33.4M357.5 339.3h33.4M390.9 208v145h6.5v26.8M390.9 353h-30.1M300.7 273.3l10.4 8.5M298.7 233.4h-14.3M285.2 233.7v11.4h5.4v-11.2"
                />
                <path
                    className="st3"
                    d="M169.7 235.2V61M176.7 235.9V107M157.9 234.5V78.4M144.6 231.8V84.7M132.8 230.4V81.9M117.4 228.3V73.5M101.4 227.6V79.1M92.3 199.7l85.1 21.4M94.4 175.3l81.8 26.2M112.5 152.3l64.5 29.2M112.5 85.3l64.1 44.6"
                />
            </g>
            <g id="freepik--Streetlight--inject-5">
                <path
                    className="st4"
                    d="m161 136.2-31.4-1.6v-13.1l31.4-1.6zM161.6 93.6h57.5v16.3h-57.5zM103.5 163.6H161v16.3h-57.5z"
                />
                <path
                    className="st5"
                    d="M148 167.6h-29.2V165l-11.4 6.5 11.4 6.4v-2.6H148zM155 124l-17.5.5v-2.2l-5.7 5.5 5.7 5.5v-2.2l17.5.6z"
                />
                <path className="st6" d="M167.5 390.5h-11.2L158 61.1h7.8z" />
                <path className="st6" d="M151.6 386.5h20.1v28.7h-20.1z" />
                <path
                    className="st7"
                    d="M150 70.9h21.5v11.6c0 5.9-4.8 10.8-10.8 10.8h0c-5.9 0-10.8-4.8-10.8-10.8v0-11.6z"
                />
                <path
                    className="st6"
                    d="M158.5 57.9h4.5c6.1 0 11 4.9 11 11v11.3h0-26.5 0V68.8c0-6 4.9-10.9 11-10.9z"
                />
                <path
                    className="st5"
                    d="M174.4 105.6h29.2v2.6l11.4-6.5-11.4-6.4v2.6h-29.2z"
                />
            </g>
            <g id="freepik--Character--inject-5">
                <ellipse
                    cx={258.2}
                    cy={418.2}
                    rx={31.4}
                    ry={10.3}
                    style={{
                        opacity: 0.11,
                        enableBackground: "new"
                    }}
                />
                <path
                    className="st6"
                    d="M267 241.8s14.8-.1 21.5 2.4 9.7 18.3 9.7 24.4-3.9 8.6-3.9 8.6l.6 3.9c4.7 1.3 7.9 5.8 7.4 10.7-.3 8.9-.5 18.5-7.2 21.6s-36.2-.7-40.8-7.7 4.1-18.5 2.2-29.7-4-22.1-3.4-30.1 13.9-4.1 13.9-4.1z"
                />
                <path
                    d="M302.3 291.8c.4-4.9-2.7-9.4-7.4-10.7l-.6-3.9s4-2.5 3.9-8.6c-.1-5.6-2.6-19.3-8.1-23.5l-.1.1s-5.2-2.8-8.1 3.1-3.8 16.1-3.4 22.5-2.2 20.6-3.2 30.1c-.6 5.9 1.4 10.5 5.2 13.4 6.4.6 12.2.4 14.7-.8 6.6-3.3 6.8-12.8 7.1-21.7z"
                    style={{
                        fill: "#9bcda3",
                        stroke: "#263238",
                        strokeLinecap: "round",
                        strokeLinejoin: "round"
                    }}
                />
                <path
                    className="st6"
                    d="M295.7 256.8c-4.5 1.4-9.1 2.4-13.7 3l-.2 2.8c4.9-.2 9.7-1.2 14.3-2.8l-.4-3zM301.6 287.1c-3 1.3-6 2.5-9.1 3.5-4 1.2-6 1.2-5.8 3.2s.4 10.9 3.6 13.3 9.2 1.2 11.3-2c1.9-3.1 3.6-10.6 0-18zM294.3 277.2s-6.6 1.8-21.4 3.9l.2 4.9s15.3-2.2 21.8-4.9l-.6-3.9z"
                />
                <path
                    className="st7"
                    d="M236.8 348.5s-2 8.4-1 14.1 10.6 29.9 11.5 33.8 1.7 7.8 1.7 7.8l5.8-.6c-.1-5.9-.6-11.7-1.5-17.5-1.6-10.9-1.5-16-2.9-20.5-.5-2-1.3-3.9-2.2-5.7l3-7.5-14.4-3.9zM259.8 355s-1.2 6.4-.9 9.5 5.9 29.4 6.9 34.5 1.8 10.6 1.8 10.6l5 .5s2.7-15 2.1-23.9-4.2-20.7-4.2-20.7l5.2-11.7-15.9 1.2z"
                />
                <path
                    className="st6"
                    d="M241.6 316.7s-3.7 11-5.5 19.5c-1 5.1-1.7 10.2-2 15.4 2.8 1 5.6 1.7 8.5 2.2 4.1.6 9.5 1.3 9.5 1.3l.1 1s2.2 1.9 11.5 1.6c5.7-.2 11.3-.6 16.9-1.4 0 0 .8-24 .1-28-.5-2.4-.8-4.8-1-7.2 0 0-11.8.1-23-.7s-15.1-3.7-15.1-3.7z"
                />
                <path
                    className="st10"
                    d="M234.5 348.6s12.2 3.3 17.9 2.9M252.6 352.3s14.9 2.9 28.1-.5"
                />
                <path
                    className="st10"
                    d="m252.1 355.1 3-23.9c1.1-.8 2.2-1.6 3.4-2.3M255.1 332.1l-5-5.4"
                />
                <path
                    className="st7"
                    d="M255.5 252s-8.4-1-11.4-.6-8.7 1.7-11.2 1.6-11.1.1-14.5-.1-8.6-.2-7.6 2.5 7.8 8.5 14.8 10.5l11.4 3.3s4.5 11.9 6.6 16.1 2 9.1 2.1 11.7-5 20.9-5 20.9 4.9 3.6 16.3 5c7.3.8 14.7.6 21.9-.5.6 0 1.1-.1 1.6-.2 0 0 .3-15.7.3-28.4s3.4-21.3-1.1-29.4-15.8-11-19-11.9c-1.7-.5-3.5-.7-5.2-.5z"
                />
                <path
                    className="st7"
                    d="M260.7 252.5c-1.7-.5-3.5-.7-5.2-.5 0 0-8.4-1-11.4-.6s-8.7 1.7-11.2 1.6h-5.7c-2.8 3.3-4.5 7.3-4.9 11.6 1.1.5 2.2.9 3.3 1.2l11.4 3.3s4.5 11.9 6.6 16.1 2 9.1 2.1 11.7-5 20.9-5 20.9 4.9 3.6 16.3 5c7.3.8 14.7.6 21.9-.5.6 0 1.1-.1 1.6-.2 0 0 .3-15.7.3-28.4s3.4-21.3-1.1-29.4-15.8-11-19-11.8z"
                />
                <path
                    className="st2"
                    d="M251.5 251.9c-.8 3.5-1.1 7.1-.7 10.7.7 4.9 3.7 6.3 7.4 5.6 3.7-.7 7.7-7.2 8.6-10.7s.7-4.6-3.5-5.6-9.7-3.6-11.8 0z"
                />
                <path
                    className="st11"
                    d="m232 257.4 6.5 11-4.3-4.3M238.5 268.4l-2.4-9.9M256.3 284.9s8.1 6.7 17 5.8M252.6 287.4c5 2.3 10.5 3.5 16 3.7"
                />
                <path
                    className="st6"
                    d="M244.9 301.5s4.3 4.5 16.3 3.7c12-.8 19.6-3.9 19.6-3.9v3.8s-15.7 5.5-28.3 2.9c-7.5-1.5-8.6-2.9-8.6-2.9l1-3.6z"
                />
                <path
                    className="st7"
                    d="M222.7 255.4s3.1-1.4 7.4-5.9 8.5-6.8 10.5-7.1c2.2-.4 4.3-1 6.4-1.7.6-.3 3.3-.8 4.2-2.6s-.9-1-1.9-.9-.1-1.2 1-2.5 3.8-4.5 3.5-6.2-2.8-.8-6.8 1-6.5 5.4-7.4 6.8c-.4.7-.9 1.2-1.5 1.7 0 0-9.3 4-16.8 7.7s-10.8 6.6-10.7 9.2 2.8 3.2 2.8 3.2"
                />
                <path
                    className="st2"
                    d="M251.7 250.2s.2 4.8.9 9.3c.6 4.5 2.7 7.9 6.6 5.3 4-2.6 5.4-9 5.4-9-4.8-.8-9.2-2.7-12.9-5.6z"
                />
                <path
                    className="st7"
                    d="M245 239.9s-1.4 1.1-.5 4c.6 1.8 2.6 2.8 4.5 2.2h.1c.2-.3 1.1 3.9 2.4 5.9s7.6 5.5 10.3 6 5-1.1 5.4-2.7c.6-4.2 1-8.5 1-12.8 0-4.3.4-6.1-.5-7.8s-2.3-3.2-7.6-3.6-8.4 1.4-11 3.3c-1.5.9-2.3 2.5-2.1 4.2l-2 1.3z"
                />
                <path
                    className="st6"
                    d="M246.7 238.6c.4 1 .9 1.9 1.6 2.7.5.6 1 1.1 1.6 1.5l-.5-2.5 1.1.8c0-.5-.1-1.1-.1-1.6-.1-.8 1.2.2 1.2.2 0-1 .1-2 .5-3 .2-.4.5-.9.8-1.2 0-.2-5.5 1.7-6.2 3.1z"
                />
                <path
                    d="M245 239.9s8-6.7 23.1-4.6c0 0-.8-8.6-2.1-10s-5.4-3.8-11.8-1.8-10.3 5.6-10.5 7.6 1.3 8.8 1.3 8.8z"
                    style={{
                        fill: "#78bf8a",
                        stroke: "#263238",
                        strokeLinecap: "round",
                        strokeLinejoin: "round"
                    }}
                />
                <path
                    className="st4"
                    d="M252.1 236.6s4.1 1.3 7.2.4c3.9-1.5 8-2.1 12.2-1.9 5.7.4 4.9.5 4.9.5s-3.2-2-11-1.7-13.8 2.1-13.3 2.7z"
                />
                <path
                    className="st4"
                    d="M267 235.4s1.6 1 6.5 1.9 4.4-.4 2.9-1.6-9-.9-9.4-.3z"
                />
                <path
                    className="st2"
                    d="M263.5 238c-.4.6-.5 1.3-.2 1.9.5 1.5.7 3 .8 4.6 0 .3-.1.8-1.2.7-.9-.1-1.8-.3-2.6-.5"
                />
                <path
                    className="st13"
                    d="M259 239.9c0 .7-.4 1.2-.7 1.1s-.6-.6-.6-1.2.4-1.2.7-1.1.7.6.6 1.2zM266.3 239.4c0 .7-.4 1.2-.7 1.1s-.6-.6-.5-1.2.4-1.2.7-1.1.5.6.5 1.2z"
                />
                <path className="st2" d="M255.5 237.8s1.5-2.6 4.1-1.8" />
                <path className="st7" d="M264.6 235.5s2.4-.8 2.9 1.1" />
                <path className="st2" d="M260 249.9s2-2.8 4-.6" />
                <path
                    className="st6"
                    d="M272.3 257s-5.2.8-6.5 10.3c-1.2 8.7 3.3 17.9 5.8 21.3s4.4 5.4 5.6 5.5 2.5.2 3.9-7 4.4-12.8 1.4-19.4-4.8-7.3-10.2-10.7zM247.8 251.5c-3 3.4-5.2 7.5-6.3 11.9-2.2 7.8 2.1 21.1 2.6 22.4.5 1.2.8 2.5 1 3.8-2.8-3.6-5-7.6-6.5-11.9-2.3-5.7-2.8-12-1.4-18 .9-2.9 2.5-5.7 4.5-8 2-.4 4-.5 6.1-.2z"
                />
                <path
                    className="st14"
                    d="M274.1 407.2s-6.8.2-7.5.5-.7 2.7-.7 2.7-4.4 6.2-6.6 7.4-5.7 3.4-5.2 4.6 1.2 1.5 5.5 1.8 11.2-3 13.9-3.3 2.4-.7 2.1-2.6-1.5-7.4-1.5-7.4c.4-1.2.4-2.5 0-3.7z"
                />
                <path
                    className="st7"
                    d="M266 410.4s-1.3-4.3.4-4.5 3.5-.4 3.5 1.2c-.1 1.4-.4 2.7-.9 4 .1 0-2.1.1-3-.7z"
                />
                <path
                    className="st7"
                    d="M269.1 411.1s-2.2 3.6-4.3 4.9-3-.2-2.9-.6 4-5.1 4-5.1l3.2.8z"
                />
                <path
                    className="st6"
                    d="M258.4 422.9c-3-.2-3.9-1.1-4.1-1.6-.2.3-.2.7-.1 1.1.5 1.2 1.2 1.5 5.5 1.8 4.3.3 11.2-3 13.9-3.3s2.4-.7 2.1-2.6v-.3c-1.1.4-2.8 1-4.5 1.5-3.2.8-8.8 3.7-12.8 3.4z"
                />
                <path
                    className="st14"
                    d="M255.5 401s-6.7.3-7.4 1.1-2.9 4.9-5.8 6.9-5.6 1.9-6.5 3.6 1.8 2.1 5 2.9 5.2.1 8.1-1.5 5.4-1.1 8-2-.3-7.4-.2-8 .6-1 .2-2.2c-.1-.6-.6-1-1.2-1 0 .1-.1.2-.2.2z"
                />
                <path
                    className="st6"
                    d="M256.9 412.1c1.1-.4 1.2-1.7 1-3.1-1.2 1.2-3.8 1.5-6.2 2.5-2.8 1.2-4.9 2.8-9.1 2.8-2.4 0-4.7-.5-6.9-1.5-.8 1.6 1.9 2 5 2.8s5.2.1 8.1-1.5 5.5-1.1 8.1-2z"
                />
                <path
                    className="st7"
                    d="M246.6 404.8s-.7-2.9-1.2-4 3.7-2.1 4.3-.8-1.1 5.5-1.1 5.5l-2-.7zM248.6 405.5s-1.8 3.5-4 4.5-3.1-.4-3.1-.4c2.1-1.1 3.7-2.7 4.8-4.8l2.3.7z"
                />
                <path
                    d="M275.5 338.1s-7-.6-13-1.3-8.5-1-8.5-1 2 12.7 2 13.5-4.1 13-4.1 13l4.5 14.2s19.6-.6 26.8-.9S302 373 302 373s-6.8-9.1-8.2-11.4c-.7-1.2-1.6-2.4-2.5-3.5l10.3-11.1s-1.4-9.8-2-11.2-.8-2.1-.8-2.1-19.2 4.9-23.3 4.4z"
                    style={{
                        fill: "#92e3a9"
                    }}
                />
                <defs>
                    <path
                        id="SVGID_1_"
                        d="M275.5 338.1s-7-.6-13-1.3-8.5-1-8.5-1 2 12.7 2 13.5-4.1 13-4.1 13l4.5 14.2s19.6-.6 26.8-.9S302 373 302 373s-6.8-9.1-8.2-11.4c-.7-1.2-1.6-2.4-2.5-3.5l10.3-11.1s-1.4-9.8-2-11.2-.8-2.1-.8-2.1-19.2 4.9-23.3 4.4z"
                    />
                </defs>
                <clipPath id="SVGID_00000154395477247737419560000013300149233922792838_">
                    <use
                        xlinkHref="#SVGID_1_"
                        style={{
                            overflow: "visible"
                        }}
                    />
                </clipPath>
                <g
                    style={{
                        clipPath:
                            "url(#SVGID_00000154395477247737419560000013300149233922792838_)"
                    }}
                >
                    <path
                        className="st5"
                        d="M288.1 345.7h13.3c-.1-.6-.2-1.3-.3-2.1h-11.3c2.5-3.2 5.4-7.2 6.8-9.4l-3.1.7c-3 4.3-7.4 10.2-8.9 11.4-1.8 1.4-12.8 6.9-19.1 10 1.4-3.6 2.6-7.2 2.4-8.3-.5-1.3-1.1-2.6-1.8-3.9l6.8-6.3-2.8-.3-5 4.6c-1-1.9-2-3.8-2.9-5.4l-2.5-.3c2.7 4.9 5.9 11 6.1 11.9.1 1.1-2.4 7.5-4.6 12.8-.2.5-.1 1 .4 1.3l4.2 2.7-7.7 3-5.5-3.7 1 3.2 2.1 1.4-1.5.6.6 2 3-1.2 6.5 4.3c.4.3.9.2 1.2-.1l6.6-5.6c3.7 2.3 7.8 4.7 10.8 6.4h.1c1.8-.1 3.8-.3 5.9-.5l-4.9-5.1c2.3 1 4.9 1.5 7.4 1.4 2.9 0 5.8-.4 8.5-1.1-.4-.6-.9-1.2-1.3-1.8-5.6 1.2-11.1 1.1-13.9-.4-.9-.4-1.6-1.2-1.8-2.2-.1-.4 0-.8.3-1 1.4-1.7 6.9-2.3 11.2-2.3-.3-.4-.5-.7-.6-1-.3-.4-.5-.8-.7-1.1-3.8.1-9.4.7-11.4 3-.7.8-1 1.8-.7 2.8.1.3.1.5.2.8l-7-7.2.5-5.7 1.3-.7 15.5 4.5 1.6-1.7-14.4-4.1c2.5-1.2 4.9-2.6 7.2-4.1.8-.6 1.5-1.3 2.2-2.2zm-23.3 27.1-4.6-3.1 7.8-3 2.3 1.5-5.5 4.6zm21 2c-1.9-1-4.6-2.5-7.5-4.3l2.5-.9 5 5.2zm-6.6-6.8-3.2 1.1c-4.6-2.8-9.5-5.9-12.5-7.8.3-.6.6-1.3.9-2 1-.5 4.3-2.1 8.1-4l-.4 4.9c0 .3.1.6.3.8l6.8 7z"
                    />
                    <path
                        d="m301.6 347-45.6 2.4c-.1.8-4.1 13-4.1 13l39.5-4.2 10.2-11.2z"
                        style={{
                            opacity: 0.26,
                            enableBackground: "new"
                        }}
                    />
                    <path
                        className="st11"
                        d="m277.3 340.4-10.8 1.4M277.5 342.4l-7.6 2.8M283.7 342l8.4 1.6M284.5 340h6.2"
                    />
                </g>
                <path
                    className="st2"
                    d="M275.5 338.1s-7-.6-13-1.3-8.5-1-8.5-1 2 12.7 2 13.5-4.1 13-4.1 13l4.5 14.2s19.6-.6 26.8-.9S302 373 302 373s-6.8-9.1-8.2-11.4c-.7-1.2-1.6-2.4-2.5-3.5l10.3-11.1s-1.4-9.8-2-11.2-.8-2.1-.8-2.1-19.2 4.9-23.3 4.4z"
                />
                <path
                    className="st7"
                    d="M286.5 296.8c0-3.1-.8-6.7-.5-11s.3-15.5-1.8-18.8-8.5-6.5-8.5-6.5c-2.3 1.5-4 3.9-4.6 6.6-1.1 4.5.2 10.4.2 10.4s4.7 15.3 4.8 15.6c.2 3.3.2 6.6 0 10-.2 3.9.2 7.7 1.2 11.4.9 5.2 1.4 10.5 1.4 15.7-.1 1.4-.8 2.7-1.9 3.6-.9 1-2.2 2.9-1.6 4.5h1.7s.6 2.4.7 3-.1 3.6 1 3.7 2.1-1.9 2.1-1.9 3-.1 3.7-2 .1-6.8.1-6.8 1.6-12.6 2.5-20.8-.5-13.6-.5-16.7z"
                />
                <path
                    className="st7"
                    d="M275.7 260.5c-2.3 1.5-4 3.9-4.6 6.6-1.1 4.5.2 10.4.2 10.4s1.8 5.8 3.2 10.4c3.8 1 7.8 1 11.6-.2 0-.6 0-1.2.1-1.8.3-4.4.3-15.5-1.8-18.8s-8.7-6.6-8.7-6.6z"
                />
                <path
                    className="st11"
                    d="M275.9 269.2c-3.2 3.5-3.9 10.6-3.9 10.6s-.9-7.6 2.4-13"
                />
            </g>
        </svg>
    )
}