// @vendors
import { useState, useCallback, useEffect } from 'react'
import { Predicates } from 'prismic-javascript'

// @utils
import { client, clientPNN } from 'utils/prismic'

function getClient(clientName) {
  const clients = {
    client,
    clientPNN
  }

  return clients[clientName] || null
}

export default function usePrismicQueryByTags(clientName, customType, tags, noTags, pageLimit) {
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState([])

  const getInfo = useCallback(async(newTags) => {
    try {
      const updatedTags = newTags || tags
      const items = await getClient(clientName).query(
        [
          Predicates.at('document.type', customType),
          updatedTags && Predicates.at('document.tags', updatedTags),
          noTags && Predicates.not('document.tags', noTags)
        ],
        { pageSize: pageLimit, orderings: '[document.first_publication_date]' }
      )
      setInfo(items.results)
      setLoading(false)
    } catch (error) {
      console.error('Error getting blog info', error)
    }
  }, [])

  useEffect(() => {
    getInfo()
  }, [])

  return { info, loading, refetch: getInfo }
}
