// @vendors
import { useState, useCallback, useMemo, useEffect } from 'react'

// @hooks
import useFetch from 'hooks/useFetch'

// @api
import { getVisitedDestinations } from 'api/destination'

const filterTypes = {
    all: 'todos',
    destination: 'destinos',
    department: 'departamentos',
    pnn: 'pnn'
}

export default function useVisited({ email }) {
    const [filterType, setFilterType] = useState(filterTypes.all)
    const { data: visitedDestinations, loading, setData: setVisitedDestinations } = useFetch(
        getVisitedDestinations,
        { params: [email], fetchOnMount: true, showErrorMessage: true }
    )

    const handleFilterTypeChange = useCallback((newType) => () => {
        setFilterType(newType)
    }, [])

    const filteredVisitedDestinations = useMemo(() => {
        if (filterType === filterTypes.all) {
            return visitedDestinations
        }

        return visitedDestinations.filter(({ type }) => type === filterType)
    }, [filterType, visitedDestinations])

    const filterTypeCount = useMemo(() => ({
        [filterTypes.all]: (visitedDestinations ?? []).length,
        [filterTypes.destination]: (visitedDestinations ?? []).filter(({ type }) => type === filterTypes.destination).length,
        [filterTypes.department]: (visitedDestinations ?? []).filter(({ type }) => type === filterTypes.department).length,
        [filterTypes.pnn]: (visitedDestinations ?? []).filter(({ type }) => type === filterTypes.pnn).length
    }), [filterType, visitedDestinations])

    useEffect(() => {
        if (!(filteredVisitedDestinations ?? []).length) {
            setFilterType(filterTypes.all)
        }
    }, [visitedDestinations])

    return {
        visitedDestinations: filteredVisitedDestinations,
        loading,
        setVisitedDestinations,
        setFilterType: handleFilterTypeChange,
        filterType,
        filterTypeCount
    }
}