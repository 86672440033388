// @vendors
import { Button, Form, Input } from 'antd'

export default function UpdatePasswordForm({ onSubmit, loading }) {
  const onChangePassword = (values) => {
    onSubmit(values.newPassword)
  }

  return (
    <>
      <h3 className='title__ppal sm c--dr' style={{ marginBottom: '20px' }}>
        <strong>Actualizar contraseña</strong>
      </h3>
      <Form
        layout='vertical'
        onFinish={onChangePassword}
      >
        <Form.Item
          label='Nueva'
          name='newPassword'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label='Confirmar'
          name='confirmedPassword'
          rules={[
            {
              required: true,
              message: 'Este campo es obligatorio'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('newPassword') === value || !value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Las contraseñas nuevas deben coincidir'))
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className='actions ant-form-item--last'>
          <Button
            className='solid c-1'
            loading={loading}
            htmlType="submit"
          >
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
