export function mapDestinationItems(items) {
  return items.map((item) => ({
    linkTo: `/${item.tags[0]}/${item.uid}`,
    imageUrl: item.data.thumb[0].image.url,
    imageAlt: item.data.thumb[0].image.alt,
    name: item.data.name,
    caption: item.data.depto,
    type: item.type
  }))
}

export function mapDepartmentItems(items) {
  return items.map((item) => ({
    linkTo: `/${item.uid}`,
    imageUrl: item.data.thumb[0].image.url,
    imageAlt: item.data.thumb[0].image.alt,
    name: item.data.name,
    caption: 'Departamento',
    type: item.type
  }))
}

export function mapNationalParksItems(items) {
  return items.map((item) => ({
    linkTo: `/pnn/${item.uid}`,
    imageUrl: item.data.thumb[0].image.url,
    imageAlt: item.data.thumb[0].image.alt,
    name: item.data.name,
    caption: 'Parque Nacional Natural',
    type: item.type
  }))
}