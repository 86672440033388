// @vendors
import moment from 'moment'
import jwtDecode from 'jwt-decode'

export function getDecodedToken(token) {
  if (!token) {
    return null
  }
  return jwtDecode(token)
}

export function isTokenExpired(token) {
  if (!token) {
    return true
  }

  const decodedToken = getDecodedToken(token)
  const unixDate = moment.unix(decodedToken.exp)
  return unixDate.isSameOrBefore(moment())
}
