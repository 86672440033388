// @vendors
import { Helmet } from 'react-helmet'
import { useEffect, useMemo, useState } from 'react'
import { Skeleton, Select, Form } from 'antd'

// @hooks
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags'
import CardDestination from 'components/card-destination'

// @constants
import { DEFAULT } from 'constants/default'

// @utils
import { convertToSlug } from 'utils/format'

const { Option } = Select

export default function PatrimonyTowns() {
  const {
    info: list,
    loading
  } = usePrismicQueryByTags(
    'client',
    'destination',
    ['pueblo-patrimonio'],
    [''],
    20
  )

  const destinations = useMemo(() =>
    list.map((item) => ({
      linkTo: `/${convertToSlug(item.data.depto)}/${item.uid}`,
      imageUrl: item.data.thumb[0].image.url,
      imageAlt: item.data.thumb[0].image.alt,
      name: item.data.name,
      caption: item.data.depto,
      type: item.type
    })), [list])

  const [categories, setCategories] = useState([])
  const [filteredTowns, setFilteredTowns] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('Todos')

  useEffect(() => {
    if (destinations.length) {
      const categoriesIndexes = []

      destinations.forEach(category => {
        if (!categoriesIndexes.includes(category.caption)) {
          categoriesIndexes.push(category.caption)
        }
      })

      setCategories(categoriesIndexes)
      setFilteredTowns(destinations)
    }
  }, [destinations])

  useEffect(() => {
    let newTowns = []
    if (selectedCategory === 'Todos') {
      newTowns = destinations
    } else {
      newTowns = destinations.filter(({
        caption
      }) => caption === selectedCategory)
    }
    setFilteredTowns(newTowns)
  }, [selectedCategory])

  return (
    <>
      <Helmet>
        <title>Pueblos Patrimonio de Colombia {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          {loading &&
            <>
              <Skeleton
                className='ant-skeleton-basic'
                paragraph={{ rows: 0 }}
                active
              />
              <Skeleton
                title=''
                className='ant-skeleton-mosaic'
                paragraph={{ rows: 6 }}
                active
              />
            </>
          }
          {!loading &&
            <>
              <div className='title__with-extra'>
                <h1 className='title__ppal md'>
                  <strong>Pueblos Patrimonio</strong>
                </h1>
                <div className='extra__container'>
                  <span>Por departamento:</span>
                  <Form id='select-departament'>
                    <Form.Item>
                      <Select
                        defaultValue='Todos'
                        placeholder='Departamento'
                        onChange={(value) => setSelectedCategory(value)}
                        showSearch
                        getPopupContainer={() => document.getElementById('select-departament')}
                      >
                        <Option key='depto-item-Todos' value='Todos'>
                          Todos
                        </Option>
                        {categories.map((item, k) => (
                          <Option key={`depto-item-${convertToSlug(item)}-${k}`} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <div className='list__items-mosaic-card'>
                {filteredTowns.map((town, k) => (
                  <CardDestination destination={town} key={`card-destination-${k}`} />
                ))}
              </div>
            </>
          }
        </div>
      </section>
    </>
  )
}
