// @vendors
import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Skeleton, Tabs } from 'antd'
import { Link } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'

// @components
import CardDestination from 'components/card-destination'
import SearchBar from 'components/searchbar'

// @context
import { AppContext } from 'context/app'

// @constants
import { ACTIVITIES } from 'constants/activities'

// @utils
import { onScroll } from 'utils/on-scroll'

// @styles
import './styles.scss'

const { TabPane } = Tabs

export default function Sidebar({ destinations }) {
  const {
    app: {
      onOpenSearchSidebar,
      handleChangeSearchText,
      searchParamsInfo: {
        getSearchParam
      }
    }
  } = useContext(AppContext)
  const [activeTab, setActiveTab] = useState(() => {
    const currentIndexByUrl = Object.values(destinations)
      .findIndex(({ title }) => title === getSearchParam('tab'))

    return currentIndexByUrl >= 0 ? String(currentIndexByUrl) : '0'
  })

  const values = Object.values(destinations)
  const items = !values.length
    ? []
    : values.map(({ cardsList, title }) => ({
      title,
      cards: cardsList.map((destination, itemIndex) => (
        <CardDestination destination={destination} key={`card-detinations-home-${itemIndex}`} />
      ))
    }))

  return (
    <>
      <section className='home__container'>
        <div className='home__container-inner'>
          <h2 className='title__ppal md c-dark'>
            Explorar Colombia
          </h2>
          <div className='home__container-inner--search'>
            <SearchBar
              id="home-sidebar-searchbar"
              onSearch={onOpenSearchSidebar}
            />
          </div>
          <Tabs
            activeKey={activeTab}
            className='tabs__home'
            onTabClick={setActiveTab}
          >
            {
              items.map(({ title, cards }, index) => (
                <TabPane
                  tab={<Link to={`?tab=${title}`}>{title}</Link>}
                  key={index}
                >
                  {!cards.length && (
                    <Skeleton
                      className='ant-skeleton-cards'
                      paragraph={{ rows: 5 }}
                      title=''
                      active
                    />
                  )}
                  <>
                    <ScrollContainer className='list__items-home'>
                      {cards}
                      {title === 'Parques Nacionales' && (
                        <div className='destination__item destination__item--see-all'>
                          <Link to='/pnn' className='destination__item-data'>
                            <em className='fal fa-leaf' />
                            <span>Ver todos</span>
                          </Link>
                        </div>
                      )}
                    </ScrollContainer>

                    <div className='ant-tabs-tabpane-actions'>
                      <Button
                        className='solid c-gr-2 ant-btn-only--icon ant-btn-size--xs ant-btn-next'
                        onClick={onScroll(-400, 'home')}
                      >
                        <em className='fal fa-chevron-left' />
                      </Button>
                      <Button
                        className='solid c-gr-2 ant-btn-only--icon ant-btn-size--xs ant-btn-next'
                        onClick={onScroll(400, 'home')}
                      >
                        <em className='fal fa-chevron-right' />
                      </Button>
                    </div>
                  </>
                </TabPane>
              ))
            }
          </Tabs>
          <h3 className='subtitle__ppal c-dark'>Para todos los gustos</h3>
          <ScrollContainer className='list__items-activities'>
            {
              ACTIVITIES.map((activity, k) => (
                <li
                  key={k}
                  onClick={handleChangeSearchText(activity.title)}
                >
                  <div className='activity__container'>
                    <div className='icon'>
                      <em className={`fal fa-${activity.iconName}`} />
                    </div>
                    <span>
                      {activity.title}
                    </span>
                  </div>
                </li>
              ))
            }
          </ScrollContainer>
        </div>
      </section>
    </>
  )
}

Sidebar.propTypes = {
  activities: PropTypes.array,
  destinations: PropTypes.object
}

Sidebar.defaultProps = {
  activities: [],
  destinations: {}
}
