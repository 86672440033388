// @vendors
import { useContext } from 'react'

// @context
import { DepartmentPageContext } from 'context/pages'

// @styles
import './styles.scss'

export default function DepartmentBanner() {
  const departmentInfo = useContext(DepartmentPageContext)
  const bannerInfo = departmentInfo.depto_banner[0]

  return (
    <div className="banner__depto">
      <div className="banner__bg">
        <img src={bannerInfo.banner_img.url} alt={bannerInfo.banner_img.alt} />
        <div className='banner__bg-copy'>
          Foto: {bannerInfo.banner_img.copyright ? bannerInfo.banner_img.copyright : 'Háblanos para créditos'}
        </div>
      </div>
    </div>
  )
}
