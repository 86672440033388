// @vendors
import { Button, Drawer, Tag, Typography } from 'antd'
import cx from 'classnames'
import { RichText } from 'prismic-reactjs'
import ScrollContainer from 'react-indiana-drag-scroll'

// @utils
import { useWindowDimensions } from 'utils/DOM-functions'
import { convertToSlug } from 'utils/format'

// @components
import Experience from './experience'
import Lodging from './lodging'
import Restaurant from './restaurant'

// @styles
import './styles.scss'

const providerType = {
    lodging: 'Hospedaje',
    restaurant: 'Restaurante',
    experience: 'Experiencia'
}

const providerTextByType = {
  restaurant: 'Ver menú',
  lodging: 'Reserva'
}

function getInfoByProviderType(provider, destination) {
  switch (provider.type) {
    case 'experience':
      return (
          <Experience
              entities={provider.data.body2}
              name={provider.data.name}
              destination={destination}
          />
      )
    case 'lodging':
      return (
          <Lodging
              provider={provider}
              destination={destination}
          />
      )
    case 'restaurant':
      return (
          <Restaurant
              provider={provider}
              destination={destination}
          />
      )
    default:
      return null
  }
}

export default function DrawerProvider({ visible, onClose, data, destination }) {
    const provider = data.item
    const { width } = useWindowDimensions()

    return (
        <Drawer
            destroyOnClose
            visible={visible}
            onClose={onClose}
            footer={provider?.type !== 'experience' && (
                <>
                    <div className='ant-drawer-footer-price'>
                        {provider?.type === 'lodging' && (
                            <>
                                <strong>Desde</strong>
                                <span>${provider?.data?.prices?.find(({ destination: place }) => place === destination).price}<sub> / noche</sub></span>
                            </>
                        )}
                    </div>
                    <div className='ant-drawer-footer-actions'>
                        <Button
                            onClick={() => window.open(`${provider?.data?.booking[0]?.link?.url}`)}
                            className='solid c-1'
                            id={`action-provider-${provider?.type}-${convertToSlug(provider?.data?.name)}-${destination}`}
                        >
                            {
                              provider?.type === 'lodging' &&
                                <em className={`fab fa-${provider?.data?.booking[0]?.icon}`} />
                            }
                            {
                              provider?.type === 'restaurant' &&
                                <em className='fal fa-book' />
                            }
                            <span className='text'>
                              {providerTextByType[provider?.type || '']}
                            </span>
                        </Button>
                    </div>
                </>
            )}
            title={(
                <>
                    <h3 className='title__ppal md c-dr'>
                        <span>
                            {providerType[provider?.type]} en
                        </span>
                        {destination}
                    </h3>
                    <Button
                        className='ant-btn-close'
                        onClick={onClose}
                    >
                        <em className='fal fa-times' />
                    </Button>
                </>
            )}
            placement='bottom'
            className='drawer__default drawer__default-provider'
            getContainer={!!(width < 860)}
            height={'100%'}
            mask={false}
            zIndex={width < 860 ? 1001 : 10}
        >
            <div className='provider__detail'>
                {provider?.data?.photos?.length
                    ? (
                        <ScrollContainer className={
                            cx(
                                'provider__detail-gallery',
                                { 'provider__detail-gallery-empty': !provider.data.photos[0]?.image?.url }
                            )
                        }>
                            {provider.data.photos.map((item, index) => (
                                <img
                                    src={item.image.url}
                                    alt={item.image.alt}
                                    key={`provider-photo-${index}`}
                                />
                            ))}
                        </ScrollContainer>
                    )
                    : ''
                }
                {provider &&
                    <div className='provider__detail-content'>
                        <div className='provider__detail-header'>
                            <img
                              src={provider?.data?.logo?.url}
                              alt={provider?.data?.logo?.alt}
                            />
                            <h5 className='title__ppal xs c-dr'>
                              {provider?.data?.name}
                            </h5>
                        </div>
                        <div className='provider__detail-body'>
                            {provider?.type !== 'experience' && (
                                <div className='provider__detail-body-languages'>
                                    <Tag>Español</Tag>
                                    {
                                      provider?.data?.languages?.map((item, index) => (
                                        <Tag key={index}>{item.text}</Tag>
                                      ))
                                    }
                                </div>
                            )}
                            <Typography.Text type='secondary'>
                                <RichText render={provider?.data?.description} />
                            </Typography.Text>

                            {getInfoByProviderType(provider, destination)}

                            {
                              provider?.type !== 'experience' && (
                                <div className='provider__detail-body-location'>
                                    <h4 className='title__ppal xs c-dr'>Ubicación</h4>
                                    <div className='provider__detail-body-location-btns'>
                                        <Button
                                            onClick={() => window.open(`https://waze.com/ul?q=${provider?.data?.name}+${destination}+Colombia`)}
                                            className='outline c-dr'
                                            id={`provider-location-waze-${convertToSlug(provider?.data?.name)}-${destination}`}
                                        >
                                            <em className='fab fa-waze' />
                                            <span className='text'>
                                                Waze
                                            </span>
                                        </Button>
                                        <Button
                                            className='outline c-dr'
                                            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${provider?.data?.name}+${destination}+Colombia`)}
                                            id={`provider-location-maps-${convertToSlug(provider?.data?.name)}-${destination}`}
                                        >
                                            <em className='fab fa-google' />
                                            <span className='text'>Google</span>
                                        </Button>
                                    </div>
                                </div>
                              )
                            }
                        </div>
                    </div>
                }
            </div>
        </Drawer>
    )
}