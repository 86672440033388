export default function ManBeach() {
  return (
    <svg
      id="prefix__Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 429.6 348.8"
      xmlSpace="preserve"
    >
      <style>
        {
          '.prefix__st2,.prefix__st3{enable-background:new}.prefix__st2{opacity:.55;fill:#b8c8d3}.prefix__st3{opacity:.5}.prefix__st3,.prefix__st7{fill:#fff}.prefix__st8{opacity:.1;enable-background:new}.prefix__st9{stroke-width:.75;stroke-dasharray:2}.prefix__st11,.prefix__st12,.prefix__st13,.prefix__st14,.prefix__st9{fill:none;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st12,.prefix__st13,.prefix__st14{fill:#263238}.prefix__st13,.prefix__st14{fill:#92e3a9}.prefix__st14{fill:#fff}.prefix__st16{opacity:.21;enable-background:new}.prefix__st17{fill:none;stroke:#fff;stroke-width:.75;stroke-linecap:round;stroke-linejoin:round}.prefix__st18{fill:#92e3a9}.prefix__st20{opacity:.2;enable-background:new}'
        }
      </style>
      <g id="prefix__freepik--background-simple--inject-559">
        <path
          d="M309.1 24.6s-60.4-34.1-129.5-8.5-113 92.6-150 146S-1.6 277.3 42 312.6s82.9 1.2 150.7 11 84.3 22.6 146.7-6 87.6-117.3 54.7-192-85-101-85-101z"
          fill="#e7edf4"
        />
        <path
          d="M309.1 24.6s-60.4-34.1-129.5-8.5-113 92.6-150 146S-1.6 277.3 42 312.6s82.9 1.2 150.7 11 84.3 22.6 146.7-6 87.6-117.3 54.7-192-85-101-85-101z"
          opacity={0.8}
          fill="#fff"
        />
      </g>
      <g id="prefix__freepik--Clouds--inject-559">
        <path
          className="prefix__st2"
          d="M349.8 73.5c-7.4-7.3-13.2-16.8-24-16.8-8 0-10.3 8.8-17.1 11.1-7.1 2.5-12.5-3.9-19-5.2-10.9-2.3-10.6 5.4-15.3 11.9-4.5 6.2-14.4-2.5-19.2 4.8-4.2 6.4-10.7 10.5-14.2 9.9s-11.3-4.9-18.3-.7-9.9 7.8-16.9 7.8-16.2 2.1-16.2 2.1h190.6s-6.4-15.5-16.2-16.9c-5.9-.9-10.3-4.1-14.2-8zM130.5 102c-3.8-3.7-6.7-8.6-12.2-8.6-4.1 0-5.2 4.5-8.7 5.6-3.6 1.2-6.4-2-9.6-2.7-5.5-1.1-5.4 2.7-7.8 6.1-2.3 3.1-7.3-1.3-9.8 2.4-2.2 3.2-5.4 5.4-7.2 5s-5.7-2.5-9.3-.3c-3.6 2.2-5 3.9-8.6 3.9-2.8.1-5.5.5-8.2 1.1h96.7s-3-7.9-8-8.6c-2.9-.4-5.4-1.8-7.3-3.9zM228.6 22.7c-2.1-2.1-3.8-4.8-6.9-4.8-2.3 0-3 2.5-4.9 3.2s-3.6-1.1-5.5-1.5c-3.1-.7-3 1.6-4.4 3.4s-4.1-.7-5.5 1.4c-1.2 1.8-3.1 3-4.1 2.8s-3.3-1.4-5.3-.2-2.9 2.2-4.9 2.2c-1.6.1-3.1.3-4.7.6h51.9s1.2-4.5-1.6-4.9c-1.6-.2-3-1-4.1-2.2z"
        />
        <path
          className="prefix__st3"
          d="M349.8 73.5c-7.4-7.3-13.2-16.8-24-16.8-8 0-10.3 8.8-17.1 11.1-7.1 2.5-12.5-3.9-19-5.2-10.9-2.3-10.6 5.4-15.3 11.9-4.5 6.2-14.4-2.5-19.2 4.8-4.2 6.4-10.7 10.5-14.2 9.9s-11.3-4.9-18.3-.7-9.9 7.8-16.9 7.8-16.2 2.1-16.2 2.1h190.6s-6.4-15.5-16.2-16.9c-5.9-.9-10.3-4.1-14.2-8zM130.5 102c-3.8-3.7-6.7-8.6-12.2-8.6-4.1 0-5.2 4.5-8.7 5.6-3.6 1.2-6.4-2-9.6-2.7-5.5-1.1-5.4 2.7-7.8 6.1-2.3 3.1-7.3-1.3-9.8 2.4-2.2 3.2-5.4 5.4-7.2 5s-5.7-2.5-9.3-.3c-3.6 2.2-5 3.9-8.6 3.9-2.8.1-5.5.5-8.2 1.1h96.7s-3-7.9-8-8.6c-2.9-.4-5.4-1.8-7.3-3.9zM228.6 22.7c-2.1-2.1-3.8-4.8-6.9-4.8-2.3 0-3 2.5-4.9 3.2s-3.6-1.1-5.5-1.5c-3.1-.7-3 1.6-4.4 3.4s-4.1-.7-5.5 1.4c-1.2 1.8-3.1 3-4.1 2.8s-3.3-1.4-5.3-.2-2.9 2.2-4.9 2.2c-1.6.1-3.1.3-4.7.6h51.9s1.2-4.5-1.6-4.9c-1.6-.2-3-1-4.1-2.2z"
        />
      </g>
      <g id="prefix__freepik--Mountains--inject-559">
        <defs>
          <path
            id="prefix__SVGID_1_"
            d="M309.1 24.6s-60.4-34.1-129.5-8.5-113 92.6-150 146S-1.6 277.3 42 312.6s82.9 1.2 150.7 11 84.3 22.6 146.7-6 87.6-117.3 54.7-192-85-101-85-101z"
          />
        </defs>
        <clipPath id="prefix__SVGID_2_">
          <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_2_)">
          <path
            fill="#8e9ca7"
            d="M52 352.6h276.5l102.1-104.2 1.6-3.6-111.1-139.9-22.2 20.6L210 34.1l-85.6 118.5-38.7-27.9-94.4 136.1z"
          />
          <path
            opacity={0.55}
            fill="#fff"
            d="M52 352.6h276.5l102.1-104.2 1.6-3.6-111.1-139.9-22.2 20.6L210 34.1l-85.6 118.5-38.7-27.9-94.4 136.1z"
          />
          <path
            className="prefix__st7"
            d="M337.5 137l-8.2-4.9 3.3 18.9-33.7-25.5 22.2-20.6 19.1 24.1-2.7 8zM196.8 81l6.6 9.9 11.5-13.2 26.3 40.3 8.2-29.6 11.5 8.2.8-9.3L210 34.2l-36.6 50.7.4 12.7 23-16.6zM117 164.2l2.1-15.4-33.4-24.2-26.4 38.1 17.4-15.8 8.2 14 13.2-9.9 18.9 13.2z"
          />
          <path
            className="prefix__st8"
            d="M90 176l-11.3-9.2-6.4 23.3 27.5 39.6-15.5 2.8 9.2 56.5 36-108.7-9.2-.8 4.1-26.9-38.7-27.9L98.1 151zM243.7 150.2l9.9 107.8-18.1 28.2 88.4 66.4h4.6l102.1-104.2 1.6-3.6-111.1-139.9 18.1 39.5-18.1-9.1-7.4 2.5-14.8-12.3L210 34.1l60.1 135.8z"
          />
        </g>
      </g>
      <g id="prefix__freepik--Wind--inject-559">
        <path
          className="prefix__st9"
          d="M67.8 102s6.8-2.7 19.2 0 24 11.7 42.5 14.4 30.2-3.4 32.2-13.7-7.5-11.7-13.7-6.9 0 13 0 13"
        />
        <path
          className="prefix__st9"
          d="M126.1 123.2s8.9 3.4 24 0 16.4-20.6 28.1-29.5 21.2.7 21.2 8.9-6.2 9.6-12.3 9.6M295.5 207.4s8.9-4.1 20.6 6.9 25.4 23.3 43.9 21.9 26-17.8 41.8-21.2 23.3 6.2 23.3 12.3-10.3 14.4-15.8 8.2M273.5 221.2s22.6-6.9 34.3 2.7 21.9 24 39.1 24"
        />
      </g>
      <g id="prefix__freepik--Character--inject-559">
        <path
          className="prefix__st7"
          d="M229.6 165.2s21.7.2 25 4 .2 17.2.2 17.2c3.6 6.5 6.1 13.5 7.6 20.8 2.1 11.6-2.8 23.1-2.8 23.1s-2.1 10.6-4.2 15.3-17.5 4.5-26.7 2.8-5.4-17.7-4.7-29.5-2.1-44.1-2.1-47.2 2-4.2 7.7-6.5z"
        />
        <defs>
          <path
            id="prefix__SVGID_3_"
            d="M229.6 165.2s21.7.2 25 4 .2 17.2.2 17.2c3.6 6.5 6.1 13.5 7.6 20.8 2.1 11.6-2.8 23.1-2.8 23.1s-2.1 10.6-4.2 15.3-17.5 4.5-26.7 2.8-5.4-17.7-4.7-29.5-2.1-44.1-2.1-47.2 2-4.2 7.7-6.5z"
          />
        </defs>
        <clipPath id="prefix__SVGID_4_">
          <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_4_)">
          <path
            className="prefix__st8"
            d="M229.6 165.2s21.7.2 25 4 .2 17.2.2 17.2c3.6 6.5 6.1 13.5 7.6 20.8 2.1 11.6-2.8 23.1-2.8 23.1s-2.1 10.6-4.2 15.3-17.5 4.5-26.7 2.8-5.4-17.7-4.7-29.5-2.1-44.1-2.1-47.2 2-4.2 7.7-6.5z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M229.6 165.2s21.7.2 25 4 .2 17.2.2 17.2c3.6 6.5 6.1 13.5 7.6 20.8 2.1 11.6-2.8 23.1-2.8 23.1s-2.1 10.6-4.2 15.3-17.5 4.5-26.7 2.8-5.4-17.7-4.7-29.5-2.1-44.1-2.1-47.2 2-4.2 7.7-6.5z"
        />
        <path
          className="prefix__st12"
          d="M254.9 186.4s-12.5 4-21.7 5.2l-.2-4.2s19.1-1.6 22.4-4.5l-.5 3.5zM259.6 230.3s-14.6 3.3-19.8 2.8l-.5 4s14.4-.2 19.6-2.6l.7-4.2zM199.6 244.7s-32.6-2.8-40.4 0-9 12.5-9 19.1-5 36.1-5.4 37.3-5.7 11.6-4 13.9 16.5 0 19.1-2.6 9.2-31.6 11.6-39.2c1.3-4.3 3.1-8.5 5.2-12.5 0 0 30.9 12.8 47.2 8.3s10.4-16 1.4-20.8-25.7-3.5-25.7-3.5z"
        />
        <path
          className="prefix__st13"
          d="M142.8 299.2s-8.5 2.6-12.7 2.6-10.6-1.4-11.6 4.5 4.5 11.6 16.5 12.3 15.3.5 17.9-2.1 2.1-5.9-.5-10.1-7-7.4-9.6-7.2z"
        />
        <path
          className="prefix__st14"
          d="M149.9 307.5c.9-.4 1.7-.9 2.4-1.5-2.6-4.1-6.9-7-9.5-6.8 0 0-8.5 2.6-12.7 2.6h-.7c-1.7.6-3.8 2.1-2.4 5.2 2.1 5 14.4 4.2 22.9.5z"
        />
        <path
          className="prefix__st7"
          d="M327.5 335.8s-36.8-60.9-39.2-61.4-12.3-.9-12.3-.9-26.9-14.6-31.6-14.6-27.4 13.7-29.7 14.6-15.1 2.4-15.1 4.2-9 17-9 17l-18.4 6.6-1.4 7.1-5.7 31.6h168l-5.6-4.2z"
        />
        <defs>
          <path
            id="prefix__SVGID_5_"
            d="M327.5 335.8s-36.8-60.9-39.2-61.4-12.3-.9-12.3-.9-26.9-14.6-31.6-14.6-27.4 13.7-29.7 14.6-15.1 2.4-15.1 4.2-9 17-9 17l-18.4 6.6-1.4 7.1-5.7 31.6h168l-5.6-4.2z"
          />
        </defs>
        <clipPath id="prefix__SVGID_6_">
          <use xlinkHref="#prefix__SVGID_5_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_6_)">
          <path
            className="prefix__st16"
            d="M337.3 341.1l-9.1-7.7-38-60.6-17.2-2.6s-27-13.9-30-13.5-33.2 17.9-34.3 17.9-8.4-.4-10.2 2.2-9.5 15.7-9.5 15.7l-19 6.2-1.8 13.1 15 .7 30.3 30h127.2l-3.4-1.4z"
          />
          <ellipse
            className="prefix__st7"
            cx={267.1}
            cy={283.8}
            rx={8.5}
            ry={5.3}
          />
          <path
            className="prefix__st7"
            d="M292.6 277.9c0 2.9-3.8 5.3-8.5 5.3s-8.5-2.4-8.5-5.3 3.8-5.3 8.5-5.3 8.5 2.4 8.5 5.3zM259.6 285.9c0 1.2-1.5 2.2-3.4 2.2s-3.4-1-3.4-2.2 1.5-2.2 3.4-2.2 3.4.9 3.4 2.2z"
          />
          <ellipse
            className="prefix__st7"
            cx={278.3}
            cy={284.2}
            rx={5.8}
            ry={3.9}
          />
          <path
            className="prefix__st7"
            d="M274.9 290.8c0 1.7-2.5 3-5.6 3s-5.6-1.4-5.6-3 2.5-3.1 5.6-3.1 5.6 1.4 5.6 3.1zM251.8 285.9c0 1-1.4 1.9-3 1.9s-3.1-.8-3.1-1.9 1.4-1.9 3.1-1.9 3 .8 3 1.9zM261.3 276.9c0 .6-1.1 1-2.4 1s-2.4-.5-2.4-1 1.1-1 2.4-1 2.4.4 2.4 1zM278.3 277.5c0 1.5-1.7 2.7-3.7 2.7s-3.7-1.2-3.7-2.7 1.7-2.7 3.7-2.7 3.7 1.2 3.7 2.7zM270.8 276.4c0 .6-1.4 1.2-3.1 1.2s-3.1-.5-3.1-1.2 1.4-1.2 3.1-1.2 3.1.5 3.1 1.2z"
          />
          <path
            className="prefix__st7"
            d="M281.7 295c0 1.8-2.5 3.2-5.6 3.2s-5.6-1.4-5.6-3.2 2.5-3.2 5.6-3.2 5.6 1.5 5.6 3.2z"
          />
          <ellipse
            className="prefix__st7"
            cx={285.1}
            cy={290.1}
            rx={7.8}
            ry={5.1}
          />
          <path
            className="prefix__st7"
            d="M297.3 284.7c0 2.8-3.5 5.1-7.8 5.1s-7.8-2.3-7.8-5.1 3.5-5.1 7.8-5.1 7.8 2.3 7.8 5.1zM238.6 291.2c0 1.3-1.5 2.4-3.4 2.4s-3.4-1.1-3.4-2.4 1.5-2.4 3.4-2.4 3.4 1 3.4 2.4zM242.7 300c0 2.6-4.3 4.8-9.5 4.8s-9.5-2.1-9.5-4.8 4.3-4.8 9.5-4.8 9.5 2.1 9.5 4.8z"
          />
          <path
            className="prefix__st7"
            d="M246.7 294.7c0 2-2.6 3.6-5.8 3.6s-5.8-1.6-5.8-3.6 2.6-3.6 5.8-3.6 5.8 1.6 5.8 3.6zM221.8 286c0 1.7-2.5 3.1-5.6 3.1s-5.6-1.4-5.6-3.1 2.5-3 5.6-3 5.6 1.3 5.6 3zM215.5 292.2c0 1.1-2.1 2-4.8 2s-4.8-.9-4.8-2 2.1-2 4.8-2 4.8.8 4.8 2z"
          />
          <ellipse
            className="prefix__st7"
            cx={217.7}
            cy={293.3}
            rx={3.2}
            ry={1.9}
          />
          <path
            className="prefix__st7"
            d="M202.6 300.6c0 1.7-3 3.1-6.6 3.1s-6.6-1.4-6.6-3.1 3-3 6.6-3 6.6 1.3 6.6 3z"
          />
          <ellipse
            className="prefix__st7"
            cx={203.6}
            cy={311}
            rx={7.1}
            ry={4.9}
          />
          <path
            className="prefix__st7"
            d="M208 301.5c0 1-1.5 1.9-3.4 1.9s-3.4-.8-3.4-1.9 1.5-1.9 3.4-1.9 3.4.9 3.4 1.9z"
          />
          <ellipse
            className="prefix__st7"
            cx={193.3}
            cy={306.1}
            rx={3.9}
            ry={2}
          />
          <path
            className="prefix__st7"
            d="M218.9 309.8c0 1.3-2 2.4-4.4 2.4s-4.4-1.1-4.4-2.4 2-2.4 4.4-2.4 4.4 1.1 4.4 2.4z"
          />
          <path
            className="prefix__st8"
            d="M288.4 274.4l1.4 29.2-44.5-11.6 1 33.9-23.8-17-2.1 11.7-19.6 4.2-19.1-19.2-8.4 19.7-40.3 5.9-33.9 10.7h119.4l-.9-2 115.6.1-5.7-4.2z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M327.5 335.8s-36.8-60.9-39.2-61.4-12.3-.9-12.3-.9-26.9-14.6-31.6-14.6-27.4 13.7-29.7 14.6-15.1 2.4-15.1 4.2-9 17-9 17l-18.4 6.6-1.4 7.1-5.7 31.6h168l-5.6-4.2z"
        />
        <path
          className="prefix__st8"
          d="M199.6 277.7c0 1.5-5.3 10.7-7.8 14.9l19.1-9 38.6-1.7 13.6-5.7h12.7l2.2-2.7-2-.1s-26.9-14.6-31.6-14.6-27.4 13.7-29.7 14.6-15.1 2.5-15.1 4.3z"
        />
        <path
          className="prefix__st11"
          d="M190.7 294.7h16l23.6 21.7-.5 15.1-15.5 8.1M215 301.7l20.5-15.5 30.7 18 13.7 36.3"
        />
        <path
          className="prefix__st11"
          d="M266.2 304.2l-5.7 18.8-22.6 6.2-7.6-5.2M237.9 329.2l.9 10.8M260.5 323l7.1 16.6"
        />
        <path
          className="prefix__st12"
          d="M242.3 244s.5 12-1.9 17.2c-2.4 5.2-5.9 7.8-19.6 8.7s-32.1-5.7-32.1-5.7-9.9 16.8-13.9 24.1-11.3 15.6-13.4 18.4-4.5 7.1-7.3 8.5-9.4-7.1-10.6-10.1-2.1-5.2-1.9-6.6 5.4-3.8 5.4-3.8 8-15.1 11.8-21.2 6.6-18.6 9.9-23.1 10.9-7.1 20.3-6.6 24.8 2.4 28.8 2.4 24.5-2.2 24.5-2.2z"
        />
        <path
          className="prefix__st17"
          d="M150.6 288.5c3.9-6.9 10.4-18.7 12.2-23.5 2.7-7 5.7-13 5.7-13M147.6 293.7s.5-.8 1.2-2.1"
        />
        <path
          className="prefix__st18"
          d="M205.6 187s-4.6 5.8-5.8 10.5-5.8 21.4-6.8 22.6-3.9 5.5-6.8 7.5-12.2 5.8-15.7 8.8-1.8 4.4-3.2 6.8 5 3.8 6.4 3.8 11.8-.6 17.6-2.7 14.9-13.5 15.3-14.2 2-11 2.5-23 2.5-20.1-3.5-20.1z"
        />
        <defs>
          <path
            id="prefix__SVGID_7_"
            d="M205.6 187s-4.6 5.8-5.8 10.5-5.8 21.4-6.8 22.6-3.9 5.5-6.8 7.5-12.2 5.8-15.7 8.8-1.8 4.4-3.2 6.8 5 3.8 6.4 3.8 11.8-.6 17.6-2.7 14.9-13.5 15.3-14.2 2-11 2.5-23 2.5-20.1-3.5-20.1z"
          />
        </defs>
        <clipPath id="prefix__SVGID_8_">
          <use xlinkHref="#prefix__SVGID_7_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_8_)">
          <path
            className="prefix__st20"
            d="M209.9 195.8c-.1-.8-.2-1.3-.2-1.3l-5.9 4.6c-1.8 3-2.6 6.5-2.4 10 .3 6.2 1.8 10.3.9 10.3s-3.8-3.5-3.8 0 2.1 6.2 2.7 8.3-7.4-6.8-6.5-3.8 4.7 6.5 4.4 8.3-5-6.2-6.8-4.1-.3 5.6-2.1 6.2c-1.5.5-13.9 9.6-15.2 12.8 3.3-.2 11.4-.9 16.3-2.6 5.8-2 14.9-13.5 15.3-14.2s2-11 2.5-23c.3-4.4.7-8.3.8-11.5z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M205.6 187s-4.6 5.8-5.8 10.5-5.8 21.4-6.8 22.6-3.9 5.5-6.8 7.5-12.2 5.8-15.7 8.8-1.8 4.4-3.2 6.8 5 3.8 6.4 3.8 11.8-.6 17.6-2.7 14.9-13.5 15.3-14.2 2-11 2.5-23 2.5-20.1-3.5-20.1z"
        />
        <path
          className="prefix__st11"
          d="M203.8 229.7s-8.4-9.5-8.7-7.3 5.7 7.3 5.7 9.8-8.7-7-9.5-4.3c-.4 1.6.4 3.2 1.9 3.8"
        />
        <path
          className="prefix__st12"
          d="M175.5 237s-4.9-1.8-6.9 0-9.7 8.2-6.6 10 8.6 1.9 10.5.3c1.1-.9 2.3-1.7 3.6-2.3 0 0-.8 2.8 1.8 1.3s4.9-6.7 3.3-7.9-5.7-1.4-5.7-1.4z"
        />
        <path
          className="prefix__st13"
          d="M203.7 186.2c-.7 6.2-1 12.4-.9 18.6.3 8 1.7 23.8.1 28.8s-1.5 9.5 1.6 11.5 17 4.2 25.7 3 13.9-2.7 14.5-6 .9-32.1-.7-42.6-4.8-20.3-8-23.5-6.5-2-14.6 1.2-15.4 5.8-17.7 9z"
        />
        <path
          className="prefix__st12"
          d="M220.6 201.3s-7.6 4.7-18.2 0c0 0-.5 5 .2 5 3.3 1 6.7 1.5 10.1 1.4 6.8 0 9.4-1.6 9.4-1.6l-1.5-4.8z"
        />
        <path
          className="prefix__st12"
          d="M219.9 184.1s-3.1 7.5-2.4 14.4 3.1 12.5 9.9 12.5 12.5-14.9 12.8-23.1-2.6-11.9-9.2-11.9-11.1 8.1-11.1 8.1z"
        />
        <path
          className="prefix__st18"
          d="M202.4 184.6s4 8 5 9.6 1.2.9 2.2.4 20.4-17.2 22.2-17.9 5.2.9 5.4-.8-7.2-12.5-9.3-14.2-6.4 4.8-7.4 5.3-1.5 0-3.3.4-11.3 12.3-14.8 17.2z"
        />
        <defs>
          <path
            id="prefix__SVGID_9_"
            d="M202.4 184.6s4 8 5 9.6 1.2.9 2.2.4 20.4-17.2 22.2-17.9 5.2.9 5.4-.8-7.2-12.5-9.3-14.2-6.4 4.8-7.4 5.3-1.5 0-3.3.4-11.3 12.3-14.8 17.2z"
          />
        </defs>
        <clipPath id="prefix__SVGID_10_">
          <use xlinkHref="#prefix__SVGID_9_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_10_)">
          <path
            className="prefix__st20"
            d="M228 161.6c-2.1-1.7-6.4 4.8-7.4 5.3s-1.5 0-3.3.4c-1.1.2-4.8 4.4-8.4 8.8l2 11.3s9.2-4.2 11.8-5.6c-2.5 2.5-4.9 5.1-7.1 7.9 6.2-5.2 15-12.6 16.2-13.1 1.7-.7 5.2.9 5.4-.8s-7.1-12.5-9.2-14.2z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M202.4 184.6s4 8 5 9.6 1.2.9 2.2.4 20.4-17.2 22.2-17.9 5.2.9 5.4-.8-7.2-12.5-9.3-14.2-6.4 4.8-7.4 5.3-1.5 0-3.3.4-11.3 12.3-14.8 17.2z"
        />
        <path
          className="prefix__st11"
          d="M217 177.2s12.8-8.9 8.4-8.9-4.9 5.4-4.9 5.4"
        />
        <path
          className="prefix__st7"
          d="M206.5 183.8l4.4 3.6c2.2-3.3 4.3-6.7 6.1-10.2l-2.2-7.4-8.3 14z"
        />
        <defs>
          <path
            id="prefix__SVGID_11_"
            d="M206.5 183.8l4.4 3.6c2.2-3.3 4.3-6.7 6.1-10.2l-2.2-7.4-8.3 14z"
          />
        </defs>
        <clipPath id="prefix__SVGID_12_">
          <use xlinkHref="#prefix__SVGID_11_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_12_)">
          <path
            className="prefix__st18"
            d="M206.5 183.8l4.4 3.6c2.2-3.3 4.3-6.7 6.1-10.2l-2.2-7.4-8.3 14z"
          />
          <path
            className="prefix__st3"
            d="M206.5 183.8l4.4 3.6c2.2-3.3 4.3-6.7 6.1-10.2l-2.2-7.4-8.3 14z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M206.5 183.8l4.4 3.6c2.2-3.3 4.3-6.7 6.1-10.2l-2.2-7.4-8.3 14z"
        />
        <path
          className="prefix__st7"
          d="M192.6 147c-2.2 2.9-3.5 6.4-3.5 10 0 6.6 2.9 16.6 4.9 20.7s7 9.8 9.4 10 8.4-9 9.6-11.3c.7-1.2 1.2-2.5 1.5-3.9 0 0 3.4-1.6 3.1-8s-2.9-15.9-2.2-17.5 3.7-4.6 3.1-5.7-2-.3-3.4.5-7-.4-11.1 0c-2.8.3-5.4 1.1-7.9 2.4-1.5.7-2.6 1.7-3.5 2.8z"
        />
        <defs>
          <path
            id="prefix__SVGID_13_"
            d="M192.6 147c-2.2 2.9-3.5 6.4-3.5 10 0 6.6 2.9 16.6 4.9 20.7s7 9.8 9.4 10 8.4-9 9.6-11.3c.7-1.2 1.2-2.5 1.5-3.9 0 0 3.4-1.6 3.1-8s-2.9-15.9-2.2-17.5 3.7-4.6 3.1-5.7-2-.3-3.4.5-7-.4-11.1 0c-2.8.3-5.4 1.1-7.9 2.4-1.5.7-2.6 1.7-3.5 2.8z"
          />
        </defs>
        <clipPath id="prefix__SVGID_14_">
          <use xlinkHref="#prefix__SVGID_13_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_14_)">
          <path
            className="prefix__st18"
            d="M203.8 187.6c2.6-.5 8.1-9.1 9.3-11.2.7-1.2 1.2-2.5 1.5-3.9 0 0 3.4-1.6 3.1-8s-2.9-15.9-2.2-17.5 3.7-4.6 3.1-5.7c-.3-.4-.8-.6-1.3-.5-3.6 2.7-6.9 5.8-9.9 9.3-4.1 5.3-.2 12.7-.2 12.7s2.6 6.7 2.1 11.5c-.6 3.9-3.2 9.7-5.5 13.3z"
          />
          <path
            className="prefix__st3"
            d="M203.8 187.6c2.6-.5 8.1-9.1 9.3-11.2.7-1.2 1.2-2.5 1.5-3.9 0 0 3.4-1.6 3.1-8s-2.9-15.9-2.2-17.5 3.7-4.6 3.1-5.7c-.3-.4-.8-.6-1.3-.5-3.6 2.7-6.9 5.8-9.9 9.3-4.1 5.3-.2 12.7-.2 12.7s2.6 6.7 2.1 11.5c-.6 3.9-3.2 9.7-5.5 13.3z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M192.6 147c-2.2 2.9-3.5 6.4-3.5 10 0 6.6 2.9 16.6 4.9 20.7s7 9.8 9.4 10 8.4-9 9.6-11.3c.7-1.2 1.2-2.5 1.5-3.9 0 0 3.4-1.6 3.1-8s-2.9-15.9-2.2-17.5 3.7-4.6 3.1-5.7-2-.3-3.4.5-7-.4-11.1 0c-2.8.3-5.4 1.1-7.9 2.4-1.5.7-2.6 1.7-3.5 2.8z"
        />
        <path
          className="prefix__st11"
          d="M196.4 168c-.1 1.2-.5 2.4-1.2 3.4-1.1 1.3-.5 2.2 1.1 2.1.9-.1 1.8-.4 2.5-1.1"
        />
        <path
          className="prefix__st12"
          d="M196.2 162.4s-10.5 1.6-11.4 3.2 5.6 7.6 7.6 6.5 3.4-5.1 5-5 4.9 3.3 7.8 2.8 6.4-7.3 6.1-8.6-11.8.3-15.1 1.1z"
        />
        <path
          className="prefix__st13"
          d="M202.2 164.7c-5.3-2.5-7.1 0-10.9 3.2-1.1.9-2.4 1.6-3.8 2.1 1.8 1.5 3.8 2.7 4.8 2.2 2-1.1 3.4-5.1 5-5s4.9 3.3 7.8 2.8c1.6-.3 3.4-2.4 4.6-4.6-2.5.5-5.1.2-7.5-.7z"
        />
        <path className="prefix__st11" d="M197.6 176.3c2.7.7 5.6-.3 7.2-2.7" />
        <path
          className="prefix__st13"
          d="M211.3 159.8s1.3 6.4.9 7.8 1.1.4 1.6-2.1c.3-1.1.5-2.3.5-3.5-.7-1-1.8-1.8-3-2.2z"
        />
        <path
          d="M218.3 141.3c-.7-1.1-2-.3-3.4.5s-7-.4-11.1 0c-2.8.3-5.4 1.1-7.9 2.4-1.3.7-2.4 1.6-3.3 2.7-2.2 2.9-3.5 6.4-3.5 10 0 1.5.1 3 .4 4.6 3.8-2.7 14.2-7.9 28.1 3.7v-.7c-.4-6.4-2.9-15.9-2.2-17.5s3.6-4.6 2.9-5.7z"
          fill="#263238"
        />
        <defs>
          <path
            id="prefix__SVGID_15_"
            d="M218.3 141.3c-.7-1.1-2-.3-3.4.5s-7-.4-11.1 0c-2.8.3-5.4 1.1-7.9 2.4-1.3.7-2.4 1.6-3.3 2.7-2.2 2.9-3.5 6.4-3.5 10 0 1.5.1 3 .4 4.6 3.8-2.7 14.2-7.9 28.1 3.7v-.7c-.4-6.4-2.9-15.9-2.2-17.5s3.6-4.6 2.9-5.7z"
          />
        </defs>
        <clipPath id="prefix__SVGID_16_">
          <use xlinkHref="#prefix__SVGID_15_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_16_)">
          <path
            className="prefix__st18"
            d="M201.7 143.9c3.7-.7 5.1-.2 5.6.1.2.2.4.4.4.7.3 2.5 2.8 2.5 5.6 2.5.7 0 1.4.3 1.9.8 0-.3 0-.7.1-1 0-.1.1-.1.1-.2-.6-.4-1.4-.7-2.1-.7-2.9 0-4.3-.1-4.5-1.6-.1-.6-.4-1.1-.8-1.4-1.1-.8-3.2-.9-6.4-.3-1.4.2-1.7.1-2.2-.1-.5.2-1 .3-1.5.5.3.1.7.2 1 .4.8.5 1.9.6 2.8.3zM216.2 160.4c-1.6-3.6-2.6-3.8-4.5-3.2l-.4.1c-1.6.5-3.4.7-5-1.6s-4.4-2.5-7.5-.4c-1.6 1.1-2.1.9-3.5.4-.4-.1-.8-.3-1.3-.4-1.7-.5-3.6.9-4.8 2.1 0 .5 0 1 .1 1.5 1.1-1.3 3-3.1 4.4-2.6.5.1.9.3 1.3.4 1.4.8 3.2.6 4.5-.5 1.9-1.2 4.4-2.2 6.1.1 1.9 2.7 4.2 2.5 6.1 2l.5-.1c1.3-.4 1.8-.6 3.2 2.6.5 1.2 1.2 2.2 2.2 3 0-.5-.1-1.1-.2-1.7-.5-.5-.9-1.1-1.2-1.7zM213.7 142.1h-1.3c1 1.5 2.6 2.4 4.3 2.7.2-.3.5-.6.7-1-1.4-.2-2.7-.7-3.7-1.7z"
          />
          <path
            className="prefix__st18"
            d="M193.3 150.2c.5 0 1 .1 1.4.4.6.4 1.2.7 3.1.1.8-.3 1.5-.6 2.2-.9 2.2-1 3.5-1.5 4.6-.3 1.8 2.1 3 2.7 5.2 2.7h1.1c1.9-.1 3.2-.1 3.7 1 .4.9 1 1.6 1.7 2.2-.1-.6-.2-1.3-.3-1.9-.2-.2-.3-.5-.4-.8-.8-1.8-2.9-1.7-4.7-1.6h-1.1c-1.8 0-2.6-.3-4.4-2.3-1.6-1.8-3.6-.9-5.8 0-.7.3-1.4.6-2.1.8-1.5.5-1.8.3-2.2.1-.6-.4-1.3-.6-2-.6-1 .1-1.9.5-2.7 1.1-.3.7-.6 1.4-.8 2.1 1.1-.9 2.4-2.1 3.5-2.1z"
          />
        </g>
        <path
          className="prefix__st11"
          d="M218.3 141.3c-.7-1.1-2-.3-3.4.5s-7-.4-11.1 0c-2.8.3-5.4 1.1-7.9 2.4-1.3.7-2.4 1.6-3.3 2.7-2.2 2.9-3.5 6.4-3.5 10 0 1.5.1 3 .4 4.6 3.8-2.7 14.2-7.9 28.1 3.7v-.7c-.4-6.4-2.9-15.9-2.2-17.5s3.6-4.6 2.9-5.7z"
        />
        <path
          className="prefix__st12"
          d="M192.6 235.7c-2.4-.2-4.9-.6-7.2-1.3-3-.9-4.6-1.6-6.3-.3s-6 8-6.3 9.4 1 2.5 1.6 2 6-4.2 6-4.2c1.4 1.2 3.1 2 4.9 2.4 1.5.2 3-.2 4.2-1.1l3 .5.1-7.4z"
        />
        <path
          className="prefix__st18"
          d="M237.4 181.3s-3.9-3.9-11 1.9-5 17.3-4.9 22 1.9 16.7 1.9 19.2v4.1s-9.3 4.1-12.9 5.2-10.7 1.3-12.3 1.6-5.5-1.4-6.4.3-2.8 8.3-.3 9.4 15.1 1.3 23.9 1.3c8.8 0 22.3-2.2 25.5-4.1s2.5-12.7 2.5-20.1-.2-23.8-1.4-30.5-3.4-9-4.6-10.3z"
        />
        <g>
          <defs>
            <path
              id="prefix__SVGID_17_"
              d="M237.4 181.3s-3.9-3.9-11 1.9-5 17.3-4.9 22 1.9 16.7 1.9 19.2v4.1s-9.3 4.1-12.9 5.2-10.7 1.3-12.3 1.6-5.5-1.4-6.4.3-2.8 8.3-.3 9.4 15.1 1.3 23.9 1.3c8.8 0 22.3-2.2 25.5-4.1s2.5-12.7 2.5-20.1-.2-23.8-1.4-30.5-3.4-9-4.6-10.3z"
            />
          </defs>
          <clipPath id="prefix__SVGID_18_">
            <use xlinkHref="#prefix__SVGID_17_" overflow="visible" />
          </clipPath>
          <g clipPath="url(#prefix__SVGID_18_)">
            <path
              className="prefix__st20"
              d="M242 191.5c-1.3-6.8-3.3-9-4.6-10.2 0 0-2.1-2.1-6.1-.9l-.3.4s6.2.9 5 3.5c-1.2 2.6-11.5 10.9-11.5 10.9s5.9-3.5 11.2-3.8 2.1 3.2-.9 3.8 8.3 1.5 3.8 2.7-8.9-.6-6.8 2.4 10.3 3.8 8 6.8-10 5.9-8.3 10.3 9.7 3.5 9.7 6.2-2.1 3.8-5.9 4.4 6.9 1.1 4.3 5.1-5.5-1.9-10.2 1.1-7.1 8.3-13.9 8.9-12.1 0-17.4 1.8c-1 .3-1.9.6-2.8.9 5.1.5 13.5.6 20 .6 8.8 0 22.3-2.2 25.5-4.1s2.5-12.7 2.5-20.1-.1-23.9-1.3-30.7z"
            />
          </g>
        </g>
        <path
          className="prefix__st11"
          d="M237.4 181.3s-3.9-3.9-11 1.9-5 17.3-4.9 22 1.9 16.7 1.9 19.2v4.1s-9.3 4.1-12.9 5.2-10.7 1.3-12.3 1.6-5.5-1.4-6.4.3-2.8 8.3-.3 9.4 15.1 1.3 23.9 1.3c8.8 0 22.3-2.2 25.5-4.1s2.5-12.7 2.5-20.1-.2-23.8-1.4-30.5-3.4-9-4.6-10.3zM225.4 228.9s7.3.3 11.4 5.1"
        />
        <path
          className="prefix__st11"
          d="M225.7 227.8s7-2.2 10.8-.5 7.3-1.1 1.1-3.2-11.2.4-12.7 2.4M237.6 188.5s-2.2-2.4-9 3-5.1 8.7-.8 6.5 11.9-4.9 12.5-1.6-7.6 1.6-6 3.5 5.4 1.9 5.4 1.9"
        />
        <path className="prefix__st14" d="M148.5 247.2h48.1v2.2h-48.1z" />
        <path
          className="prefix__st14"
          d="M148.5 247.2h26.3v2.2h-26.3zM148.5 247.2l-10.7-23.7h28.8l8.2 23.7z"
        />
        <path
          className="prefix__st7"
          d="M214.3 339.6s-29.7-34-32.6-34-69.4 12.7-71.7 16-10.9 20.3-10.9 20.3h119.4l-4.2-2.3z"
        />
        <g>
          <defs>
            <path
              id="prefix__SVGID_19_"
              d="M214.3 339.6s-29.7-34-32.6-34-69.4 12.7-71.7 16-10.9 20.3-10.9 20.3h119.4l-4.2-2.3z"
            />
          </defs>
          <clipPath id="prefix__SVGID_20_">
            <use xlinkHref="#prefix__SVGID_19_" overflow="visible" />
          </clipPath>
          <g clipPath="url(#prefix__SVGID_20_)">
            <path
              className="prefix__st16"
              d="M183.9 303.5l32.9 34.7 6.2 5.5-127.9-.7 14.6-23.8 69.4-16.1s1.5-.7 4.8.4z"
            />
            <path
              className="prefix__st7"
              d="M181.6 311.7c0 1.2-2 2.2-4.5 2.2s-4.5-1-4.5-2.2 2-2.2 4.5-2.2 4.5.9 4.5 2.2zM154.7 326.1c0 3.2-3.5 5.8-7.8 5.8s-7.8-2.6-7.8-5.8 3.5-5.8 7.8-5.8 7.8 2.6 7.8 5.8z"
            />
            <path
              className="prefix__st7"
              d="M179.5 317.6c0 3.2-3.5 5.8-7.8 5.8s-7.8-2.6-7.8-5.8 3.5-5.8 7.8-5.8 7.8 2.6 7.8 5.8zM161.5 320.7c0 1.7-1.9 3.1-4.2 3.1s-4.2-1.4-4.2-3.1 1.9-3 4.2-3 4.2 1.3 4.2 3z"
            />
            <ellipse
              className="prefix__st7"
              cx={162.2}
              cy={314.6}
              rx={4.2}
              ry={3.1}
            />
            <ellipse
              className="prefix__st7"
              cx={155.9}
              cy={316.3}
              rx={4.2}
              ry={3.1}
            />
            <path
              className="prefix__st7"
              d="M122 327.5c0 1.7-1.9 3.1-4.2 3.1s-4.2-1.4-4.2-3.1 1.9-3 4.2-3 4.2 1.3 4.2 3zM132.3 322.4c0 1.3-1.8 2.4-4.1 2.4s-4.1-1.1-4.1-2.4 1.8-2.4 4.1-2.4 4.1 1.1 4.1 2.4z"
            />
            <path
              className="prefix__st8"
              d="M201 324.8l-.2.1-19.1-19.2-8.4 19.8-40.2 5.8-33.9 10.8h119.4l-4.2-2.4c-.1-.1-6.3-7.2-13.4-14.9z"
            />
          </g>
        </g>
        <path
          className="prefix__st11"
          d="M214.3 339.6s-29.7-34-32.6-34-69.4 12.7-71.7 16-10.9 20.3-10.9 20.3h119.4l-4.2-2.3z"
        />
      </g>
    </svg>
  )
}