// @vendors
import { Link } from 'react-router-dom'
import { Typography } from 'antd'

// @images
import lntImage from 'assets/images/lnt/logo.png'

// @styles
import './styles.scss'

const { Text } = Typography

export default function LeaveNoTrace() {
  return (
    <div className='lnt__message'>
      <img src={lntImage} alt='Leave No Trace Logo' className='lnt__message-image' />
      <div className='lnt__message-content'>
        <Text type='secondary'>
          Conoce los 7 principios de <strong>No Deje Rastro</strong> y hagamos del planeta un lugar mejor.
        </Text>
        <Link to='/ndr'>
          <em className='fal fa-long-arrow-right' /> Ver 7 principios
        </Link>
      </div>
    </div>
  )
}
