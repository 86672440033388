// @vendors
import { useCallback, useContext, useState } from "react"
import { Alert, Space, Button } from "antd"
import { ROUTES } from "constants/routes"
import { Link } from "react-router-dom"

// @context
import { AppContext } from 'context/app'

// @styles
import './styles.scss'

export default function CookieAlert() {
    const { auth: { acceptCookies, user } } = useContext(AppContext)
    const [showCookies, setShowCookies] = useState(true)
    const currentCookie = user?.cookies ? user.cookies[0] : null

    const handleSubmitCookiesUpdate = useCallback(() => {
      acceptCookies(
        {
          acceptedDate: new Date().getTime(),
          version: currentCookie.version
        },
        () => setShowCookies(false)
      )
    }, [user])

    if (!showCookies || !user || !currentCookie) return null

    return (
        <Alert
            className='ant-alert-cookies'
            message="¡Cookies!"
            description={currentCookie.description}
            action={
                <Space>
                    <Link
                        to={ROUTES.LEGALS.COOKIES}
                        target='_blank'
                    >
                        Cookies <em className='fal fa-external-link' />
                    </Link>
                    <Button
                        className='solid c-1'
                        onClick={handleSubmitCookiesUpdate}
                    >
                        Estoy de acuerdo
                    </Button>
                </Space>
            }
        />
    )
}