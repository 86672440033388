// @components
import ExperiencesForm from 'components/forms/experiences'
import RecommendForm from 'components/forms/recommend'
import RegisterForm from 'components/forms/auth/register'
import SuggestionsForm from 'components/forms/suggestions'

// @images
import GuatapeImage from 'assets/images/shapes/guatape-landscape.png'
import YogaImage from 'assets/images/shapes/yoga-landscape.png'
import ClimbImage from 'assets/images/shapes/climb-landscape.png'
import MailBox from 'components/icons/svg/mailbox'

export const MODAL_STEPS = {
  EXPERIENCE: {
    customClass: 'experiences',
    title: 'Experiencias',
    subtitle: 'para todos',
    message: <p>Próximamente podrás pautar con nosotros, ofrecer tus servicios y ganar dinero extra.</p>,
    imageUrl: YogaImage,
    buttonText: 'Saber más',
    form: props => <ExperiencesForm {...props} />
  },
  RECOMMEND: {
    customClass: 'recommend',
    title: 'Recomienda',
    subtitle: 'un lugar',
    message:
      <>
        <p>Para que toda la comunidad de Noomada siempre tenga un lugar por conocer.</p>
      </>,
    imageUrl: GuatapeImage,
    buttonText: '¡Hagámoslo!',
    form: props => <RecommendForm {...props} />
  },
  SUGGESTIONS: {
    customClass: 'suggestions',
    title: 'Sugerencias',
    message:
      <>
        <p>
          Si viste algo por mejorar o se te ocurrió una super idea que podamos implementar, este es tu espacio.
        </p>
      </>,
    imageUrl: '',
    svg: <MailBox />,
    buttonText: '¡Quiero sugerir!',
    form: props => <SuggestionsForm {...props} />
  },
  REGISTER: {
    customClass: 'register',
    title: 'Crea una cuenta,',
    subtitle: 'tranqui... ¡es gratis!',
    message:
      <>
        <p>Imagínate poder hacer estas cosas en un solo sitio web:</p>
      </>,
    imageUrl: ClimbImage,
    buttonText: 'Crea tu cuenta',
    items: [
      {
        iconName: 'suitcase-rolling',
        text: 'Planificar y agregarle destinos a tu viaje'
      },
      {
        iconName: 'images',
        text: 'Tener álbunes virtuales para raspar',
        pro: true
      },
      {
        iconName: 'thumbs-up',
        text: 'Marcar los destinos que ya visitaste'
      },
      {
        iconName: 'ellipsis-h',
        text: 'Y otras cosas más...'
      }
    ],
    form: props => <RegisterForm {...props} />
  }
}
