// @vendors
import { useEffect } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Skeleton } from 'antd'

// @components
import CardDestination from 'components/card-destination'

// @hooks
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags'

// @utils
import { convertToSlug } from 'utils/format'

export default function HeritageTowns({ mapDestinationItems, name }) {
  const { info, loading, refetch } = usePrismicQueryByTags(
    'client',
    'destination',
    [convertToSlug(name), 'pueblo-patrimonio'],
    [''],
    20
  )

  useEffect(() => {
    refetch([convertToSlug(name), 'pueblo-patrimonio'])
  }, [name])

  if (!info.length) {
    return null
  }

  return (
    <>
      <h2 className='title__ppal xs c-dr'>
        Pueblos Patrimonio
      </h2>
      {loading &&
        <Skeleton
          className='ant-skeleton-cards'
          paragraph={{ rows: 6 }}
          title=''
          active
        />}
      <ScrollContainer className='list__items-home'>
        {!loading &&
          mapDestinationItems(info).map((item, k) => {
            return (
              <CardDestination
                destination={item}
                key={k}
                type=''
              />
            )
          }
          )}
      </ScrollContainer>
    </>
  )
}
