// @vendors
import { useContext, useState } from 'react'
import { Button, Modal, Form, Input } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
    FacebookShareButton,
    EmailShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    TwitterShareButton,
    LinkedinShareButton
} from 'react-share'
import cx from 'classnames'

// @context
import { AppContext } from 'context/app'

// @constants
import { QUERY_PARAM_SHARE_DESTINATION } from 'constants/query-params'
import { DEFAULT } from 'constants/default'

// @styles
import './styles.scss'

export default function ModalShare({ destination }) {
  const {
    app: {
      onCloseShareTrip,
      isModalOpened
    }
  } = useContext(AppContext)
  const url = `${window.location.origin}${window.location.pathname}`
  const [copied, setCopied] = useState(false)
  const visible = isModalOpened(QUERY_PARAM_SHARE_DESTINATION)

    return (
        <Modal
            destroyOnClose
            visible={visible}
            onCancel={onCloseShareTrip}
            footer={null}
            title={null}
            width='450px'
            centered
            wrapClassName={
                cx(
                    'modal__default',
                    'modal__default--full-mobile',
                    'modal__steps',
                    'modal__auto'
                )
            }
        >
            <div className='step__default'>
                <h5 className='title__ppal sm c--dr'>
                    <strong>Comparte este {destination.typeLabel}</strong>
                </h5>
                <div className='share'>
                    <div className='share-header'>
                        <img
                            src={destination.image}
                            alt={destination.imageAlt}
                        />
                        <h5 className='title__ppal c-dr'>
                            <strong>{destination.title}{destination.department && ','}</strong>
                            {destination.department && `en el departamento de ${destination.department}`}
                        </h5>
                    </div>
                    <div className='share-body'>
                        <div className='list__social'>
                            <FacebookShareButton
                                url={url}
                                quote={`¿Ya conoces ${destination.title} en el departamento de ${destination.department}?`}
                                className='ant-btn outline c-dr'
                                resetButtonStyle={false}
                            >
                                <em className='fab fa-facebook-f' />
                                <span className='text'>
                                    Facebook
                                </span>
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={url}
                                title={`¿Ya conoces ${destination.title} en el departamento de ${destination.department}?`}
                                className='ant-btn outline c-dr'
                                resetButtonStyle={false}
                            >
                                <em className='fab fa-whatsapp' />
                                <span className='text'>
                                    WhatsApp
                                </span>
                            </WhatsappShareButton>
                            <TelegramShareButton
                                url={url}
                                title={`¿Ya conoces ${destination.title} en el departamento de ${destination.department}?`}
                                className='ant-btn outline c-dr'
                                resetButtonStyle={false}
                            >
                                <em className='fab fa-telegram' />
                                <span className='text'>
                                    Telegram
                                </span>
                            </TelegramShareButton>
                            <TwitterShareButton
                                url={url}
                                hashtags={['NoomadaColombia']}
                                title={`¿Ya conoces ${destination.title} en el departamento de ${destination.department}?`}
                                className='ant-btn outline c-dr'
                                resetButtonStyle={false}
                            >
                                <em className='fab fa-twitter' />
                                <span className='text'>
                                    Twitter
                                </span>
                            </TwitterShareButton>
                            <LinkedinShareButton
                                url={url}
                                title='Hola'
                                summary='fsf'
                                className='ant-btn outline c-dr'
                                resetButtonStyle={false}
                            >
                                <em className='fab fa-linkedin' />
                                <span className='text'>
                                    LinkedIn
                                </span>
                            </LinkedinShareButton>
                            <EmailShareButton
                                url={url}
                                body={`¿Ya conoces ${destination.title} en el departamento de ${destination.department}?`}
                                className='ant-btn outline c-dr'
                                resetButtonStyle={false}
                            >
                                <em className='fal fa-envelope' />
                                <span className='text'>
                                    Email
                                </span>
                            </EmailShareButton>
                        </div>

                        <h6
                            className='subtitle__ppal xxs c-dr'
                            style={{ margin: '30px 0 10px' }}
                        >
                            <strong>Copia el enlace</strong>
                        </h6>
                        <Form.Item
                            className='ant-form-item-extra'
                            style={{ marginBottom: '0' }}
                        >
                            <Input
                                readOnly
                                defaultValue={`${DEFAULT.site.urlBase}${destination.link}`}
                                style={{ paddingRight: '100px' }}
                            />
                            <CopyToClipboard
                                text={`${DEFAULT.site.urlBase}${destination.link}`}
                                onCopy={() => setCopied(true)}
                            >
                                <Button
                                    className={cx(
                                        'solid ant-btn-size--xs ant-form-item-extra-action',
                                        { 'c-wh': copied },
                                        { 'c-2': !copied }
                                    )}
                                    style={{ width: '90px' }}
                                >
                                    {copied ? '¡Copiado!' : 'Copiar'}
                                </Button>
                            </CopyToClipboard>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Modal >
    )
}