// @vendors
import PropTypes from 'prop-types'

export default function Image({ info }) {
    return (
        <div className='image__full'>
            <img src={info.url} alt={info.alt} />
            <div className='copy-image'>
                Foto: {info.copyright ? info.copyright : 'Háblanos para créditos' }
            </div>
        </div>
    )
}

Image.propTypes = {
    info: PropTypes.any
}
