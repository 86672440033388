// @vendors
import { useContext } from 'react'
import PropTypes from 'prop-types'

// @components
import Account from './account'
import Albums from './albums'
import Sidebar from './sidebar'
import Visited from './visited'
import Trips from './trips'
import TripDetail from './trips/trip-detail'

// @context
import { AppContext } from 'context/app'

// @constants
import { ROUTES } from 'constants/routes'

// @styles
import './styles.scss'

const CHILDS = {
  [ROUTES.PROFILE.MY_TRIPS]: <Trips />,
  [ROUTES.PROFILE.TRIP_DETAIL]: <TripDetail />,
  [ROUTES.PROFILE.ALBUMBS]: <Albums />,
  [ROUTES.PROFILE.VISITED]: <Visited />,
  [ROUTES.PROFILE.ACCOUNT]: <Account />
}

export default function Profile({ path }) {
  const { auth: { user } } = useContext(AppContext)

  if (!user) {
    return null
  }

  return (
    <>
      <Sidebar />
      <main className='dashboard__profile'>
        <div className='dashboard__profile-content'>
          {CHILDS[path]}
        </div>
      </main>
    </>
  )
}

Profile.propTypes = {
  path: PropTypes.string
}

Profile.defaultProps = {
  path: ''
}
