export default function Schedule({ schedules }) {
    return (
        <div className="provider__detail-body-schedules">
            <h6 className='title__ppal xs c-dr'>
                Horarios
            </h6>

            <ul className='list__provider-schedules'>
                {schedules.map((item, index) => (
                    <li
                        key={index}
                        className="list__provider-schedules-item"
                    >
                        <em className="fal fa-watch" />
                        <span>
                            {item.title}
                            <strong>{item.schedule}</strong>
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    )
}