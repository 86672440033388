// @vendors
import { useCallback, useContext, useEffect } from 'react'
import {
  Drawer,
  Collapse,
  Button,
  Skeleton
} from 'antd'

// @context
import { AppContext } from 'context/app'

// @components
import { EmptyData } from 'components/empty'

// @hooks
import useFetch from 'hooks/useFetch'

// @api
import { acceptTripInvitation, rejectTripInvitation } from 'api/notifications'

// @utils
import { messageKey, notify } from 'utils/notifications'
import Broadcaster from 'utils/broadcaster'

// @constants
import { INVITATION_ACCEPTED } from 'constants/broadcast-channels'
import { QUERY_PARAM_NOTIFICATIONS_PANEL } from 'constants/query-params'

function removeNotificationAfterRejectOrAccept(notifications, notificationToProcess) {
  return notifications.filter(({ notificationId }) => notificationId !== notificationToProcess.notificationId)
}

export default function NotificationPanel() {
  const {
    app: {
      onCloseNotificationsPanel,
      openModalWithSessionOnly,
      isModalOpened
    },
    auth: {
      getNotifications,
      notifications,
      setNotifications,
      user,
      loadingNotifications
    }
  } = useContext(AppContext)

  const { refetch: removeNotificationFetch, loading: rejectingTrip } = useFetch(rejectTripInvitation)
  const { refetch: acceptNotificationFetch, loading: acceptingTrip } = useFetch(acceptTripInvitation)
  const visible = openModalWithSessionOnly(() => isModalOpened(QUERY_PARAM_NOTIFICATIONS_PANEL))

  const handleRejectInvitation = useCallback((notification) => () => {
    const message = messageKey.tripRejected(notification.travelTitle, notification.ownerName)
    removeNotificationFetch(
      [user.email, notification.notificationId],
      () => {
        setNotifications(removeNotificationAfterRejectOrAccept(notifications, notification))
        notify('success', message.success.description, message.success.message)
      },
      () => {
        notify('error', message.error.description, message.error.message)
      }
    )
  }, [notifications])

  const handleAcceptInvitation = useCallback((notification) => () => {
    const message = messageKey.tripAccepted(notification.travelTitle, notification.ownerName)
    acceptNotificationFetch(
      [user.email, notification.notificationId],
      (acceptedTrip) => {
        setNotifications(removeNotificationAfterRejectOrAccept(notifications, notification))
        notify('success', message.success.description, message.success.message)
        const acceptedTripChannel = new Broadcaster(INVITATION_ACCEPTED)
        acceptedTripChannel.pub(acceptedTrip)
      },
      () => {
        notify('error', message.error.description, message.error.message)
      }
    )
  }, [notifications])

  useEffect(() => {
    if (visible) {
      getNotifications()
    }
  }, [visible])

  return (
    <Drawer
      width='500px'
      placement="right"
      title='Notificaciones'
      closable={false}
      className='drawer__default'
      onClose={onCloseNotificationsPanel}
      visible={visible}
      footer={null}
      destroyOnClose
    >
      <div
        className='ant-drawer-close'
        onClick={onCloseNotificationsPanel}
      >
        <em className='fal fa-times' />
      </div>
      {
        loadingNotifications && (
          <Skeleton
            className='ant-skeleton-notifi'
            paragraph={{ rows: 2 }}
            active
          />
        )
      }
      {
        !notifications.length && !loadingNotifications && (
          <EmptyData type='notificationDrawer' flex='vertical' />
        )
      }
      {!loadingNotifications && (
        <Collapse
          className='collapse__trips-guest'
          accordion
        >
          {
            notifications.map((notification) => (
              <Collapse.Panel
                header={
                  <>
                    <div className='collapse__trips-guest-icon'>
                      <em className='fal fa-bookmark' />
                    </div>
                    <div className='collapse__trips-guest-description'>
                      <div className='trips-guest--host'>
                        {notification.ownerName} te ha invitado al viaje:
                      </div>
                      <div className='trips-guest--name'>
                        {notification.travelTitle}
                      </div>
                    </div>
                  </>
                }
                key={notification.notificationId}
              >
                <Button
                  className='solid c-rgba'
                  disabled={acceptingTrip}
                  loading={rejectingTrip}
                  onClick={handleRejectInvitation(notification)}
                >
                  Rechazar
                </Button>
                <Button
                  className='solid c-1'
                  disabled={rejectingTrip}
                  loading={acceptingTrip}
                  onClick={handleAcceptInvitation(notification)}
                >
                  Unirme
                </Button>
              </Collapse.Panel>
            ))
          }
        </Collapse>
      )}
    </Drawer>
  )
}
