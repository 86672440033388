// @vendors
import { useContext, useEffect, useState } from 'react'
import { Modal } from 'antd'
import cx from 'classnames'

// @components
import RegisterForm from './register'
import FullRegisterForm from './full-register'

// @context
import { RegisterFormContext, REGISTER_FORM_ACTIONS } from 'context/register-form'
import { AppContext } from 'context/app'

// @constants
import { QUERY_PARAM_AUTH_FORM } from 'constants/query-params'

export default function AuthForm() {
  const { dispatchRegisterForm } = useContext(RegisterFormContext)
  const {
    app: {
      onCloseAuthForm,
      isModalOpened
    }
  } = useContext(AppContext)
  const loginFormVisible = isModalOpened(QUERY_PARAM_AUTH_FORM)
  const [fullRegisterFormVisible, setFullRegisterFormVisible] = useState(false)
  const visibleDrawer = loginFormVisible || fullRegisterFormVisible

  useEffect(() => {
    if (!visibleDrawer) {
      dispatchRegisterForm({ type: REGISTER_FORM_ACTIONS.RESET_FORM })
    }
  }, [visibleDrawer])

  useEffect(() => {
    if (!loginFormVisible) {
      setFullRegisterFormVisible(false)
    }
  }, [loginFormVisible])

  return (
    <Modal
      destroyOnClose
      footer={null}
      title={null}
      width='400px'
      centered
      wrapClassName={
        cx(
          'modal__default',
          'modal__default--full-mobile',
          'modal__steps'
        )
      }
      visible={visibleDrawer}
      onCancel={onCloseAuthForm}
    >
      <div className='step__message'>
        <RegisterForm
          goToFullRegisterForm={() => {
            setFullRegisterFormVisible(true)
          }}
          onClose={onCloseAuthForm}
        />
      </div>

      {
        fullRegisterFormVisible && (
          <FullRegisterForm
            onClose={onCloseAuthForm}
            visible={loginFormVisible && fullRegisterFormVisible}
          />
        )
      }

    </Modal>
  )
}