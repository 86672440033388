// @vendors
import { Button } from 'antd'
import { useContext, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'
import { linkResolver } from 'prismic-config'
import cx from 'classnames'

// @context
import { AppContext } from 'context/app'

// @components
import ModalDefault from 'components/modal-default'

// @utils
import { notify } from 'utils/notifications'
import { getPathFromUrl } from 'utils/format'
import { onScroll } from 'utils/on-scroll'

const setLink = (link) => {
  if (link.link_type === 'Web') {
    return link.url
  }

  return linkResolver(link)
}

export default function WhatToDo({ items, city, image, depto }) {
  const {
    auth: { user },
    app: {
      onOpenAuthForm
    }
  } = useContext(AppContext)
  const [visibleModalRecommend, setVisibleModalRecommend] = useState(false)
  const [showWhatToDoDetail, setShowWhatToDoDetail] = useState(null)

  const PLACE_PREVIEW = {
    title: city,
    image: image
  }

  const validateSessionToOpenModal = () => {
    if (user?.email) {
      setVisibleModalRecommend(true)
      return
    }

    notify(
      'success',
      <>
        Para recomendar debes estar registrado. <div className="fake-link" onClick={onOpenAuthForm}>¿Qué esperas? ¡es gratis!</div>
      </>,
      ''
    )
  }

  return (
    <>
      <div className="list__items-whatToDo">
        <Button
          className='solid c-wh ant-btn-only--icon ant-btn-prev'
          onClick={onScroll(-330, 'whatToDo-container')}
        >
          <em className='fal fa-chevron-left' />
        </Button>
        <Button
          className='solid c-wh  ant-btn-only--icon ant-btn-next'
          onClick={onScroll(330, 'whatToDo-container')}
        >
          <em className='fal fa-chevron-right' />
        </Button>
        <ScrollContainer className='list__items-whatToDo-container'>
          {items.map((item, k) => (
            item.link.link_type !== 'Any'
              ? (
                <Link
                  to={item.link.link_type === 'Web' ? getPathFromUrl(item.link?.url) : setLink(item.link)}
                  className="item item-link"
                  key={k}
                >
                  <img src={item.image.url} alt={item.image.alt} />
                  <span>
                    {RichText.asText(item.name)}
                  </span>
                  <em className="fal fa-external-link-square" />
                </Link>
              )
              : <div
                className={
                  cx(
                    'item',
                    {
                      'item-detail': item.search_parameter,
                      'item-show': showWhatToDoDetail === k
                    }
                  )
                }
                key={k}
                onClick={() => setShowWhatToDoDetail(k)}
              >
                <img src={item.image.url} alt={item.image.alt} />
                <span>
                  {RichText.asText(item.name)}
                </span>
                {item.search_parameter && (
                  <div className='extra-actions'>
                    <Button
                      onClick={() => window.open(`https://waze.com/ul?q=${item.search_parameter}+${city}+${depto}+Colombia`)}
                      className="outline c-wh"
                    >
                      <em className='fab fa-waze' />
                      <span className='text'>Waze</span>
                    </Button>
                    <Button
                      onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${item.search_parameter}+${city}+${depto}+Colombia`)}
                      className="outline c-wh"
                    >
                      <em className='fab fa-google' />
                      <span className='text'>Maps</span>
                    </Button>
                  </div>
                )}
                {item.search_parameter && <em className="fal fa-route" />}
              </div>
          ))}
          <div className="item item-recommend" onClick={validateSessionToOpenModal}>
            <div className="parag">
              <em className="fal fa-plus" />
              <span>Recomendar en <strong>{city}</strong></span>
            </div>
          </div>
        </ScrollContainer>
      </div>
      <ModalDefault
        type='recommend'
        dates={PLACE_PREVIEW}
        visible={visibleModalRecommend}
        onClose={() => setVisibleModalRecommend(false)}
      />
    </>
  )
}
