// @vendors
import { useEffect, useState } from "react"
import { Button, Dropdown, Menu } from "antd"
import cx from 'classnames'

// @utils
import { utcToLocalTime } from 'utils/date'
import { showConfirm } from 'utils/alerts'

function MenuComment({
  onClose,
  openReportComment,
  openUpdateReview,
  removeReview,
  showActions,
  refetchReviewsSummary,
  selectComment
}) {
  const handleClick = (callback) => () => {
    onClose()
    callback()
    selectComment()
  }

  const handleEdit = () => {
    onClose()
    openUpdateReview()
    refetchReviewsSummary()
  }

  const handleRemove = () => {
    onClose()
    showConfirm({
      content: '¿Seguro(a) deseas eliminar tu reseña?',
      onOk: () => {
        removeReview()
      }
    })
  }

  return (
    <div className='menu__container menu__container-xs'>
      <Menu>
        {
          showActions && (
            <Menu.Item key="0">
              <Button onClick={handleEdit}>
                Editar
              </Button>
            </Menu.Item>
          )
        }
        {!showActions && (
          <Menu.Item key="1">
            <Button onClick={handleClick(openReportComment)}>
              Reportar
            </Button>
          </Menu.Item>
        )}
        {
          showActions && (
            <Menu.Item key="2">
              <Button onClick={handleRemove}>
                Eliminar
              </Button>
            </Menu.Item>
          )
        }
      </Menu>
    </div>
  )
}

export default function CommentsList({
  isSelected,
  clearSelectedComment,
  selectComment,
  comment,
  email,
  openReportComment,
  openReviewDrawer,
  openCommentDrawer,
  removeReview,
  showActions,
  refetchReviewsSummary
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const isEdited = comment?.createdAt
    ? comment?.createdAt !== comment?.updatedAt
    : false

  useEffect(() => {
    if (openCommentDrawer) {
      setIsMenuVisible(false)
    } else {
      clearSelectedComment()
    }
  }, [openCommentDrawer])

  return (
    <div className={
      cx(
        'comments__list-item',
        { 'comments__list-item--me': email === comment.createdBy }
      )
    }>
      <div className='comment__photo'>
        {comment.picture && <img src={comment.picture} alt={comment.fullName} />}
        {!comment.picture && <div className='first-letter'>{comment?.fullName?.slice(0, 1)}</div>}
      </div>
      <div className='comment__content'>
        <div className='comment__content-fullname'>
          <span>
            {comment.createdBy === email
              ? 'Tú'
              : comment.fullName
            }
          </span>
          <Dropdown
            overlay={
              <MenuComment
                showActions={showActions}
                onClose={clearSelectedComment}
                openReportComment={openReportComment}
                openUpdateReview={() => openReviewDrawer(comment)}
                removeReview={removeReview}
                refetchReviewsSummary={refetchReviewsSummary}
                selectComment={selectComment}
              />
            }
            onVisibleChange={setIsMenuVisible}
            visible={isSelected && isMenuVisible}
            trigger={['click']}
            placement="bottomRight"
            arrow
          >
            <Button
              className='outline c-dr ant-btn-only--icon'
              onClick={() => {
                selectComment()
                setIsMenuVisible(false)
              }}
            >
              <em className='fal fa-ellipsis-v' style={{ fontSize: '20px' }} />
            </Button>
          </Dropdown>
        </div>
        <div className='comment__content-date'>
          {utcToLocalTime(comment.updatedAt)}
          {isEdited && <span> - editado</span>}
        </div>
        <div className='comment__content-message'>
          {comment.message}
        </div>
      </div>
    </div>
  )
}