// @vendors
import { useContext } from 'react'
import { Typography } from 'antd'

// @context
import { DestinationPageContext } from 'context/pages'

// @styles
import './styles.scss'

export default function PNNMessage() {
    const info = useContext(DestinationPageContext)
    return (
        <div className='pnn__message'>
            <Typography.Text type='secondary'>
                <p><strong>{info.name}</strong> pertenece a la red de Parques Nacionales Naturales de Colombia y el ingreso a ellos es administrado por la misma entidad <a href="https://www.parquesnacionales.gov.co" target="_blank" rel="noreferrer">www.parquesnacionales.gov.co</a></p>
                <p>Contacta un guía en el botón de <strong>Experiencias</strong> para disfrutar mejor de tu paso por este PNN.</p>
            </Typography.Text>
        </div>
    )
}
