// @vendors
import { Button } from 'antd'
import { RichText } from 'prismic-reactjs'
import ScrollContainer from 'react-indiana-drag-scroll'

// @utils
import { onScroll } from 'utils/on-scroll'

export default function TypicalDishes({ info }) {
  return (
    <div className='list__items-dishes'>
      <Button
        className='solid c-wh ant-btn-only--icon ant-btn-prev'
        onClick={onScroll(-330, 'dishes-container')}
      >
        <em className='fal fa-chevron-left' />
      </Button>
      <Button
        className='solid c-wh  ant-btn-only--icon ant-btn-next'
        onClick={onScroll(330, 'dishes-container')}
      >
        <em className='fal fa-chevron-right' />
      </Button>
      <ScrollContainer className='list__items-dishes-container'>
        {info.map((item, k) => (
          <div key={k} className='item'>
            <img src={item.food_image.url} alt={item.food_image.alt} />
            <span>
              {RichText.asText(item.food_name)}
            </span>
          </div>
        ))}
      </ScrollContainer>
    </div>
  )
}
