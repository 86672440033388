// @vendors
import { useState } from 'react'
import { Form, Radio, Input, Button } from 'antd'
import cx from 'classnames'

// @hooks
import useFetch from 'hooks/useFetch'

// @api
import { reportReviewFromTrip } from 'api/notifications'

// @utils
import { notify } from 'utils/notifications'

const options = [
  {
    title: 'Es vulgar, sexualmente explícito o contiene lenguaje violento'
  },
  {
    title: 'Describe o promueve la participación en actividades ilegales'
  },
  {
    title: 'No está publicada en el destino correcto'
  },
  {
    title: 'No describe una experiencia personal'
  },
  {
    title: 'Contiene información falsa o engañosa'
  }
]

export default function ReportCommentForm({ destinationId, onClose, selectedComment }) {
  const [reason, setReason] = useState(null)

  const { loading, refetch } = useFetch(
    reportReviewFromTrip,
    {
      showErrorMessage: false,
      onSuccess: () => {
        notify('success', 'Reseña reportada correctamente')
        onClose()
      },
      onError: () => {
        notify('success', 'Error reportando reseña')
      }
    }
  )

  const handleChangeReason = (event) => {
    setReason(event.target.value)
  }

  const onSubmit = (values) => {
    const selectedReason = options[values.reason]
    const payload = {
      email: selectedComment.createdBy,
      fullname: selectedComment.fullName,
      destination: destinationId,
      message: values.message,
      reason: selectedReason.title,
      link: window.location.href,
      originalComment: selectedComment.message
    }

    refetch([{ ...payload }])
  }

  return (
    <Form
      layout='vertical'
      style={{ marginTop: '10px' }}
      onFinish={onSubmit}
      initialValues={{ reason: null, message: '' }}
    >
      <Form.Item
        name="reason"
        rules={[{
          required: true,
          message: 'Se te olvidó marcar una opción'
        }]}
      >
        <Radio.Group
          className='radio__default-group'
          onChange={handleChangeReason}
          disabled={loading}
        >
          {options.map((item, index) => (
            <Radio
              value={index}
              key={index}
            >
              <span>{item.title}</span>
              {index === reason && (
                <Form.Item
                  label={<>¿Quieres agregar alguna otra información que nos ayude a entender tu inquietud? <span>(opcional)</span></>}
                  name="message"
                  className={
                    cx(
                      {
                        'ant-form-item--disabled': loading
                      }
                    )
                  }
                >
                  <Input.TextArea
                    allowClear
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    disabled={loading}
                  />
                </Form.Item>
              )}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item className='actions ant-form-item--last'>
        <Button
          className='solid c-1'
          htmlType='submit'
          loading={loading}
        >
          Reportar
        </Button>
      </Form.Item>
    </Form>
  )
}