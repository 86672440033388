// @vendors
import { Modal } from 'antd'
import cx from 'classnames'

// @components
import ColaboratorsForm from 'components/forms/colaborators'
import RecommendSearchForm from 'components/forms/recommend-search'
import RecommendForm from 'components/forms/recommend'

const availableForms = {
    colaborators: props => <ColaboratorsForm {...props} />,
    recommendSearch: props => <RecommendSearchForm {...props} />,
    recommend: props => <RecommendForm {...props} />
}

export default function ModalDefault({ type, dates, visible, onClose, search }) {
    const SelectedForm = availableForms[type]

    return (
        <Modal
            destroyOnClose
            footer={null}
            title={null}
            width='400px'
            centered
            wrapClassName={
                cx(
                    'modal__default',
                    'modal__default--bottom-mobile',
                    'modal__steps',
                    'modal__auto'
                )
            }
            visible={visible}
            onCancel={onClose}
        >

            <div className='step__message'>
                <SelectedForm dates={dates} onClose={onClose} search={search} />
            </div>
        </Modal>
    )
}