// @vendors
import { Helmet } from 'react-helmet'
import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Skeleton } from 'antd'

// @components
import CardDestination from 'components/card-destination'

// @constants
import { DEFAULT } from 'constants/default'

// @hooks
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags'

function getActiveClass(selectedCategory, item) {
  return selectedCategory === item ? 'active' : ''
}

export default function Departments() {
  const { search } = useLocation()
  const {
    info: list,
    loading
  } = usePrismicQueryByTags(
    'client',
    'departament',
    [],
    [''],
    32
  )
  const [categories, setCategories] = useState([])
  const [filteredDepartments, setFilteredDepartments] = useState([])

  const selectedCategory = useMemo(
    () => new URLSearchParams(search).get('search') || 'Todos',
    [search]
  )

  const departments = useMemo(() =>
    list.map((item) => ({
      linkTo: `/${item.uid}`,
      imageUrl: item.data.thumb[0].image.url,
      imageAlt: item.data.thumb[0].image.alt,
      name: item.data.name,
      caption: `Región ${item.data.dpto_region}`,
      type: item.type
    })), [list])

  useEffect(() => {
    if (departments.length) {
      const clearedCategories = new Set(departments.map(({ caption }) => caption))
      setCategories(Array.from(clearedCategories))
      setFilteredDepartments(departments)
    }
  }, [departments])

  useEffect(() => {
    const searchParam = new URLSearchParams(search).get('search') || 'Todos'

    const newFilteredDepartments = searchParam === 'Todos'
      ? departments
      : departments.filter(({ caption }) => caption === searchParam)

    setFilteredDepartments(newFilteredDepartments)
  }, [departments, search])

  return (
    <>
      <Helmet>
        <title>Departamentos de Colombia {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          {loading &&
            <>
              <Skeleton
                className='ant-skeleton-basic ant-skeleton-basic-filters'
                paragraph={{ rows: 2 }}
                active
              />
              <Skeleton
                title=''
                className='ant-skeleton-mosaic'
                paragraph={{ rows: 6 }}
                active
              />
            </>
          }
          {!loading &&
            <>
              <h1 className='title__ppal md'>
                <strong>Departamentos</strong>
              </h1>
              <ul className='filters__category solid c-2'>
                <li>
                  <Link
                    to='?search=Todos'
                    className={getActiveClass(selectedCategory, 'Todos')}
                  >
                    Todos
                  </Link>
                </li>
                {
                  categories.map((item, k) => (
                    <li key={k}>
                      <Link
                        to={`?search=${item}`}
                        className={getActiveClass(selectedCategory, item)}
                      >
                        {item}
                      </Link>
                    </li>
                  ))
                }
              </ul>
              <div className='list__items-mosaic-card'>
                {
                  filteredDepartments.map((item, k) => (
                    <CardDestination destination={item} key={`card-pnn-${k}`} />
                  ))
                }
              </div>
            </>
          }
        </div>
      </section>
    </>
  )
}
