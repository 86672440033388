// @vendors
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

// @components
import Survivor from 'components/icons/svg/not-found'

// @contants
import { DEFAULT } from 'constants/default'

// @styles
import './styles.scss'

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Página no encontrada {DEFAULT.site.name}</title>
            </Helmet>
            <section className='sec__default sec__default-full'>
                <div className='container'>
                    <div className='notFound'>
                        <div className='notFound-description'>
                            <h1 className='title__ppal'>
                                <strong>¡Oh no,<br />oh no...! 🎶</strong>
                            </h1>
                            <h1 className='subtitle__ppal'>
                                No hemos podido encontrar la página que buscas.
                            </h1>
                            <div className='text__enr'>
                                <p>
                                    Te invitamos a darle clic al siguiente botón y conocer lugares increibles.
                                </p>
                            </div>
                            <Link to='/' className='ant-btn solid c-1'>
                                Explorar
                            </Link>
                        </div>
                        <div className='notFound-image'>
                            <Survivor />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
