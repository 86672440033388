// @vendors
import { useCallback, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import cx from 'classnames'
import { Dropdown, Menu, Button, Divider } from 'antd'

// @components
import NotificationPanel from 'components/notification-panel'

// @constants
import { ROUTES } from 'constants/routes'

// @utils
import { useWindowDimensions } from 'utils/DOM-functions'

// @context
import { AppContext } from 'context/app'

// @styles
import './styles.scss'

function MenuUserDefault({
  setShowDropdownAuth,
  setNotificationsVisible,
  haveNotifications,
  width
}) {
  const history = useHistory()
  const {
    auth: { logout, user },
    app: { onOpenNotificationsPanel }
  } = useContext(AppContext)

  const handleLogout = useCallback(() => {
    logout()
    history.push(ROUTES.HOME)
    setShowDropdownAuth(false)
  }, [history])

  const handleOpenNotificationPanel = () => {
    setShowDropdownAuth(false)
    onOpenNotificationsPanel()
  }

  if (user) {
    return (
      <div className='menu__container menu__container-default'>
        <Menu>
          <Menu.Item className={cx(
            { 'ant-menu-item-have-noti': haveNotifications }
          )}>
            <Button className='ant-btn-access' onClick={handleOpenNotificationPanel}>
              Notificaciones
            </Button>
          </Menu.Item>
          <Menu.Item onClick={() => setShowDropdownAuth(false)}>
            <Link to={ROUTES.PROFILE.MY_TRIPS}>
              Viajes
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => setShowDropdownAuth(false)}>
            <Link to={ROUTES.PROFILE.VISITED}>
              Visitados
            </Link>
          </Menu.Item>
          <Divider style={{ margin: '5px 0' }} />
          <Menu.Item onClick={() => setShowDropdownAuth(false)}>
            <Link to={ROUTES.PROFILE.ACCOUNT}>
              Cuenta
            </Link>
          </Menu.Item>
          {width < 720 && <>
            <Divider style={{ margin: '5px 0' }} />
            <Menu.Item onClick={() => setShowDropdownAuth(false)}>
              <Link to={ROUTES.NATIONAL_PARKS}>
                Parques
              </Link>
            </Menu.Item>
            <Menu.Item onClick={() => setShowDropdownAuth(false)}>
              <Link to={ROUTES.DEPARTMENTS}>
                Departamentos
              </Link>
            </Menu.Item>
            <Menu.Item onClick={() => setShowDropdownAuth(false)}>
              <Link to={ROUTES.BLOG}>
                Blog
              </Link>
            </Menu.Item>
            <Menu.Item onClick={() => setShowDropdownAuth(false)}>
              <Link to={ROUTES.LEGALS.CREDITS}>
                Créditos
              </Link>
            </Menu.Item>
            <Divider style={{ margin: '5px 0' }} />
          </>}
          <Menu.Item>
            <Button className='ant-btn-access' onClick={handleLogout}>
              Cerrar sesión
            </Button>
          </Menu.Item>
        </Menu>
      </div>
    )
  }

  return null
};

export default function UserDropdDown({ openStepModal, setData }) {
  const [showDropdownAuth, setShowDropdownAuth] = useState(false)
  const { auth: { user, isLoggingIn, notifications } } = useContext(AppContext)
  const { width } = useWindowDimensions()
  const profileClasses = cx(
    'access__profile',
    { 'access__profile--not-logged': !user },
    { 'access__profile-have--notifi': notifications.length },
    { 'access__profile--loading': isLoggingIn }
  )

  if (!user) {
    return (
      <div
        className={profileClasses}
        onClick={openStepModal}
      >
        <div className='icon'>
          <em className='fal fa-user' />
        </div>
        <em className='far fa-bars' />
      </div>
    )
  }

  return (
    <>
      <Dropdown
        overlay={
          <MenuUserDefault
            openStepModal={openStepModal}
            setData={setData}
            setShowDropdownAuth={setShowDropdownAuth}
            haveNotifications={!!notifications.length}
            width={width}
          />
        }
        visible={showDropdownAuth}
        onVisibleChange={setShowDropdownAuth}
        arrow
        trigger='click'
      >
        <div className={profileClasses}>
          {user && user.picture && <img src={user.picture} alt={user.firstName} />}
          {user && !user.picture && user.firstName &&
            (
              <div className='icon'>
                {user?.firstName?.slice(0, 1)}
              </div>
            )
          }
          <em className='far fa-bars' />
        </div>
      </Dropdown>

      <NotificationPanel />
    </>
  )
}
