// @constants
import { ROUTES, SOCIAL_NETWORKS } from 'constants/routes'

export const HEADER_ITEMS = [
  {
    iconName: 'leaf',
    path: ROUTES.NATIONAL_PARKS,
    title: 'Parques'
  },
  {
    iconName: 'newspaper',
    path: ROUTES.BLOG,
    title: 'Blog'
  }
]

export const USER_ITEMS = [
  {
    iconName: 'bookmark',
    path: ROUTES.PROFILE.MY_TRIPS,
    title: 'Viajes'
  },
  {
    iconName: 'heart',
    path: ROUTES.PROFILE.VISITED,
    title: 'Visitados'
  }
]

export const ADDITIONAL_MENU = {
  GENERALS: {
    title: 'Más',
    items: [
      {
        path: ROUTES.ABOUT_US,
        title: 'Nosotros'
      },
      {
        path: ROUTES.DEPARTMENTS,
        title: 'Departamentos'
      },
      {
        path: ROUTES.PATRIMONY_TOWNS,
        title: 'Pueblos Patrimonio'
      },
      {
        path: ROUTES.LNT,
        title: 'No Deje Rastro (NDR)'
      },
      {
        path: ROUTES.CONTACT,
        title: 'Hablemos'
      }
    ]
  },
  LEGAL_NOTIFICATIONS: {
    title: 'Avisos legales',
    items: [
      {
        path: ROUTES.LEGALS.TERMS,
        title: 'Términos y condiciones de uso'
      },
      {
        path: ROUTES.LEGALS.PRIVACY,
        title: 'Política de privacidad'
      },
      {
        path: ROUTES.LEGALS.COOKIES,
        title: 'Política de cookies'
      },
      {
        path: ROUTES.LEGALS.CREDITS,
        title: 'Créditos'
      }
    ]
  },
  NETWORKS: {
    title: 'Síguenos',
    items: SOCIAL_NETWORKS
  }
}
