// @vendors
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Typography, Form, Select } from 'antd'

// @constants
import { DEFAULT } from 'constants/default'

// @components
import ReportForm from 'components/forms/report'
import SuggestionsForm from 'components/forms/suggestions'

const formTitles = {
  R0001: 'Soporte general y comentarios',
  R0002: 'Infracciones de derechos de autor',
  R0003: 'Publicidad'
}

const choiceReport = {
    R0001: <SuggestionsForm type={formTitles.R0001} />,
    R0002: <ReportForm type={formTitles.R0002} />,
    R0003: <SuggestionsForm type={formTitles.R0003} />
}

export default function Requests() {
    const [selectedOption, setSelectedOption] = useState('')

    return (
        <>
            <Helmet>
                <title>Nueva solicitud {DEFAULT.site.name}</title>
            </Helmet>
            <section className='sec__default sec__default-basic sec__default-compact'>
                <div className='container'>
                    <h1 className='title__ppal md'>
                        <strong>¿Preguntas, comentarios o inquietudes?</strong>
                    </h1>
                    <Typography.Text
                        type='secondary'
                        style={{ margin: '20px 0', display: 'block' }}
                    >
                        Puedes utilizar el siguiente formulario para ponerse en contacto con Noomada. Nuestro horario de atención es de lunes a viernes, de 9 am a 5 pm, hora UTC-5 Colombia. Intentamos responder a las solicitudes en un plazo de 1 a 72 horas día laborable.
                    </Typography.Text>
                    <div className='container-compact' style={{ marginTop: '40px' }}>
                        <Form
                            layout='vertical'
                        >
                            <Form.Item
                                label='Elije tu tema'
                                name='choice'
                                className='ant-form-item-featured'
                                rules={[{
                                    required: true,
                                    message: 'Este campo es obligatorio'
                                }]}
                            >
                                <Select
                                    defaultValue='-'
                                    onChange={(e) => setSelectedOption(e)}
                                >
                                    <Select.Option value='-'>
                                        Seleccionar
                                    </Select.Option>
                                    <Select.Option value='R0001'>
                                      {formTitles.R0001}
                                    </Select.Option>
                                    <Select.Option value='R0002'>
                                      {formTitles.R0002}
                                    </Select.Option>
                                    <Select.Option value='R0003'>
                                       {formTitles.R0003}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                        {choiceReport[selectedOption]}
                    </div>
                </div>
            </section>
        </>
    )
}