// @vendors
import { useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

// @utils
import { clientPGS } from 'utils/prismic'

export default function useBlogDetail() {
  const {
    params: {
      uid
    }
  } = useRouteMatch()
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(true)

  const getInfo = useCallback(async() => {
    try {
      const info = await clientPGS.getByUID('blog', uid)
      if (info) {
        setInfo(info)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }, [uid])

  useEffect(() => {
    if (uid) {
      getInfo()
    }
  }, [uid])

  return { info, loading }
}
