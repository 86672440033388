// @vendors
import { useMemo } from 'react'

// @constants
import FEATURE_FLAGS from 'constants/feature-flags'

export default function useFlags() {
  const flags = useMemo(() => FEATURE_FLAGS, [])

  return flags
}