// @vendors
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Drawer,
  Button,
  Typography,
  Tooltip,
  Skeleton
} from 'antd'
import { Link } from 'react-router-dom'
import cx from 'classnames'

// @hooks
import usePrismicQueryBySearch from 'hooks/usePrismicQueryBySearch'

// @context
import { AppContext } from 'context/app'

// @constants
import DESTINATION_TYPES from 'constants/destination-type'

// @components
import SearchBar from 'components/searchbar'
import ModalDefault from 'components/modal-default'

// @utils
import { client, clientPNN } from 'utils/prismic'

// @helpers
import {
  mapDestinationItems,
  mapNationalParksItems,
  mapDepartmentItems
} from './helpers'

// @styles
import './styles.scss'

const MIN_LENGTH_SEARCH = 3

export default function SearchForm({
  title,
  onBookmarClick,
  clearOnVisible = false,
  itemActionIcon = 'bookmark',
  showDepartmentsOnResults = true,
  itemsWithoutActions = [],
  actionTooltip = 'Abrir viajes',
  visible,
  onClose
}) {
  const {
    app: { searchText }
  } = useContext(AppContext)
  const [finalSearchText, setFinalSearchText] = useState(searchText)
  const [visibleModalRecommendSearch, setVisibleModalRecommendSearch] = useState(false)

  const {
    data: destinations,
    dataSecondary: destinationsSecondary,
    resetData: resetDestinations,
    loading: loadingDestinations,
    setSearchText: setSearchDestinationsText
  } = usePrismicQueryBySearch(client, 'destination')

  const {
    data: departments,
    dataSecondary: departmentsSecondary,
    resetData: resetDepartments,
    loading: loadingDepartments,
    setSearchText: setSearchDepartmentsText
  } = usePrismicQueryBySearch(client, DESTINATION_TYPES.DEPARTMENT, showDepartmentsOnResults)

  const {
    data: nationalParks,
    dataSecondary: nationalParksSecondary,
    resetData: resetNationalParks,
    loading: loadingNationalParks,
    setSearchText: setSearchNationalParksText
  } = usePrismicQueryBySearch(clientPNN, 'pnn')

  const handleSearchPlaces = useCallback(() => {
    if (searchText.length >= MIN_LENGTH_SEARCH) {
      setSearchDestinationsText(searchText)
      setSearchDepartmentsText(searchText)
      setSearchNationalParksText(searchText)
      setFinalSearchText(searchText)
    }

    if (!searchText) {
      setFinalSearchText('')
    }
  }, [searchText])

  const results = useMemo(() => [
    ...mapDestinationItems(destinations),
    ...mapNationalParksItems(nationalParks),
    ...mapDepartmentItems(departments)
  ], [
    destinations,
    nationalParks,
    departments
  ])

  const resultsSecondary = useMemo(() => [
    ...mapDestinationItems(destinationsSecondary),
    ...mapNationalParksItems(nationalParksSecondary),
    ...mapDepartmentItems(departmentsSecondary)
  ], [
    destinationsSecondary,
    nationalParksSecondary,
    departmentsSecondary
  ])

  const filteredResults = resultsSecondary.filter(x => !results.find(y => y.linkTo === x.linkTo))

  const loading = useMemo(() =>
    loadingDestinations || loadingDepartments || loadingNationalParks,
    [loadingDestinations, loadingDepartments, loadingNationalParks]
  )

  const emptySearchMessage = useMemo(() =>
    finalSearchText && (!results.length && !resultsSecondary.length)
      ? (
        <Typography.Text type='secondary' className='ant-typography-helping'>
          <h1 className='subtitle__ppal xs'>No se encontraron resultados</h1>
          <p>
            Posiblemente se debe a que aún no tenemos la información de todos los destinos que tiene nuestro país, pero estamos en ello 😉
          </p>
          <p>
            Si consideras que la deberíamos tener, dale clic al siguiente botón y ayuda a más noomadas.
          </p>
          <Button
            className='solid c-1'
            onClick={() => setVisibleModalRecommendSearch(true)}
          >
            Recomendar
          </Button>
        </Typography.Text>
      )
      : '',
    [finalSearchText, results, resultsSecondary]
  )

  useEffect(() => {
    if (visible) {
      if (searchText || !clearOnVisible) {
        setSearchDestinationsText(searchText)
        setSearchDepartmentsText(searchText)
        setSearchNationalParksText(searchText)
        setFinalSearchText(searchText)
      } else {
        resetDestinations()
        resetDepartments()
        resetNationalParks()
      }
    }
  }, [visible])

  const destinationsWithoutActions = [
    ...itemsWithoutActions,
    ...departments.map(({ data: { name } }) => name),
    ...departmentsSecondary.map(({ data: { name } }) => name)
  ]

  return (
    <>
      <Drawer
        width='530px'
        placement="right"
        title={
          <>{title} <SearchBar id="add-destination-searchbar" onSearch={handleSearchPlaces} /></>
        }
        closable={false}
        className='drawer__default drawer__search'
        onClose={onClose}
        visible={visible}
        footer={null}
        zIndex={title === 'Buscar' ? 11 : 12}
        destroyOnClose
      >
        <div
          className='ant-drawer-close'
          onClick={onClose}
        >
          <em className='fal fa-times' />
        </div>
        {
          !emptySearchMessage && (!results.length && !resultsSecondary.length) && (
            <Typography.Text type='secondary' className='ant-typography-helping'>
              Para obtener mejores resultados, busque por palabras precisas o palabras clave.
            </Typography.Text>
          )
        }

        {emptySearchMessage}

        {results && results.length
          ? (
            <div className='search__results' style={{ marginBottom: '20px' }}>
              {loading && (
                <>
                  <Skeleton
                    className='ant-skeleton-search'
                    paragraph={{ rows: 2 }}
                    active
                  />
                  <Skeleton
                    className='ant-skeleton-search'
                    paragraph={{ rows: 2 }}
                    active
                  />
                </>
              )}
              {!loading && results.map((item, index) => (
                <div
                  key={`result-${index}`}
                  className={cx('search__results-item')}
                >
                  {(
                    !destinationsWithoutActions.find(title => title === item.name) &&
                    item.type !== DESTINATION_TYPES.DEPARTMENT
                  ) && (
                    <Tooltip placement='top' title={actionTooltip}>
                      <Button onClick={() => onBookmarClick(item)}>
                        <em className={`fal fa-${itemActionIcon}`} />
                      </Button>
                    </Tooltip>
                  )}
                  <Link
                    to={item.linkTo}
                    onClick={onClose}
                  >
                    <img
                      src={item.imageUrl}
                      alt={item.imageAlt}
                      className='search__results-item--image'
                    />
                    <div className='search__results-item--content'>
                      <h4 className='result-title'>
                        {item.name}
                      </h4>
                      <div className='result-complement'>
                        {item.caption}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )
          : ''
        }

        {
          !loading && finalSearchText && !emptySearchMessage && filteredResults.length
            ? (
              <h5 className='subtitle__ppal xxs' style={{ marginBottom: '15px' }}>
                Cerca o relacionado con: {finalSearchText}
              </h5>
            )
            : ''
        }

        <div className='search__results'>
          {!loading && filteredResults.map((item, index) => (
            <div
              key={`result-${index}`}
              className={cx('search__results-item')}
            >
              {
                (
                !destinationsWithoutActions.find(title => title === item.name) &&
                item.type !== DESTINATION_TYPES.DEPARTMENT
              ) && (
                  <Tooltip placement='top' title={actionTooltip}>
                    <Button onClick={() => onBookmarClick(item)}>
                      <em className={`fal fa-${itemActionIcon}`} />
                    </Button>
                  </Tooltip>
                )
              }
              <Link
                to={item.linkTo}
                onClick={onClose}
              >
                <img
                  src={item.imageUrl}
                  alt={item.imageAlt}
                  className='search__results-item--image'
                />
                <div className='search__results-item--content'>
                  <h4 className='result-title'>
                    {item.name}
                  </h4>
                  <div className='result-complement'>
                    {item.caption}
                  </div>
                </div>
              </Link>
            </div>
          ))}
          {!loading && filteredResults.length
            ? (
              <Typography.Text type='secondary'>
                Si no encuentras lo que buscas, recuerda que puedes <button className='fake-link' onClick={() => setVisibleModalRecommendSearch(true)}>recomendarnos</button> un destino en cualquier momento.
              </Typography.Text>
            )
            : ''}
        </div>
      </Drawer>
      <ModalDefault
        type='recommendSearch'
        visible={visibleModalRecommendSearch}
        onClose={() => setVisibleModalRecommendSearch(false)}
        search={finalSearchText}
      />
    </>
  )
}
