// @vendors
import { createContext } from 'react'

export const DestinationPageContext = createContext()
export const DestinationPageContextProvider = DestinationPageContext.Provider

export const DepartmentPageContext = createContext()
export const DepartmentPageContextProvider = DepartmentPageContext.Provider

export const PnnPageContext = createContext()
export const PnnPageContextProvider = PnnPageContext.Provider