// @vendors
import {
  useCallback,
  useEffect,
  useState
} from 'react'

// @api
import { getUserNotifications } from 'api/notifications'
import { acceptUserCookies } from 'api/user'

// @hooks
import useFetch from 'hooks/useFetch'
import useModals from 'hooks/useModals'
import useAuth from 'hooks/useAuth'

// @utils
import { notify } from 'utils/notifications'

export default function useApp() {
  const modalsHandlers = useModals()
  const authInfo = useAuth(modalsHandlers)
  const [autoSearchText, setAutoSearchText] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedDestination, setSelectedDestination] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [addDestinationToTripsDrawerVisible, setAddDestinationToTripsDrawerVisible] = useState(false)

  const { refetch: fetchUserNotifications, loading: loadingNotifications } = useFetch(getUserNotifications)
  const { refetch: fetchAcceptCookies } = useFetch(acceptUserCookies)

  const { onOpenAuthForm, setLoggedInEmail } = modalsHandlers
  const { user, setUser } = authInfo

  const handleChangeAddToTripsVisibility = (isOpened, openerCallback, newSelectedDestination = null) => () => {
    if (isOpened && !user) {
      notify(
        'error',
        <>Para agregar a tus viajes debes estar registrado. <div className="fake-link" onClick={onOpenAuthForm}>¿Qué esperas? ¡es gratis!</div></>,
        ''
      )
      return
    }
    openerCallback()
    setSelectedDestination(newSelectedDestination)
  }

  const onAcceptCookies = useCallback(({ version, acceptedDate }, onSuccess) => {
    fetchAcceptCookies(
      [user.email, version, acceptedDate],
      () => {
        onSuccess()
        const updatedUser = {
          ...user,
          cookies: []
        }
        setUser(updatedUser)
      }
    )
  }, [user])

  const handleFetchUserNotifications = () => {
    fetchUserNotifications(
      [user.email],
      (newNotifications) => {
        setNotifications(newNotifications)
      }
    )
  }

  const handleChangeSearchText = useCallback(newSearchText => () => {
    setSearchText(newSearchText)
    setAutoSearchText(true)
  }, [])

  useEffect(() => {
    setLoggedInEmail(user?.email)
  }, [user])

  useEffect(() => {
    if (autoSearchText) {
      setAutoSearchText(false)
    }
  }, [autoSearchText])

  return {
    auth: {
      ...authInfo,
      notifications,
      setNotifications,
      getNotifications: handleFetchUserNotifications,
      loadingNotifications,
      acceptCookies: onAcceptCookies
    },
    app: {
      autoSearchText,
      setAutoSearchText,
      searchText,
      setSearchText,
      handleChangeSearchText,
      addTripsModalOpened: addDestinationToTripsDrawerVisible,
      onOpenAddDestinationToMyTrips: () => setAddDestinationToTripsDrawerVisible(true),
      onCloseAddDestinationToMyTrips: () => setAddDestinationToTripsDrawerVisible(false),
      ...modalsHandlers
    },
    trips: {
      handleChangeAddToTripsVisibility,
      selectedDestination
    }
  }
}
