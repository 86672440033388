export const ROUTES_PARAMS = {
  NATIONAL_PARKS: {
    ID: 'nationalParkId'
  },
  STORE: {
    ID: 'storeId'
  },
  LEGAL: {
    ID: 'uid'
  },
  BLOG: {
    ID: 'uid'
  },
  DESTINATION: {
    ID: 'destinationId',
    SLUG: 'destinationSlug'
  },
  DEPARTMENT: {
    ID: 'departmentId'
  }
}

export const ROUTES = {
  HOME: '/',
  ABOUT_US: '/nosotros',
  EXPERIENCES: '/experiencias',
  DONATE: '/donar',
  LEGAL: `/legal/:${ROUTES_PARAMS.LEGAL.ID}`,
  LEGALS: {
    TERMS: '/legal/terminos',
    PRIVACY: '/legal/privacidad',
    REPORT: '/legal/infracciones-de-derechos-de-autor',
    CREDITS: '/legal/creditos',
    COOKIES: '/legal/cookies'
  },
  REQUESTS: {
    NEW: '/solicitudes/nueva'
  },
  CONTACT: '/hablemos',
  LNT: '/ndr',
  PATRIMONY_TOWNS: '/pueblos-patrimonio',
  DEPARTMENTS: '/departamentos',
  NATIONAL_PARKS: '/pnn',
  NATIONAL_PARKS_DETAILS: `/pnn/:${ROUTES_PARAMS.NATIONAL_PARKS.ID}`,
  STORE: '/tienda',
  STORE_DETAILS: `/tienda/:${ROUTES_PARAMS.STORE.ID}`,
  BLOG: '/blog',
  BLOG_DETAILS: `/blog/:${ROUTES_PARAMS.BLOG.ID}`,
  DESTINATION: `/:${ROUTES_PARAMS.DESTINATION.SLUG}/:${ROUTES_PARAMS.DESTINATION.ID}`,
  DEPARTMENT: `/:${ROUTES_PARAMS.DEPARTMENT.ID}`,
  LOGIN: '/login',
  PROFILE: {
    MY_TRIPS: '/viajes',
    VISITED: '/visitados',
    ACCOUNT: '/perfil/cuenta',
    ALBUMBS: '/albumes',
    SUMMARY: '/perfil',
    TRIP_DETAIL: '/viajes/:tripId'
  },
  REGISTER: '/registro',
  I_LIKED: '/u/esvefe/me-gusta',
  RECOVERY_PASSWORD: `/recuperar`
}

export const PRIVATE_ROUTES = [
  ROUTES.PROFILE.MY_TRIPS,
  ROUTES.PROFILE.VISITED,
  ROUTES.PROFILE.ACCOUNT,
  ROUTES.PROFILE.ALBUMBS,
  ROUTES.PROFILE.SUMMARY,
  ROUTES.PROFILE.TRIP_DETAIL,
  ROUTES.I_LIKED,
  ROUTES.STORE,
  ROUTES.STORE_DETAILS,
  ROUTES.RECOVERY_PASSWORD
]

export const ROUTES_WITH_FOOTER = [
  ROUTES.ABOUT_US,
  ROUTES.RECOVERY_PASSWORD
]

export const SOCIAL_NETWORKS = [
  {
    iconName: 'instagram',
    title: 'Instagram',
    path: 'https://www.instagram.com/noomada_/',
    target: '_blank'
  },
  {
    iconName: 'facebook',
    title: 'Facebook',
    path: 'https://www.facebook.com/noomada.col',
    target: '_blank'
  },
  {
    iconName: 'youtube',
    title: 'YouTube',
    path: '',
    target: '_blank'
  },
  {
    iconName: 'twitter',
    title: 'Twitter',
    path: 'https://www.twitter.com/noomada_',
    target: '_blank'
  }
]