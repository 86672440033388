// @vendors
import { Skeleton } from 'antd'
import cx from 'classnames'

// @utils
import { pluralWord } from 'utils/format'

// @styles
import './styles.scss'

export default function Score({ average, total, loading }) {
  return (
    <div className='score'>
      <div className='score__stars'>
        <em className='fas fa-star' />
        <div
          className={cx(
            'score__stars-value',
            {
              'score__stars-value--empty': !total
            }
          )}
        >
          {loading && (
            <Skeleton
              paragraph={0}
              className='ant-skeleton-only-one basic'
              active
            />
          )}
          {!loading && !!total && (
            <>{average} <span>{total} {pluralWord('reseña', total)}</span></>
          )
          }
        </div>
      </div>
    </div>
  )
}