export const DEFAULT = {
  site: {
    name: '· Noomada',
    slogan: 'Conoce cada rincón de Colombia y vive miles de experiencias',
    urlBase: 'https://noomada.com',
    twitter: 'noomadacol'
  },
  whatsapp: {
    providers: {
      number: '3245355295',
      message: 'Hola, Noomada. Me interesa saber más de cómo puedo ofrecer mis servicios en'
    }
  }
}

export const GENERES = [
  {
    name: 'Hombre',
    value: 'Hombre'
  },
  {
    name: 'Mujer',
    value: 'Mujer'
  },
  {
    name: 'Prefiero no especificar',
    value: 'Indefinido'
  }
]