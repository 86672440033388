// @vendors
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'
import { Skeleton, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import {
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from 'react-share'
import moment from 'moment'

// @hooks
import useBlogDetail from 'hooks/useBlogDetail'

// @components
import Text from './text'
import Image from './image'
import Ad from './ad'

// @pages
import NotFound from 'pages/not-found'

// @constants
import { DEFAULT } from 'constants/default'

// @styles
import './styles.scss'

const sliceSelector = {
  text: (props) => <Text info={props.slice.primary.text} />,
  publicidad: (props) => <Ad />,
  image_with_caption: (props) => <Image info={props.slice.primary.image} />,
  referencias: (props) => <div />
}

export default function BlogDetailPage() {
  const { info, loading } = useBlogDetail()

  if (loading) {
    return (
      <div className='post__detail'>
        <div className='post__detail-container'>
          <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
        </div>
      </div>
    )
  }

  if (!info) {
    return (
      <div className='post__detail'>
        <div className='post__detail-container'>
          <NotFound />
        </div>
      </div>
    )
  }

  const person = info.data.person[0]

  return (
    <>
      <Helmet>
        <title>Blog: {info.data.title} {DEFAULT.site.name}</title>
      </Helmet>
      <div className='post__detail'>
        <div className='post__detail-container'>
          <div className='post__detail-back'>
            <Link to={'/blog'}>
              <em className='fal fa-arrow-left' /> <span>Volver al blog</span>
            </Link>
          </div>
          <div className='post__detail-headline'>
            <img src={info.data.image.url} alt={info.data.image.alt} />
            <div className='copy-image'>
              Foto: {info.data.image.copyright !== null ? info.data.image.copyright : '¿tuya?, contáctanos'}
            </div>
            <h1 className='title__ppal md'>
              <strong>{info.data.title}</strong>
            </h1>
          </div>
          <div className='post__detail-actions'>
            <div className='person'>
              <img src={person.photo?.url} alt={person.photo?.alt} />
              <div className='person__data'>
                <div className='person__data-name'>
                  Por <strong>{RichText.asText(person.name)}</strong>
                  <a href={person.link.url} target='_blank' rel='noopener noreferrer'>
                    <em className={`fab fa-${RichText.asText(person.social_network)}`} />
                  </a>
                </div>
                <div className='person__data-date'>
                  {moment(info.data.date, 'YYYY-MM-DD').format('LL')}
                </div>
              </div>
            </div>
            <div className='share'>
              <Tooltip title='Facebook' placement='top'>
                <FacebookShareButton
                  url={`${DEFAULT.site.urlBase}/blog/${info.uid}`}
                  quote={`${info.data.title} | ${DEFAULT.site.name}`}
                  className='ant-btn outline c-dr ant-btn-only--icon'
                  resetButtonStyle={false}
                >
                  <em className='fab fa-facebook-f' />
                </FacebookShareButton>
              </Tooltip>
              <Tooltip title='WhatsApp' placement='top'>
                <WhatsappShareButton
                  url={`${DEFAULT.site.urlBase}/blog/${info.uid}`}
                  title={`${info.data.title} | ${DEFAULT.site.name}`}
                  className='ant-btn outline c-dr ant-btn-only--icon'
                  resetButtonStyle={false}
                >
                  <em className='fab fa-whatsapp' />
                </WhatsappShareButton>
              </Tooltip>
              <Tooltip title='Telegram' placement='top'>
                <TelegramShareButton
                  url={`${DEFAULT.site.urlBase}/blog/${info.uid}`}
                  title={`${info.data.title} | ${DEFAULT.site.name}`}
                  className='ant-btn outline c-dr ant-btn-only--icon'
                  resetButtonStyle={false}
                >
                  <em className='fab fa-telegram' />
                </TelegramShareButton>
              </Tooltip>
              <Tooltip title='Twitter' placement='top'>
                <TwitterShareButton
                  url={`${DEFAULT.site.urlBase}/blog/${info.uid}`}
                  title={`${info.data.title} | ${DEFAULT.site.name}`}
                  hashtags={['NoomadaColombia']}
                  className='ant-btn outline c-dr ant-btn-only--icon'
                  resetButtonStyle={false}
                >
                  <em className='fab fa-twitter' />
                </TwitterShareButton>
              </Tooltip>
              <Tooltip title='LinkedIn' placement='top'>
                <LinkedinShareButton
                  url={`${DEFAULT.site.urlBase}/blog/${info.uid}`}
                  title={`${info.data.title} | ${DEFAULT.site.name}`}
                  summary='fsf'
                  className='ant-btn outline c-dr ant-btn-only--icon'
                  resetButtonStyle={false}
                >
                  <em className='fab fa-linkedin' />
                </LinkedinShareButton>
              </Tooltip>
              <Tooltip title='Email' placement='top'>
                <EmailShareButton
                  url={`${DEFAULT.site.urlBase}/blog/${info.uid}`}
                  title={`${info.data.title} | ${DEFAULT.site.name}`}
                  className='ant-btn outline c-dr ant-btn-only--icon'
                  resetButtonStyle={false}
                >
                  <em className='fal fa-envelope' />
                </EmailShareButton>
              </Tooltip>
            </div>
          </div>
          <div className='post__detail-content'>
            {info.data.body
              .map((item, index) => {
                const SelectedSlice = sliceSelector[item.slice_type]

                return <SelectedSlice slice={item} key={`${item.slice_type}-${index}`} />
              })}
          </div>
          <div className='post__detail-back'>
            <Link to={'/blog'}>
              <em className='fal fa-arrow-left' /> <span>Volver al blog</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
