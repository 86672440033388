// @vendors
import axios from 'axios'

// @constants
import { USER_INFO_LOCAL_STORAGE_KEY } from 'constants/user'

axios.interceptors.request.use(function(config) {
    const localConfig = { ...config }
    const accessToken = JSON.parse(window.localStorage.getItem(USER_INFO_LOCAL_STORAGE_KEY))

    if (accessToken) {
        localConfig.data.access_token = accessToken
    }
    return localConfig
})
