// @vendors
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'

// @hooks
import useHome from 'hooks/useHome'
import usePrismicQueryByTags from 'hooks/usePrismicQueryByTags'

// @constants
import { DEFAULT } from 'constants/default'

// @components
import Banner from 'pages/home/banner'
import Sidebar from './sidebar'

// @utils
import { shuffleArray } from 'utils/format'

// @styles
import './styles.scss'

function mapDestinationItems(destinations) {
  return destinations.map((destination) => ({
    name: destination.data.name,
    caption: destination.type === 'pnn'
      ? destination.data.deptos
        .map((dest) => dest.name)
        .reduce((prev, curr) => [prev, ', ', curr])
      : destination.data.depto,
    imageAlt: destination.data.thumb[0].image.alt,
    imageUrl: destination.data.thumb[0].image.url,
    linkTo: destination.type === 'pnn'
      ? `/pnn/${destination.uid}`
      : `/${destination.tags[0]}/${destination.uid}`
  }))
}

const baseDestinations = {
  mas_populares: {
    title: 'Recomendados',
    loading: true,
    cardsList: []
  },
  pueblos_patrimonio: {
    title: 'Pueblos Patrimonio',
    loading: true,
    cardsList: []
  },
  capitales: {
    title: 'Capitales',
    loading: true,
    cardsList: []
  },
  parques_nacionales: {
    title: 'Parques Nacionales',
    loading: true,
    cardsList: []
  }
}

const HomePage = () => {
  const { activities, backgroundImage } = useHome()
  const {
    info: parks,
    loading: loadingParks
  } = usePrismicQueryByTags(
    'clientPNN',
    'pnn',
    [],
    [''],
    20
  )
  const {
    info: capitals,
    loading: loadingCapitals
  } = usePrismicQueryByTags(
    'client',
    'destination',
    ['capital'],
    [''],
    32
  )
  const {
    info: towns,
    loading: loadingTowns
  } = usePrismicQueryByTags(
    'client',
    'destination',
    ['pueblo-patrimonio'],
    [''],
    20
  )
  const {
    info: populars,
    loading: loadingPopulars
  } = usePrismicQueryByTags(
    'client',
    'destination',
    ['featured'],
    [''],
    30
  )

  const destinations = useMemo(() => {
    if (loadingPopulars || loadingTowns || loadingCapitals || loadingParks) {
      return { ...baseDestinations }
    }

    if (!loadingPopulars && !loadingTowns && !loadingCapitals && !loadingParks) {
      const updatedDestinations = { ...baseDestinations }
      updatedDestinations.mas_populares.loading = false
      updatedDestinations.mas_populares.cardsList = shuffleArray(mapDestinationItems(populars))
      updatedDestinations.pueblos_patrimonio.loading = false
      updatedDestinations.pueblos_patrimonio.cardsList = shuffleArray(mapDestinationItems(towns))
      updatedDestinations.capitales.loading = false
      updatedDestinations.capitales.cardsList = shuffleArray(mapDestinationItems(capitals))
      updatedDestinations.parques_nacionales.loading = false
      updatedDestinations.parques_nacionales.cardsList = shuffleArray(mapDestinationItems(parks))
      return updatedDestinations
    }
  }, [loadingPopulars, loadingTowns, loadingCapitals, loadingParks])

  return (
    <>
      <Helmet>
        <title>{DEFAULT.site.slogan} {DEFAULT.site.name}</title>
        <meta name='description' content='Encuentra lugares inexplorados y un sin fin de experiencias únicas en Colombia.' />
      </Helmet>
      <Banner backgroundImage={backgroundImage} />
      <Sidebar activities={activities} destinations={destinations} />
    </>
  )
}

export default HomePage
