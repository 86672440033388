// @vendors
import {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'

// @utils
import { getPercentageOfVisitedDestinationInTrip } from 'utils/percentage'

// @hooks
import useFetch from 'hooks/useFetch'

// @context
import { AppContext } from 'context/app'

// @api
import {
  deleteTrip,
  deleteDestinationFromTrip,
  updateTrip,
  getTripDetails,
  markDestinationAsVisitedInTrip,
  removeInvitedFromTrip,
  leaveTrip
} from 'api/destination'

// @utils
import { notify } from 'utils/notifications'

// @constants
import { ROUTES } from 'constants/routes'

export default function useTripDetails(tripId) {
  const [isTripEditMode, setIsTripEditMode] = useState(null)
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visibleShareTrip, setVisibleShareTrip] = useState(false)
  const [updatedTripTitle, setUpdatedTripTitle] = useState('')
  const history = useHistory()
  const { auth: { user } } = useContext(AppContext)
  const {
    data: tripDetails,
    setData: setTripDetails,
    loading: loadingTripDetails,
    refetch: refetchTripDetails
  } = useFetch(
    getTripDetails,
    {
      initialData: null,
      params: [user.email, tripId],
      fetchOnMount: true,
      showErrorMessage: false,
      onError: () => {
        notify('error', 'No se encontró el viaje')
      }
    }
  )

  const {
    refetch: onDeleteTrip
  } = useFetch(
    deleteTrip,
    {
      showErrorMessage: false,
      onSuccess: () => {
        notify('success', 'Viaje eliminado satisfactoriamente')
        history.push(ROUTES.PROFILE.MY_TRIPS)
      },
      onError: () => {
        notify('error', 'Error eliminando el viaje')
      }
    }
  )

  const {
    loading: loadingEditingTrip,
    refetch: onEditTrip
  } = useFetch(
    updateTrip,
    {
      showErrorMessage: false,
      onSuccess: (res) => {
        notify('success', 'Viaje editado satisfactoriamente')
        setIsTripEditMode(false)
        setTripDetails(currentTripDetails => ({
          ...currentTripDetails,
          title: res.title
        }))
      },
      onError: () => {
        notify('error', 'Error editando el viaje')
      }
    }
  )

  const {
    refetch: onDeleteDestinationFromTrip
  } = useFetch(
    deleteDestinationFromTrip,
    {
      showErrorMessage: false
    }
  )

  const {
    refetch: markDestinationInTrip
  } = useFetch(
    markDestinationAsVisitedInTrip,
    {
      showErrorMessage: false
    }
  )

  const { refetch: onRemoveInvitedFromTrip } = useFetch(removeInvitedFromTrip)
  const { refetch: onLeaveTrip } = useFetch(leaveTrip)

  const visitedPercentage = useMemo(() => {
    return getPercentageOfVisitedDestinationInTrip(tripDetails?.destinations)
  }, [tripDetails?.destinations])

  const handleEditTrip = () => {
    onEditTrip([user.email, updatedTripTitle, tripId])
  }

  const onRemoveInvitedFromList = (invitedEmail) => {
    setTripDetails((currentTripDetails) => ({
      ...currentTripDetails,
      shared: currentTripDetails.shared.filter(({ email }) => email !== invitedEmail)
    }))
  }

  const handleDeleteDestinationFromTrip = (destinationTitle, onSucces, onError) => {
    onDeleteDestinationFromTrip(
      [user.email, tripDetails.codeId, destinationTitle],
      (result) => {
        onSucces(result)
        setTripDetails(currentTripDetails => ({
          ...currentTripDetails,
          destinations: result.destinations
        }))
      },
      onError
    )
  }

  const handleRemoveInvitedFromTrip = (invitedInfo) => {
    onRemoveInvitedFromTrip(
      [user.email, invitedInfo.email, tripDetails.codeId],
      () => {
        notify('success', <>Has eliminado a <strong>{invitedInfo.fullName}</strong> de este viaje</>, '')
        onRemoveInvitedFromList(invitedInfo.email)
      }
    )
  }

  const handleLeaveTrip = (callback) => {
    onLeaveTrip(
      [tripDetails.email, user.email, tripDetails.codeId],
      () => {
        notify('success', 'Has abando este viaje', '')
        history.push(ROUTES.PROFILE.MY_TRIPS)
        callback()
      }
    )
  }

  useEffect(() => {
    if (isTripEditMode === false) {
      refetchTripDetails()
    }
    setUpdatedTripTitle(isTripEditMode ? tripDetails.title : '')
  }, [isTripEditMode])

  return {
    tripDetails,
    setTripDetails,
    visitedPercentage,
    onDeleteTrip: () => onDeleteTrip([user.email, tripDetails.codeId, tripDetails.shared.map(({ email }) => email)]),
    onEditTrip: handleEditTrip,
    loadingEditingTrip,
    isTripEditMode,
    setIsTripEditMode,
    refetchTripDetails,
    loadingTripDetails,
    visibleMenu,
    setVisibleMenu,
    updatedTripTitle,
    setUpdatedTripTitle,
    visibleShareTrip,
    setVisibleShareTrip,
    onDeleteDestinationFromTrip: handleDeleteDestinationFromTrip,
    markDestinationInTrip,
    onRemoveInvitedFromTrip: handleRemoveInvitedFromTrip,
    onLeaveTrip: handleLeaveTrip
  }
}