import { BroadcastChannel } from "broadcast-channel"

export default class Broadcaster {
    constructor(channelName) {
        this.channel = new BroadcastChannel(channelName)
        this.messageTransmitter = 'message'

        this.close = this.close.bind(this)
        this.pub = this.pub.bind(this)
        this.sub = this.sub.bind(this)
    }

    pub(message) {
        this.channel.postMessage(message)
    }

    sub(callback) {
        this.channel.addEventListener(
          this.messageTransmitter,
          (data) => {
            callback(data)
          }
        )
    }

    close() {
        this.channel.close()
    }
}