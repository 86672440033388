// @vendors
import {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Typography,
  Select
} from 'antd'
import cx from 'classnames'
import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

// @context
import { AppContext } from 'context/app'

// @constants
import { COUNTRIES } from 'constants/countries'
import { ROUTES } from 'constants/routes'
import { GENERES } from 'constants/default'

// @utils
import { convertToSlug, validateEmail } from 'utils/format'

const dateFormat = 'DD/MM/YYYY'
const { Text } = Typography

export default function SocialFullRegisterForm() {
  const { auth: { user, updateUser } } = useContext(AppContext)
  const [isTryingToRegister, setIsTryingToRegister] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleSuccessRegister = () => {
    setVisible(false)
  }

  const handleUpdateSocialRegisteredUser = async (values) => {
    const userToRegister = {}

    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'email') {
        userToRegister[key] = value
      }
    })
    setIsTryingToRegister(true)
    const { error } = await updateUser(userToRegister)
    setIsTryingToRegister(false)

    if (!error) {
      handleSuccessRegister()
    }
  }

  useEffect(() => {
    if (user?.fullRecord === false) {
      setVisible(true)
    }
  }, [user])

  if (!visible) {
    return null
  }

  return (
    <Modal
      wrapClassName={
        cx(
          'modal__default',
          'modal__default--full-mobile',
          'modal__steps'
        )
      }
      centered
      width='400px'
      footer={null}
      closable={false}
      visible
    >
      <div className='step__message'>

        <h3 className='title__ppal sm c--dr'>
          <strong>Completar registro</strong>
        </h3>
        <Form
          layout='vertical'
          onFinish={handleUpdateSocialRegisteredUser}
          fields={[
            {
              name: ["name"],
              value: user.firstName
            },
            {
              name: ["email"],
              value: user.email
            },
            {
              name: ["gender"],
              value: user.gender || 'Indefinido'
            },
            {
              name: ["nationality"],
              value: 'Colombia'
            }
          ]}
        >
          <Form.Item
            label='Nombre'
            name='firstName'
            rules={[
              {
                message: 'Se te olvidó este campo',
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Apellidos'
            name='lastName'
            rules={[
              {
                message: 'Se te olvidó este campo',
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <div id='genere-full-register-modal' style={{ position: 'relative' }}>
            <Form.Item
              label='Género'
              name='gender'
              rules={[
                {
                  message: 'Se te olvidó este campo',
                  required: true
                }
              ]}
            >
              <Select
                placeholder='Seleccionar'
                dropdownClassName='ant-dropdown-always-bottom'
                getPopupContainer={() => document.getElementById('genere-full-register-modal')}
              >
                {GENERES.map((item) => (
                  <Select.Option
                    value={item.value}
                    key={item.value}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            label='Fecha de nacimiento'
            name='birthday'
            rules={[
              {
                message: 'Se te olvidó este campo',
                required: true
              }
            ]}
          >
            <DatePicker
              locale={locale}
              placeholder='dd/mm/aaaa'
              format={dateFormat}
              showToday={false}
              inputReadOnly
            />
          </Form.Item>
          <Form.Item
            label='Correo electrónico'
            name='email'
            rules={[
              {
                message: 'Se te olvidó este campo',
                required: true
              },
              {
                validator(_, value) {
                  if (validateEmail(value) || !value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Ingrese un correo válido'))
                }
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <div id='nationality-full-register-modal' style={{ position: 'relative' }}>
            <Form.Item
              id='select-nationality'
              label='Nacionalidad'
              name='nationality'
              rules={[
                {
                  message: 'Se te olvidó este campo',
                  required: true
                }
              ]}
            >
              <Select
                placeholder='Seleccionar'
                showSearch
                dropdownClassName='ant-dropdown-always-bottom'
                getPopupContainer={() => document.getElementById('nationality-full-register-modal')}
              >
                {COUNTRIES.map((country) => (
                  <Select.Option
                    value={country.name}
                    key={convertToSlug(country.code)}
                  >
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label=''
              name='reCaptcha'
              rules={[
                {
                  required: true,
                  message: '¡Oh, no! ¿Eres un bot? 😮‍💨'
                }
              ]}
            >
              <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`}
                hl='es'
              />
            </Form.Item>
          </div>
          <Text
            type='secondary'
            style={{ margin: '-10px 0 20px', display: 'block' }}
          >
            Al dar clic en <strong>Finalizar</strong>, estás aceptando los <Link to={ROUTES.LEGALS.TERMS}>Términos de servicio</Link> y la <Link to={ROUTES.LEGALS.PRIVACY}>Política de privacidad</Link> de Noomada.
          </Text>
          <Form.Item className='actions'>
            <Button
              className='solid c-1'
              htmlType="submit"
              loading={isTryingToRegister}
            >
              Finalizar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}
