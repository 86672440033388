// @vendors
import { useCallback, useEffect, useState } from 'react'
import {
    Button,
    Modal,
    Form,
    Input,
    Tooltip
} from 'antd'
import cx from 'classnames'

// @api
import { shareTrip } from 'api/destination'

// @hooks
import useFetch from 'hooks/useFetch'

// @utils
import { validateEmail } from 'utils/format'
import { notify } from 'utils/notifications'

function isUserAlreadyInvited(invitedUsers, userToInvite) {
    return !!invitedUsers.find(({ email }) => email === userToInvite)
}

export default function ShareTrip({
    visible,
    onCancel,
    tripId,
    tripTitle,
    user,
    sharedWith
}) {
    const [active, setActive] = useState(false)
    const [emailToAdd, setEmailToAdd] = useState({ email: '', error: false })
    const [invitedUsers, setInvitedUsers] = useState([])
    const isTryingToInviteThemselve = user.email.toLowerCase() === emailToAdd.email.toLowerCase()

    const { refetch: fetchShareTrip, loading: sharingTrip } = useFetch(shareTrip)

    const handleAddInvitedToList = useCallback((event) => {
        if (event) {
            event.preventDefault()
        }

        if (!isUserAlreadyInvited(sharedWith, emailToAdd.email)) {
            if (!emailToAdd.error && !!emailToAdd.email) {
                setInvitedUsers(currentInvitedUsers => ([
                    ...currentInvitedUsers,
                    {
                        status: '',
                        email: emailToAdd.email,
                        message: ''
                    }
                ]))
                setEmailToAdd({ email: '', error: false })
            }
        }
    }, [emailToAdd])

    const handleEnter = (event) => {
      if (!isTryingToInviteThemselve) {
        handleAddInvitedToList(event)
      }
    }

    const handleRemoveInvitedToList = useCallback((index) => () => {
        setInvitedUsers(currentInvitedUsers => currentInvitedUsers.filter((_, idx) => idx !== index))
    }, [])

    const handleChangeEmail = useCallback((event) => {
        const newEmail = event.target.value
        const existEmail = !!invitedUsers.find(({ email }) => email === newEmail)

        setEmailToAdd({
            email: newEmail,
            error: !validateEmail(newEmail) || existEmail
        })
    }, [emailToAdd, invitedUsers, sharedWith])

    const handleSendInvitation = useCallback((event) => {
        event.preventDefault()
        const allInvitedUsers = invitedUsers.map(({ email }) => ({ email }))
        const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim()
        fetchShareTrip(
            [user.email, tripId, tripTitle, fullName, allInvitedUsers],
            () => {
                notify('success', 'Viaje compartido existosamente')
                setActive(true)
                setEmailToAdd({ email: '', error: false })
            },
            () => {
                notify('error', 'Error compartiendo viaje')
            }
        )
    }, [invitedUsers])

    const onReset = () => {
        setActive(false)
        setEmailToAdd({ email: '', error: false })
        setInvitedUsers([])
    }

    const formItemProps = {
        help: '',
        rules: [],
        label: 'Correo electrónico',
        name: "email-to-add",
        className: 'ant-form-item-extra',
        validateStatus: ''
    }

    if (isUserAlreadyInvited(sharedWith, emailToAdd.email)) {
        formItemProps.help = 'Este noomada ya está en este viaje'

        formItemProps.validateStatus = 'error'
    } else {
        formItemProps.help = emailToAdd.error
            ? 'Ingresa un correo válido'
            : 'Presiona ENTER o da click en el [+] para agregar.'

        formItemProps.validateStatus = emailToAdd.error
            ? 'error'
            : 'success'
    }

    useEffect(() => {
        if (!visible) {
            onReset()
        }
    }, [visible])

    return (
        <Modal
            wrapClassName={
                cx(
                    'modal__default',
                    'modal__default--bottom-mobile',
                    'modal__steps',
                    'modal__auto'
                )
            }
            centered
            onCancel={onCancel}
            width='400px'
            footer={null}
            visible={visible}
        >
            <div className='step__message'>
                <h3 className='title__ppal sm c--dr'>
                    <strong>Invitar noomadas</strong>
                </h3>
                <Form layout='vertical'>
                    <Form.Item {...formItemProps}>
                        <Input
                            onChange={handleChangeEmail}
                            onPressEnter={handleEnter}
                            disabled={active}
                            value={emailToAdd.email}
                        />
                        { }
                        <Button
                            className='solid c-2 ant-btn-size--xs ant-form-item-extra-action ant-btn-only--icon'
                            disabled={
                                active ||
                                emailToAdd.error ||
                                !emailToAdd.email ||
                                isTryingToInviteThemselve ||
                                isUserAlreadyInvited(sharedWith, emailToAdd.email)
                            }
                            onClick={handleAddInvitedToList}
                        >
                            <em className='fal fa-plus' />
                        </Button>
                    </Form.Item>
                    {invitedUsers.length
                        ? (
                            <ul className='step__message-invitations'>
                                {invitedUsers.map((item, index) => (
                                    <li
                                        key={`noomada-${index}`}
                                        className={cx(
                                            'step__message-invitations-item',
                                            {
                                                'step__message-invitations-item-sended': item.status === 'sended' && active,
                                                'step__message-invitations-item-error': item.status === 'error' && active,
                                                'step__message-invitations-item-loading': item.status === 'loading' && active
                                            }
                                        )}
                                    >
                                        <span>
                                            {item.email}
                                        </span>
                                        {
                                            !active &&
                                            <button
                                                className='delete'
                                                onClick={handleRemoveInvitedToList(index)}
                                            >
                                                <em className='fal fa-times' />
                                            </button>
                                        }
                                        {active && (
                                            <Tooltip placement='right' title={item.message}>
                                                <div className='status' />
                                            </Tooltip>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )
                        : null
                    }
                    <Form.Item className='actions'>
                        {!active && (
                            <Button
                                onClick={handleSendInvitation}
                                className='solid c-1'
                                disabled={!invitedUsers.length}
                                loading={sharingTrip}
                            >
                                Enviar invitación
                            </Button>
                        )}
                        {active && (
                            <Button
                                onClick={() => {
                                    setActive(false)
                                    setInvitedUsers([])
                                }}
                                className='outline c-dr'
                            >
                                Seguir enviando
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
