// @vendors
import { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import esEs from 'antd/lib/calendar/locale/es_ES'
import 'antd/dist/antd.css'
import TagManager from 'react-gtm-module'

// @components
import Header from 'components/header'
import Footer from 'components/footer'
import Page from 'components/page'
import PrivateRoute from 'components/private-route'
import PublicRoute from 'components/public-route'

// @constants
import { ROUTES } from 'constants/routes'

// @pages
import AboutUs from 'pages/about-us'
import Home from 'pages/home'
import Credits from 'pages/credits'
import Legal from 'pages/legal'
import Requests from 'pages/requests'
import Contact from 'pages/contact'
import LNT from 'pages/lnt'
import PatrimonyTowns from 'pages/patrimony-towns'
import NationalParks from 'pages/national-parks'
import Departments from 'pages/departments'
import DepartmentPage from 'pages/department'
import DestinationPage from 'pages/destination'
import Blog from 'pages/blog'
import BlogDetailPage from 'pages/blog-detail'
import NationalParkPage from 'pages/national-park'
import Profile from 'pages/profile'
import NotFound from 'pages/not-found'
import RecoveryPasswordPage from 'pages/recovery-password'

// @components
import SocialFullRegister from 'components/social-full-register'
import CookieAlert from 'components/cookies-alert'

// @styles
import './styles.scss'

// @GTM
const tagManagerArgs = {
  gtmId: 'GTM-W2BRGFX'
}

TagManager.initialize(tagManagerArgs)

export default function Pages() {
  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <ConfigProvider locale={esEs}>
        <Page>
          <Header />
          <CookieAlert />
          <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.ABOUT_US} component={AboutUs} />
            <Route exact path={ROUTES.LEGALS.CREDITS} component={Credits} />
            <Route exact path={ROUTES.LEGAL} component={Legal} />
            <Route exact path={ROUTES.REQUESTS.NEW} component={Requests} />
            <Route exact path={ROUTES.CONTACT} component={Contact} />
            <Route exact path={ROUTES.LNT} component={LNT} />
            <PrivateRoute exact path={ROUTES.PROFILE.MY_TRIPS}>
              <Profile path={ROUTES.PROFILE.MY_TRIPS} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.PROFILE.TRIP_DETAIL}>
              <Profile path={ROUTES.PROFILE.TRIP_DETAIL} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.PROFILE.ALBUMBS}>
              <Profile path={ROUTES.PROFILE.ALBUMBS} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.PROFILE.VISITED}>
              <Profile path={ROUTES.PROFILE.VISITED} />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.PROFILE.ACCOUNT}>
              <Profile path={ROUTES.PROFILE.ACCOUNT} />
            </PrivateRoute>
            <PublicRoute exact path={ROUTES.RECOVERY_PASSWORD}>
              <RecoveryPasswordPage />
            </PublicRoute>
            <Route exact path={ROUTES.DONATE} component={() => null} />
            <Route exact path={ROUTES.PATRIMONY_TOWNS} component={PatrimonyTowns} />
            <Route exact path={ROUTES.DEPARTMENTS} component={Departments} />
            <Route exact path={ROUTES.NATIONAL_PARKS} component={NationalParks} />
            <Route exact path={ROUTES.NATIONAL_PARKS_DETAILS} component={NationalParkPage} />
            <Route exact path={ROUTES.STORE} component={() => null} />
            <Route exact path={ROUTES.STORE_DETAILS} component={() => null} />
            <Route exact path={ROUTES.BLOG} component={Blog} />
            <Route exact path={ROUTES.BLOG_DETAILS} component={BlogDetailPage} />
            <Route exact path={ROUTES.DESTINATION} component={DestinationPage} />
            <Route exact path={ROUTES.DEPARTMENT} component={DepartmentPage} />
            <Route>
              <NotFound />
            </Route>
          </Switch>
          <SocialFullRegister />
          <Footer />
        </Page>
      </ConfigProvider>
    </Suspense>
  )
}
