// @vendors
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd'

// @hooks
import useBlog from 'hooks/useBlog'

// @components
import PostItem from './post-item'

// @constants
import { SOCIAL_NETWORKS } from 'constants/routes'
import { DEFAULT } from 'constants/default'

// @styles
import './styles.scss'

const LATEST_POSTS_NUMBER = 3

function getNotLatestBlogs(blogs = []) {
  return blogs.filter((_, index) => index >= LATEST_POSTS_NUMBER)
}

export default function Blog() {
  const {
    featuredPost,
    loading,
    posts
  } = useBlog()

  const [categories, setCategories] = useState([])
  const [filteredBlogs, setFilteredBlogs] = useState(() => getNotLatestBlogs(posts))
  const [selectedCategory, setSelectedCategory] = useState('Todos')

  useEffect(() => {
    if (posts.length) {
      const categoriesIndexes = []

      posts.forEach(category => {
        if (!categoriesIndexes.includes(category.data.category)) {
          categoriesIndexes.push(category.data.category)
        }
      })

      setCategories(categoriesIndexes)
      setFilteredBlogs(getNotLatestBlogs(posts))
    }
  }, [posts])

  useEffect(() => {
    let newBlogs = []
    if (selectedCategory === 'Todos') {
      newBlogs = getNotLatestBlogs(posts)
    } else {
      newBlogs = getNotLatestBlogs(posts).filter(({ data: { category } }) => category === selectedCategory)
    }
    setFilteredBlogs(newBlogs)
  }, [selectedCategory])

  return (
    <>
      <Helmet>
        <title>Blog {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic blog__main'>
        <div className='container'>
          {loading && <Skeleton className='ant-skeleton-blog' paragraph={{ rows: 3 }} active />}
          {!loading && (
            <>
              <h1 className='title__ppal md'>
                <strong>Blog</strong>
              </h1>
              <div className='blog__main-featured'>
                {featuredPost.map((post) => (
                  <PostItem post={post} key={post.id} />
                ))}
              </div>
              <h2 className='title__ppal sm'>
                Últimas 3 publicaciones
              </h2>
              <div className='blog__main-latest'>
                {posts
                  .filter((_, index) => index < LATEST_POSTS_NUMBER)
                  .map((post) => (
                    <PostItem post={post} key={post.id} />
                  ))}
                <div className='item__blog-false'>
                  <div className='title__ppal sm'>
                    <strong>Síguenos</strong>
                  </div>
                  <p className='parag__ppal'>
                    Compartimos lugares y experiencias increibles
                  </p>
                  <ul className='social'>
                    {SOCIAL_NETWORKS.map((network, index) =>
                      network.path && (
                        <li key={index}>
                          <a href={network.path} target='_blank' rel='noopener noreferrer'>
                            <em className={`fab fa-${network.iconName}`} />
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>

              <h2 className='title__ppal sm'>
                Categorías
              </h2>
              <ul className='filters__category solid c-1'>
                <li>
                  <Link
                    to='?search=Todos'
                    className={selectedCategory === 'Todos' ? 'active' : ''}
                    onClick={() => setSelectedCategory('Todos')}
                  >
                    Todos
                  </Link>
                </li>
                {
                  categories.map((item, k) => (
                    <li key={k}>
                      <Link
                        to={`?search=${item}`}
                        className={selectedCategory === item ? 'active' : ''}
                        onClick={() => setSelectedCategory(item)}
                      >
                        {item}
                      </Link>
                    </li>
                  ))
                }
              </ul>
              <div className='blog__main-all'>
                {filteredBlogs.map((post) => (
                  <PostItem post={post} type='asd' key={post.id} />
                ))}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  )
}
