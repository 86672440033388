// @vendors
import React, {
  useCallback,
  useContext,
  useRef
} from 'react'
import {
  Drawer,
  Form,
  Input,
  Button
} from 'antd'
import moment from 'moment'

// @hooks
import useFetch from 'hooks/useFetch'
import useMyTrips from 'hooks/useMyTrips'

// @context
import { AppContext } from 'context/app'

// @api
import { addDestinationToMyTrips } from 'api/destination'

// @utils
import { notify } from 'utils/notifications'

// @constants
import { QUERY_PARAM_CREATE_TRIP_FORM } from 'constants/query-params'

export default function TripCreate() {
  const {
    auth: { user },
    app: {
      onCloseCreateTripForm,
      openModalWithSessionOnly,
      isModalOpened
    }
  } = useContext(AppContext)
  const { refetchMyTrips } = useMyTrips({ fetchOnMount: false })
  const formRef = useRef()

  const visible = openModalWithSessionOnly(() => isModalOpened(QUERY_PARAM_CREATE_TRIP_FORM))

  const handleClose = (wasCancelled) => () => {
    formRef?.current?.resetFields()
    onCloseCreateTripForm()

    if (!wasCancelled) {
      refetchMyTrips()
    }
  }

  const { loading, refetch } = useFetch(addDestinationToMyTrips, { showErrorMessage: false })

  const handleCreateTrip = useCallback(async () => {
    const title = formRef?.current?.getFieldValue('title')

    if (!title) {
      notify('error', 'Debes agregar un título al viaje', '')
      return
    }

    refetch(
      [
        user.email,
        {
          title,
          destinations: [],
          shared: [],
          privated: true,
          createdOn: moment.utc().format('lll')
        }
      ],
      () => {
        handleClose(false)()
        notify('success', 'Viaje creado exitosamente', '')
      },
      (error) => {
        const finalErrorMessage = typeof error === 'string'
          ? error.replace('registro', 'viaje')
          : ''
        notify('error', `Error creando el viaje: ${finalErrorMessage}`, '')
      }
    )
  }, [user])

  return (
    <Drawer
      width='450px'
      placement="right"
      title='Crea un nuevo viaje'
      closable={false}
      footer={[
        <Button
          className='outline c-dr'
          disabled={loading}
          key='trip-cancel'
          onClick={handleClose(true)}
        >
          Cancelar
        </Button>,
        <Button
          className='solid c-1'
          loading={loading}
          key='trip-add'
          onClick={handleCreateTrip}
        >
          Crear
        </Button>
      ]}
      onClose={handleClose(true)}
      className='drawer__default'
      visible={visible}
      zIndex={1001}
    >
      <Form
        layout='vertical'
        initialValues={{ title: '' }}
        name='createTripForm'
        ref={formRef}
      >
        <Form.Item
          label=''
          name='title'
        >
          <Input.TextArea
            disabled={loading}
            showCount
            maxLength={50}
            rows={3}
            onPressEnter={handleCreateTrip}
            placeholder='Ej.: Conociendo el Eje Cafetero'
          />
        </Form.Item>
      </Form>
    </Drawer>
  )
}