// @vendors
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'

// @styles
import './styles.scss'

export default function Mission({ info }) {
  return (
    <section className="sec__default mission__vision">
      {
        info.map((item, index) => (
          <div className="mission__vision-content" key={`about-us-mission-${index}`}>
            <em className={`fal fa-${RichText.asText(item.icon)}`} />
            <h2 className="title__ppal md">
              <strong>{RichText.asText(item.title)}</strong>
            </h2>
            <div className="text__enr">
              <RichText render={item.description} />
            </div>
          </div>
        ))
      }
    </section>
  )
}

Mission.propTypes = {
  info: PropTypes.array
}

Mission.defaultProps = {
  info: []
}