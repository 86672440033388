// @vendors
import { Button, Form, Input } from 'antd'

export default function PasswordChangeForm({
  editData,
  editPass,
  setEditPass,
  loading,
  onUpdatePassword
}) {
  return (
    <>
      <h3 className='title__ppal sm c-dr'>
        <strong>Contraseña</strong>
      </h3>
      <Form
        layout='vertical'
        onFinish={onUpdatePassword}
      >
        {editPass && (
          <>
            <Form.Item
              label='Actual'
              name='currentPassword'
              rules={[{
                required: true,
                message: 'Este campo es obligatorio'
              }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label='Nueva'
              name='newPassword'
              rules={[
                {
                  required: true,
                  message: 'Este campo es obligatorio'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Las contraseñas nuevas deben coincidir'))
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label='Confirmar nueva'
              name='confirmedPassword'
              rules={[
                {
                  required: true,
                  message: 'Este campo es obligatorio'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Las contraseñas nuevas deben coincidir'))
                  }
                })
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}
        <Form.Item className='actions actions-one ant-form-item--last'>
          {!editPass && (
            <Button
              className='outline c-dr'
              onClick={() => setEditPass(true)}
              disabled={editData}
            >
              <em className='fal fa-pencil' />
              <span className='text'>
                Editar
              </span>
            </Button>
          )}
          {editPass && (
            <>
              <Button
                className='outline c-dr'
                disabled={loading}
                onClick={() => setEditPass(false)}
              >
                <span className='text'>
                  Cancelar
                </span>
              </Button>
              <Button
                className='solid c-2'
                htmlType="submit"
                loading={loading}
              >
                <em className='fal fa-check' />
                <span className='text'>
                  Cambiar
                </span>
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  )
}
