// @vendors
import { useState } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Button } from 'antd'
import cx from 'classnames'

// @utils
import { useWindowDimensions } from 'utils/DOM-functions'

// @static
import video from 'assets/videos/colombia-noomada.mp4'

// @styles
import './styles.scss'

const handleTogglePlay = (isPlay, setIsPlay) => {
  setIsPlay(!isPlay)
}

const Banner = ({ backgroundImage }) => {
  const [isPlay, setIsPlay] = useState(true)
  const { width } = useWindowDimensions()

  return (
    <section className='banner__home'>
      {width > 860 && (
        <div className='banner__home-video'>
          <ReactPlayer
            width='100%'
            height='100%'
            url={video}
            className='react-player'
            playing={isPlay}
            controls={false}
            loop
          />
        </div>
      )}

      <div className='banner__home-caption'>
        <Button
          className='ant-btn-only--icon'
          onClick={() => handleTogglePlay(isPlay, setIsPlay)}
        >
          <em
            className={
              cx(
                'fas',
                { 'fa-play': !isPlay },
                { 'fa-pause': isPlay }
              )
            }
          />
        </Button>
        <h1 className='title__ppal md c-wh'>
          Cientos de lugares por conocer, miles de experiencias por vivir.
        </h1>
      </div>
    </section>
  )
}

Banner.propTypes = {
  backgroundImage: PropTypes.string.isRequired
}

export default Banner
