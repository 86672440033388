// @vendors
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'

export default function Text({ info }) {
  return (
    <div className='text__enr'>
      <RichText
        render={info}
      />
    </div>
  )
}

Text.propTypes = {
  info: PropTypes.any
}
