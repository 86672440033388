// @vendors
import { useCallback, useEffect, useMemo, useState } from 'react'

// @utils
import { notify } from 'utils/notifications'

const extraInfoInitialValue = {
  initialData: null,
  errorMessage: '',
  fetchOnMount: false,
  params: [],
  showErrorMessage: true,
  onSuccess: () => {},
  onError: () => {},
  successMessage: '',
  initLoading: false
}

export default function useFetch(requestFunction, extraInfo = {}) {
  const extra = useMemo(() => ({ ...extraInfoInitialValue, ...extraInfo }), [extraInfo])
  const [loading, setLoading] = useState(() => !!extra.fetchOnMount || !!extra.initLoading)
  const [error, setError] = useState(null)
  const [data, setData] = useState(extra.initialData)
  const {
    fetchOnMount,
    params,
    showErrorMessage,
    onSuccess,
    onError,
    errorMessage,
    successMessage
  } = extra

  const handleFetch = useCallback(async(updatedParams = null, newOnSuccess, newOnError) => {
    setLoading(true)
    const finalParams = updatedParams || params
    const { error, result } = await requestFunction(...finalParams)

    setLoading(false)
    setError(error)
    setData(result)

    const finalOnSuccess = newOnSuccess || onSuccess
    const finalOnError = newOnError || onError

    if (!error) {
      finalOnSuccess(result)
      if (successMessage) {
        notify('success', successMessage, '')
      }
    } else {
      finalOnError(error)
      if (showErrorMessage) {
        notify('error', errorMessage || error, 'Error')
      }
    }
  }, [params])

  useEffect(() => {
    if (fetchOnMount) {
      handleFetch()
    }
  }, [])

  return {
    loading,
    error,
    data,
    setData,
    refetch: handleFetch
  }
}