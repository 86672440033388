// @vendors
import { useState } from "react"
import Compressor from "compressorjs"

export default function useCompressImage({ onFetch, previewRef }) {
  const [image, setImage] = useState('')

  const onPreview = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function() {
      previewRef.current.src = reader.result
    }
  }

  const onCompress = (onSuccess) => {
    // eslint-disable-next-line no-new
    new Compressor(
      image,
      {
        success: onSuccess,
        quality: 0.7,
        resize: 'cover',
        height: 200,
        width: 200
      }
    )
  }

  const onUpload = () => {
    onCompress((compressedImage) => {
      const reader = new FileReader()
      reader.readAsDataURL(compressedImage)
      reader.onload = function() {
        onFetch(reader)
      }
    })
  }

  const onSelect = (event) => {
    const file = event.target.files[0]
    setImage(file)
    onPreview(file)
  }

  return {
    image,
    setImage,
    onUpload,
    onSelect
  }
}