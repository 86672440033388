// @vendors
import { useContext } from 'react'
import { Button, Tabs } from 'antd'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from 'prismic-config'
import ScrollContainer from 'react-indiana-drag-scroll'

// @utils
import { customLink } from 'utils/prismic'
import { sortToDoList } from 'utils/sort-link'
import { onScroll } from 'utils/on-scroll'

// @context
import { DestinationPageContext } from 'context/pages'

// @components
import WhatToDo from '../what-to-do'

// @styles
import './styles.scss'

const { TabPane } = Tabs

const slices = {
  tarifas: ({ zone, reservationInfo }) => (
    <div className='ant-tabs-tabpane-container'>
      {(zone.primary.pric_adults[0]?.text ||
        zone.primary.pric_children[0]?.text) && (
          <ul className="rates__list">
            {zone.primary.pric_adults[0]?.text && (
              <li>
                <em className="fal fa-suitcase" />
                <span>
                  Precio Adultos
                  <strong>$ {RichText.asText(zone.primary.pric_adults)}<sup>aprox</sup></strong>
                </span>
              </li>
            )}
            {zone.primary.pric_children[0]?.text && (
              <li>
                <em className="fal fa-backpack" />
                <span>
                  Precio Infantil
                  <strong>$ {RichText.asText(zone.primary.pric_children)}<sup>aprox</sup></strong>
                </span>
              </li>
            )}
          </ul>
        )}

      <div className="rates__restrictions">
        {zone.primary.pric_restrictions[0]?.text && (
          <span>{RichText.asText(zone.primary.pric_restrictions)}</span>
        )}
        {(zone.primary.pric_adults[0]?.text ||
          zone.primary.pric_children[0]?.text) && (
            <span>Los precios es un aproximado y están en la moneda local Colombiana (COP).</span>
          )}
      </div>

      {reservationInfo.type === 'Reserva' &&
        reservationInfo.link.url.length > 0 && (
          <a href={reservationInfo.link.url} rel="noopener noreferrer" className="btn solid c-1 rates__reservation" target="_blank">
            <em className="fal fa-external-link" /> Reservar
          </a>
        )}
    </div>
  ),
  servicios: ({ zone }) => (
    <div className='ant-tabs-tabpane-container'>
      <ul className="list__services">
        {zone.items.map((item, k) => (
          <li key={k}>
            <em className={`fal fa-${RichText.asText(item.serv_icon)}`} />
            <span>
              {RichText.asText(item.serv_name)}
            </span>
          </li>
        ))}
      </ul>
      <div className="service__restrictions">
        <span>
          Algunos servicios pueden tener un costo extra.
        </span>
      </div>
    </div>
  ),
  como_llegar: ({ zone }) => (
    <div className='ant-tabs-tabpane-container'>
      <div className="how__to-get">
        {(zone.primary.info)[0]?.text &&
          <div className={`text__enr ${zone.primary.waze_link.length && zone.primary.waze_link[0].text ? 'margin-b' : ''}`}>
            <RichText
              render={zone.primary.info}
              linkResolver={linkResolver}
              serializeHyperlink={customLink}
            />
          </div>
        }
        {zone.primary.waze_link.length &&
          zone.primary.waze_link[0].text
          ? <Button
            onClick={() => window.open(RichText.asText(zone.primary.waze_link))}
            className="ant-btn solid c-waze"
          >
            <em className="fab fa-waze" /> <span className="text">Waze</span>
          </Button>
          : ''}
        {zone.primary.google_maps_link.length &&
          zone.primary.google_maps_link[0].text
          ? <Button
            onClick={() => window.open(RichText.asText(zone.primary.google_maps_link))}
            className="ant-btn solid c-google"
            rel="noopener noreferrer"
            target="_blank"
          >
            <em className="fab fa-google" /> <span className="text">Maps</span>
          </Button>
          : ''}
      </div>
    </div>
  ),
  horarios: ({ zone }) => (
    <div className='ant-tabs-tabpane-container'>
      <div className="schedules">
        {zone.items.length && (
          <ul className="schedules__list">
            {zone.items.map((item, index) => (
              <li key={`schedule-${index}`}>
                <em className="fal fa-watch" />
                <span>
                  {item.title[0]?.text}
                  <strong>
                    <RichText render={item.description} />
                  </strong>
                </span>
              </li>
            ))}
          </ul>
        )}
        <div className="schedules__restrictions">
          {zone.primary.restrictions[0]?.text && (
            <span><RichText render={zone.primary.restrictions} /></span>
          )}
          <span>La entidad reguladora puede cambiar los horarios sin previo aviso.</span>
          <span>Los horarios están bajo la zona horaria UTC-5.</span>
        </div>
      </div>
    </div>
  ),
  que_hacer: ({ zone, place, image, depto }) => {
    return (
      <WhatToDo items={sortToDoList(zone.items)} city={place} depto={depto} image={image} />
    )
  },
  flora_fauna: ({ zone }) => (
    <div className="list__items-FaunaFlora">
      <Button
        className='solid c-wh ant-btn-only--icon ant-btn-prev'
        onClick={onScroll(-330, 'FaunaFlora-container')}
      >
        <em className='fal fa-chevron-left' />
      </Button>
      <Button
        className='solid c-wh  ant-btn-only--icon ant-btn-next'
        onClick={onScroll(330, 'FaunaFlora-container')}
      >
        <em className='fal fa-chevron-right' />
      </Button>
      <ScrollContainer className='list__items-FaunaFlora-container'>
        {zone.items.map((item, k) => (
          <div className="item" key={k}>
            <div className="tag">
              {item.type}
            </div>
            <img src={item.imagen.url} alt={item.imagen.alt} />
            <span>
              {RichText.asText(item.name)}
            </span>
          </div>
        ))}
      </ScrollContainer>
    </div>
  ),
  recomendaciones: ({ zone }) => (
    <div className='ant-tabs-tabpane-container'>
      <ul className="list__recommendations">
        {zone.items.map((item, k) => (
          <li key={k}>
            <em className={`fal fa-${RichText.asText(item.icon)}`} />
            <span>
              {RichText.asText(item.name)}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )

}

function switchNavNames(name) {
  switch (name) {
    case 'tarifas':
      return <><em className="fal fa-money-bill-wave" /> <span>Tarifas</span></>
    case 'servicios':
      return <><em className="fal fa-concierge-bell" /> <span>Servicios</span></>
    case 'como_llegar':
      return <><em className="fal fa-map-marker-alt" /> <span>¿Cómo llegar?</span></>
    case 'horarios':
      return <><em className="fal fa-clock" /> <span>Horarios</span></>
    case 'que_hacer':
      return <><em className="fal fa-map-marked-alt" /> <span>¿Qué hacer?</span></>
    case 'flora_fauna':
      return <><em className="fal fa-flower-tulip" /> <span>Flora y Fauna</span></>
    case 'recomendaciones':
      return <><em className="fal fa-thumbs-up" /> <span>Recomendaciones</span></>

    default:
      return null
  }
}

function getInfoByType(type, destination) {
  const muniOptions = ['Ciudad', 'Municipio', 'Pueblo Patrimonio', 'Destino']
  const allOptions = [...muniOptions, 'Destino', 'Parque Nacional', 'Santuario']

  if (!allOptions.includes(type)) {
    return null
  }

  const info = {
    slices: [],
    info: {}
  }

  if (type === 'Destino') {
    info.slices = [
      destination?.body2.find((item) => item.slice_type === 'que_hacer'),
      destination?.body1.find((item) => item.slice_type === 'tarifas'),
      destination?.body1.find((item) => item.slice_type === 'servicios'),
      destination?.body1.find((item) => item.slice_type === 'horarios'),
      destination?.body.find((item) => item.slice_type === 'como_llegar')
    ].filter(value => !!value)

    info.info = {
      ...destination,
      lodging: destination.body.find((item) => item.slice_type === 'hospedajes')
    }
  } else if (type === 'Parque Nacional' || type === 'Santuario') {
    info.slices = [...destination.body]
  } else if (muniOptions.includes(type)) {
    info.slices = [
      destination.body2.find((item) => item.slice_type === 'que_hacer'),
      destination.body.find((item) => item.slice_type === 'como_llegar')
    ].filter(value => !!value)
  }

  return info
}

function UrlParty({ src, children }) {
  if (src === 'Any') {
    return <div className="city__party">{children}</div>
  }

  return (
    <a
      href={src.url}
      className="city__party"
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}

export default function ExtraInfo() {
  const info = useContext(DestinationPageContext)

  const reservationInfo = {
    type: info.state,
    link: info.reservation_link
  }

  return (
    <div className="dest__sidebar-caption-extra">
      {info.party && info.party[0]?.name[0]?.text && (
        <UrlParty src={info.party[0].link.link_type}>
          <div className="city__party-photo">
            <img src={info.party[0].image.url} alt={info.party[0].image.alt} />
          </div>
          <div className="city__party-info">
            <span>Fiesta representativa</span>
            <strong>{RichText.asText(info.party[0].name)}</strong>
            {info.party[0]?.date[0]?.text && (
              <div className='city__party-info-date'>
                {info.party[0]?.date[0]?.text}
              </div>
            )}
          </div>
        </UrlParty>
      )}

      <Tabs defaultActiveKey='0' className='tabs__default'>
        {(getInfoByType(info.dest_type, info)?.slices || []).map((item, k) => (
          <TabPane
            tab={item.slice_type !== 'hospedajes' && switchNavNames(item.slice_type)}
            key={`desintation-tab-${k}`}
          >
            {
              slices[item.slice_type]({
                zone: item,
                reservationInfo,
                place: info.name,
                image: info.thumb[0].image,
                depto: info.depto
              })
            }
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}
