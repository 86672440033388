import { useState, useCallback, useEffect } from 'react'

// @utils
import { client, clientPGS } from 'utils/prismic'

function getClient(clientName) {
  const clients = {
    client,
    clientPGS
  }

  return clients[clientName] || null
}

export default function usePrismicQuerySingle(clientName, customType) {
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState({
    page: null,
    slices: []
  })

  const getInfo = useCallback(async() => {
    try {
      const info = await getClient(clientName).getSingle(customType)

      setInfo({
        page: info.data,
        slices: info.data.body
      })
      setLoading(false)
    } catch (error) {
      console.warn('Error getting about-us info', error)
    }
  }, [])

  useEffect(() => {
    getInfo()
  }, [])

  return { info, loading }
}
