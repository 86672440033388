// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://travelyando.cdn.prismic.io/api/v2'
export const apiEndpointPNN = 'https://travelyando-pnn.cdn.prismic.io/api/v2'
export const apiEndpointPages = 'https://travelyando-pages.cdn.prismic.io/api/v2'
export const apiEndpointProviders = 'https://noomada-providers.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = ''
export const accessTokenPNN = ''
export const accessTokenShop = ''
export const accessTokenPages = ''
export const accessTokenProviders = ''

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'about_us') return '/nosotros'
  if (doc.type === 'contact') return '/contacto'
  if (doc.type === 'shop') return '/tienda'
  if (doc.type === 'departaments') return '/departamentos'
  if (doc.type === 'towns_home') return '/pueblos-patrimonio'
  if (doc.type === 'pnn') return `/pnn/${doc.uid}`
  if (doc.type === 'product') return `/tienda/${doc.uid}`
  if (doc.type === 'blog_home') return '/blog'
  if (doc.type === 'blog') return `/blog/${doc.uid}`
  if (doc.type === 'destination') return `/${doc.tags[0]}/${doc.uid}`
  if (doc.type === 'departament') return `/${doc.uid}`
  return '/'
}
