export default function Survivor() {
    return (
        <svg
            id="prefix__Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 500 500"
            xmlSpace="preserve"
        >
            <style>
                {
                    '.prefix__st0{fill:#fff;stroke:#adadad}.prefix__st0,.prefix__st2,.prefix__st3{stroke-linecap:round;stroke-linejoin:round}.prefix__st2{fill:none;stroke:#263238}.prefix__st3{fill:#c2c2c2}.prefix__st3,.prefix__st4,.prefix__st5{stroke:#263238}.prefix__st4{fill:#fff;stroke-linecap:round;stroke-linejoin:round}.prefix__st5{fill:none;stroke-miterlimit:10}.prefix__st6,.prefix__st7{fill:#263238;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st7{fill:none;stroke:#b5b5b5}.prefix__st9{fill:#263238}.prefix__st10{fill:#858585;stroke:#263238;stroke-linecap:round;stroke-linejoin:round}.prefix__st11{fill:#adadad}'
                }
            </style>
            <g id="prefix__freepik--Clouds--inject-7">
                <path
                    className="prefix__st0"
                    d="M324.3 69.6s-3.4-17.5-18.6-23.7-24.9 1.7-27.1 7.3-2.8 15.3-11.3 11.3-11.9-8.5-22.6-4-7.4 14.1-15.3 15.8-5.1-2.8-13.6-1.7-7.9 6.2-10.2 9-2.3.6-10.2 1.7c-4.9.7-9.4 2.9-13 6.2h174.7s4.5-9.1-7.4-15.8-22 .7-25.4-6.1zM107.7 158.5s-2.2-11.4-12.1-15.4-16.1 1.1-17.6 4.8-1.8 9.9-7.3 7.3-7.7-5.5-14.7-2.6-4.8 9.2-9.9 10.2-3.3-1.8-8.8-1.1-5.1 4-6.6 5.9-1.5.4-6.6 1.1c-3.1.5-6.1 1.9-8.4 4h113.2s2.9-5.9-4.8-10.2-14.2.4-16.4-4zM246.3 304.3s-2.2-11.4-12.1-15.4-16.1 1.1-17.6 4.8-1.8 9.9-7.3 7.3-7.7-5.5-14.6-2.6-4.8 9.2-9.9 10.3-3.3-1.8-8.8-1.1-5.1 4-6.6 5.9-1.5.4-6.6 1.1c-3.1.5-6.1 1.9-8.4 4h113.2s2.9-5.9-4.8-10.3-14.3.4-16.5-4zM436.8 202.3s-1.3-6.5-6.9-8.8-9.2.6-10.1 2.7-1 5.7-4.2 4.2-4.4-3.1-8.4-1.5-2.7 5.2-5.7 5.9-1.9-1-5-.6-2.9 2.3-3.8 3.4-.9.2-3.8.6c-1.8.3-3.5 1.1-4.8 2.3H449s1.7-3.4-2.7-5.9-8.2.2-9.5-2.3z"
                />
            </g>
            <g id="prefix__freepik--Sea--inject-7">
                <path
                    d="M466.6 360.6s-6.5 10.3-43.9 7.1-62.7-16.6-92.3-19.3-55.4 3.2-81.2.6-32.2-5.8-51-5.8-52.2 11.6-52.2 11.6 36.8 4.5 29.7 5.2-34.2.6-61.3 1.3-36.8-1.3-49 .6S28 371 28 371s4.5 12.3 31 11.6 28.4-4.5 40.6-7.7 29.5-2.7 29.5-2.7-36.6 20.8-55.3 21.4-21.9 1.3-21.9 1.3-.7 11.6 13.5 16.8 27.7 16.1 52.9 12.2 48.4 3.2 63.2 3.9 21.3 1.3 30.3-1.3 11.6 0-9 2.6-26.6 8-39.3 4.5-23.9-3.9-33.5-1.9-23.9 7.1-23.9 7.1 8.4 8.4 40 11.6 32.9 9 59.3-.6 19.3-12.9 52.2-10.3 73.5 16.8 101.9 17.4 47.7-14.2 47.7-14.2-21.3 4.5-62-2.6l-40.6-7.1s41.9-9 58-9.7 44.5-2.6 54.8-5.8S440 402 440 402s-16.1 3.2-31.6 0-35.5-8.4-35.5-8.4 31-5.2 45.1-6.5 57 3.2 48.6-26.5z"
                    fill="#b8c3cf"
                />
                <path
                    className="prefix__st2"
                    d="M187.7 373.5s-39.8-10.1-63.3-4.7-31 13.5-49.9 14.8-33.7-2-39.1-1.4M466 354.6s-28.6 8.5-71.4 1.4-69.4-13.5-95-11.5-48.1 5.9-67.5 2.3-41-10.4-63.9-3.6-20.9 8.1-55.9 10.8-40.4-2.7-59.3.7-24.4 7.3-24.4 7.3M350.1 431.4s18.8-2.5 22.9-3.6 24.3-3.8 33.7-2.5 33 2 41.1 0M329.9 422.6s-19.5 4-46.5.7-41.1-2.7-54.6 3.4-15.5 7.4-37.7 6.1-23.6-7.4-37.7-2M463.3 386.2s-20.2-2-35 3.4-34 1.4-34 1.4"
                />
            </g>
            <g id="prefix__freepik--Boat--inject-7">
                <path
                    className="prefix__st3"
                    d="M303.5 88.2l-.8 282.4s.8 1.9 6.1.8l2.7-283.1c0-.1-4.8-2.7-8-.1z"
                />
                <path
                    className="prefix__st3"
                    d="M397.1 87.3l-178.2 23.3s-2.1.9-1.5 4.3l1.3 2.5 178.9-22.6s3.4.3 3.5-4-4-3.5-4-3.5z"
                />
                <path
                    className="prefix__st4"
                    d="M234.6 107.9s-4.9-.8-6 0-1.9 4-2.1 6.7.9 3.4 2 3.6 1.9-2.7 4.1-5.2 4.4-4.4 2-5.1z"
                />
                <path
                    className="prefix__st4"
                    d="M233.5 108.7s-1.9 1.3-6.1 9.4-12.6 12.6-26.8 47.2-14.9 73-7.8 99.1 46.2 54.9 61 65.2 47.2 23.2 51.5 22 3.7-.3 5-3.5 36.2-77.8 42-102.7 6.1-79.4 14.5-111.7 19.7-44.9 19.7-44.9-19 1.3-23.9 2.6-15.5 12-34.2 21.3-28.5 14.2-48.5 6.8-32.6-12.3-32.6-12.3-8.3-.5-13.8 1.5z"
                />
                <path
                    className="prefix__st2"
                    d="M334.5 121.3c5.4-6.2 8.8-11 9.5-11.7M252.6 112.9s15.2 6.8 23.2 18.7 22.3 19.7 38.8 8.1c6.3-4.5 12-9.7 17.2-15.4M366.2 93.5s-11.3 10.3-21.6 21M238.7 108.4s10 6.1 15.2 16.5M376.9 93.5s-11.3 12.9-14.5 29.4M362 127.4l-1.3 9"
                />
                <path
                    className="prefix__st4"
                    d="M384.4 88.4c-2.1.7-3.5 2.7-3.5 4.9 0 3.8 1 5.9 3.5 6.2s6.2-3.8 6.2-3.8l-4.5.7s-1.5-6.5 2.5-8.1c0 0-.8-1.3-4.2.1z"
                />
                <path
                    className="prefix__st3"
                    d="M101.2 380.7s14.9-2.3 23.6-4.7 43.8-8.1 46.8-8.7 40.9-9.1 42.8-9.5 4.3.9 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-15.1 3.8-27.4 8.9s-44.7 8.9-56 12.7S109 406 109 406l-7.8-25.3z"
                />
                <path
                    className="prefix__st4"
                    d="M115.2 397.3c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.8-13.9 12.8-13.9s13.9 9.5 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M100.1 385.4s-5.5 3.2-6.2 7.4.4 7.2 3.2 8.1 8.9 2.6 11-1.7-4.2-15.1-8-13.8z"
                />
                <path
                    className="prefix__st5"
                    d="M103.8 393s-2.6-3.1-4.5-2.4-4.4 3.7-2.8 5 4.3 2.8 5.8 1.6 3.4-2.8 1.5-4.2z"
                />
                <path
                    className="prefix__st4"
                    d="M114.1 391.2s4.3-1.7 8.1-3 12.8-3.4 12.8-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M123.3 390.9l18.3-5.1M217.3 367.8l-12.5 4.2M219.9 364.2l-8.5 2.7M110.5 382.9l6-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M206.8 375s1.3-7.8-1.2-10.3-7.8-3.5-7.8-3.5 0-1.1 3.1-1.5 7.3 2.1 8.2 5.6.9 8 .2 9.5-2.2 1.3-2.5.2zM139.4 394.6s1.7-11.1-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1c4-.6 9.5 3.1 10.7 8s1.2 11.4.3 13.5-3.1 1.7-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M121.5 383.3s14.9-2.3 23.6-4.7 59.2-9.4 62.2-10 30.8-8.9 32.7-9.3 4.3 1 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-15.1 3.8-27.4 8.9s-49.9 10-61.3 13.7-27.4 11.5-27.4 11.5l-7.6-25.2z"
                />
                <path
                    className="prefix__st4"
                    d="M135.4 399.9c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.8-13.9 12.8-13.9 6.9 0 13.9 9.6 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M120.3 388s-5.5 3.2-6.2 7.4.4 7.2 3.2 8.1 8.9 2.6 11-1.7c2.1-4.4-4.2-15.1-8-13.8z"
                />
                <path
                    className="prefix__st5"
                    d="M124.1 395.7s-2.6-3.1-4.5-2.4-4.4 3.7-2.8 5 4.3 2.7 5.8 1.6 3.3-2.9 1.5-4.2z"
                />
                <path
                    className="prefix__st4"
                    d="M134.3 393.9s4.3-1.7 8.1-3 12.8-3.4 12.8-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M143.6 393.5l18.3-5.1M242.8 369.4l-12.5 4.1M245.4 365.8l-8.5 2.6M130.7 385.6l6.1-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M234 378.5s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3.1-1.6 7.3 2.3 8.2 6 .9 8.6.2 10.2-2.3 1.4-2.5.3zM159.3 397.6s1.7-11.1-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.8-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M149.3 385.5s14.9-2.3 23.6-4.7 68.6-10.4 71.6-10.9 30.8-8.9 32.7-9.2 4.3.9 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-39.9 6-52.2 11.1s-34.6 8.7-45.9 12.5-27.3 11.4-27.3 11.4l-7.7-25.3z"
                />
                <path
                    className="prefix__st4"
                    d="M163.3 402.1c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.9-13.9 12.8-13.9s13.9 9.5 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M147.4 409s7.4-1.5 9.6-5.8 2-7.9-.9-10-9.3-6-13.1-2.1-.4 18 4.4 17.9z"
                />
                <path
                    className="prefix__st5"
                    d="M150.3 403.6s4.4-1.8 4.1-4.1-2.9-6.1-4.9-4.7-4.5 3.8-3.7 5.8 2.3 4.6 4.5 3z"
                />
                <path
                    className="prefix__st4"
                    d="M162.1 396.1s4.3-1.7 8.1-3 12.8-3.4 12.8-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M171.4 395.7l18.3-5.1M280 370.6l-12.4 4.2M282.7 367l-8.5 2.7M158.6 387.8l6-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M268.2 379.7s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3.1-1.6 7.3 2.3 8.2 6 .9 8.6.2 10.2-2.3 1.5-2.5.3zM183.9 400.7s1.7-11-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.7-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M176.6 388.4s14.9-2.3 23.6-4.7 59.8-12.5 62.8-13.1 30.8-8.9 32.7-9.2 4.4.9 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-15.1 3.8-27.4 8.9s-50.5 13.1-61.9 16.9-27.4 11.5-27.4 11.5l-7.6-25.4z"
                />
                <path
                    className="prefix__st4"
                    d="M190.5 405c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.9-13.9 12.8-13.9s13.9 9.5 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M175.4 393.1s-5.5 3.2-6.2 7.4.4 7.2 3.2 8.1 8.9 2.6 11-1.7-4.2-15.1-8-13.8z"
                />
                <path
                    className="prefix__st5"
                    d="M174.5 398.4s-3 2.6-2.3 4.5 3.8 4.3 5.1 2.6 2.6-4.3 1.5-5.9-3-3.1-4.3-1.2z"
                />
                <path
                    className="prefix__st4"
                    d="M189.4 399s4.3-1.7 8.1-3 12.8-3.4 12.8-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M198.6 398.6l18.4-5.1M298.5 371.3l-12.5 4.2M301.1 367.7l-8.5 2.7M185.8 390.6l6-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M290.3 380s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3-1.6 7.3 2.3 8.2 6 1 8.6.2 10.2-2.2 1.5-2.4.3zM210.6 402.9s1.7-11-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.7-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M203.9 390.4s14.9-2.3 23.6-4.7 43.8-8.1 46.8-8.7 46.3-12.4 48.2-12.8 4.3 1 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-15.1 3.8-27.4 8.9s-50.1 12.2-61.4 16-27.4 11.5-27.4 11.5l-7.6-25.3z"
                />
                <path
                    className="prefix__st4"
                    d="M217.9 407c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.9-13.9 12.8-13.9s13.9 9.5 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M202 413.8s7.4-1.5 9.6-5.8 2-7.9-.9-10-9.3-6-13.1-2.1-.4 18 4.4 17.9z"
                />
                <path
                    className="prefix__st5"
                    d="M204.9 408.4s4.4-1.8 4.1-4.1-2.9-6.1-5-4.7-4.5 3.8-3.7 5.8 2.4 4.6 4.6 3z"
                />
                <path
                    className="prefix__st4"
                    d="M216.8 400.9s4.4-1.7 8.1-3 12.9-3.4 12.9-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M226 400.5l18.3-5M325.4 374.2l-12.5 4.2M328 370.6l-8.5 2.6M213.2 392.6l6-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M317.2 382.6s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3.1-1.6 7.3 2.3 8.2 6 .9 8.6.2 10.2-2.3 1.4-2.5.3zM234.9 405.7s1.7-11-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.8-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M231.8 392.4s14.9-2.3 23.6-4.7 43.8-8.1 46.8-8.7 44-11.8 45.9-12.1 4.3 1 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-28.3 6.6-40.6 11.8s-34.6 8.7-45.9 12.5-27.4 11.5-27.4 11.5l-7.6-25.4z"
                />
                <path
                    className="prefix__st4"
                    d="M245.8 409c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.6-12.8 0-7.1 5.9-13.9 12.8-13.9s14 9.6 14 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M229.9 415.9s7.4-1.5 9.6-5.8 2-7.9-.9-10-9.3-6-13.1-2.1-.4 18 4.4 17.9z"
                />
                <path
                    className="prefix__st5"
                    d="M232.8 410.5s4.4-1.8 4.1-4.1-2.9-6.1-5-4.7-4.5 3.8-3.7 5.8 2.4 4.6 4.6 3z"
                />
                <path
                    className="prefix__st4"
                    d="M244.6 403s4.4-1.7 8.1-3 12.8-3.4 12.8-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M253.9 402.6l18.3-5.1M350.9 376.9l-12.4 4.2M353.6 373.3l-8.5 2.7M241.1 394.7l6-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M344.7 384.5s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3-1.6 7.3 2.3 8.2 6 .9 8.6.2 10.2-2.1 1.4-2.4.3zM262.2 408.2s1.7-11.1-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.7-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M260.4 394.9s14.9-2.3 23.6-4.7 43.8-8.1 46.8-8.7 45.2-12.3 47-12.6 4.4.9 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-29.5 7.2-41.8 12.3s-34.6 8.7-45.9 12.5-27.4 11.5-27.4 11.5l-7.5-25.4z"
                />
                <path
                    className="prefix__st4"
                    d="M274.4 411.5c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.9-13.9 12.8-13.9 6.8 0 13.9 9.5 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M259.3 399.6s-5.5 3.2-6.3 7.4.4 7.2 3.2 8.1 8.9 2.6 11-1.7-4.2-15.1-7.9-13.8z"
                />
                <path
                    className="prefix__st5"
                    d="M258.3 404.9s-3 2.6-2.3 4.5 3.8 4.3 5.1 2.6 2.6-4.3 1.5-5.9-3-3.1-4.3-1.2z"
                />
                <path
                    className="prefix__st4"
                    d="M273.2 405.4s4.4-1.7 8.1-3 12.9-3.4 12.9-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M282.5 405.1l18.3-5.1M380.7 378.8l-12.5 4.2M383.4 375.2l-8.5 2.7M269.6 397.1l6.1-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M373.5 387s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3-1.6 7.3 2.3 8.2 6 .9 8.6.2 10.2-2.2 1.5-2.4.3zM285.8 412.2s1.7-11.1-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.7-3.4.2z"
                />
                <path
                    className="prefix__st3"
                    d="M287.8 396.9s14.9-2.3 23.6-4.7 43.8-8.1 46.8-8.7 40.4-10.6 42.3-11 4.3.9 6 3.8 1.5 6.2 1.3 8.1c-.2 1.3-.9 2.5-2.1 3.2-.6.4-24.7 5.5-37 10.6s-34.5 8.7-45.9 12.5-27.4 11.5-27.4 11.5l-7.6-25.3z"
                />
                <path
                    className="prefix__st4"
                    d="M301.8 413.6c0 7.1-7.3 10.1-14.2 10.1-7-.1-12.6-5.8-12.5-12.8 0-7.1 5.9-13.9 12.8-13.9s13.9 9.5 13.9 16.6z"
                />
                <path
                    className="prefix__st5"
                    d="M286.6 401.7s-5.5 3.2-6.2 7.4.4 7.2 3.2 8.1 8.9 2.6 11-1.7-4.2-15.2-8-13.8z"
                />
                <path
                    className="prefix__st5"
                    d="M285.7 407s-3 2.6-2.3 4.5 3.8 4.3 5.1 2.6 2.6-4.3 1.5-5.9-3-3.1-4.3-1.2z"
                />
                <path
                    className="prefix__st4"
                    d="M300.6 407.5s4.3-1.7 8.1-3 12.8-3.4 12.8-3.4l14.2-3.4"
                />
                <path
                    className="prefix__st2"
                    d="M309.9 407.1l18.3-5.1M403.3 382.5l-12.4 4.2M406 378.9l-8.5 2.7M297 399.2l6.1-1.7"
                />
                <path
                    className="prefix__st6"
                    d="M313.1 414s1.7-11.1-1.5-14.7-10.1-4.9-10.1-4.9 0-1.5 4-2.1 9.5 3.1 10.7 8 1.2 11.4.3 13.5-3.1 1.8-3.4.2zM394.2 391s1.3-8.4-1.2-11.2-7.8-3.7-7.8-3.7 0-1.2 3-1.6 7.3 2.3 8.2 6 .9 8.6.2 10.2-2.1 1.5-2.4.3z"
                />
            </g>
            <g id="prefix__freepik--Character--inject-7">
                <path
                    className="prefix__st4"
                    d="M346.2 284.6l11.2 14.3s-1.5 3.3-4.4 3.9-8.8-2.2-10.5-7.3c-1.1-4.1.3-8.4 3.7-10.9z"
                />
                <path
                    className="prefix__st6"
                    d="M251.6 293.4c-.1 2-.4 3.9-.8 5.9-.5 1.5-1.3 3.6-1.3 3.6s1-.5 2 .8 2.1-3.3 3.8-2.8c1.7.5 4.8-.5 4.8-.5l1-11.5-9.5 4.5z"
                />
                <path
                    className="prefix__st4"
                    d="M256.8 277.6l-8.4 4s-4.2 8.8-5.5 11.2c-1 1.7-2.1 3.4-3.3 4.9 0 0 2 1.1 8.2-.2 4.7-1.1 9.3-2.7 13.7-4.6 0 .1.8-12.1-4.7-15.3z"
                />
                <path
                    className="prefix__st6"
                    d="M270.3 349.9l-8.4 5.1s-4 .9-20.5-14.7-18.3-1.1-18.1 6.4 40.1 35.2 49.7 38.1 37.2-5.5 48-8.4 12.5-12.5 11.2-14.5 7.7-2.8-4.6-2-25.3 2.8-39.2-3.5-18.1-6.5-18.1-6.5z"
                />
                <path
                    className="prefix__st7"
                    d="M271.9 366.3l3.6 4.3M264.6 357.4l5.1 6.2"
                />
                <path
                    d="M302 265.7s-16.7-1.8-18.5-1.8-7.3 2.8-8.6 3.3-19.4 11-19.4 11 2.2 1.3 2.4 7c.1 5.4-.1 10.8-.7 16.1-.4 2.2-1.3 8.6-1.3 8.6s4-3.3 5.1-3.7.7-1.8 3.3-3.1 2.8-.5 2.4-3.1c-.2-1.6.4-3.2 1.6-4.2.7.3 1.5.2 2.2-.2.9-.7 7.7-2.9 7.7-2.9s-2 22-1.3 29.5-2 12.6-2.9 16.1l-.9 3.5c-1.9-.3-3.7 1-4 2.9-.9 3.5-1.5 6.8-1.5 6.8s12.5 11.4 24.9 12.8 34.5-.7 36.8-2.2 1.6-8.5 1.6-10.5-.4-13.6-.4-14.7 6.8-22.7 6.8-22.7l4.8 11s22.4-11 22.9-11.4c.6-.3 1.2-.6 1.8-.7 0 0-3.1-9-4.4-10.8s-5.1-5.1-5.5-6.6c-.2-.9-.5-1.9-.9-2.8-.4 1.3-1.1 2.5-1.8 3.7-1.1 1.5-1.3 1.8-2.2 2-1 .1-2 .1-2.9 0l-4-2.9-.4-3.7.5-2c.1-1 .5-1.9 1.3-2.6l1.1-.7 1.1-2-1.1-3.7c-2.4-.3-4.8-.8-7.1-1.6-2.6-1.1-5.3-2-8.1-2.8 0 0-6.4-3.3-7.5-3.9s-22.9-7-22.9-7z"
                    fill="#92e3a9"
                    stroke="#263238"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    className="prefix__st2"
                    d="M277.9 275.6l.3 17.1-4.6-15.1M296.3 289.7c.9 1.4 1.9 2.7 3 3.9M292.7 268.9c-.9 5.8-.2 11.7 1.8 17.2M333.8 281.9s-6 3.7-8.2 4.6M299.4 348.6c.8 1.1 1.6 2.1 2.2 2.9M283.5 321.6s7.5 14.2 13.5 23.6M287.6 336.2l6.9 10.2"
                />
                <path
                    className="prefix__st4"
                    d="M332 276.5c-1.5-.8-6.1-3.2-7.1-3.6-1.1-.5-22.9-7.1-22.9-7.1l-4-.4c-1.5 4.8-3.4 16 8.2 21.3 9.8 4.4 19.9-3.8 25.8-10.2z"
                />
                <path
                    className="prefix__st4"
                    d="M301.1 276.6l.4-16.9 25.6 6.2-1.4 12.1"
                />
                <path
                    className="prefix__st4"
                    d="M300.4 248.1s-.5 15.8-.4 19.2 4.8 11.2 6.8 12.1 6.4 1.8 7.7 1.3 9.7-5.7 11.7-8.1c1.5-2 2.7-4.2 3.5-6.6 0 0 3.9.7 5-6.4s-.2-15-5-18.7-8.1-9.5-21.3-4.2-8 11.4-8 11.4z"
                />
                <path
                    className="prefix__st2"
                    d="M310.1 261.7s-1.1 1.6-.4 2.4 4 1.5 5.1.9c.7-.4 1.2-1.1 1.5-1.8M309.9 269.2l3.3.6"
                />
                <path
                    className="prefix__st9"
                    d="M309.6 255.2c0 .9-.4 1.6-.9 1.6s-.9-.7-.9-1.6.4-1.6.9-1.6.9.7.9 1.6zM320.2 257.5c0 .9-.4 1.5-.9 1.5s-.9-.7-.9-1.5.4-1.6.9-1.6.9.7.9 1.6z"
                />
                <path
                    className="prefix__st2"
                    d="M305.9 249.4s4 .4 5.7 2.9M317.8 253.6s4.4-1.3 6.4 1.1"
                />
                <path
                    className="prefix__st6"
                    d="M320.9 223.8s-7.7-4.8-14.7-1.8-8.8 7.9-9.5 8.2-6.6.4-6.6.4l2.6 2.8 1.8.9s-4.8 2.9-5.3 4.2c-.4 1.7-.7 3.4-.7 5.1l3.1-1.5-.4 2.9 2.4-1.3c.1 1.5.5 3 1.3 4.3 1.3 2 5 3.7 5 3.7.7-1.8 1.6-3.5 2.8-5.1.8-.9 1.4-1.9 1.8-2.9l1.1 4.6s.2-.7 2.4-3.7c1.4-1.9 3-3.7 4.8-5.3l5 6.8 1.3-2.2 1.5 4.8 7-3.5s.5 6.4 1.5 7.7c.6.9.9 2 .9 3.1l-.2 2.2s2-6.2 3.1-3.3c1.1 2.9.7 5.1-.4 6.6-1.4 1.6-3 3-4.6 4.4-.7.7-1.5 6.2-1.5 7s1.6 1.1 4.6-.4c1.9-1.1 3.5-2.7 4.6-4.6l1.8 2.4c1.1-1 2-2.1 2.6-3.5 1.1-2.4 2.2-10.3 2.2-10.3l.9 2.6s4.2-2.6 3.3-9.9-3.5-13.4-5.5-14.7-3.9-2.6-3.9-2.6l1.6-1.1s-2.8-4-4.4-4.2-4.8-.2-4.8-.2l-2.6-3.1-3.1 2.4-2.8-1.9z"
                />
                <path
                    className="prefix__st10"
                    d="M358.6 382s14.3 4.9 16.6 6.9 24.6 36.3 24.6 36.3l-26.8 2.6s-20.5-31.2-21-35.1 1-11 1-11l5.6.3z"
                />
                <path
                    className="prefix__st4"
                    d="M282.3 380.6s-13.9-3.5-21.8-11.9-19.8-31.5-25.5-37.2-16.5-6.6-21.8 2.6 9.2 27.5 16.7 33.2 37.9 18.3 46.6 22.9 19.1 10.5 23.5 10.3 14.3-2 16.1-7.5.7-5.5 0-7.3 2-.9-6.6-1.8-6.6 1.3-16.9-.9l-10.3-2.4z"
                />
                <path
                    className="prefix__st6"
                    d="M245.8 348.2c-2.2-.8-4.4-1.5-6.6-2-6.4-1.8-7-.4-7.1.5-.1.9-.2 1.8-.2 2.8 0 0-1.6-1.6-2.6-.4-.6.9-.9 1.9-.9 2.9-.9-.5-2-.5-2.9-.2-1.8.5-2.6.7-2.9 2s-.6 1.5-2.2 3.3c-1.8 1.8-3.8 3.4-5.9 5 1.3-1.7 1-4.2-.7-5.5-.2-.1-.4-.3-.6-.4-3.9-2.4-6-2.4-6-2.4s5.7-3.5 5.9-5.3-2.4-5.9-2.4-9.9 4.6-9.3 8.6-11.2 7.3-1.3 11.2.4 6.2 4.8 7.9 7.5 7.4 12.9 7.4 12.9z"
                />
                <path
                    className="prefix__st2"
                    d="M311 389.3s2.7 2.2 5.6 1.7M309.4 394c1.7.9 3.7 1.3 5.6 1.2M306.3 396.6s1.3 1.5 4.5 1.2M301.4 397.5s.3.9 3.4 1.9"
                />
                <path
                    className="prefix__st4"
                    d="M325.9 383.5c5.3-1.4 10.4-3.7 15-6.8 3.8-2.6 7.4-5.9 8.7-9.5 3.1-8.6-7-18.1-11.7-18.1s-8.4.9-15.4 4.6-21.8 12.5-34.3 17.6-26.4 9-31.9 8.2-20.5-4.8-25.5-7.1-6.2.7-5.7 2.6c.2 1 .9 1.8 1.8 2.2.1 1.3.5 2.5 1.1 3.7.7 1.6 1.6 3.1 2.6 4.6 1.7 2.3 3.9 4.2 6.4 5.5 3.3 1.6 6.2 3.5 14.8 3.5 8.6 0 19.4-5.1 26.4-6s23.1-.7 33.4-1.6c8.5-1 8.4-1.9 14.3-3.4z"
                />
                <path
                    className="prefix__st2"
                    d="M226.9 377.2c1.4-.7 3.1-.5 4.2.5M228 380.8s1.1-1.1 3.3 0M230.6 385.4c.6-1 1.8-1.5 2.9-1.3M233.1 388c.3-1.1 1.4-1.7 2.5-1.4.1 0 .2.1.3.1"
                />
                <path
                    className="prefix__st6"
                    d="M314.3 359c1.1-.2 2.3.1 3.1.9 1.1 1.3 0 3.7-.4 4.4s2.9.4 2 2-2 3.5-2 3.5 5-3.7 7.3-1.1-5.1 10.8-5.1 10.8 2.4.7 2.9 1.8c.3.7.5 1.4.5 2.2 0 0 12.8-1.1 16.9-4.2s7.7-4.4 9.9-12.1.5-11.7-3.9-15.8-7.1-3.7-12.6-2.4c-5.3 1.3-10.5 2.9-15.6 4.8-2.7 1.2-3.4 3.2-5.4 3.7s-4.4.9-4.4.9l6.8.6z"
                />
                <path
                    className="prefix__st10"
                    d="M361.1 393.2c-.9 0-1.7-.4-2.1-1.2L247.4 213c-.7-1.2-.4-2.7.8-3.4 1.2-.7 2.7-.4 3.4.8l111.6 179c.7 1.2.4 2.7-.8 3.5-.4.2-.9.3-1.3.3z"
                />
                <path
                    className="prefix__st4"
                    d="M320.4 336.7s-4.8 4-4.6 4.8 1.6 4.4 3.3 5 2.4-3.5 2.2-5.5c-.2-1.6-.5-3-.9-4.3z"
                />
                <path
                    className="prefix__st4"
                    d="M367.1 311.5s-7.5-1.1-11.5 2.4-21.1 21.3-21.1 21.3-10.3-3.3-11.5-2.4-5 7.1-4.6 7.7 5.7 7.5 8.8 8.8 5.3.4 5.9-.2 5.3-.5 6.4-2 3.1-3.7 3.1-3.7 28-18.3 29.5-20 1.3-3.5-.5-6.8c-1.2-1.9-2.7-3.7-4.5-5.1z"
                />
                <path
                    className="prefix__st2"
                    d="M325.9 335.4l-5.5 6.8M329 338.9l-6.6 6.6M325.9 348l6.6-6.6"
                />
                <path
                    className="prefix__st4"
                    d="M266.7 232s2.8-1.6 3.9-1.1 2.9 3.3 2.9 4.2-3.5 2.8-5.1 1.8-1.7-4.9-1.7-4.9z"
                />
                <path
                    className="prefix__st4"
                    d="M263.6 228.4s-7.1 6-7.1 7.1.7 12.3.7 12.3-20.9 28-24 32.6-3.5 8.2-.2 13.4 7 4.4 10.6 3.7 8.8-16.9 13.2-27.5c4.4-10.6 6.6-16.3 6.6-16.3s3.5-2.6 4-4.2c.4-1.5.5-3 .4-4.6 0-.9 1.6-1.6 2.2-1.8s2-.2 2.2-1.3c0-1.1-.3-2.1-.7-3.1 0 0-2.4-7.3-4-9-1.1-1.1-2.5-1.5-3.9-1.3z"
                />
                <path
                    className="prefix__st2"
                    d="M268.7 232.6s-3.5-1.1-4.2-.9-5 4.2-5 4.2M270.2 235.9s-3.3-.9-3.9-.7c-1.6 1.2-3.1 2.6-4.6 4M272 239.9s-2.2-1.5-2.9-1.3c-1.3.6-2.6 1.4-3.9 2.2M337.4 314.3l-4.4-11.5"
                />
            </g>
            <g id="prefix__freepik--Birds--inject-7">
                <path
                    className="prefix__st11"
                    d="M83.2 270l.9-2.3c-.8-.1-1.6 0-2.3.4-1.1.6-2.1 1-2.1 1-.9-.3-1.8-.8-2.6-1.3-2.3-1.3-4.9-1.9-7.6-1.9-2.4 0-11.1 1.3-9.1 2.1s7 .2 8.1.8 1.9 1.5 2.8 2.1c1.3.6 2.7 1 4.2 1.3-.6.6-1.3 1-2.1 1.1l-1.5.4c.5 1.6 1.9 2.7 3.6 2.6 1.6.1 3.2.1 4.7-.2l-.2-2.6s0 .9 4.9 1.1 7.2 1.3 7.2 1.3 8.9 3.2 8.3 2.6-4.3-4.3-6.4-5.1c-1.9-.6-3.9-1.1-5.8-1.3-1.7-.4-3.4-1.1-5-2.1zM127.9 262.8l.9-2.3c-.8-.1-1.6 0-2.3.4-1.1.6-2.1 1-2.1 1-.9-.3-1.8-.7-2.6-1.3-2.3-1.3-4.9-1.9-7.6-1.9-2.4 0-11.1 1.3-9.1 2.1s7 .2 8.1.8 1.9 1.5 2.8 2.1c1.3.6 2.7 1 4.2 1.3-.6.6-1.3 1-2.1 1.1l-1.5.4c.5 1.6 1.9 2.7 3.6 2.6 1.6.1 3.2.1 4.7-.2l-.2-2.6s0 .9 4.9 1.1 7.2 1.3 7.2 1.3 8.9 3.2 8.3 2.6-4.3-4.4-6.4-5.1c-1.9-.6-3.9-1.1-5.9-1.3-1.6-.5-3.3-1.2-4.9-2.1zM136.8 293.6l.7-1.7c-.6-.1-1.2 0-1.7.3-.9.4-1.6.7-1.6.7-.7-.2-1.4-.6-2-1-1.7-.9-3.7-1.4-5.6-1.4-1.8 0-8.3 1-6.8 1.5s5.2.1 6 .6 1.4 1.1 2.1 1.5c1 .4 2 .8 3.1 1-.4.4-1 .7-1.6.8l-1.1.3c.3 1.2 1.4 2 2.7 2 1.2.1 2.4.1 3.5-.1l-.1-2s0 .7 3.7.8c1.8 0 3.6.3 5.3 1 0 0 6.6 2.4 6.2 2s-3.2-3.2-4.8-3.8c-1.4-.5-2.9-.8-4.4-1-1.2-.3-2.4-.8-3.6-1.5zM107.7 297.4c.1-.8.4-1.6.9-2.3.8-.8 1.9-1.3.8-3s-9.8-7-8.9-5.1 4.3 3.8 3.8 5.5c-.3 1.3-1 2.4-2.1 3.2l-1.7.9c-.6-.4-1.3-.6-2.1-.6-1.5 0-.2-.4-1.5 0s-3 .9-2.3 2.8 1 2.6 2.1 1.9c1-.7 2.1-1.2 3.3-1.5 1.3-.4 3 1.1 4 2.5s2.3.8 0 3-4.3 3.8-3 4 8.3-4.3 8.9-5.9-1.5-2.3-1.1-2.8c.5-.6 1.3-.9 2.1-.8l1.9.2s-1.1-2.3-2.4-2.3c-1 .1-1.9.2-2.7.3z"
                />
            </g>
        </svg>
    )
}