// @vendors
import { useCallback, useEffect, useState } from 'react'
import { Predicates } from 'prismic-javascript'

// @utils
import { clientPGS } from 'utils/prismic'

export default function useBlog() {
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState({ featuredPost: [], posts: [] })

  const getBlogInfo = useCallback(async() => {
    try {
      const posts = await clientPGS.query(
        [
          Predicates.at('document.type', 'blog'),
          Predicates.not('document.tags', ['featured'])
        ],
        { orderings: '[my.blog_post.date desc]' }
      )

      const featuredPost = await clientPGS.query(
        [
          Predicates.at('document.type', 'blog'),
          Predicates.at('document.tags', ['featured'])
        ],
        { orderings: '[my.blog_post.date desc]' }
      )

      setInfo({
        featuredPost: featuredPost.results,
        posts: posts.results
      })
      setLoading(false)
    } catch (error) {
      console.warn('Error getting blog info', error)
    }
  }, [])

  useEffect(() => {
    getBlogInfo()
  }, [])

  return {
    ...info,
    loading
  }
}
