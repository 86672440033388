// @vendors
import { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

// @context
import { AppContext } from 'context/app'

// @utils
import { isTokenExpired } from 'utils/user'

// @constants
import { ROUTES } from 'constants/routes'

export default function PrivateRoute({ children, exact, path }) {
  const { auth: { accessToken } } = useContext(AppContext)
  const isUserLoggedIn = !isTokenExpired(accessToken)

  if (!isUserLoggedIn) {
    return <Redirect to={ROUTES.HOME} />
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  exact: PropTypes.bool,
  path: PropTypes.string
}
