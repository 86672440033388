// @vendors
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'
import { Button, Skeleton, Typography } from 'antd'

// @contants
import { DEFAULT } from 'constants/default'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle'

const { Text } = Typography

export default function Contact() {
  const { info, loading } = usePrismicQuerySingle('clientPGS', 'contact')
  return (
    <>
      <Helmet>
        <title>Hablemos {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          {loading
            ? <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
            : (
              <>
                <h1 className='title__ppal md'>
                  <strong>{RichText.asText(info.page.title)}</strong>
                </h1>
                <div className='block__aditional side-right'>
                  <div className='text__enr'>
                    <RichText render={info.page.message} />
                    {info.page.whatsapp[0]?.text && (
                      <Button
                        onClick={() => window.open(`https://wa.me/57${info.page.whatsapp[0].text}`)}
                        target='_blank'
                        rel='noreferrer'
                        className='ant-btn solid c-whatsapp'
                      >
                        <em className='fab fa-whatsapp' /> WhatsApp
                      </Button>
                    )}
                  </div>
                  <div className='block__info'>
                    <h2 className='title__ppal xs c-dr'>
                      Envíanos un correo a:
                    </h2>
                    <ul className='block__info-list'>
                      {info.page.emails
                        .map((email, index) =>
                          email.enabled && (
                            <li key={`contat-email-${index}`}>
                              <em className={`fal fa-${email.icon[0].text}`} />
                              <Text type='secondary'>
                                <a href={`mailto:${email.name[0].text}`}>
                                  {email.name[0].text}
                                </a>
                                {email.to[0].text}
                              </Text>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </>
            )}
        </div>
      </section>
    </>
  )
}
