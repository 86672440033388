// @vendors
import { createContext } from 'react'

// @reducer
import localRegisterFormReducer from './reducer'

export const RegisterFormContext = createContext(null)
export const RegisterFormContextProvider = RegisterFormContext.Provider

export { REGISTER_FORM_ACTIONS } from './actions'

export { INITIAL_STATE_REGISTER_FORM } from './constants'

export const registerFormReducer = localRegisterFormReducer
