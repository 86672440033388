// @vendors
import { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'
import { Button, Skeleton } from 'antd'

// @components
import Artists from './artists'
import ModalDefault from 'components/modal-default'

// @images
import CameraSVG from 'components/icons/svg/camera'

// @constants
import { DEFAULT } from 'constants/default'

// @hooks
import usePrismicQuerySingle from 'hooks/usePrismicQuerySingle'

// @styles
import './styles.scss'

const SECTIONS = {
  artistas: Artists
}

export default function Credits() {
  const { info, loading } = usePrismicQuerySingle('clientPGS', 'credits')
  const [visibleModalColaborators, setVisibleModalColaborators] = useState(false)

  return (
    <>
      <Helmet>
        <title>Créditos {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          {loading
            ? <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
            : (
              <>
                <h1 className='title__ppal md'>
                  <strong>{RichText.asText(info.page.title)}</strong>
                </h1>
                <div className='block__infographic side-right'>
                  <CameraSVG />
                  <div className='text__enr'>
                    <RichText render={info.page.message} />
                  </div>
                  <Button
                    className='solid c-1'
                    onClick={() => setVisibleModalColaborators(true)}
                  >
                    ¡Quiero colaborar!
                  </Button>
                </div>
                {
                  info.slices.map((item, index) => {
                    const SelectedSlice = SECTIONS[item.slice_type]

                    return (
                      <SelectedSlice
                        info={item}
                        key={`credits-item-${item.slice_type}-${index}`}
                      />
                    )
                  })
                }
              </>
            )}
        </div>
      </section>
      <ModalDefault
        type='colaborators'
        visible={visibleModalColaborators}
        onClose={() => setVisibleModalColaborators(false)}
      />
    </>
  )
}
