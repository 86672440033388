// @vendors
import { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useHistory } from 'react-router-dom'
import { Typography, Spin } from 'antd'

// @components
import UpdatePasswordForm from 'components/forms/change-password'

// @hooks
import useFetch from 'hooks/useFetch'
import useQuery from 'hooks/useQuery'

// @api
import { updateUser, validateRecoveryToken } from 'api/user'

// @utils
import { notify } from 'utils/notifications'
import { getDecodedToken } from 'utils/user'

// @constants
import { ROUTES } from 'constants/routes'
import { DEFAULT } from 'constants/default'
import { QUERY_PARAM_AUTH_FORM } from 'constants/query-params'

function RecovertWrapper({ children }) {
  return (
    <>
      <Helmet>
        <title>Actualizar contraseña {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          <div className="block__form-default">
            {children}
          </div>
        </div>
      </section>
    </>
  )
}

export default function RecoveryPasswordPage() {
  const history = useHistory()
  const query = useQuery()
  const recoveryToken = query.get('recoveryToken')
  const { id: email } = getDecodedToken(recoveryToken)
  const { loading: validating, data: isValidToken, error: errorValidating } = useFetch(
    validateRecoveryToken,
    {
      fetchOnMount: true,
      params: [email, recoveryToken],
      showErrorMessage: false
    }
  )
  const { loading: updating, refetch: updatePassword } = useFetch(
    updateUser,
    {
      params: [recoveryToken, email],
      showErrorMessage: false,
      onError: () => {
        notify('error', 'Error actualizando la contraseña')
      },
      onSuccess: () => {
        notify('success', 'Contraseña actualizada correctamente')
        history.push({ pathname: ROUTES.HOME, search: `${QUERY_PARAM_AUTH_FORM}=1` })
      }
    }
  )

  const onSubmit = useCallback((newPassword) => {
    updatePassword([recoveryToken, email, { password: newPassword }])
  }, [])

  if (!recoveryToken) {
    return <Redirect to={ROUTES.HOME} />
  }

  if (validating) {
    return (
      <RecovertWrapper>
        <Spin />
      </RecovertWrapper>
    )
  }

  if (!isValidToken || errorValidating) {
    return (
      <RecovertWrapper>
        <>
          <h3 className='title__ppal sm c--dr' style={{ marginBottom: '20px' }}>
            <strong>Enlace inválido</strong>
          </h3>
          <Typography.Text type='secondary'>
            Lo sentimos, el enlace ha expirado, debes solicitar uno nuevo.
          </Typography.Text>
        </>
      </RecovertWrapper>
    )
  }

  return (
    <RecovertWrapper>
      <UpdatePasswordForm
        loading={updating}
        onSubmit={onSubmit}
      />
    </RecovertWrapper>
  )
}