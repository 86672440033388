// @vendors
import { RichText } from 'prismic-reactjs'

// @utils
import { convertToSlug } from 'utils/format'

export default function Artists({ info }) {
  const data = info.primary
  const artists = info.items

  return (
    <div className='credits__panel'>
      <h1 className='title__ppal sm'>
        <strong>{RichText.asText(data.subtitle)}</strong>
      </h1>
      <ul className='artists__list'>
        {artists.map((artist) => (
          <a
            href={artist.portfolio.url}
            key={convertToSlug(artist.fullname[0].text)}
            target='_blank'
            rel='noreferrer'
            className='artists__list-item link'
            title={`${artist.fullname[0].text} - ${artist.category}`}
          >
            <span className='artists__list-item-category'>{artist.category}</span>
            <div className='artists__list-item-name'>
              {artist.fullname[0].text}
            </div>
            {artist.network.length
              ? (
                <em
                  className={
                    artist.network[0].text !== 'web'
                      ? `fab fa-${artist.network[0].text}`
                      : 'fal fa-globe-americas'
                  }
                />
              )
              : (
                <em className='fal fa-user' />
              )}
          </a>
        ))}
      </ul>
    </div>
  )
}
