// @vendors
import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function useSearchParams() {
  const location = useLocation()
  const history = useHistory()
  const { search } = location
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search])

  const addSearchParam = (key, value) => {
    urlSearchParams.set(key, value)
    history.push({ search: urlSearchParams.toString() })
  }

  const removeSearchParam = (key) => {
    if (urlSearchParams.has(key)) {
      urlSearchParams.delete(key)
      history.push({ search: urlSearchParams.toString() })
    }
  }

  const compairSearchParam = (key, value) => {
    return urlSearchParams.get(key) === value
  }

  const getSearchParam = (key) => {
    if (!urlSearchParams.has(key)) {
      return null
    }

    return urlSearchParams.get(key)
  }

  return {
    location,
    addSearchParam,
    removeSearchParam,
    getSearchParam,
    compairSearchParam,
    urlSearchParams
  }
}
