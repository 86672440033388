// @vendors
import { useContext, useRef } from 'react'
import { Button, Form, Input, Typography } from 'antd'

// @context
import { AppContext } from 'context/app'

// @hooks
import useFetch from 'hooks/useFetch'

// @api
import { sendInfoEmail } from 'api/notifications'

export default function RecommendForm({ dates, onClose }) {
  const { auth: { user } } = useContext(AppContext)
  const ref = useRef()

  const { refetch, loading } = useFetch(
    sendInfoEmail,
    {
      errorMessage: 'Error enviando recomendación',
      successMessage: 'Recomendación enviada correctamente',
      onSuccess: () => {
        ref.current.resetFields()
        onClose()
      }
    }
  )

  const onFinish = (values) => {
    refetch(
      [
        user.email,
        {
          ...values,
          fullname: `${user.firstName} ${user.lastName}`,
          email: user.email,
          url: window.location.href,
          destination: dates.title
        },
        'recommend']
    )
  }

  return (
    <>
      <div className='place__preview' style={{ marginBottom: '30px' }}>
        <img src={dates.image.url} alt={dates.image.alt} className='place__preview-image' />
        <h3 className='title__ppal sm c--dr'>
          <span>Recomendar en</span>
          <strong>{dates.title}</strong>
        </h3>
      </div>
      <Form layout='vertical' onFinish={onFinish} ref={ref}>
        <Form.Item
          label='¿Qué deseas recomendar?'
          name='place'
          rules={[{
            required: true,
            message: 'Este campo es obligatorio'
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='¿Por qué?'
          name='message'
          rules={[{
            required: true,
            message: 'Por favor, cuéntanos algo más'
          }]}
        >
          <Input.TextArea allowClear autoSize={{ minRows: 3, maxRows: 6 }} />
        </Form.Item>
        <Typography.Text
          type='secondary'
          style={{ fontSize: '14px', marginBottom: '20px' }}
        >
          <p>Recomienda solo actividades y lugares turísticos que puedes hacer en este destino.</p>
        </Typography.Text>
        <Form.Item className='actions'>
          <Button className='solid c-1' htmlType='submit' loading={loading}>
            <span className='text'>
              Recomendar
            </span>
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
