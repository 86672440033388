// @vendors
import { useState, useCallback, useEffect } from 'react'
import { Predicates } from 'prismic-javascript'

export default function usePrismicQueryBySearch(client, customType, searchOnChange = true, initLoading = true) {
  const [loading, setLoading] = useState(initLoading)
  const [data, setData] = useState([])
  const [dataSecondary, setDataSecondary] = useState([])
  const [searchText, setSearchText] = useState('')

  const getInfo = useCallback(async() => {
    setLoading(true)
    try {
      const items = await client.query(
        [
          Predicates.at('document.type', customType),
          Predicates.fulltext(`my.${customType}.name`, searchText)
        ],
        { pageSize: 32, orderings: '[document.first_publication_date]' }
      )
      const itemsSecondary = await client.query(
        [
          Predicates.at('document.type', customType),
          Predicates.fulltext(`my.${customType}.meta-keywords`, searchText)
        ],
        { pageSize: 32, orderings: '[document.first_publication_date]' }
      )

      setData(items.results)
      setDataSecondary(itemsSecondary.results)
      setLoading(false)
    } catch (error) {
      console.error('Error getting search info', error)
    }
  }, [searchText])

  const resetData = useCallback(() => {
    setData([])
    setDataSecondary([])
  }, [])

  useEffect(() => {
    if (searchText && searchOnChange) {
      getInfo()
    } else {
      setData([])
      setDataSecondary([])
      setLoading(false)
    }
  }, [searchText])

  return {
    data,
    dataSecondary,
    resetData,
    loading,
    setSearchText
  }
}
