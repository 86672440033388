// @vendors
import moment from 'moment'

export function getPostDate(date) {
  const dateFormat = {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  }

  return new Intl.DateTimeFormat('es-CO', dateFormat).format(new Date(date).getTime())
}

export const DEFAULT_DATE_TIME_FORMAT = 'LLL'

export function localTimeToUTC(localDateTime) {
  return moment(localDateTime, DEFAULT_DATE_TIME_FORMAT).utc().format(DEFAULT_DATE_TIME_FORMAT)
}

export function utcToLocalTime(utcDateTime) {
  return moment.utc(utcDateTime, DEFAULT_DATE_TIME_FORMAT).local().format(DEFAULT_DATE_TIME_FORMAT)
}
