// @constants
import { INITIAL_STATE_REGISTER_FORM } from './constants'

// @actions
import { REGISTER_FORM_ACTIONS as ACTIONS } from './actions'

export default function registerFormReducer(state, action) {
  const { payload, type } = action

  switch(type) {
    case ACTIONS.SET_EMAIL:
      return {
        ...state,
        email: payload
      }
    case ACTIONS.RESET_FORM:
      return INITIAL_STATE_REGISTER_FORM
    default:
      return state
  }
}