// @components
import { Button, Collapse, Tag } from 'antd'
import { convertToSlug } from 'utils/format'

// @styles
import './styles.scss'

export default function Experience({ entities, name, destination }) {
    return (
        <div className='guides'>
            <h4 className='title__ppal xs c-dr'>
                Guías
            </h4>

            <Collapse
                className='guides__list'
                expandIconPosition='right'
            >
                {entities.map((item, index) => (
                    <Collapse.Panel
                        header={(
                            <>
                                <div className='guide__item-info'>
                                    <img
                                        src={item.primary.logotype.url}
                                        alt={item.primary.logotype.alt}
                                        className='guide-image'
                                    />
                                    <div className='guide-name'>
                                        {item.primary.entity}
                                        <span>{item.primary.rnt}</span>
                                    </div>
                                </div>
                                <div className='guide__item-tags'>
                                    <Tag>Español</Tag>
                                    {
                                      item.primary.languages.map((item, index) => (
                                        <Tag key={index}>{item.text}</Tag>
                                      ))
                                    }
                                </div>
                            </>
                        )}
                        className='guide__item'
                        key={index}
                    >
                        <ul className='list__provider-services'>
                            {item.items.map((item, index) => (
                                <li
                                    key={index}
                                    className='list__provider-services-item'
                                >
                                    <em className={`fal fa-${item.icon}`} />
                                    <span className='text'>
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <div className='list__provider-actions'>
                            <div className='list__provider-actions-price'>
                                {item.primary.freiwillig
                                    ? (
                                        <>
                                            <strong>Aporte</strong>
                                            <strong>Voluntario</strong>
                                        </>
                                    )
                                    : (
                                        <>
                                            <strong>Desde</strong>
                                            <span>${item.primary.price}</span>
                                        </>
                                    )}
                            </div>
                            <div className='list__provider-actions-contact'>
                                {
                                  !!item.primary.whatsapp && (
                                    <Button
                                        onClick={() => window.open(`https://wa.me/57${item.primary.whatsapp}?text=¡Hola, ${item.primary.entity}! Estuve por noomada.com y vi que ofreces ${name} en ${destination}. Quisiera saber más.`)}
                                        className='solid c-wh ant-btn-chat'
                                        id={`guide-whatsapp-${convertToSlug(item.primary.entity)}-${convertToSlug(name)}-${destination}`}
                                    >
                                        <em className='fab fa-whatsapp' />
                                    </Button>
                                  )
                                }
                                {
                                  !!item.primary.phone && (
                                    <Button
                                        onClick={() => window.open(`tel:${item.primary.phone}`)}
                                        className='solid c-wh ant-btn-call'
                                        id={`guide-phone-${convertToSlug(item.primary.entity)}-${convertToSlug(name)}-${destination}`}
                                    >
                                        <em className='fal fa-phone' />
                                    </Button>
                                  )
                                }
                            </div>
                        </div>
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
    )
}