// @vendors
import axios from 'axios'

const URL = 'https://mav9fqqo48.execute-api.us-east-2.amazonaws.com/Prod/users'

export async function registerNewUser(userInfo) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { ...userInfo } }),
        httpMethod: 'CREATE_USER_CREDENTIALS'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: { ...data.body.Item, accessToken: data.body.access_token }
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function manualUserLogin(email, password) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, password } }),
        httpMethod: 'GET_USER_CREDENTIALS'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data.body
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function registerNewUserWithSocialNetwork(info) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { ...info } }),
        httpMethod: 'CREATE_USER_SOCIAL'
      }
    )

    return {
      error: !data?.body?.access_token,
      result: data.body
        ? {
          access_token: data.body.access_token,
          fullRecord: data.body.Item.fullRecord,
          birthday: data.body.Item.birthday,
          cookies: data.body.Item.cookies,
          firstName: data.body.Item.firstName,
          gender: data.body.Item.gender,
          lastName: data.body.Item.lastName,
          picture: data.body.Item.picture,
          nationality: data.body.Item.nationality
        }
        : null
    }
  } catch (error) {
    return { error }
  }
}

export async function updateUser(accessToken, email, updatedData) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: {
            access_token: accessToken,
            key: { email },
            data: updatedData
          }
        }),
        httpMethod: 'UPDATE_USER'
      }
    )

    return {
      error: Number(data?.statusCode) !== 200,
      result: null
    }
  } catch (error) {
    return { error }
  }
}

export async function getUserInfoByToken(access_token) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { access_token }
        }),
        httpMethod: 'REFRESH_USER_CREDENTIALS'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function acceptUserCookies(email, version, acceptedDate) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, version, acceptedDate }
        }),
        httpMethod: 'ADD_USER_COOKIE'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function updateUserImage(email, picture, pictureName) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, picture, pictureName } }),
        httpMethod: 'SET_PROFILE_PICTURE'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function validateRecoveryToken(email, recoveryToken) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email } }),
        access_token: recoveryToken,
        httpMethod: 'VALIDATE_RECOVERY_TOKEN'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function sendEmailToRecoveryPassword(email) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email } }),
        httpMethod: 'PASSWORD_RECOVERY'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: null
    }
  } catch (error) {
    return { error }
  }
}

export async function removeUserAccount(email) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email } }),
        httpMethod: 'DELETE_ACCOUNT'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: null
    }
  } catch (error) {
    return { error }
  }
}