// @vendors
import { useState } from 'react'

// @api
import { getReviewsByTrip } from "api/destination"

// @hooks
import useFetch from "hooks/useFetch"

// @utils
import { notify } from "utils/notifications"

const REVIEWS_PER_PAGE = 20

export default function useDestinationReviews({ destinationId, email }) {
  const [comments, setComments] = useState([])
  const [shouldFetchMore, setShouldFetchMore] = useState(true)
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined)

  const { refetch: getReviews, loading } = useFetch(
    getReviewsByTrip,
    {
      fetchOnMount: false,
      initialData: [],
      showErrorMessage: false,
      onError: () => {
        notify('error', 'Error cargando reseñas')
      }
    }
  )

  const fetchMoreComments = () => {
    if (shouldFetchMore) {
      getReviews(
        [{
          tripId: destinationId,
          email,
          limit: REVIEWS_PER_PAGE,
          lastEvaluatedKey
        }],
        ({ reviews, LastEvaluatedKey }) => {
          const shouldGetComments = !!LastEvaluatedKey
          setLastEvaluatedKey(LastEvaluatedKey)
          setComments((currentComments) => [...currentComments, ...reviews])
          setShouldFetchMore(shouldGetComments)
        }
      )
    }
  }

  return {
    comments,
    fetchMoreComments,
    shouldFetchMore,
    loading
  }
}
