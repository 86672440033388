// @vendors
import { useContext, useState } from 'react'
import { Button, Form, Input, Rate } from 'antd'
import moment from 'moment'

// @context
import { AppContext } from "context/app"

// @api
import { addReviewToTrip } from "api/destination"

// @hooks
import useFetch from "hooks/useFetch"

// @utils
import { notify } from "utils/notifications"
import { DEFAULT_DATE_TIME_FORMAT, localTimeToUTC } from 'utils/date'

// @styles
import './styles.scss'

export default function NewCommentForm({
  comment = {},
  destinationId,
  onClose,
  onUpdateList,
  refetchReviewsSummary
}) {
  const { auth: { user } } = useContext(AppContext)
  const [defaultStartValue, setDefaultStartValue] = useState(comment?.score || 3)
  const { loading, refetch: addReview } = useFetch(
    addReviewToTrip,
    {
      showErrorMessage: false,
      onError: () => {
        notify('error', 'Error agregando reseña')
      },
      onSuccess: () => {
        onClose()
        notify('error', `Reseña ${comment?.message ? 'actualizada' : 'agregada'} correctamente`)
        onUpdateList()
        refetchReviewsSummary()
      }
    }
  )

  const onSubmitReview = (values) => {
    const currentUtcDate = localTimeToUTC(moment().format(DEFAULT_DATE_TIME_FORMAT))
    const updatedComment = {
      ...values,
      createdAt: comment?.createdAt
        ? comment.createdAt
        : currentUtcDate,
      updatedAt: currentUtcDate,
      picture: user.picture,
      createdBy: user.email,
      fullName: `${user.firstName} ${user.lastName}`
    }

    addReview([user.email, destinationId, updatedComment])
  }

  return (
      <Form
        className="add-review-form"
        onFinish={onSubmitReview}
        layout='vertical'
        style={{ marginTop: '-5px' }}
        initialValues={{
          score: comment?.score || defaultStartValue,
          message: comment?.message || ''
        }}
      >
        <div className="add-review-form__content">
          <div className="add-review-form__score-container">
            <Form.Item
                label=''
                name='score'
                rules={[{
                    required: true,
                    message: 'Este campo es obligatorio'
                }]}
            >
              <Rate
                  style={{ marginTop: '-8px' }}
                  allowClear={false}
                  allowHalf
                  onChange={(e) => setDefaultStartValue(e)}
              />
            </Form.Item>
            <span className="ant-rate-text add-review-form__score-value">{defaultStartValue}</span>
          </div>
            <Form.Item
                label='Comparte tu experiencia con otros noomadas'
                name='message'
                rules={[{
                    required: true,
                    message: 'Este campo es obligatorio'
                }]}
            >
                <Input.TextArea
                    allowClear
                    maxLength={250}
                    showCount
                    autoSize={{ minRows: 3, maxRows: 5 }}
                />
            </Form.Item>
          </div>
          <Form.Item className="actions">
            <Button
                className='ant-btn-only--link'
                disabled={loading}
                key='comment-cancel'
                onClick={onClose}
            >
                Cancelar
            </Button>
            <Button
                className='solid c-1'
                key='comment-add'
                htmlType="submit"
                loading={loading}
            >
                {comment ? 'Actualizar' : 'Enviar'}
            </Button>
          </Form.Item>
      </Form>
  )
}
