// @vendors
import { useContext } from 'react'
import { Link } from 'react-router-dom'

// @context
import { AppContext } from 'context/app'

// @constants
import { ROUTES, SOCIAL_NETWORKS } from 'constants/routes'
import { FOOTER_ITEMS, FOOTER_ITEM_FOR_EVERYBODY } from 'constants/footer'

// @styles
import './styles.scss'
import { Tooltip } from 'antd'

export default function Footer() {
  const {
    app: {
      handleChangeSearchText,
      onOpenSearchSidebar
    }
  } = useContext(AppContext)
  const thisyear = new Date()

  return (
    <footer className='footer__ppal'>
      <div className='footer__ppal-links'>
        {FOOTER_ITEMS.map((group) => (
          <div className={`cell cell__column-${group.column}`} key={group.title}>
            <h4 className='title'>
              {group.title}
            </h4>
            <ul className='list__items'>
              {group.items.map((menuItem) => (
                <li
                  key={menuItem.title}
                  onClick={
                    group.clickable && group.type === FOOTER_ITEM_FOR_EVERYBODY
                      ? () => {
                          onOpenSearchSidebar()
                          handleChangeSearchText(menuItem.title)()
                        }
                      : () => {}
                  }
                >
                  {
                    group.clickable &&
                    group.type === FOOTER_ITEM_FOR_EVERYBODY
                      ? (
                        <span style={{ cursor: 'pointer' }}>
                          {menuItem.title}
                        </span>
                      )
                      : (
                        <Link to={`${menuItem.path}`}>
                          {menuItem.title}
                        </Link>
                      )
                  }
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className='footer__ppal-bottom'>
        <div className='copy'>
          <span>© {thisyear.getFullYear()} Noomada - Todos los derechos reservados</span>
          <a href={ROUTES.LEGALS.TERMS}>Términos</a>
          <a href={ROUTES.LEGALS.PRIVACY}>Privacidad</a>
          <a href={ROUTES.LEGALS.COOKIES}>Cookies</a>
          <a href={ROUTES.LEGALS.CREDITS}>Créditos</a>
        </div>
        <ul className='extra'>
          {SOCIAL_NETWORKS.map((network) => (
            <Tooltip placement='top' title={network.title} key={network.iconName}>
              {network.path && (
                <li>
                  <a href={network.path} target='_blank' rel='noopener noreferrer'>
                    <em className={`fab fa-${network.iconName}`} />
                  </a>
                </li>
              )}
            </Tooltip>
          ))}
        </ul>
      </div>
    </footer>
  )
}
