// @vendors
import { useCallback, useEffect, useState } from 'react'
import { Predicates } from 'prismic-javascript'

// @utils
import { client, clientPNN } from 'utils/prismic'

function getCaption(categoryName, item) {
  const selecter = {
    capitales: () => item.dest_depto,
    mas_populares: () => item.dest_complement.length
      ? item.dest_complement[0].text
      : item.dest_municipality[0].text,
    parques_naturales: () => `Región ${item.data.region}`,
    pueblos_patrimonios: () => item.dest_depto
  }

  return selecter[categoryName]()
}

export default function useHome() {
  const [homeInfo, setHomeInfo] = useState({ backgroundImage: '' })

  const getInfo = useCallback(async() => {
    try {
      const info = await client.getSingle('home')
      const parks = await clientPNN.query([
        Predicates.at('document.type', 'pnn'),
        Predicates.at('document.tags', ['parques-nacionales', 'featured'])
      ])

      const mappedInfo = {
        activities: [],
        backgroundImage: info.data.home_bg.url,
        parks: parks.results,
        destinations: {}
      }

      info.data.body.forEach((destinationItem) => {
        if (!mappedInfo.destinations[destinationItem.slice_type]) {
          mappedInfo.destinations[destinationItem.slice_type] = {
            cardsList: [],
            title: destinationItem.primary.sec_title[0].text
          }
        }

        const selectedDestinationItem = destinationItem.slice_type === 'parques_naturales'
          ? parks.results
          : destinationItem.items

        selectedDestinationItem.forEach((item) => {
          mappedInfo.destinations[destinationItem.slice_type].cardsList.push({
            caption: getCaption(destinationItem.slice_type, item),
            department: item.dest_depto,
            imageAlt: destinationItem.slice_type === 'parques_naturales'
              ? item.data.thumb[0].image.alt
              : item.dest_image.alt,
            imageUrl: destinationItem.slice_type === 'parques_naturales'
              ? item.data.thumb[0].image.url
              : item.dest_image.url,
            linkTo: destinationItem.slice_type === 'parques_naturales'
              ? `/pnn/${item.uid}`
              : item.dest_link.uid
                ? `/${item.dest_link.tags[0]}/${item.dest_link.uid}`
                : item.dest_link,
            name: destinationItem.slice_type === 'parques_naturales'
              ? item.data.dest_name[0].text
              : item.dest_name[0].text
          })
        })
      })

      mappedInfo.activities = info.data.home_activities.map(activity => ({
        active: activity.home_act_active,
        color: `rgb(${activity.home_act_color[0].text})`,
        iconName: activity.home_act_icon[0].text,
        title: activity.home_act_activity[0].text
      }))

      setHomeInfo(mappedInfo)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    getInfo()
  }, [])

  return homeInfo
}
