// @vendors
import { useContext, useState } from 'react'
import { Button, Divider, Form, Typography } from 'antd'
import { Helmet } from 'react-helmet'

// @context
import { AppContext } from 'context/app'

// @components
import ChangePhoto from '../change-photo'
import GeneralUserForm from './general-user-form'
import PasswordChangeForm from './password-change-form'

// @utils
import { notify } from 'utils/notifications'

// @constants
import { DEFAULT } from 'constants/default'
import { USER_REGISTER_TYPE } from 'constants/user'

// @styles
import './styles.scss'
import ModalRemoveAccount from 'components/modal-remove-account'

export default function Account() {
  const { auth: { user, updateUser } } = useContext(AppContext)
  const [editData, setEditData] = useState(false)
  const [editPass, setEditPass] = useState(false)
  const [openModalRemoveAccount, setOpenModalRemoveAccount] = useState(false)
  const [openChangePhoto, setOpenChangePhoto] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleUpdateUser = (updatedUser, successCallback) => {
    updateUser(
      updatedUser,
      () => {
        setLoading(false)
        notify('success', 'Usuario actualizado exitosamente', '')
        successCallback()
      },
      () => {
        setLoading(false)
        notify('error', 'Error actualizando el usuario', '')
      }
    )
  }

  const onUpdateUser = (values) => {
    setLoading(true)
    const updatedUser = {
      ...values,
      birthday: user.birthdate
    }

    handleUpdateUser(
      updatedUser,
      () => {
        setEditData(false)
      }
    )
  }

  const onUpdatePassword = (values) => {
    setLoading(true)
    handleUpdateUser(
      { password: values.newPassword },
      () => {
        setEditPass(false)
      }
    )
  }

  return (
    <>
      <Helmet>
        <title>Configura tu cuenta {DEFAULT.site.name}</title>
      </Helmet>
      <div className='container'>
        <div className='account__dashboard'>
          <div className='account__dashboard-data'>
            <h3 className='title__ppal md c-dr'>
              <strong>Datos Personales</strong>
            </h3>
            <GeneralUserForm
              user={user}
              onOpenPhotoEditor={() => setOpenChangePhoto(true)}
              onUpdateUser={onUpdateUser}
              editData={editData}
              setEditData={setEditData}
              editPass={editPass}
              loading={loading}
            />
            <Divider style={{ margin: '30px 0' }} />
            {
              user?.source === USER_REGISTER_TYPE.manual && (
                <PasswordChangeForm
                  editData={editData}
                  editPass={editPass}
                  setEditPass={setEditPass}
                  loading={loading}
                  onUpdatePassword={onUpdatePassword}
                />
              )
            }
            <div className='account__dashboard-data-block'>
              <h3 className='title__ppal sm c-dr'>
                <em className='fal fa-skull-crossbones' /><strong>Zona de Peligro</strong>
              </h3>
              <Form
                layout='vertical'
              >
                <Form.Item className='actions actions-one ant-form-item--last'>
                  <Button
                    className='solid c-danger'
                    disabled={editPass || editData}
                    onClick={() => setOpenModalRemoveAccount(true)}
                  >
                    <span className='text'>
                      Eliminar cuenta
                    </span>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className='account__dashboard-faqs'>
            <div className='account__dashboard-faqs-item'>
              <em className='fal fa-lock-alt' />
              <h4 className='subtitle__ppal c-dr'>
                ¿Qué datos no puedes editar?
              </h4>
              <Typography.Text type='secondary'>
                No puedes editar la fecha de nacimiento y el correo electrónico.
              </Typography.Text>
            </div>
            <Divider style={{ margin: '30px 0' }}></Divider>
            <div className='account__dashboard-faqs-item'>
              <em className='fal fa-id-badge' />
              <h4 className='subtitle__ppal c-dr'>
                ¿Qué información se comparte con los demás?
              </h4>
              <Typography.Text type='secondary'>
                <p>Ningún usuario puede ver a la información personal, viajes (a no ser que sea un viaje compartido) y/o álbumes de otros usuarios.</p>
                <p>Sólo los administradores de NOOMADA pueden ver dicha información, exceptuando tu contraseña.</p>
              </Typography.Text>
            </div>
            <Divider style={{ margin: '30px 0' }}></Divider>
            <div className='account__dashboard-faqs-item'>
              <em className='fal fa-skull-crossbones' />
              <h4 className='subtitle__ppal c-dr'>
                ¿Qué pasa si eliminas tu cuenta?
              </h4>
              <Typography.Text type='secondary'>
                <p>Borraremos tu información personal (fecha de nacimiento, nombre completo, género, nacionalidad y contraseña).</p>
                <p>Sólo quedará el registro que algún día estuviste dando pasos por NOOMADA.</p>
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>

      <ChangePhoto
        visible={openChangePhoto}
        onClose={() => setOpenChangePhoto(false)}
        photo={user.picture}
      />
      <ModalRemoveAccount
        visible={openModalRemoveAccount}
        onClose={() => setOpenModalRemoveAccount(false)}
      />
    </>
  )
}