// @vendors
import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button, Input } from 'antd'

// @context
import { AppContext } from 'context/app'

import './styles.scss'

export default function SearchBar({ id, onSearch }) {
  const {
    app: {
      searchText,
      setSearchText,
      autoSearchText
    }
  } = useContext(AppContext)

  useEffect(() => {
    if (autoSearchText) {
      onSearch()
    }
  }, [autoSearchText])

  return (
    <div className={cx(
      'search__big',
      { 'search__big-active': searchText }
    )}>
      <Input
        placeholder='¿A dónde quieres ir?'
        onChange={e => setSearchText(e.target.value)}
        onPressEnter={onSearch}
        value={searchText}
        id={id}
      />
      <label htmlFor={id} className='search__big-actions'>
        <Button onClick={() => setSearchText('')} disabled={searchText.length < 1}>
          <em className='fal fa-trash-alt' />
        </Button>
        <Button onClick={onSearch} disabled={searchText.length < 3}>
          <em className='fal fa-search' />
        </Button>
      </label>
    </div>
  )
}

SearchBar.propTypes = {
  onSearch: PropTypes.func
}
