// @vendors
import axios from 'axios'

const URL = 'https://mav9fqqo48.execute-api.us-east-2.amazonaws.com/Prod/notifications'

export async function getUserNotifications(email) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email } }),
        httpMethod: 'GET_USER_NOTIFICATIONS'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function rejectTripInvitation(email, notificationId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, notificationId } }),
        httpMethod: 'REMOVE_USER_NOTIFICATION'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function acceptTripInvitation(email, notificationId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, notificationId } }),
        httpMethod: 'ACCEPT_TRAVEL_NOTIFICATION'
      }
    )

    let result = null

    if (data.body) {
      const parsedInfo = JSON.parse(data.body)
      result = parsedInfo.body
    }

    return {
      error: Number(data.statusCode) !== 200,
      result
    }
  } catch (error) {
    return { error }
  }
}

export async function sendInfoEmail(from, replace, template) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email: from, template, replace } }),
        httpMethod: 'SEND_INFO_MAIL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function reportReviewFromTrip(payload) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { ...payload } }),
        httpMethod: 'REPORT_REVIEW'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body?.Items
    }
  } catch (error) {
    return { error }
  }
}
