// @vendprs
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useEffect, useRef } from 'react'

// @constants
import { ROUTES } from 'constants/routes'

// @utils
import { useWindowDimensions } from 'utils/DOM-functions'

// @styles
import './styles.scss'

const flexColumnsPages = [
  ROUTES.ABOUT_US,
  ROUTES.BLOG,
  ROUTES.LNT,
  ROUTES.BLOG_DETAILS,
  ROUTES.PATRIMONY_TOWNS,
  ROUTES.NATIONAL_PARKS,
  ROUTES.DEPARTMENTS,
  ROUTES.CONTACT,
  ROUTES.LEGALS.CREDITS,
  ROUTES.LEGALS.COOKIES,
  ROUTES.RECOVERY_PASSWORD
]
const profilePages = [
  ROUTES.PROFILE.MY_TRIPS,
  ROUTES.PROFILE.VISITED,
  ROUTES.PROFILE.ALBUMBS,
  ROUTES.PROFILE.ACCOUNT
]

function compairFlexRowPages(pathname) {
  const ROUTES_FLEX_ROW = [
    ROUTES.HOME,
    ROUTES.PROFILE
  ]

  return ROUTES_FLEX_ROW.includes(pathname)
}
function compairFlexDestinationPages(pathname) {
  const ROUTES_NOT_DESTINATION = [
    ROUTES.HOME,
    ROUTES.ABOUT_US,
    ROUTES.LNT,
    ROUTES.LEGALS,
    ROUTES.CONTACT,
    ROUTES.DONATE,
    ROUTES.PROFILE,
    ROUTES.PATRIMONY_TOWNS,
    ROUTES.DESTINATION,
    ROUTES.DEPARTMENTS,
    ROUTES.NATIONAL_PARKS,
    ROUTES.NATIONAL_PARKS_DETAILS,
    ROUTES.BLOG,
    ROUTES.BLOG_DETAILS,
    ROUTES.STORE,
    ROUTES.LEGALS.TERMS,
    ROUTES.LEGALS.PRIVACY,
    ROUTES.LEGALS.REPORT,
    ROUTES.LEGALS.CREDITS,
    ROUTES.LEGALS.COOKIES,
    ROUTES.RECOVERY_PASSWORD,
    ROUTES.REQUESTS.NEW
  ]

  return !ROUTES_NOT_DESTINATION.includes(pathname)
}

export default function Page({ children, className }) {
  const location = useLocation()
  const { width } = useWindowDimensions()
  const pageContainerRef = useRef()

  useEffect(() => {
    if (width < 861) {
      document.getElementsByTagName('body')[0].scrollTo(0, 0)
    } else {
      pageContainerRef.current.scrollTo(0, 0)
    }
  }, [location])

  function onScrollPage() {
    const scrollToActiveHeader = 30

    // add background to header
    if (
      document.getElementsByClassName('page-container')[0].scrollTop >= scrollToActiveHeader
    ) {
      document.getElementsByClassName('header__ppal')[0].classList.add('header__ppal-active--scroll')
    } else {
      document
        .getElementsByClassName('header__ppal')[0].classList.remove('header__ppal-active--scroll')
    }
  }

  const classes = cx(
    'page-container',
    { 'flex-column': flexColumnsPages.includes(location.pathname) },
    { 'flex-destination': compairFlexDestinationPages(location.pathname) },
    { 'flex-row': compairFlexRowPages(location.pathname) },
    { 'flex-profile': profilePages.includes(location.pathname) },
    className
  )

  let extraPathClasses = ''
  const isBlogDetailPage = location.pathname.match(/\/blog\/.+/g)
  const isTripDetailPage = location.pathname.match(/\/viajes\/.+/g)
  const isLegalPage = location.pathname.match(/\/legal\/.+/g)
  const isRequestPage = location.pathname.match(/\/solicitudes\/.+/g)

  if (isBlogDetailPage || isLegalPage || isRequestPage) {
    extraPathClasses = cx(
      'flex-column'
    )
  }
  if (isTripDetailPage) {
    extraPathClasses = cx('flex-profile')
  }

  return (
    <div
      className={cx(classes, extraPathClasses)}
      onScroll={onScrollPage}
      ref={pageContainerRef}
    >
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

Page.defaultProps = {
  className: ''
}