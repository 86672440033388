// @vendors
import { RichText } from 'prismic-reactjs'
import { Helmet } from 'react-helmet'
import { Skeleton, Typography } from 'antd'

// @components
import Text from './text'

// @hooks
import usePrismicQueryUID from 'hooks/usePrismicQueryUID'

// @constants
import { DEFAULT } from 'constants/default'

// @utils
import { getPostDate } from 'utils/date'

const sliceSelector = {
  texto: (props) => <Text info={props.slice.primary.text} />
}

export default function Legal() {
  const { info, loading } = usePrismicQueryUID('clientPGS', 'legal')

  if (loading) {
    return (
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
        </div>
      </section>
    )
  }

  if (!info) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{RichText.asText(info.data.title)} {DEFAULT.site.name}</title>
      </Helmet>
      <section className='sec__default sec__default-basic'>
        <div className='container'>
          <h1 className='title__ppal md'>
            <strong>{RichText.asText(info.data.title)}</strong>
          </h1>
          <div className='legal-content'>
            {info.data.body
              .map((item, index) => {
                const SelectedSlice = sliceSelector[item.slice_type]

                return <SelectedSlice slice={item} key={`${item.slice_type}-${index}`} />
              })}
            <Typography.Text
              type='secondary'
              style={{ marginTop: '35px', display: 'block' }}
            >
              Última actualización: {getPostDate(info.data.modification_date)}
            </Typography.Text>
          </div>
        </div>
      </section>
    </>
  )
}