// @vendors
import { useCallback, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Skeleton } from 'antd'
import cx from 'classnames'
import ScrollContainer from 'react-indiana-drag-scroll'

// @api
import { removeDestinationFromVisited } from 'api/destination'

// @context
import { AppContext } from 'context/app'

// @hooks
import useFetch from 'hooks/useFetch'
import useVisited from 'hooks/useVisited'

// @components
import { EmptyData } from 'components/empty'
import CardDestination from 'components/card-destination'

// @constants
import { DEFAULT } from 'constants/default'

// @utils
import { messageKey, notify } from 'utils/notifications'

// @styles
import './styles.scss'

const FILTERS_CATEGORIES = [
  {
    FILTER: 'todos',
    LABEL: 'Todos'
  },
  {
    FILTER: 'destinos',
    LABEL: 'Destinos'
  },
  {
    FILTER: 'departamentos',
    LABEL: 'Departamentos'
  },
  {
    FILTER: 'pnn',
    LABEL: 'Parques Nacionales'
  }
]

function mapDestination(destination) {
  return {
    type: destination.type,
    caption: destination.caption,
    name: destination.title,
    linkTo: destination.link,
    imageUrl: destination.image,
    imageAlt: destination.imageAlt
  }
}

export default function Visited() {
  const { auth: { user } } = useContext(AppContext)
  const {
    visitedDestinations,
    loading,
    setVisitedDestinations,
    setFilterType,
    filterType,
    filterTypeCount
  } = useVisited({
    email: user.email
  })
  const { error: errorRemoving, refetch: markDestinationAsNotVisited } = useFetch(
    removeDestinationFromVisited
  )

  const handleRemoveDestination = useCallback((destination) => () => {
    markDestinationAsNotVisited([user?.email, destination.title])

    if (!errorRemoving) {
      setVisitedDestinations(currentDestinations => currentDestinations.filter(({ title }) => title !== destination.title))
      const notificationInfo = messageKey.notVisited(destination.title).success
      notify('success', notificationInfo.description, notificationInfo.message)
    }
  }, [errorRemoving, user])

  return (
    <>
      <Helmet>
        <title>Visitados {DEFAULT.site.name}</title>
      </Helmet>
      <div
        className={cx(
          'container',
          { 'visited-page--loading': loading },
          { 'visited-page--empty': !loading && !visitedDestinations?.length },
          { 'visited-page--full': !loading && !!visitedDestinations?.length }
        )}
      >
        {loading && <>
          <Skeleton
            className='ant-skeleton-basic ant-skeleton-basic-filters'
            paragraph={{ rows: 2 }}
            active
          />
          <Skeleton
            title=''
            className='ant-skeleton-mosaic ant-skeleton-mosaic-profile'
            paragraph={{ rows: 5 }}
            active
          />
        </>}
        {!loading && <>
          <h3 className='title__ppal md c-dr'>
            <strong>Visitados</strong>
          </h3>
          {!!filterTypeCount[filterType] && (
            <ScrollContainer
              className='filters__category solid c-2'
              component='ul'
            >
              {FILTERS_CATEGORIES.filter(({ FILTER }) =>
                !!filterTypeCount[FILTER]).map((category) => (
                  <li
                    key={`${category.FILTER}-providers`}
                    onClick={setFilterType(category.FILTER)}
                  >
                    <div
                      className={cx(
                        { active: filterType === category.FILTER }
                      )}
                    >
                      {category.LABEL}
                      <span className='count'>
                        {filterTypeCount[category.FILTER]}
                      </span>
                    </div>
                  </li>
                ))}
            </ScrollContainer>
          )}

          {
            !visitedDestinations?.length && (
              <EmptyData type='visited' />
            )
          }

          <div className='list__items-mosaic-card list__items-mosaic-card-profile'>
            {
              (visitedDestinations || []).map((destination, index) => (
                <CardDestination
                  destination={mapDestination(destination)}
                  onRemove={handleRemoveDestination(destination)}
                  showRemoveButton
                  key={`visited-destination-${index}`}
                />
              ))
            }
          </div>
        </>}
      </div>
    </>
  )
}