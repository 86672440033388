// @vendors
import { Helmet } from 'react-helmet'

// @constants
import { DEFAULT } from 'constants/default'

// @components
import { EmptyData } from 'components/empty'

export default function Albums() {
  return (
    <>
      <Helmet>
        <title>Álbumes {DEFAULT.site.name}</title>
      </Helmet>
      <div className='container'>
        <h3 className='title__ppal md c-dr'>
          <strong>Álbumes</strong>
        </h3>

        <EmptyData type='albums' />
      </div>
    </>
  )
}