// @vendors
import { useCallback, useContext, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Divider, Button, Tooltip } from 'antd'

// @constans
import { ROUTES, SOCIAL_NETWORKS } from 'constants/routes'

// @components
import UserAvatar from 'components/user-avatar'
import ChangePhoto from '../change-photo'

// @context
import { AppContext } from 'context/app'

// @styles
import './styles.scss'

export default function Sidebar() {
  const history = useHistory()
  const { auth: { logout, user } } = useContext(AppContext)
  const [openChangePhoto, setOpenChangePhoto] = useState(false)

  const handleLogout = useCallback(() => {
    logout()
    history.push(ROUTES.HOME)
  }, [history])

  return (
    <>
      <div className='sidebar__profile'>
        <div className='sidebar__profile-container'>
          <div className='user__profile'>
            <div className='user__profile-image'>
              <Tooltip placement='right' title='Editar foto'>
                <Button
                  className='solid c-2 ant-btn-only--icon'
                  onClick={() => setOpenChangePhoto(true)}
                >
                  <em className='fal fa-pencil' />
                </Button>
              </Tooltip>
              <UserAvatar user={user} size={100} />
            </div>
            <div className='user__profile-fullname'>
              <span>Hola de nuevo,</span>
              <strong>{user.firstName.split(' ')[0]}</strong>
            </div>
          </div>
          <nav className='user__navigation'>
            <NavLink
              exact
              to={ROUTES.PROFILE.MY_TRIPS}
              className='user__navigation-item'
            >
              <em className='fal fa-bookmark' />
              Viajes
            </NavLink>
            <NavLink
              exact
              to={ROUTES.PROFILE.VISITED}
              className='user__navigation-item'
            >
              <em className='fal fa-heart' />
              Visitados
            </NavLink>
            <Divider style={{
              margin: '10px 0'
            }} />
            <NavLink
              exact
              to={ROUTES.PROFILE.ACCOUNT}
              className='user__navigation-item'
            >
              <em className='fal fa-tools' />
              <span>Cuenta</span>
            </NavLink>
            <div
              className='user__navigation-item'
              onClick={handleLogout}
            >
              <em className='fal fa-sign-out' />
              <span>Cerrar sesión</span>
            </div>
          </nav>
        </div>
        <div className='sidebar__profile-footer'>
          <span>Síguenos</span>
          <div className='sidebar__profile-footer-social'>
            {SOCIAL_NETWORKS.map(item => item.path && (
              <a
                href={item.path}
                target='_blank'
                rel="noreferrer"
                key={item.iconName}
              >
                <em className={`fab fa-${item.iconName}`} />
              </a>
            ))}
          </div>
        </div>
      </div>

      <ChangePhoto
        visible={openChangePhoto}
        onClose={() => setOpenChangePhoto(false)}
        photo={user.picture}
      />
    </>
  )
}
