// @vendors
import { useCallback, useContext } from 'react'
import {
  Button
} from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'

// @context
import { AppContext } from 'context/app'

// @utils
import { notify } from 'utils/notifications'
import { showConfirm } from 'utils/alerts'

// @constants
import { TYPE_DESTINATIONS_WITH_HIDDEN_ADD_TO_TRIP_OPTION } from 'constants/trips'

// @styles
import './styles.scss'
export default function CardDestination({
  destination,
  edit,
  onRemove = () => { },
  showRemoveButton,
  onAddToTrip = () => { },
  tripTitle
}) {
  const {
    app: { onOpenAddDestinationToMyTrips },
    trips: { handleChangeAddToTripsVisibility }
  } = useContext(AppContext)
  const captionText = destination.caption

  const handleDelete = useCallback(() => {
    showConfirm(
      {
        content: <>¿Seguro(a) que deseas eliminar <strong>{destination.name}</strong> del viaje &ldquo;{tripTitle}&rdquo;?</>,
        onOk: () => onRemove(
          () => notify('success', <>Has eliminado <strong>{destination.name}</strong>, del viaje &ldquo;{tripTitle}&rdquo;.</>, ''),
          () => notify('error', `Ocurrió un error eliminando ${destination.name} del viaje "${tripTitle}", por favor, inténtalo más tarde.`, '')
        )
      }
    )
  }, [destination])

  const handleAddTrip = useCallback(() => {
    const visitedMessage = destination.visited ? 'no visitado' : 'visitado'
    onAddToTrip(
      () => notify('success', <>Has marcado <strong>{destination.name}</strong>, del viaje &ldquo;{tripTitle}&rdquo; como {visitedMessage}.</>, ''),
      () => notify('error', `Ocurrió un error marcando ${destination.name} como ${visitedMessage}", por favor, inténtalo más tarde.`, '')
    )
  }, [destination])

  return (
    <div
      className={
        cx(
          'destination__item',
          { 'destination__item--visited': destination.visited },
          { 'destination__item--edit': edit }
        )
      }
    >
      <div className='destination__item-actions'>
        {!TYPE_DESTINATIONS_WITH_HIDDEN_ADD_TO_TRIP_OPTION.includes(destination.type) && (
          <Button className='ant-btn-save' onClick={handleChangeAddToTripsVisibility(true, onOpenAddDestinationToMyTrips, destination)}>
            <em className="fal fa-bookmark" />
          </Button>
        )}
        {
          showRemoveButton && (
            <Button className='ant-btn-remove active' onClick={onRemove}>
              <em className="fas fa-heart" />
            </Button>
          )
        }
        {destination.type === 'trip' && (
          <>
            <Button
              className='ant-btn-delete'
              onClick={handleDelete}
            >
              <em className='fal fa-trash-alt' />
            </Button>
            <Button className='ant-btn-save' onClick={handleAddTrip}>
              <em className={`${!destination.visited ? 'fal' : 'fas'} fa-check`} />
            </Button>
          </>
        )}
      </div>
      <Link className='destination__item-data' to={!edit ? destination.linkTo : '#'}>
        <img src={destination.imageUrl} alt={destination.imageAlt} className='destination__item-data--img' />
        <section className='destination__item-data--caption'>
          <h4 className='title' translate='no'>{destination.name}</h4>
          <div className='location' translate='no'>{captionText}</div>
        </section>
      </Link>
    </div>
  )
}

CardDestination.propTypes = {
  destination: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  showRemoveButton: PropTypes.bool
}

CardDestination.defaultProps = {
  onRemove: () => { },
  showRemoveButton: false
}
