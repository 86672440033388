// @vendors
import { useRef, useState } from 'react'
import {
  Button,
  Form,
  Input,
  Checkbox,
  Typography
} from 'antd'
import { Link } from 'react-router-dom'

// @constants
import { ROUTES } from 'constants/routes'
import { EMAIL_REGEX } from 'constants/format'

// @hooks
import useFetch from 'hooks/useFetch'

// @api
import { sendInfoEmail } from 'api/notifications'

export default function ReportForm({ type }) {
  const ref = useRef()
  const { refetch, loading } = useFetch(
    sendInfoEmail,
    {
      errorMessage: 'Error enviando correo',
      successMessage: 'Correo enviado correctamente',
      onSuccess: () => {
        ref.current.resetFields()
        setTrustChecked(false)
        setPromiseChecked(false)
      }
    }
  )
  const [trustChecked, setTrustChecked] = useState(false)
  const [promiseChecked, setPromiseChecked] = useState(false)

  const onFinish = (values) => {
    const { email, promise, trust, ...replace } = values
    refetch([email, { ...replace, form_type: type }, 'copy'])
  }

  const checkboxValidator = (checked) => (_, __, callback) => {
    if(checked) {
      return callback()
    }
    // eslint-disable-next-line node/no-callback-literal
    return callback('Debes marcar este campo')
  }

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      ref={ref}
    >
      <Form.Item
        label='Nombre completo'
        name='fullname'
        rules={[{
          required: true,
          message: 'Este campo es obligatorio'
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Correo electrónico'
        name='email'
        rules={[{
          required: true,
          message: 'Este campo es obligatorio'
        }, {
          pattern: EMAIL_REGEX,
          message: 'Formato de correo inválido'
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={<>Nombre de la empresa <span>(opcional)</span></>}
        name='company'
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Asunto'
        name='subject'
        rules={[{
          required: true
        }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Mensaje'
        name='message'
        help='Por favor ingresa los detalles de tu solicitud.'
        rules={[{
          required: true
        }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item
        label={<>Titular de los derechos de autor que representa <span>(opcional)</span></>}
        name='manager'
        help='Si representas a alguien que no seas tu mismo.'
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Dirección de residencia'
        name='address'
        help='Dirección completa, código postal y país.'
        rules={[{
          required: true
        }]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label='Ubicación del trabajo original protegido por derechos de autor'
        name='location_work'
        rules={[{
          required: true
        }]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label='Descripción del trabajo original protegido por derechos de autor'
        help='Nos ayuda a identificar el trabajo referenciado específico.'
        name='description_work'
        rules={[{
          required: true
        }]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        label='Ubicación de material no autorizado'
        help='Proporcione URL específicas, una por línea.'
        name='location_element'
        rules={[{
          required: true
        }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item
        name="trust"
        rules={[{ validator: checkboxValidator(trustChecked) }]}
      >
        <Checkbox
          checked={trustChecked}
          onChange={e => setTrustChecked(e.target.checked)}
        >
          Creo de buena fe que el uso de los materiales con derechos de autor descritos anteriormente como supuestamente infractores no está autorizado por el propietario de los derechos de autor, su agente o la ley.
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="promise"
        rules={[{ validator: checkboxValidator(promiseChecked) }]}
      >
        <Checkbox
          checked={promiseChecked}
          onChange={e => setPromiseChecked(e.target.checked)}
        >
          Juro, bajo pena de perjurio, que la información contenida en la notificación es precisa y que soy el propietario de los derechos de autor o estoy autorizado para actuar en nombre del propietario de un derecho exclusivo que supuestamente ha sido infringido.
        </Checkbox>
      </Form.Item>
      <Form.Item
        label='Firma'
        help='Firma electrónicamente escribiendo tu nombre completo.'
        name='signature'
        rules={[{
          required: true
        }]}
      >
        <Input />
      </Form.Item>
      <Typography.Text
        type='secondary'
        style={{ margin: '-10px 0 20px', display: 'block' }}
      >
        Al dar clic en <strong>Enviar</strong>, estás aceptando los <Link to={ROUTES.LEGALS.TERMS} target='_blank'>Términos y condiciones</Link> y la <Link to={ROUTES.LEGALS.PRIVACY} target='_blank'>Política de privacidad</Link> de Noomada.
      </Typography.Text>
      <Form.Item className='actions ant-form-item--last'>
        <Button className='solid c-1' htmlType="submit" loading={loading}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
  )
}
