// @vendors
import {
  useContext,
  useState
} from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  DatePicker,
  Select,
  Typography,
  notification
} from 'antd'
import 'moment/locale/es-mx'
import locale from 'antd/es/date-picker/locale/es_ES'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

// @context
import { AppContext } from 'context/app'
import { RegisterFormContext } from 'context/register-form'

// @constants
import { COUNTRIES } from 'constants/countries'
import { ROUTES } from 'constants/routes'
import { GENERES } from 'constants/default'

// @utils
import { convertToSlug, validateEmail } from 'utils/format'

const dateFormat = 'DD/MM/YYYY'
const { Text } = Typography

export default function FullRegisterForm({ onClose, visible }) {
  const {
    auth: { registerUser }
  } = useContext(AppContext)
  const {
    registerFormInfo: {
      email
    }
  } = useContext(RegisterFormContext)
  const [isTryingToRegister, setIsTryingToRegister] = useState(false)

  const handleRegisterNewUser = async (values) => {
    setIsTryingToRegister(true)
    const newUserPayload = { ...values }
    delete newUserPayload.confirmPassword
    const { error } = await registerUser(newUserPayload)
    setIsTryingToRegister(false)

    if (!error) {
      onClose()
    } else {
      notification.open({
        message: 'Error',
        description: 'Error registrando usuario',
        placement: 'bottomLeft'
      })
    }
  }

  return (
    <Drawer
      className='drawer__login'
      title={null}
      placement='bottom'
      height='100%'
      closable={false}
      onClose={onClose}
      visible={visible}
      getContainer={false}
      style={{ position: 'absolute' }}
    >
      <h3 className='title__ppal sm c--dr'>
        <strong>Completar registro</strong>
      </h3>
      <Form
        layout='vertical'
        onFinish={handleRegisterNewUser}
        fields={[
          {
            name: ["email"],
            value: email
          },
          {
            name: ["nationality"],
            value: 'Colombia'
          }
        ]}
      >
        <Form.Item
          label='Nombre'
          name='firstName'
          rules={[
            {
              message: 'Se te olvidó este campo',
              required: true
            }
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          label='Apellidos'
          name='lastName'
          rules={[
            {
              message: 'Se te olvidó este campo',
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <div id='genere-full-register-modal' style={{ position: 'relative' }}>
          <Form.Item
            label='Género'
            name='gender'
            rules={[
              {
                message: 'Se te olvidó este campo',
                required: true
              }
            ]}
          >
            <Select
              placeholder='Seleccionar'
              dropdownClassName='ant-dropdown-always-bottom'
              getPopupContainer={() => document.getElementById('genere-full-register-modal')}
            >
              {GENERES.map((item) => (
                <Select.Option
                  value={item.value}
                  key={item.value}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label='Fecha de nacimiento'
          name='birthday'
          rules={[
            {
              message: 'Se te olvidó este campo',
              required: true
            }
          ]}
        >
          <DatePicker
            locale={locale}
            placeholder='dd/mm/aaaa'
            format={dateFormat}
            showToday={false}
            inputReadOnly
          />
        </Form.Item>
        <Form.Item
          label='Correo electrónico'
          name='email'
          rules={[
            {
              message: 'Se te olvidó este campo',
              required: true
            },
            {
              validator(_, value) {
                if (validateEmail(value) || !value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Ingrese un correo válido'))
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        <div id='nationality-full-register-modal' style={{ position: 'relative' }}>
          <Form.Item
            id='select-nationality'
            label='Nacionalidad'
            name='nationality'
            rules={[
              {
                message: 'Se te olvidó este campo',
                required: true
              }
            ]}
          >
            <Select
              placeholder='Seleccionar'
              showSearch
              dropdownClassName='ant-dropdown-always-bottom'
              getPopupContainer={() => document.getElementById('nationality-full-register-modal')}
            >
              {COUNTRIES.map((country) => (
                <Select.Option
                  value={country.name}
                  key={convertToSlug(country.code)}
                >
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label='Contraseña'
          name='password'
          rules={[
            {
              required: true,
              message: 'Debes crear una contraseña'
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label='Confirmar Contraseña'
          name='confirmPassword'
          rules={[
            {
              required: true,
              message: 'Confírmanos tu contraseña'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('password') === value || !value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Las contraseñas deben coincidir'))
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label=''
          name='reCaptcha'
          rules={[
            {
              required: true,
              message: '¡Oh, no! ¿Eres un bot? 😮‍💨'
            }
          ]}
        >
          <ReCAPTCHA
            sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`}
            hl='es'
          />
        </Form.Item>
        <Text
          type='secondary'
          style={{ margin: '-10px 0 20px', display: 'block' }}
        >
          Al dar clic en <strong>Finalizar</strong>, estás aceptando los <Link to={ROUTES.LEGALS.TERMS} target='_blank'>Términos de servicio</Link> y la <Link to={ROUTES.LEGALS.PRIVACY} target='_blank'>Política de privacidad</Link> de Noomada.
        </Text>
        <Form.Item className='actions'>
          <Button
            className='solid c-1'
            htmlType="submit"
            loading={isTryingToRegister}
          >
            Finalizar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
