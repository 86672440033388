// @vendors
import { Drawer } from "antd"

// @components
import NewCommentForm from "components/forms/new-review"

export default function NewCommentDrawer({
  visible,
  onClose,
  selectedComment,
  destinationId,
  getReviews,
  refetchReviewsSummary
}) {
  return (
    <Drawer
      width='450px'
      onClose={onClose}
      className='drawer__default drawer__default-comments'
      title='¿Qué tal te pareció este destino?'
      maskClosable
      visible={visible}
    >
      <NewCommentForm
        destinationId={destinationId}
        comment={selectedComment}
        onClose={onClose}
        onUpdateList={getReviews}
        refetchReviewsSummary={refetchReviewsSummary}
      />
    </Drawer>
  )
}