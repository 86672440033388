// @vendors
import { Link } from 'react-router-dom'

// @svgs
import WomanBeach from 'components/icons/svg/woman-beach'
import ManBeach from 'components/icons/svg/man-beach'
import ManMountain from 'components/icons/svg/man-mountain'
import WomanCity from 'components/icons/svg/woman-city'
import NotificationSVG from 'components/icons/svg/notification'
import UserLost from 'components/icons/svg/user-lost'

// @styles
import './styles.scss'

const message = {
  visited: {
    description: (
      <>
        <div className='text__enr-2'>
          <p>
            Parece que no has marcado ningún destino como visitado.
          </p>
          <p>
            Tenemos cientos de lugares de los cuales posiblemente ya conozcas al menos uno, y sino lo has hecho, no te preocupes, nosotros te motivaremos.
          </p>
          <Link to='/' className='ant-btn solid c-1'>Explorar Colombia</Link>
        </div>
        <WomanBeach />
      </>
    )
  },
  trips: {
    description: (
      <>
        <div className='text__enr-2'>
          <p>
            Sabemos lo que conlleva planificar un viaje, por eso, hemos creado esta sección para tí.
          </p>
          <p>
            Organiza de la mejor manera tus viajes y ten a la mano lo que puedes hacer en cada destino.
          </p>
          <p>
            Crea tu viaje dando clic en el botón <strong>[+]</strong> y comienza tu aventura.
          </p>
        </div>
        <ManBeach />
      </>
    )
  },
  tripsDrawer: {
    description: (
      <>
        <div className='text__enr-2'>
          <p>
            <strong>Aún no tienes viajes creados.</strong> Organiza de la mejor manera tus viajes y ten a la mano lo que puedes hacer en cada destino.
          </p>
          <p>
            Crea tu viaje dando clic en el siguiente botón y comienza tu aventura.
          </p>
        </div>
        <ManBeach />
      </>
    )
  },
  notificationDrawer: {
    description: (
      <>
        <div className='text__enr-2'>
          <p>
            <strong>No tienes notificaciones.</strong>
            <br />
            Cuando te inviten a un viaje, en este panel lo podrás aceptar 🥳
          </p>
        </div>
        <NotificationSVG />
      </>
    )
  },
  albums: {
    description: (
      <>
        <div className='text__enr-2'>
          <p>Imagínate estar en cualquier lugar y poder raspar tus álbumes de aventurero.</p>
          <p>
            Próximamente podrás tener tus álbumes virtuales para raspar los destinos que ya visitaste y motivarte a seguir viajando y conocer toda Colombia.
          </p>
        </div>
        <ManMountain />
      </>
    )
  },
  tripDetail: {
    description: (
      <>
        <div className='text__enr-2'>
          <p>Comienza a agregar destinos a este viaje y dale inicio a tu aventura.</p>
          <p>Una vez visites un destino dentro de tu viaje, no te olvides de marcarlo como visitado para que sepas en que porcentaje se encuentra tu viaje.</p>
          <p>Invita <strong>noomadas</strong> a tu viaje para que entre todos armen la mejor aventura del mundo mundial 🙌</p>
        </div>
        <WomanCity />
      </>
    )
  },
  tripNotFound: {
    description: (
      <>
        <div className='text__enr-2'>
          <h1 style={{ margin: '0 0 20px', lineHeight: '1.2' }}>
            <strong>¿Te has perdido? 👀</strong>
          </h1>
          <p>Parece que este viaje no existe o no estás invitado/a.</p>
          <p>Recuerda que puedes crear los viajes que quieras e invitar a quien desees.</p>
        </div>
        <UserLost />
      </>
    )
  }
}

export function EmptyData({ type, flex }) {
  return (
    <div
      className={`empty__data empty__data-${flex}`}
    >
      {message[type].description}
    </div>
  )
}