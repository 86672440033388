// @vendors
import { Helmet } from 'react-helmet'
import { Skeleton } from 'antd'
import { useLocation } from 'react-router-dom'

// @hooks
import useDestinationPage from 'hooks/useDestinationPage'

// @context
import { DepartmentPageContextProvider } from 'context/pages'

// @constants
import { DEFAULT } from 'constants/default'

// @pages
import NotFound from 'pages/not-found'

// @components
import HeritageTowns from 'components/heritage-towns'
import PopularDestinations from 'components/popular-destinations'
import NationalParks from 'components/national-parks'
import DepartmentBanner from './department-banner'
import DepartmentCaption from './department-caption'
import Interactions from 'components/caption/interactions'
import LeaveNoTrace from 'components/caption/leave-no-trace'

// @utils
import { prismicDepartmentToDepartment } from 'utils/destination'

function mapDestinationItems(destinations) {
  return destinations.map(destination => ({
    caption: !!destination.data.nearby_city_complement?.length && `${destination.data.nearby_city_complement[0].text} ${destination.data.nearby_city[0].text}`,
    imageUrl: destination.data.thumb[0].image.url,
    imageAlt: destination.data.thumb[0].image.alt,
    linkTo: destination.type === 'pnn'
      ? `/pnn/${destination.uid}`
      : `/${destination.tags[0]}/${destination.uid}`,
    name: destination.data.name,
    type: destination.type
  }))
}

export default function DepartmentPage() {
  const {
    destinationPageInfo: departmentInfo,
    loading,
    notFound
  } = useDestinationPage('client', 'departament', 'departmentId')
  const { pathname } = useLocation()

  if (notFound) {
    return <NotFound />
  }

  if (!notFound && loading) {
    return (
      <>
        <div className='dest__gallery dest__gallery-depto' />
        <div className='dest__sidebar dest__sidebar-depto'>
          <div className='dest__sidebar-caption'>
            <div className='dest__sidebar-caption-resume'>
              <Skeleton className='ant-skeleton-text' paragraph={{ rows: 5 }} active />
            </div>
          </div>
        </div>
        <div className='dest__sidebar dest__sidebar-extra'>
          <div className='dest__sidebar dest__sidebar-extra-caption' />
        </div>
      </>
    )
  }

  return (
    <DepartmentPageContextProvider value={departmentInfo}>
      <Helmet>
        <title>Departamento de {departmentInfo.name} {DEFAULT.site.name}</title>
      </Helmet>
      <div className='dest__gallery dest__gallery-depto'>
        <DepartmentBanner />
      </div>
      <div className='dest__sidebar dest__sidebar-depto'>
        <Interactions
          destination={prismicDepartmentToDepartment(departmentInfo, pathname)}
        />
        <DepartmentCaption />
      </div>
      <div className='dest__sidebar dest__sidebar-extra'>
        <div className='dest__sidebar-extra-caption'>
          <HeritageTowns mapDestinationItems={mapDestinationItems} name={departmentInfo.name} />
          <PopularDestinations mapDestinationItems={mapDestinationItems} name={departmentInfo.name} />
          <NationalParks mapDestinationItems={mapDestinationItems} name={departmentInfo.name} />
        </div>
        <LeaveNoTrace />
      </div>
    </DepartmentPageContextProvider>
  )
}
