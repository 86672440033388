// @vendors
import axios from 'axios'

const URL = 'https://mav9fqqo48.execute-api.us-east-2.amazonaws.com/Prod/destinations'

export async function getVisitedDestinations(email) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email } }),
        httpMethod: 'GET_VISITED'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: data?.body?.Items || []
    }
  } catch (error) {
    return {
      error,
      result: null
    }
  }
}

export async function addDestinationAsVisited(email, destination) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, ...destination } }),
        httpMethod: 'ADD_VISITED'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: data?.body?.result ?? null
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function removeDestinationFromVisited(email, title) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, title } }),
        httpMethod: 'DELETE_VISITED'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: data?.body?.result ?? null
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function getDestinationVisited(email, title) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, title } }),
        httpMethod: 'GET_DESTINATION_VISITED'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: !!data?.body
    }
  } catch (error) {
    return {
      error,
      result: null
    }
  }
}

export async function getMyTrips(email) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email } }),
        httpMethod: 'GET_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: {
        myTrips: data?.body?.travels || [],
        invitedTrips: data?.body?.invitedTravels || []
      }
    }
  } catch (error) {
    return {
      error,
      result: null
    }
  }
}

export async function validateTripExistence(email, tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, codeId: tripId }
        }),
        httpMethod: 'GET_TRAVEL_EXISTS'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: !!data.body
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function addDestinationToMyTrips(email, trip) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, travel: trip }
        }),
        httpMethod: 'ADD_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: data?.body?.result ?? null
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function deleteTrip(email, tripId, invitedUsers) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, codeId: tripId, invitedUsers }
        }),
        httpMethod: 'DELETE_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: data?.body?.result ?? null
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function updateTrip(email, newTitle, tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, codeId: tripId, data: { title: newTitle } }
        }),
        httpMethod: 'UPDATE_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: Number(data.statusCode) !== 200 ? null : data?.body
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function addDestinationToMultipleTrips(email, destination, trips) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, destination, travels: trips }
        }),
        httpMethod: 'ADD_TRAVELS_DESTINATION'
      }
    )

    let error = Number(data.statusCode) !== 200
        ? data.body.message
        : null

    if (data.body?.errors?.length) {
      error = data.body.errors[0]
    }

    return {
      error,
      result: data?.body?.result ?? null
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function getTripDetails(email, tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, codeId: tripId }
        }),
        httpMethod: 'GET_TRAVEL_DETAIL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: Number(data.statusCode) !== 200 ? null : data?.body
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function deleteDestinationFromTrip(email, tripId, destinationTitle) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, codeId: tripId, destination: destinationTitle }
        }),
        httpMethod: 'DELETE_TRAVEL_DESTINATION'
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: Number(data.statusCode) !== 200 ? null : data?.body
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function markDestinationAsVisitedInTrip(email, tripId, destinationTitle, visited) {
  const httpMethod = visited ? 'SET_DESTINATION_VISITED' : 'REMOVE_DESTINATION_VISITED'
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: { email, codeId: tripId, destination: destinationTitle }
        }),
        httpMethod
      }
    )

    return {
      error: Number(data.statusCode) !== 200
        ? data.body.message
        : null,
      result: Number(data.statusCode) !== 200 ? null : data?.body
    }
  } catch (error) {
    return {
      error
    }
  }
}

export async function shareTrip(email, tripId, tripTitle, userName, invitedUsers) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, name: userName, travel: tripId, travelTitle: tripTitle, share: invitedUsers } }),
        httpMethod: 'SHARE_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function leaveTrip(ownerEmail, invitedEmail, tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email: invitedEmail, owner: ownerEmail, codeId: tripId } }),
        httpMethod: 'LEAVE_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function removeInvitedFromTrip(email, invitedEmail, tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { invited: invitedEmail, email, codeId: tripId } }),
        httpMethod: 'REMOVE_FROM_TRAVEL'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function addReviewToTrip(email, tripId, comment) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, destination: tripId, ...comment } }),
        httpMethod: 'ADD_REVIEW'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function getReviewsByTrip({ tripId, email, limit, lastEvaluatedKey = undefined }) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({
          Item: {
            email,
            destination: tripId,
            limit,
            LastEvaluatedKey: lastEvaluatedKey
          }
        }),
        httpMethod: 'GET_REVIEWS'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: {
        reviews: data?.body?.Items,
        LastEvaluatedKey: data?.body?.LastEvaluatedKey
      }
    }
  } catch (error) {
    return { error }
  }
}

export async function getReviewsAverage(tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { destination: tripId } }),
        httpMethod: 'GET_REVIEWS_RANK'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body
    }
  } catch (error) {
    return { error }
  }
}

export async function removeReviewFromTrip(email, tripId) {
  try {
    const { data } = await axios.post(
      URL,
      {
        body: JSON.stringify({ Item: { email, destination: tripId } }),
        httpMethod: 'DELETE_REVIEW'
      }
    )

    return {
      error: Number(data.statusCode) !== 200,
      result: data?.body?.Items
    }
  } catch (error) {
    return { error }
  }
}
