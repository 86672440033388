// @vendors
import { notification } from 'antd'

export const messageKey = {
  visited: place => ({
    success: {
      message: '',
      description: (
        <>
          {/* eslint-disable-next-line */}
          ¡Genial, visitaste <strong>{place}</strong>!
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  }),
  notVisited: place => ({
    success: {
      message: '',
      description: (
        <>
          {/* eslint-disable-next-line */}
          Eliminaste <strong>{place}</strong> de tus destinos visitados.
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  }),
  tripAdd: {
    success: {
      message: '',
      description: (
        <>
          {/* eslint-disable-next-line */}
          <strong>Guatapé, Antioquia</strong> se ha agregado al viaje: <strong>"Oriente Antioqueño"</strong>.
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  },
  tripCreate: {
    success: {
      message: '',
      description: (
        <>
          {/* eslint-disable-next-line */}
          El viaje <strong>"Oriente Antioqueño"</strong> se ha creado.
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  },
  tripAccepted: (tripName, tripOwner) => ({
    success: {
      message: '',
      description: (
        <>
          {/* eslint-disable-next-line */}
          ¡Genial, te has unido al viaje <strong>"{tripName}"</strong> de {tripOwner}!
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  }),
  tripRejected: (tripName, tripOwner) => ({
    success: {
      message: '',
      description: (
        <>
          {/* eslint-disable-next-line */}
          Has rechazado unirte al viaje <strong>"{tripName}"</strong> de {tripOwner}.
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  }),
  invitationSent: {
    success: {
      message: '¡Invitación enviada!',
      description: (
        <>
          {/* eslint-disable-next-line */}
          Hemos enviado la invitación. Cuando la acepte, lo notarás en tu viaje.
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  },
  destinationDeleted: {
    success: {
      message: '¡Eliminado correctamente!',
      description: (
        <>
          {/* eslint-disable-next-line */}
          Se eliminó <strong>Desierto de la Tatacoa</strong> del viaje <strong>Travesía 1.0</strong>
        </>
      )
    },
    error: {
      message: '',
      description: 'Inténtalo nuevamente o inténtelo más tarde.'
    }
  }
}

export function showNotification(type, key) {
  notification[type]({
    ...messageKey[key][type],
    duration: 7,
    placement: 'bottomLeft',
    className: `ant-notification--${type}`
  })
}

export function notify(type, message, title) {
  notification[type]({
    message: title,
    duration: 5,
    description: message,
    placement: 'bottomLeft',
    className: `ant-notification--${type}`
  })
}