// @vendors
import React, { useContext } from 'react'

// @components
import SearchForm from 'components/search-form'

// @constants
import { QUERY_PARAM_ADD_DESTINATION_TO_TRIP } from 'constants/query-params'

// @hooks
import useFetch from 'hooks/useFetch'

// @context
import { AppContext } from 'context/app'

// @api
import { addDestinationToMultipleTrips, getTripDetails } from 'api/destination'

// @utils
import { mapDestinationToSave } from 'utils/format'
import { notify } from 'utils/notifications'

export default function AddDestination({
  tripDetails,
  setTripDetails
}) {
  const {
    auth: { user },
    app: {
      onCloseAddDestinationToTripForm,
      isModalOpened
    }
  } = useContext(AppContext)
  const addFormDestinationVisible = isModalOpened(QUERY_PARAM_ADD_DESTINATION_TO_TRIP)

  const { refetch: refetchTripDetails } = useFetch(
    getTripDetails,
    {
      initialData: null,
      params: [user.email, tripDetails.codeId],
      fetchOnMount: false,
      showErrorMessage: false,
      onSuccess: (updatedTripDetails) => {
        setTripDetails(updatedTripDetails)
      }
    }
  )

  const { refetch: addDestinationToTrip } = useFetch(
    addDestinationToMultipleTrips,
    {
      fetchOnMount: false
    }
  )

  const destinationTitles = tripDetails.destinations
    ? tripDetails.destinations.map(({ title }) => title)
    : []

  return (
    <SearchForm
      title='Agregar destinos'
      clearOnVisible
      itemActionIcon="plus"
      onBookmarClick={(destination) => {
        addDestinationToTrip(
          [
            user.email,
            mapDestinationToSave(destination),
            [{
              codeId: tripDetails.codeId,
              owner: tripDetails.email,
              isInvited: user?.email !== tripDetails.email
            }]
          ],
          () => {
            notify('success', <>Has agregado <strong>{destination.name}</strong> al viaje &quot;{tripDetails.title}&ldquo;</>)
            refetchTripDetails()
          }
        )
      }}
      showDepartmentsOnResults={false}
      itemsWithoutActions={destinationTitles}
      actionTooltip="Agregar a mi viaje"
      visible={addFormDestinationVisible}
      onClose={onCloseAddDestinationToTripForm}
    />
  )
}