// @vendors
import { useContext, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'react-router-dom'
import { linkResolver } from 'prismic-config'
import { Menu, Dropdown, Tabs } from 'antd'

// @constants
import { DEPARTAMENTS } from 'constants/departaments'

// @components
import Dictionary from 'components/dictionary'
import TypicalDishes from 'components/typical-dishes'
import LeaveNoTrace from 'components/caption/leave-no-trace'

// @utils
import { convertToSlug } from 'utils/format'

// @context
import { DepartmentPageContext } from 'context/pages'

const { TabPane } = Tabs

function getTabByType(type) {
  const tabs = {
    diccionario: <><em className="fal fa-book" /> <span>Diccionario</span></>,
    comidas_tipicas: <><em className="fal fa-utensils" /> <span>Platos Típicos</span></>
  }

  return tabs[type] || null
}

function getSliceByType(zone) {
  const tabs = {
    diccionario: <Dictionary info={zone.items} />,
    comidas_tipicas: <TypicalDishes info={zone.items} />
  }

  return tabs[zone.slice_type] || null
}

function DepartmentMenu({ name, onCloseMenu }) {
  return (
    <div className='menu__container menu__container-default'>
      <Menu>
        <Menu.ItemGroup title='Más'>
          {
            DEPARTAMENTS.map((department, index) => (
              <Menu.Item key={`department-menu-item-${index}`}>
                {department.name && (
                  <Link
                    to={`${convertToSlug(department.name)}`}
                    onClick={onCloseMenu}
                  >
                    {department.name}
                  </Link>
                )}
              </Menu.Item>
            ))
          }
        </Menu.ItemGroup>
      </Menu>
    </div>
  )
}

export default function DepartmentCaption() {
  const [visible, setVisible] = useState(false)
  const departmentInfo = useContext(DepartmentPageContext)
  const capitalInfo = departmentInfo.dpto_capital[0]

  return (
    <div className='dest__sidebar-caption'>
      <div className='dest__sidebar-caption-resume'>
        <h1 className='title__ppal md c-dr'>
          <span className='destination'>
            Departamento
          </span>
          <Dropdown
            overlay={
              <DepartmentMenu
                name={departmentInfo.name}
                onCloseMenu={() => {
                  setVisible(false)
                }}
              />
            }
            overlayClassName='ant-dropdown-absolute-icon'
            className='ant-dropdown-deptos'
            arrow
            trigger='click'
            placement='bottomRight'
            visible={visible}
          >
            <div
              className='ant-dropdown-link'
              translate='no'
              onClick={() => {
                setVisible(visible => !visible)
              }}
            >
              {departmentInfo.name}
              <em className='fal fa-chevron-down' />
            </div>
          </Dropdown>
        </h1>
        <div className="text__enr">
          <RichText render={departmentInfo.dpto_description} />
        </div>
      </div>

      <ul className="place__info without-state">
        <li className="icon">
          <em className="fal fa-arrow-from-bottom" />
          <span><strong>Altitud-Media</strong> {RichText.asText(departmentInfo.dpto_max_altitude)} msnm</span>
        </li>
        <li className="icon">
          <em className="fal fa-chart-area" />
          <span><strong>Superficie</strong> {RichText.asText(departmentInfo.dpto_surface)} km</span>
        </li>
        <li className="icon">
          <em className="fal fa-users-class" />
          <span><strong>Gentilicio</strong> {RichText.asText(departmentInfo.dpto_demonym)}</span>
        </li>
      </ul>

      <div className='dest__sidebar-caption-extra'>
        <Link to={linkResolver(capitalInfo.capital_link)} className="depto__capital">
          <div className="depto__capital-photo">
            <img src={capitalInfo.capital_img.url} alt="" />
          </div>
          <div className="depto__capital-info">
            <span>Capital</span>
            <strong translate='no'>{RichText.asText(capitalInfo.capital_name)}</strong>
          </div>
        </Link>
        <Tabs defaultActiveKey="0" className="tabs__default">
          {departmentInfo.body.map((item, k) => (
            <TabPane tab={getTabByType(item.slice_type)} key={k}>
              {getSliceByType(item)}
            </TabPane>
          ))}
        </Tabs>
      </div>
      <LeaveNoTrace />
    </div>
  )
}