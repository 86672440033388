// @constants
import { ROUTES } from 'constants/routes'
import { ACTIVITIES } from './activities'

export const FOOTER_ITEM_FOR_EVERYBODY = 'FOOTER_ITEM_FOR_EVERYBODY'

export const FOOTER_ITEMS = [
  {
    title: 'Noomada',
    column: 2,
    items: [
      {
        path: ROUTES.ABOUT_US,
        title: 'Nosotros'
      },
      {
        path: ROUTES.BLOG,
        title: 'Blog'
      },
      {
        path: ROUTES.LNT,
        title: 'No Deje Rastro'
      },
      {
        path: ROUTES.DEPARTMENTS,
        title: 'Departamentos'
      },
      {
        path: ROUTES.PATRIMONY_TOWNS,
        title: 'Pueblos Patrimonio'
      },
      {
        path: ROUTES.NATIONAL_PARKS,
        title: 'Parques Nacionales Naturales'
      },
      {
        path: ROUTES.CONTACT,
        title: 'Hablemos'
      }
    ]
  },
  {
    title: 'Para todos los gustos',
    column: 2,
    items: ACTIVITIES,
    clickable: true,
    type: FOOTER_ITEM_FOR_EVERYBODY
  }
]
