// @vendors
import { Button, Modal } from 'antd'
import { useState, useEffect } from 'react'
import cx from 'classnames'

// @utils
import { convertToSlug } from 'utils/format'

export default function ModalSteps({
  visible,
  onClose,
  data,
  clearData,
  origin
}) {
  const [showForm, setShowForm] = useState(false)
  const [showOriginForm, setShowOriginForm] = useState(false)

  const formProps = {
    register: {
      openLoginModal: () => setShowOriginForm(true),
      goToFullRegisterForm: () => {
        setShowOriginForm(true)
      }
    }
  }

  useEffect(() => {
    if (!visible) {
      setShowForm(false)
      clearData()
      setShowOriginForm(false)
    }
  }, [visible])

  useEffect(() => {
    if (origin === 'login') {
      setShowOriginForm(true)
    }
  }, [origin])

  return (
    <Modal
      footer={null}
      title={null}
      width='400px'
      centered
      wrapClassName={
        cx(
          'modal__default',
          'modal__default--full-mobile',
          `modal__steps modal__steps-${data.customClass}`,
          { 'modal__steps-show-form': showForm }
        )
      }
      visible={visible}
      onCancel={() => {
        onClose()
      }}
    >
      <div className='step__message' style={{ backgroundImage: `url(${data.imageUrl})` }}>
        {data.svg}
        <h3 className='title__ppal sm c--dr'>
          <strong>{data.title}</strong>
          <span>{data.subtitle}</span>
        </h3>
        <div className='text__enr'>
          {data.message}
        </div>
        {data.items && (
          <ul className='step__message-features'>
            {data.items.map((item) => (
              <li key={convertToSlug(item.text)} className={`${item.pro && 'pro'}`}>
                <em className={`fal fa-${item.iconName}`} />
                <span className='text'>
                  {item.text}
                </span>
                {item.pro && (
                  <div className='tag'>
                    Pro
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        <div className='step__message-actions'>
          <Button className='solid c-1 ant-btn-send' onClick={() => setShowForm(!showForm)}>
            {data.buttonText}
          </Button>
          {data.customClass === 'register' && (
            <Button className='ant-btn-only--link' onClick={() => setShowOriginForm(!showOriginForm)}>
              Iniciar sesión
            </Button>
          )}
        </div>
      </div>
      <div className='step__fields'>
        {data.form && data.form(formProps[data.customClass])}
      </div>

      <Button
        className='solid c-wh ant-btn-only--icon ant-btn-prev'
        onClick={() => setShowForm(!showForm)}
      >
        <em className='fal fa-chevron-left' />
      </Button>
    </Modal>
  )
}
